export const translations = {
  kh: {
    "refresh the page": "សូមបើកទំព័រសាឡើងវិញម្ដងទៀត",
    "Ooops, something went wrong... Please try to {refresh}":
      "អូ! ចំណុចណាមួយមានបញ្ហា...សូមព្យាយាម {refresh}",
    Male: "ប្រុស",
    Female: "ស្រី",
    "Prefer not to say": "ជ្រើសរើសមិនឆ្លើយតប",
    "Non-Binary": "មិនប្រកាន់ខ្ចាប់ថាជាភេទប្រុស និងស្រ្តី",
    "Passport Include": "រួមបញ្ចូលលិខិតឆ្លងដែន",
    "Passport Not Include": "មិនរួមបញ្ចូលលិខិតឆ្លងដែន",
    Myanmar: "មីយ៉ាន់ម៉ា",
    Thailand: "ថៃ",
    Cambodia: "កម្ពុជា",
    Laos: "ឡាវ",
    Nepal: "ប្រទេសនេប៉ាល់",
    Indonesia: "ឥណ្ឌូនេស៊ី",
    Indian: "ឥណ្ឌា",
    Bangladeshi: "បង់ក្លាដែស",
    Others: "ផ្សេងទៀត",
    Sunday: "ថ្ងៃអាទិត្យ",
    Monday: "ថ្ងៃច័ន្ទ",
    Tuesday: "ថ្ងៃអង្គារ",
    Wednesday: "ថ្ងៃពុធ",
    Thursday: "ថ្ងៃព្រហស្បតិ៍",
    Friday: "ថ្ងៃសុក្រ",
    Saturday: "ថ្ងៃសៅរ៍",
    "It Will Vary": "វានឹងប្រែប្រួល",
    Monthly: "ប្រចាំខែ",
    "Daily Wage Rate": "អត្រាប្រាក់ឈ្នួលប្រចាំថ្ងៃ",
    "Once A Month": "មួយខែម្ដង",
    "Twice A Month": "មួយខែពីរដង",
    "Every Week": "រៀងរាល់សប្ដាហ៍",
    "Every 10 days": "រៀងរាល់ ១០ ថ្ងៃ",
    Other: "ផ្សេងទៀត",
    Regularly: "ទៀងទាត់",
    "From time to time": "ពីមួយពេលទៅមួយពេល",
    Infrequently: "ញឹកញាប់",
    Never: "មិនដែល",
    Day: "ថ្ងៃ",
    Swing: "ប្ដូរវេន",
    Night: "យប់",
    Rotating: "ផ្លាស់ប្ដូរ",
    Variable: "ប្រែប្រួល",
    "Full-time": "ពេញម៉ោង",
    "Part-time": "ក្រៅម៉ោង",
    "Moved General Waitlist": "បញ្ជីរង់ចាំទូរទៅបានផ្លាស់ប្ដូរ",
    Waitlisted: "បញ្ជីរង់ចាំ",
    "Contract Signed": "បានចុះកិច្ចសន្យាការងារ",
    Accepted: "ត្រូវបានទទួល",
    Applied: "បានដាក់ពាក្យស្នើរសុំរួចរាល់",
    Downloaded: "ត្រូវបានទាញយក",
    Offered: "ត្រូវបានផ្ដល់ជូន",
    Rejected: "ត្រូវបានបដិសេធ",
    Shortlisted: "ត្រូវបានសម្រាំងយក",
    Viewed: "ត្រូវបានពិនិត្យមើល",
    "Documents Requested": "ឯកសារត្រូវបានស្នើរសុំ",
    "Documents request accepted": "ឯកសារស្នើរសុំត្រូវបានទទួលយក",
    "Documents request rejected": "ឯកសារស្នើរសុំត្រូវបានបដិសេធ",
    "HR Assistant": "ជំនួយការផ្នែកធនធានមនុស្ស",
    "HR Interpreter": "អ្នកបកប្រែផ្នែកធនធានមនុស្ស",
    "HR Manager": "អ្នក​គ្រប់គ្រង​ផ្នែកធនធានមនុស្ស",
    "HR Officer": "មន្រ្តីធនធានមនុស្ស",
    "Candidate pays fees in advance, then is reimbursed by company after starting work":
      "បេក្ខជនបង់ថ្លៃជាមុន បន្ទាប់មកនឹងត្រូវសងត្រលប់ទៅវិញដោយក្រុមហ៊ុននៅពេលចាប់ផ្តើមការងារ",
    "Company pays all fees in advance": "ក្រុមហ៊ុនបង់ថ្លៃសេវាទាំងអស់ជាមុន",
    "India (+91)": "ប្រទេសឥណ្ឌា (+91)",
    "Myanmar (+95)": "មីយ៉ាន់ម៉ា (+95)",
    "Nepal (+977)": "នេប៉ាល់ (+977)",
    "Thailand (+66)": "ថៃ (+66)",
    "Malaysia (+60)": "ម៉ាឡេស៊ី (+60)",
    "Cambodia (+855)": "កម្ពុជា (+855)",
    "A Little Bit": "ចេះបន្តិចបន្តួច",
    Moderately: "ចេះខ្លះៗ",
    "Very Well": "ចេះស្ទាត់ជំនាញ",
    None: "មិនមែនទេ",
    Some: "ខ្លះៗ",
    Proficient: "មានជំនាញ",
    English: "ភាសាអង់គ្លេស",
    Thai: "ថៃ",
    "Bahasa Malaysia": "ម៉ាឡេ",
    Hindi: "ហិណ្ឌូ",
    "Cannot read": "មិនអាចអានបានទេ",
    APPLICABLE: "ចុចដាក់ពាក្យទីនេះ",
    APPLIED: "បានដាក់ពាក្យ",
    VIEWED: "បានពិនិត្យមើល",
    DOWNLOADED: "បានទាញយក",
    SHORTLISTED: "បានសម្រាំងយក",
    OFFERED: "ការងារត្រូវបានផ្តល់ជូន",
    ACCEPTED: "យល់ព្រមទទួលការងារដែលផ្តល់ជូន",
    REJECTED_BY_BUSINESS: "ត្រូវបានបដិសេធដោយអ្នកជ្រើសរើស",
    REJECTED_BY_APPLICANT: "ត្រូវបានបដិសេធដោយបេក្ខជនដាក់ពាក្យ",
    CONTRACT_SIGNED: "បានចុះកិច្ចសន្យា",
    APPLICANT_NA: "មិនមានបេក្ខជន",
    WAITLISTED: "ត្រូវបានដាក់ចូលក្នុងបញ្ជីរង់ចាំ",
    MOVED_GENERAL_WAITLIST: "ត្រូវបានបញ្ចូនទៅបញ្ជីរង់ចាំទូទៅ",
    "STATUS N/A": "ស្ថានភាពមិនមាន",
    "Cannot speak": "មិនអាចនិយាយបាន",
    "Network Error!": "ប្រព័ន្ធមានបញ្ហា!",
    "User with this username already exists":
      "ឈ្មោះអ្នកប្រើប្រាស់នេះមានអ្នកប្រើរួចហើយ។ សូមសាកល្បងឈ្មោះផ្សេងទៀត",
    "You are trying to login with Business User Account !":
      "អ្នកកំពុងតែចូលក្នុងគណនីប្រើប្រាស់សម្រាប់អាជីវកម្ម!",
    "There was an authentication problem": "មានបញ្ហាក្នុងការផ្ទៀងផ្ទាត់",
    Applicable: "អាចដាក់ពាក្យស្នើរសុំបាន",
    "Not Applicable": "មិនអាចដាក់ពាក្យស្នើរបាន",
    "N/A": "មិនមាន",
    "Click here for Registration.": "សូមចុចចុះឈ្មោះទីនេះ",
    "No Focal Person contact provided": "មិនមានអ្នកដែលអាចទំនាក់ទំនងបាន",
    "Some features might not work in offline mode":
      "មុខងារខ្លះមិនអាចដំណើរការបានទេ ពេលគ្មានអ៊ីនធឺណេត",
    "New version is available!": "យើងបានធ្វើកំណែទម្រង់ថ្មីរួចរាល់ហើយ!",
    "Please click refresh the app button to switch to a newer version.":
      'សូមចុចលើប៊ូតុង "ធ្វើឱ្យកម្មវិធីប្រសើរឡើង" ដើម្បីផ្លាស់ប្ដូរទៅកាន់កំណែទម្រង់ថ្មី ',
    "Updating..": "កំពុងធ្វើបច្ចុប្បន្នភាព...",
    "Refresh the app": "សូមបើកកម្មវិធីសាឡើងវិញម្ដងទៀត",
    Polls: "ការស្ទង់មតិ",
    News: "ព័ត៌មាន",
    Home: "ទំព័រដើម",
    Guide: "ការណែនាំ",
    Groups: "ក្រុមពិភាក្សា",
    Like: "ចូលចិត្ដ",
    Notifications: "ការជូនដំណឹង",
    moderator: "សុបិន្ដ​ ឥស្សរា",
    Anonymous: "អនាមិក",
    "Delete comment": "លុបមតិយោបល់",
    "Unhide comment": "បង្ហាញមតិយោបល់ឡើងវិញ",
    "Hide comment": "មិនបង្ហាញមតិយោបល់",
    Reply: "ឆ្លើយតប",
    Report: "រាយការណ៍",
    "Report inappropriate comment": "រាយការណ៍អំពីការបញ្ចេញមតិមិនសមរម្យ",
    "Text of a comment:": "សារនៃការបញ្ចេញមតិ:",
    "Please write a reason...": "សូមសរសេរមូលហេតុ...",
    Close: "បិទ",
    "Replying to {username}": "{username} लाई जवाफ दिँदै",
    "Write a reply...": "សរសេរសារឆ្លើយតប..​.",
    "Write a comment...": "សរសេរមតិយោបល់...",
    "Post a comment": "ដាក់បង្ហាញមតិយោបល់របស់អ្នក",
    Comments: "ការបញ្ចេញមតិយោបល់",
    "Go to Last Comment": "ចូលទៅកាន់មតិយោបល់ចុងក្រោយ",
    "Fetching comments ...": "កំពុងទាញយកការបញ្ចេញមតិយោបល់...",
    "Be a first person to comment on this post":
      "បញ្ចេញមតិយោបល់មុនដំបូងគេបង្អស់ទៅលើការដាក់បង្ហោះនេះ",
    "Commented: ": "បានបញ្ចេញមតិ",
    "Post comment": "ដាក់បង្ហាញមតិយោបល់",
    "replied to {recipient}": "ឆ្លើយតបទៅកាន់ {recipient}",
    Discuss: "ពិភាក្សា",
    new: "ថ្មី",
    "Published: {date}": "ចេញផ្សាយ {date}",
    Jan: "មករា",
    Feb: "កុម្ភះ",
    Mar: "មីនា",
    Apr: "មេសា",
    May: "ឧសភា",
    Jun: "មិថុនា",
    Jul: "កក្កដា",
    Aug: "សីហា",
    Sep: "កញ្ញា",
    Oct: "តុលា",
    Nov: "វិច្ឆិកា",
    Dec: "ធ្នូ",
    Save: "រក្សាទុក",
    Back: "ត្រលប់ក្រោយ",
    "Overall:": "ជាទូទៅៈ",
    Operational: "ប្រតិបត្តិការ",
    "Temporarily Suspended": "ផ្អាកបណ្ដោះអាសន្ន",
    "from: {date}": "ពី {date}",
    "to: {date}": "រហូតដល់ {date}",
    "Permanently Blacklisted": "បញ្ជូលក្នុងបញ្ជីខ្មៅជាអចិន្ត្រៃយ៍",
    "License is not extended": "អាជ្ញាបណ្ណមិនត្រូវបានបន្ដ",
    "Thai License Number": "លេខអាជ្ញាបណ្ណនៅប្រទេសថៃ",
    "License number": "លេខអាជ្ញាបណ្ណ",
    Email: "អ៊ីម៉ែល",
    "Your application will expire at": "ពាក្យស្នើសុំរបស់អ្នកនឹងត្រូវផុតកំណត់នៅ",
    "Recruitment Agencies": "ភ្នាក់ងារជ្រើសរើសពលករ",
    Employers: "និយោជក",
    "Employer Roster": "បញ្ជីឈ្មោះនិយោជកទទួលពាក្យការងារមុន",
    "Recruiter Roster": "បញ្ជីឈ្មោះភ្នាក់ជ្រើសរើសទទួលពាក្យការងារមុន",
    "Reset Filter": "កំណត់ការសម្រាំងរើសឡើងវិញ",
    "Search...": "ស្វែងរក​...",
    Filter: "ការសម្រាំងយក",
    "Select Industry": "ជ្រើសរើសប្រភេទឧស្សាហកម្ម​",
    "All industries": "គ្រប់ឧស្សាហកម្មទាំងអស់",
    "{type} country": "{type}ប្រទេស​",
    "Sort By": "ជ្រើសរើសតាមប្រភេទ​",
    "Rating: Highest": "ការវាយតម្លៃ: ខ្ពស់បំផុត",
    "Rating: Lowest": "ការវាយតម្លៃ: ទាបបំផុត",
    Popularity: "ពេញនិយម",
    "Order By": "តាមលំដាប់លំដោយដោយ",
    "Posted: Newest": "បានដាក់បង្ហាញ៖  ថ្មីបំផុត",
    "Posted: Oldest": "បានដាក់បង្ហាញ៖ យូរបំផុត",
    cm: "ស.ម",
    ft: "ហ្វីត",
    in: "អ៊ីង",
    kg: "គ.ក",
    lbs: "ផោន",
    "Recruitment closed!": "ការប្រកាសជ្រើសរើសបានបិទ!",
    "Recruitment Expired!": "ការជ្រើសរើសត្រូវបានផុតកំណត់!​",
    "{salary} {currency}/month": "{salary} {currency}/ខែ",
    "{daily_wage_rate} {currency}/day": "{daily_wage_rate} {currency}/ថ្ងៃ",
    "Have Employer Pays Policy": "មានគោលនយោបាយនិយោជកចេញថ្លៃក្នុងការជ្រើសរើស",
    "Only job seekers in Myanmar/Cambodia/Nepal can apply":
      "អ្នកដែលរស់នៅប្រទេសកម្ពុជាតែប៉ុណ្ណោះទើបអាចដាក់ពាក្យស្នើសុំបាន",
    "Only job seekers in Thailand can apply":
      "អ្នកដែលរស់នៅប្រទេសថៃតែប៉ុណ្ណោះទើបអាចដាក់ពាក្យស្នើសុំបាន",
    "Recruitment Agency Job": "ការងារប្រកាសរើសដោយទីភ្នាក់ងារជ្រើសរើសពលករ",
    "Employer Job": "ការងារដាក់បង្ហាញដោយនិយោជក",
    "Only job seekers in {country} can apply":
      "មានតែអ្នកស្វែងរកការងារធ្វើដែលរស់នៅប្រទេស  {country}  តែប៉ុណ្ណោះទើបអាចដាក់ពាក្យស្នើសុំបាន",
    Send: "បញ្ជូន",
    "Add & Verify Phone Number!": "ការបញ្ចូល និងបញ្ជាក់លេខទូរសព្ទ!",
    VERIFICATION: "ការបញ្ជាក់",
    "Update & Verify Phone Number!":
      "ធ្វើការកែតម្រូវ និងផ្ទៀងផ្ទាត់លេខទូរស័ព្ទ!",
    "{minutes} minute(s) and {seconds} second(s)":
      "{នាទី} នាទី និង {វិនាទី} វិនាទី",
    "This user has already been verified with the provided phone number.":
      "អ្នកប្រើប្រាស់នេះត្រូវបានផ្ទៀងផ្ទាត់ជាមួយលេខទូរស័ព្ទដែលបានផ្តល់រួចមកហើយ",
    "Error sending OTP!": "បញ្ហាបច្ចេកទេសក្នុងការផ្ញើលេខកូដOTP!",
    "Verification Failed! The number you entered has already been verified with another user account. Check the number again.":
      "ការផ្ទៀងផ្ទាត់មិនជោគជ័យទេ! លេខដែលអ្នកបានបញ្ចូលត្រូវបានប្រើជាមួយគណនីអ្នកប្រើប្រាស់ផ្សេងទៀតរួចហើយ សូមពិនិត្យមើលេខម្តងទៀត",
    "Please enter valid OTP!": "សូមបញ្ចូលលេខកូដOTP!",
    "Your phone number is successfully verified !":
      "លេខទូរសព្ទរបស់អ្នកត្រូវបានជោគជ័យក្នុងការបញ្ជាក់!",
    "OTP confirmation failed !": "ការបញ្ជាក់លេខកូដOTP មិនត្រឹមត្រូវទេ!",
    "Please complete the form !": "សូមបំពេញនូវបែបបទពាក្យបំពេញនេះ!",
    "Please Complete These Steps!": "សូមបំពេញតាមជំហ៊ានទាំងនេះ",
    Gender: "ភេទ",
    Nationality: "សញ្ជាតិ",
    Country: "ប្រទេស",
    "Congratulations your profile is updated!":
      "អបអរសាទរប្រូហ្វាលរបស់អ្នកគឺត្រូវបានអាប់ដេត!",
    "Phone number": "លេខទូរស័ព្ទ",
    required: "តម្រូវឱ្យមាន",
    Select: "ជ្រើសរើស",
    "Select the country code": "ជ្រើសរើសលេខកូដប្រទេស",
    "Please select a country code": "សូមជ្រើសរើសលេខកូដប្រទេស",
    "Please remove any leading zeros when entering your phone number. For example, if your phone number is 0827474736, enter it as 827474736.":
      "សូមលុបលេខសូន្យដែលនៅខាងមុខនៅពេលបញ្ចូលលេខទូរសព្ទ ជាឧទាហរណ៍៖ ប្រសិនបើលេខទូរសព្ទរបស់អ្នក 0827474736 ​ត្រូវបញ្ចូលត្រឹមតែ 827474736",
    "Enter your phone number": "បញ្ចូលលេខទូរស័ព្ទរបស់អ្នក",
    "Enter the phone number you always use. Do not include the country code, such as (+95, +66, +977, +855, +60), here again.":
      "បញ្ចូលលេខទូរសព្ទដែលអ្នកកំពុងប្រើ មិនចាំបាច់បញ្ចូលលេខកូដប្រទេស(+95 +66 +977 +855 +60) នៅទីនេះម្តងទៀតទេ",
    "Please enter valid phone number":
      "សូមបញ្ចូលលេខទូរស័ព្ទដែលអ្នកកំពុងប្រើប្រាស់",
    "Enter OTP:": "បញ្ចូលលេខកូដ OTP:",
    "Enter 6 digits OTP": "បញ្ចូលលេខកូន OTP 6ខ្ទង់",
    "Enter OTP": "បញ្ចូលលេខកូដ OTP",
    "Your OTP will expire in {expiryTime}":
      "លេខកូដ OTP នឹងផុតកំណត់​​នៅ​ {expiryTime}",
    "Please select gender": "សូមជ្រើសរើសភេទ",
    "Please select nationality": "សូមជ្រើសរើសសញ្ជាតិ",
    "Please select Country of residence": "សូមជ្រើសរើសប្រទេសដែលអ្នកស្នាក់នៅ",
    "Country of residence": "ប្រទេសដែលអ្នកស្នាក់នៅបច្ចុប្បន្ន",
    "Get OTP": "ទទួលលេខកូដ OTP",
    "Update & Get OTP": "ធ្វើការកែប្រែ នឹងទទួលលេខកូដOTP",
    "Add & Get OTP": "ការបញ្ចូល និងទទួលលេខកូដOTP",
    "Add Phone Number": "សូមបញ្ចូលលេខទូរសព្ទ",
    "Confirm OTP": "ការបញ្ជាក់លេខកូដ OTP",
    "Resend OTP": "ការផ្ញើលេខកូដOTP ឡើងវិញ",
    Previous: "ទំព័រមុន",
    Next: "បន្ទាប់",
    Submit: "ដាក់បញ្ជួន",
    "Submit Resume": "ដាក់បញ្ជួនប្រវត្តិរូបសង្ខេប",
    "Permission & Remark": "ការអនុញ្ញាត្ត និង កំណត់សម្គាល់",
    "This Recruiter might need to see your documents and information to confirm you are eligible for the job.":
      "ភ្នាក់ងារជ្រើសរើសត្រូវការពិនិត្យមើលឯកសាររបស់អ្នកដើម្បីបញ្ជាក់ថាអ្នកមានលក្ខណគ្រប់គ្រាន់សម្រាប់ការងារ",
    "This Employer might need to see your documents and information to confirm you are eligible for the job.":
      "និយោជកត្រូវការពិនិត្យមើលឯកសារដើម្បីបញ្ជាក់ថាអ្នកមានលក្ខណគ្រប់គ្រាន់សម្រាប់ការងារ",
    "You can delete your documents and any personal information anytime if you desire to do so. Do you understand this and consent to letting them look at your resume and documents?":
      "អ្នកអាចលុបឯកសារ និងព័ត៌មានផ្ទាល់ខ្លួនរបស់អ្នកបានគ្រប់ពេលប្រសិនបើអ្នកចង់លុប។ តើអ្នកបានយល់ និងយល់ព្រមឱ្យពួកគេមើលប្រវត្តិរូបសង្ខេប និងឯកសាររបស់អ្នកទេ?",
    Remark: "កំណត់សម្គាល់",
    "Enter remark": "បញ្ចូលកំណត់សម្គាល់",
    Cancel: "បោះបង់",
    "Submitting Resume": "ដាក់បញ្ជួនប្រវត្តិរូបសង្ខេប ",
    "Confirmation!": "ការបញ្ជាក់!",
    "Are you sure want to withdraw this application? Your application will no longer be seen in {business_name}'s list.":
      "តើអ្នកពិតជាចង់ដកពាក្យស្នើសុំការងារនេះមែនទេ? ពាក្យស្នើសុំរបស់អ្នកនឹងមិនមាននៅក្នុងបញ្ជី {recruiter_name} ទៀតទេ",
    "Withdrawing...": "ដកពាក្យស្នើសុំ...",
    "Yes, withdraw": "បាទ/ចាស៎ ដកពាក្យស្នើសុំ",
    "Ask a question": "សួរសំណួរ",
    "Your question goes here...": "សំណួររបស់អ្នកបង្ហាញនៅទីនេះ...",
    "Submit a question": "ដាក់បញ្ជូនសំនួរ",
    "Successfully gave the ratings!": "បានវាយតម្លៃដោយជោគជ័យ!",
    "Loading...": "កំពុងដំណើរការ...",
    "Submit rating": "ដាក់បញ្ជួនការវាយតម្លៃ",
    more: "បន្ថែម",
    "Share App": "ចែករំលែក កម្មវិធីទូរសព្ទ",
    Share: "ចែករំលែក",
    Reactions: "រីអែក",
    "Username or email": "ឈ្មោះអ្នកប្រើប្រាស់ ឬ អ៊ីម៉ែល",
    Password: "លេខសំងាត់",
    "Show Password": "បង្ហាញពាក្យសម្ងាត់",
    Login: "ចូល",
    "Successfully sent the resume": "ដាក់បញ្ជូនប្រវត្តិរូបសង្ខេបបានដោយជោគជ័យ",
    "Couldn't sent the resume due to server error":
      "មិនអាចដាក់បញ្ជូនប្រវត្តិរូបសង្ខេបបាន ដោយសារប្រព័ន្ធមានបញ្ហា",
    "Successfully extended the application !":
      "បានបន្តសុពលភាពពាក្យស្នើសុំដោយជោគជ័យ!",
    "You already sent your cv to this employer":
      "អ្នកបានបញ្ជូនប្រវត្តិរូបសង្ខេបរបស់អ្នកទៅទីភ្នាក់ងារជ្រើសរើសនេះរួចមកហើយ",
    "Withdraw application": "ដកពាក្យស្នើសុំ",
    "Extending...": "បន្តពាក្យស្នើសុំ...",
    "Extend application": "បន្តពាក្យស្នើសុំ",
    "Employer viewed your application":
      "ភ្នាក់ងារជ្រើសរើសបានពិនិត្យមើលពាក្យស្នើសុំរបស់អ្នក",
    "Employer Downloaded your resume":
      "ភ្នាក់ងារជ្រើសរើសបានទាញយកប្រវត្តិរូបសង្ខេបរបស់អ្នក",
    "Employer Moved your application to General Waitlist. Please wait Until he/she will shortlist you again for future job post.":
      "និយោជកបានបញ្ចូនពាក្យស្នើសុំរបស់អ្នកទៅក្នុងបញ្ជីរង់ចាំទូទៅ​ សូមរង់ចាំរហូតដល់មានការសម្រាំងយកម្តងទៀតសម្រាប់ការប្រកាសជ្រើសរើសនាពេលខាងមុខ",
    "Employer Shortlisted your application":
      "ភ្នាក់ងារជ្រើសរើសបានសម្រាំងយកពាក្យស្នើសុំរបស់អ្នក",
    "You cannot send your resume to this employer as you are not residing in same country with this employer":
      "អ្នកមិនអាចបញ្ជូនប្រវត្តិរូបទៅកាន់និយោជកនេះបានទេ ដោយសារអាស័យដ្ធានបច្ចុប្បន្នរបស់អ្នកមិននៅក្នុងប្រទេសដូចនិយោជកនេះ.",
    "Successfully withdrew the application !": "បានដកពាក្យស្នើសុំដោយជោគជ័យ !",
    "Couldn't withdraw the application due to server error":
      "មិនអាចដកពាក្យស្នើសុំបានទេ ដោយសារប្រព័ន្ធមានបញ្ហា",
    "Employer Detail": "ព័ត៌មានលម្អិតពីក្រុមហ៊ុន",
    Description: "ការពិពណ៌នា",
    "Registered:": "បានចុះឈ្មោះរួចរាល់:",
    "License number:": "លេខអាជ្ញាបណ្ណ:",
    "Valid to:": "មានសុពលភាពរហូតដល់:",
    "Contact Information": "ព័ត៌មានទំនាក់ទំនង",
    "Rated by ({number}) viewers": "វាយតម្លៃដោយ ({number}) អ្នកមើល",
    Address: "អាសយដ្ឋាន",
    "You can not sent your resume for this employer":
      "អ្នកមិនអាចបញ្ចូនប្រវត្តិរូបសង្ខេបរបស់អ្នកទៅកាន់និយោជកនេះបានទេ",
    "Click here to create a resume": "ចុចទីនេះដើម្បីបង្កើតប្រវត្តិរូបសង្ខេប",
    "Loading..": "កំពុងដំណើរការ...",
    "Sorry, no employers matched filter parameters, please try again.":
      "សូមអភ័យទោស! គ្មាននិយោជកដែលបានរកឃើញក្នុងតំណភ្ជាប់នេះទេ សូមព្យាយាមម្តងទៀត...",
    "Industries:": "ឧស្សាហកម្ម:",
    "Job Opportunities:": "ឱកាសការងារ​",
    "{employer_name} has listed ({number_of_jobs}) job opportunities at marketplace":
      "{employer_name} បានដាក់បញ្ចូល ({number_of_jobs}) ឱកាសការងារនៅលើទីផ្សារការងារ",
    Rating: "ការវាយតម្លៃ",
    "View rating": "ពិនិត្យមើលការវាយតម្លៃ",
    "Give rating": "ផ្ដល់ការវាយតម្លៃ",
    "Please login to write a comment..":
      "សូមចូលទៅគណនីរបស់អ្នក ដើម្បីសរសេរមតិយោបល់...",
    "Change in CMS": "កែប្រែនៅក្នុង CMS",
    "Write a comment here...": "សរសេរមតិយោបល់នៅទីនេះ...",
    "Comment Deleted !": "មតិយោបល់ត្រូវបានលុប !",
    "Delete Comment": "លុបមតិយោបល់",
    "Are you sure you want to delete this comment?":
      "តើអ្នកពិតជាចង់លុបមតិយោបល់នេះ?",
    Delete: "លុប",
    "Edit post": "កែតម្រូវការដាក់បង្ហោះ",
    "Comment Editor": "ការកែតម្រូវមតិយោបល់",
    "Update your comment": "អាប់ដេតមតិយោបល់របស់អ្នក",
    "Write your updated comment here ...":
      "សរសេរមតិយោបល់អាប់ដេតរបស់អ្នកនៅទីនេះ...",
    "Save Changes": "រក្សាទុកការកែប្រែ",
    "Delete post": "លុបមតិយោបល់",
    "Delete Post": "លុបមតិយោបល់",
    "Delete your post?": "លុបមតិយោបល់?",
    "Are you sure you want to delete this post?":
      "តើអ្នកពិតជាចង់លុបការដាក់បង្ហោះនេះ?",
    like: "ចូលចិត្ត",
    s: "ស",
    "Like List": "បញ្ជីចូលចិត្ដ",
    "Post Detail": "លំអិតអំពីការដាក់បង្ហោះ",
    "Go back Top": "ត្រឡប់ទៅខាងលើវិញ",
    "Successfully updated the post !": "បានអាប់ដេតការដាក់បង្ហោះដោយជោគជ័យ !",
    "Post Editor": "ការកែប្រែការដាក់បង្ហោះ",
    "Edit your post": "កែប្រែការដាក់បង្ហោះរបស់អ្នក",
    "Caption:": "ចំណងជើង:",
    "Please write a caption": "សូមសរសេរចំណងជើង",
    "Article:": "ប្រធានបទ:",
    "Please write an article ...": "សូមសរសេរប្រធានបទ ...",
    "Please choose an image to update": "សូមជ្រើសរើសរូបភាពដើម្បីនឹងអាប់ដេត",
    Update: "អាប់ដេត",
    "Edit Post ": "កែការប្រកាស",
    "Edit Post": "កែមតិយោបល់",
    "...See more": "...មើលបន្ថែម",
    "Group Forum": "ក្រុមពិភាគ្សា",
    "No posts found!": "មិនមានការដាក់បង្ហោះត្រូវបានរកឃើញ!",
    "Please login or register new account":
      "សូមចូលទៅកាន់គណនី ឬ ចុះឈ្មោះគណនីថ្មី",
    "Please select image! Image size should be less than 5MB":
      "សូមជ្រើសរើសរូបភាព! រូបភាពគួរមានទំហំតិចជាង5MB",
    "Successfully posted !": "បានដាក់បង្ហោះដោយជោគជ័យ !",
    "What would you like to discuss?": "តើអ្នកចង់ពិភាគ្សាអំពីអ្វី?",
    "Write your discussion here": "សសេរពីការពិភាគ្សារបស់អ្នកទីនេះ",
    "Write discussion here...": "សសេរពីការពិភាគ្សាទីនេះ...",
    "Choose an image": "ជ្រើសរើសរូបភាព",
    "Selected Image": "រូបភាពដែលបានជ្រើសរើស",
    "Remove Image": "ដករូបភាពចេញ",
    Posting: "កំពុងដាក់បង្ហោះ",
    Post: "ដាក់បង្ហាញ",
    Topics: "ប្រធានបទ",
    "All countries": "ប្រទេសទាំងអស់",
    Sort: "ជ្រើសរើសតាមប្រភេទ",
    "A to Z": "ពី ក ទៅ​ អ",
    "Z to A": "ពី អ ទៅ ក",
    "{number} found": "{number}  បានរកឃើញ",
    Search: "ស្វែងរក",
    "Loading ...": "កំពុងដំណើរការ...",
    "Sorry, no groups matched filter parameters, please try again.":
      "សូមអភ័យទោស! គ្មានក្រុមពិភាក្សាដែលបានរកឃើញក្នុងតំណភ្ជាប់នេះទេ សូមព្យាយាមម្តងទៀត...",
    "Please login to write an answer..":
      "សូមចូលទៅគណនីរបស់អ្នក ដើម្បីសរសេរការឆ្លើយតប...",
    "Pending verification": "រង់ចាំការត្រួតពិនិត្យ",
    Guides: "ការណែនាំ",
    "All categories": "គ្រប់ប្រភេទទាំងអស់",
    Category: "ប្រភេទ",
    "Date: Newest": "កាលបរិច្ឆេទ: ថ្មីបំផុត",
    "Date: Oldest": "កាលបរិច្ឆេទ: យូរបំផុត",
    "Sorry, no guides matched filter parameters, please try again.":
      "សូមអភ័យទោស! គ្មានការណែនាំដែលបានរកឃើញក្នុងតំណភ្ជាប់នេះទេ សូមព្យាយាមម្តងទៀត...",
    "Welcome, {name}": "សូមស្វាគមន៍, {name}",
    "new user": "អ្នកប្រើប្រាស់ថ្មី",
    guest: "ភ្ញៀវ",
    "Enable notifications:": "បើកដំណើរការជូនដំណឹងៈ",
    Off: "បិទ",
    On: "បើក",
    Logout: "ចាកចេញ",
    "Job Applications": "ពាក្យស្នើសុំការងារ",
    "Sorry, no job applications found!":
      "សូមអធ្យាស្រ័យ មិនមានពាក្យស្នើសុំការងារត្រូវបានរកឃើញ!",
    Jobs: "ការងារ",
    Industry: "ឧស្សាហកម្ម",
    "Most Popular": "ពេញនិយមបំផុត",
    "Most Applied": "ត្រូវបានដាក់ពាក្យស្នើរសុំច្រើនបំផុត",
    "New Jobs Available !": "មានការងារថ្មីបានប្រកាសជ្រើសរើស!",
    "There are some jobs available at the marketplace now {provinceAndIndustry}, have you checked them out?":
      "មានការងារមួយចំនួនបានប្រកាសជ្រើសរើសនៅលើទីផ្សារការងារឥលូវនេះ {provinceAndIndustry}, តើអ្នកបានចូលមើលហើយឬនៅ?",
    Check: "ឆែកមើល",
    "Job Application Confirmation !": "ការបញ្ជាក់ពីការដាក់ពាក្យស្នើសុំការងារ !",
    "Are you sure you want to apply for this {employer} Company for this position?":
      "តើអ្នកប្រាកដទេថា អ្នកចង់ដាក់ពាក្យសម្រាប់ {employer} ក្រុមហ៊ុននេះ ក្នុងមុខតំណែងនេះ?",
    "Yes, Sure": "បាទ/ចាស៎, ប្រាកដណាស់",
    NRC: "អត្តសញ្ញាណប័ណ្ណ",
    "Household List": "សៀវភៅគ្រួសារ",
    "Passport (PJ)": "លិខិតឆ្លងដែន",
    "Labour Card": "ប័ណ្ណការងារ",
    "Covid Vaccine Certificate": "ប័ណ្ណចាក់វ៉ាក់សាំងកូវីត-១៩",
    Passport: "លិខិតឆ្លងដែន",
    "Identification Card": "អត្តសញ្ញាណប័ណ្ណ",
    "Birth Certificate": "សំបុត្របញ្ជាក់កំណើត",
    "Family Record Book": "សៀវភៅគ្រួសារ",
    "Residence Book": "សៀវភៅស្នាក់នៅ",
    "Work Permit": "បណ្ណអនុញ្ញាតការងារ",
    Visa: "ទិដ្ឋាការ/វីសា",
    "Pink Card": "បណ្ណស៊ីជម្ពូរ",
    "Cancellation Letter": "លខិតលុបឈ្មោះចេញពីកន្លែងការងារ",
    "Successfully accepted job offer": "ទទួលយកការងារដែលផ្ដល់អោយ",
    "Couldn't accept due to server error":
      "ការងារដែលផ្ដល់អោយមិនអាចទទួលយកបាន ដោយសារប្រព័ន្ធមានបញ្ហា",
    "Network error, please try again later":
      "បញ្ហាបច្ទេកទេសក្នុងប្រព័ន្ធ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ!",
    "Unexpected error, please try again":
      "បញ្ហាបច្ទេកទេសក្នុងប្រព័ន្ធ សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ!",
    "Loading…": "កំពុងដំណើរការ",
    Apply: "ដាក់ពាក្យស្នើរសុំ",
    "Sorry you cannot apply for this job. This job is for the jobseekers who live in {country}.":
      "សូមអភ័យទោស លោកអ្នកមិនអាចដាក់ពាក្យស្នើសុំការងារនេះបានទេ ដោយសារតែការងារនេះគឺសម្រាប់អ្នកដែលកំពុងរស់នៅប្រទេស {country} តែប៉ុណ្ណោះ",
    "Sorry you cannot apply job now as current jobs are for those in Thailand.":
      "សូមអភ័យទោស លោកអ្នកមិនអាចដាក់ពាក្យស្នើសុំការងារបានទេ ដោយសារតែការងារនេះគឺសម្រាប់តែអ្នកដែលកំពុងរស់នៅប្រទេសថៃតែប៉ុណ្ណោះ",
    "Click here to create a resume to apply for this job":
      "ចុចទីនេះដើម្បីបង្កើតប្រវត្តិរូបសង្ខេបសម្រាប់ដាក់ពាក្យស្នើសុំការងារនេះ",
    "Successfully rejected job offer": "ត្រូវបានបដិសេធការងារដែលផ្ដល់អោយ",
    "Couldn't reject due to server error":
      "មិនអាចធ្វើការបដិសេធការងារដែលផ្ដល់អោយ ដោយសារប្រព័ន្ធមានបញ្ហា",
    "Confirm Reject Job Offer": "បញ្ជាក់អំពីការបដិសេធការងារដែលផ្ដល់អោយ",
    "Are you sure you would like to reject the job offer? This action cannot be undone later.?":
      "តើអ្នកពិតជាចង់បដិសេធការងារដែលផ្ដល់អោយមែនទេ? អ្នកនឹងមិនអាចត្រលប់ក្រោយបានទេ ក្រោយពីអ្នកបានបដិសេធ?",
    Confirm: "បញ្ជាក់",
    "Rejection Reason": "មូលហេតុនៃការបដិសេធការងារដែលផ្ដល់អោយ",
    Details: "ព័ត៌មានលំអិត",
    Posted: "បានដាក់បង្ហាញ",
    "Closing date": "កាលបរិច្ឆេទនៃការបិទ",
    "Employer Pays Policy": "គោលនយោបាយនិយោជកចេញថ្លៃក្នុងការជ្រើសរើស",
    "Employer does not have Employer Pays Policy":
      "និយោជកមិនមានគោលនយោបាយនិយោជកចេញថ្លៃក្នុងការជ្រើសរើស",
    "Employer Size": "ទំហំក្រុមហ៊ុន",
    "Nationalities of Workers working at this workplace": "សញ្ជាតិនៃពលករ",
    "Number of Workers Needed": "ចំនួនពលករដែលត្រូវការ",
    "Number of Male Headcount Needed": "ចំនួនពលករបុរសដែលត្រូវការ",
    "Number of Female Headcount Needed": "ចំនួនពលករស្រីដែលត្រូវការ",
    "Available Shifts": "វេនការងារដែលមាន",
    "Overtime Expectation": "ការងារថែមម៉ោងដែលរំពឹងទុក",
    "Minimum Working Hours": "ម៉ោងធ្វើការតិចបំផុត",
    "Day of Week Off": "ថ្ងៃឈប់សម្រាកប្រចាំសប្តាហ៍",
    "Job Description": "ការពណ៌នាអំពីការងារ",
    "Job Requirements": "លក្ខខណ្ឌការងារ",
    "Job Industry": "ប្រភេទឧស្សាហកម្ម",
    "Job Position Category": "ប្រភេទមុខតំណែងការងារ",
    "Job Position Details": "ព័ត៌មានលំអិតអំពីមុខតំណែង",
    "Terms of Payment": "អំពីប្រាក់ឈ្នួល",
    "Timing of Payment": "ពេលវេលានៃការផ្ដល់ប្រាក់ឈ្នួល",
    "Timing of Payment Other": "ពេលវេលានៃការផ្ដល់ប្រាក់ឈ្នួលផ្សេងទៀត",
    Salary: "ប្រាក់ខែ",
    "{amount} {currency}/month": "{amount} {currency}/ខែ",
    "{amount} {currency}/day": "{amount} {currency}/ថ្ងៃ",
    "Job Type": "ប្រភេទការងារ",
    "Contract Duration": "រយៈពេលនៃកិច្ចសន្យាការងារ",
    "{duration} months": "{duration} ខែ",
    "Regular OT Rate": "អត្រាប្រាក់ឈ្នួលនៃការថែមម៉ោងទូទៅ",
    "{amount} {currency}/hour": "{amount} {currency}/ម៉ោង",
    "Holiday OT Rate":
      "អត្រាប្រាក់ឈ្នួលនៃការថែមម៉ោងក្នុងថ្ងៃឈប់សម្រាកបុណ្យជាតិ",
    "Holiday Rate": "អត្រាប្រាក់ឈ្នួលថ្ងៃឈប់សម្រាកបុណ្យជាតិ",
    "ER information": "ព័ត៌មានអំពីការជ្រើសរើសប្រកបដោយក្រមសីលធម៌វិជ្ជាជីវៈ",
    "Ethical recruitment policy": "គោលនយោបាយនៃការជ្រើសរើសប្រកបដោយក្រមសីលធម៌",
    "Not Have Ethical recruitment policy":
      "មិនមានគោលនយោបាយនៃការជ្រើសរើសប្រកបដោយក្រមសីលធម៌",
    "RA has Ethical recruitment policy":
      "ទីភ្នាក់ងារជ្រើសរើសពលករមានគោលនយោបាយនៃការជ្រើសរើសប្រកបដោយក្រមសីលធម៌",
    "RA has not the Ethical recruitment policy":
      "ទីភ្នាក់ងារជ្រើសរើសពលករមិនមានគោលនយោបាយនៃការជ្រើសរើសប្រកបដោយក្រមសីលធម៌ទេ",
    "Employer pays policy": "គោលនយោបាយនិយោជកចេញថ្លៃក្នុងការជ្រើសរើស",
    "Not have Employer pays policy":
      "មិនមានគោលនយោបាយនិយោជកចេញថ្លៃក្នុងការជ្រើសរើស",
    "Recruitment Information": "ព័ត៌មានអំពីការជ្រើសរើស",
    "Not have Ethical recruitment policy":
      "មិនមានគោលនយោបាយនៃការជ្រើសរើសប្រកបដោយក្រមសីលធម៌",
    "The workers have to pay visa fee": "ពលករត្រូវចេញថ្លៃទិដ្ឋាការ/វីសារ",
    "Employer pays for the visa fees": "និយោជកចេញថ្លៃទិដ្ឋាការ/វីសា",
    "The workers have to pay work permit fee":
      "ពលករត្រូវចេញថ្លៃធ្វើប័ណ្ណអនុញ្ញាតការងារ",
    "Employer pays for the work permit fees":
      "និយោជិកចេញថ្លៃធ្វើប័ណ្ណអនុញ្ញាតការងារ",
    "The workers have to pay medical checkup fee":
      "ពលករត្រូវចេញថ្លៃពិនិត្យសុខភាព",
    "Employer pays for the medical checkup fees": "និយោជិកចេញថ្លៃពិនិត្យសុខភាព",
    "Skill Training Information": "ព័ត៌មានអំពីការបណ្ដុះបណ្ដាលជំនាញ",
    "Employer pays Cost of skill-training":
      "និយោជកចេញថ្លៃនៃវគ្គបណ្ដុះបណ្ដាលជំនាញ",
    "The workers need to pay Cost of skill-training":
      "ពលករត្រូវចេញថ្លៃនៃវគ្គបណ្ដុះបណ្ដាលជំនាញ",
    "{amount} {currency}": "{amount} {currency}",
    "The employer provides accommodation during skill-training of Workers":
      "និយោជកផ្ដល់កន្លែងស្នាក់នៅក្នុងកំលុងពេលនៃវគ្គបណ្ដុះបណ្ដាលជំនាញដល់ពលករ",
    "The workers need to pay accommodations fee during skill-training of Workers":
      "ពលករត្រូវចេញថ្លៃកន្លែងស្នាក់នៅក្នុងកំលុងពេលនៃវគ្គបណ្ដុះបណ្ដាលជំនាញ",
    "The employer provides meals during skill-training of Workers":
      "និយោជកផ្ដល់អាហារក្នុងកំលុងពេលនៃវគ្គបណ្ដុះបណ្ដាលជំនាញដល់ពលករ",
    "The workers need to pay meals fee during skill-training of Workers":
      "ពលករត្រូវចេញថ្លៃអាហារក្នុងកំលុងពេលនៃវគ្គបណ្ដុះបណ្ដាលជំនាញ",
    "Based on individual cost/spending.": "អាស្រ័យលើចំណាយ/ថ្លៃផ្ទាល់ខ្លួន",
    "The employer pays transportation costs to and from for attending skill training":
      "និយោជកចេញថ្លៃធ្វើដំណើរទៅមកក្នុងការចូលរួមវគ្គបណ្ដុះបណ្ដាលជំនាញ",
    "The workers need to pay transportation costs to and from for attending skill training":
      "ពលករត្រូវចេញថ្លៃធ្វើដំណើរទៅមកក្នុងការចូលរួមវគ្គបណ្ដុះបណ្ដាលជំនាញ",
    "Costs For Pre-Departure": "តម្លៃសម្រាប់រៀបចំមុខចេញដំណើរ",
    "Employer pays Cost of pre-departure orientation training":
      "និយោជកចេញថ្លៃនៃវគ្គបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរ",
    "The workers need to pay Cost of pre-departure orientation training":
      "ពលករត្រូវចេញថ្លៃនៃវគ្គបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរ",
    "Employer provides accommodation during pre-departure orientation training":
      "និយោជកផ្ដល់កន្លែងស្នាក់នៅកំលុងពេលនៃវគ្គបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរ",
    "The workers need to pay accommodations fee during pre-departure orientation training":
      "ពលករត្រូវចេញថ្លៃកន្លែងស្នាក់នៅកំលុងពេលនៃវគ្គបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរ",
    "Employer provides meals during pre-departure orientation training":
      "និយោជកផ្ដល់អាហារក្នុងកំលុងពេលនៃវគ្គបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរ",
    "The workers need to pay meals fee during pre-departure orientation training":
      "ពលករត្រូវចេញថ្លៃអាហារក្នុងកំលុងពេលនៃវគ្គបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរ",
    "Employer pays transportation costs to and from pre-departure orientation training":
      "និយោជកចេញថ្លៃធ្វើដំណើរទៅមកក្នុងការចូលរួមវគ្គបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរ",
    "The workers need to pay transportation costs to and from pre-departure orientation training":
      "ពលករត្រូវចេញថ្លៃធ្វើដំណើរទៅមកក្នុងការចូលរួមវគ្គបណ្ដុះបណ្ដាលណែនាំមុនចេញដំណើរ",
    "Additional information for Pre-departure orientation course":
      "ព័ត៌មានបន្ថែមនៃវគ្គបណ្ដុះបណ្ដាលណែនាំក្នុងការរៀបចំមុខចេញដំណើរ",
    "Costs and Fees for recruitment in the origin country":
      "តម្លៃ និង ថ្លៃសេវា សម្រាប់ការជ្រើសរើសនៅក្នុងប្រទេសដើម",
    "Employer pays recruitment service fee to RA and sub-agent":
      "និយោជកចំណាយលើថ្លៃសេវាជ្រើសរើសទៅដល់ទីភ្នាក់ងារជ្រើសរើសពលករ និង ទីភ្នាក់ងារជ្រើសរើសរង",
    "The workers need to pay recruitment service fee to RA and sub-agent":
      "ពលករត្រូវចំណាយលើថ្លៃសេវាជ្រើសរើសទៅដល់ទីភ្នាក់ងារជ្រើសរើសពលករ និង ទីភ្នាក់ងារជ្រើសរើសរង",
    "Employer pays travel costs for a job interview":
      "និយោជកចំណាយលើថ្លៃធ្វើដំណើរសម្រាប់ការមកសម្ភាសន៍ការងារ",
    "The workers need to pay travel costs for a job interview":
      "ពលករត្រូវចំណាយលើថ្លៃធ្វើដំណើរសម្រាប់ការមកសម្ភាសន៍ការងារ",
    "Employer pays accommodation costs for contract signing":
      "និយោជកចំណាយលើថ្លៃស្នាក់នៅសម្រាប់ការមកចុះកិច្ចសន្យា",
    "The workers need to pay accommodation costs for contract signing":
      "ពលករត្រូវចំណាយលើថ្លៃស្នាក់នៅសម្រាប់ការមកចុះកិច្ចសន្យា",
    "Employer pays transportation cost to and from for contract signing":
      "និយោជកចំណាយលើថ្លៃធ្វើដំណើរទៅមកសម្រាប់ការមកចុះកិច្ចសន្យា",
    "The workers need to pay transportation cost to and from for contract signing":
      "ពលករត្រូវចំណាយលើថ្លៃធ្វើដំណើរទៅមកសម្រាប់ការមកចុះកិច្ចសន្យា",
    "Employer provides meals for contract signing":
      "និយោជកផ្ដល់អាហារសម្រាប់ការមកចុះកិច្ចសន្យា",
    "The workers need to pay meals fee for contract signing":
      "ពលករត្រូវចេញថ្លៃអាហារសម្រាប់ការមកចុះកិច្ចសន្យា",
    "Employer pays medical check-up fee": "និយោជកចេញថ្លៃពិនិត្យសុខភាព",
    "The workers need to pay medical check-up fee":
      "ពលករត្រូវចេញថ្លៃពិនិត្យសុខភាព",
    "Employer pays passport application fee": "និយោជកចេញថ្លៃធ្វើលិខិតឆ្លងដែន",
    "The workers need to pay passport application fee":
      "ពលករត្រូវចេញថ្លៃធ្វើលិខិតឆ្លងដែន",
    "Employer pays Overseas Worker Identification Card (OWIC) fee":
      "និយោជកចេញថ្លៃធ្វើប័ណ្ណពលករកម្ពុជាទៅធ្វើការនៅបរទេស (OCWC)",
    "The workers need to pay Overseas Worker Identification Card (OWIC) fee":
      "ពលករត្រូវចេញថ្លៃធ្វើប័ណ្ណពលករកម្ពុជាទៅធ្វើការនៅបរទេស (OCWC)",
    "Employer pays Transportation costs to the border or airport":
      "និយោជកចេញថ្លៃធ្វើដំណើរទៅកាន់ព្រំដែន ឬ ប្រលានយន្តហោះ",
    "The workers need to pay Transportation costs to the border or airport":
      "ពលករត្រូវចេញថ្លៃធ្វើដំណើរទៅកាន់ព្រំដែន ឬ ប្រលានយន្តហោះ",
    "Costs and fees for recruitment in the destination country":
      "តម្លៃ និង ថ្លៃសេវា សម្រាប់ការជ្រើសរើសនៅក្នុងប្រទេសដែលពលករត្រូវធ្វើការ",
    "Employer pays Transportation cost from the Thai border to the workplace (or to the destination country)":
      "និយោជកចេញថ្លៃធ្វើដំណើរពីព្រំដែនថៃទៅដល់កន្លែងធ្វើការ (ឬទៅដល់ប្រទេសដែលពលករត្រូវធ្វើការ)",
    "The workers need to pay Transportation cost from the Thai border to the workplace (or to the destination country)":
      "ពលករត្រូវចេញថ្លៃធ្វើដំណើរពីព្រំដែនថៃទៅដល់កន្លែងធ្វើការ (ឬទៅដល់ប្រទេសដែលពលករត្រូវធ្វើការ)",
    "Employer pays Visa fee": "និយោជកចេញថ្លៃទិដ្ឋាការ/វីសា",
    "The workers need to pay Visa fee": "ពលករត្រូវចេញថ្លៃទិដ្ឋាការ/វីសា",
    "Employer pays Work permit": "និយោជកចេញថ្លៃធ្វើប័ណ្ណអនុញ្ញាតការងារ",
    "The workers need to pay Work permit":
      "ពលករត្រូវចេញថ្លៃធ្វើប័ណ្ណអនុញ្ញាតការងារ",
    "Employer pays Medical check-up fee (if workers have to take another check up)":
      "និយោជកចេញថ្លៃពិនិត្យសុខភាព (ប្រសិនបើពលករចាំបាច់ត្រូវពិនិត្យសុខភាពម្ដងទៀត)",
    "The workers need to pay Medical check-up fee (if workers have to take another check up)":
      "ពលករត្រូវចេញថ្លៃពិនិត្យសុខភាព (ប្រសិនបើពលករចាំបាច់ត្រូវពិនិត្យសុខភាពម្ដងទៀត)",
    "Other Costs": "តម្លៃផ្សេងៗទៀត",
    "Information on repatriation":
      "ព័ត៌មានអំពីការធ្វើមាតុភូមិនិវត្តន៍/ការវិលត្រលប់មកប្រទេសកំណើតវិញ",
    Benefits: "អត្ថប្រយោជន៍",
    "Paid annual leave per year":
      "ការឈប់សម្រាកប្រចាំឆ្នាំដែលមានប្រាក់ឈ្នួល ក្នុងមួយឆ្នាំ",
    "Paid sick leave per year": "ការឈប់សម្រាកឈឺដែលមានប្រាក់ឈ្នួល ក្នុងមួយឆ្នាំ",
    "Paid business leave per year":
      "ការឈប់សម្រាកដែលតម្រូវដោយរោងចក្រដែលមានប្រាក់ឈ្នួល ក្នុងមួយឆ្នាំ",
    "Paid national holidays per year":
      "ការឈប់សម្រាកបុណ្យជាតិដែលមានប្រាក់ឈ្នួល ក្នុងមួយឆ្នាំ",
    "Social security benefits are provided as required by law":
      "អត្ថប្រយោជន៍ធានារ៉ាប់រងសង្គមត្រូវបានផ្តល់ជូនតាមច្បាប់",
    "Social security and maternity leave benefits are provided as required by law":
      "អត្ថប្រយោជន៍ធានារ៉ាប់រងសង្គម និងការឈប់សម្រាកលំហែមាតុភាពត្រូវបានផ្តល់ជូនតាមច្បាប់",
    "Social security leave benefits are not provided":
      "អត្ថប្រយោជន៍ច្បាប់ឈប់សម្រាកមិនត្រូវបានផ្តល់ជូន",
    "Social security and maternity leave benefits are not provided":
      "អត្ថប្រយោជន៍ធានារ៉ាប់រងសង្គម និងការឈប់សម្រាកលំហែមាតុភាពមិនត្រូវបានផ្តល់ជូននោះទេ",
    "Any additional leave detail": "ព័ត៌មានលំអិតបន្ថែមអំពីច្បាប់ឈប់សម្រាក",
    "Other Benefits": "អត្ថប្រយោជន៍ផ្សេងៗទៀត",
    "Benefits Details": "អត្ថប្រយោជន៍លំអិត",
    "Other Information": "ព័ត៌មានផ្សេងទៀត",
    "Accommodation on-site is available":
      "មានកន្លែងស្នាក់នៅ នៅនឹងកន្លែងធ្វើការ",
    "No information is provided on whether Accommodation on-site or not":
      "មិន​មាន​ព័ត៌មាន​​ផ្តល់​ជូន​ថា​តើ​មានកន្លែង​ស្នាក់នៅ​នៅ​នឹង​កន្លែងការងារេ​ឬ​អត់​",
    "Family members can live together": "សមាជិកគ្រួសារអាចរស់នៅជាមួយគ្នាបាន",
    "No information is provided on whether Family members can live together or not":
      "មិនមានព័ត៌មានផ្តល់ជូនថាតើសមាជិកគ្រួសារអាចរស់នៅជាមួយគ្នាបានឬអត់",
    "Rent & Utilites are auto deducted from the payroll":
      "ថ្លៃបន្ទប់ជួល និង ថ្លៃប្រើប្រាស់ទឹកភ្លើងផ្សេងៗត្រូវបានកាត់កងក្នុងបញ្ជីបើកប្រាក់ខែ",
    "No information is provided for a deduction on Rent & Utilities":
      "មិនមានព័ត៌មានផ្តល់ជូនសម្រាប់ការកាត់ថ្លៃជួល និងថ្លៃទឹកភ្លើង",
    "Safe functioning grievance mechanism": "យន្ដការបណ្ដឹងតវ៉ាដែលមានសុវត្ថិភាព",
    "No Information is provided  for grievance mechanism":
      "មិនមានព័ត៌មានផ្តល់ជូនសម្រាប់យន្តការបណ្តឹងសារទុក្ខ",
    "Probation period": "រយៈពេលសាកល្បងការងារ",
    "No Information is provided for probation period":
      "មិនមានព័ត៌មានផ្តល់ជូនសម្រាប់រយៈពេលសាកល្បង",
    "Accommodation Details": "ព័ត៌មានលំអិតអំពីការស្នាក់នៅ",
    "Grievance Mechanism Details": "ព័ត៌មានលំអិតអំពីយន្ដការបណ្ដឹងតវ៉ា",
    "Probation Period Details": "ព័ត៌មានលំអិតអំពីរយៈពេលសាកល្បងការងារ",
    "Rent/utilites deduction details":
      "ព័ត៌មានលំអិតអំពីការកាត់កងថ្លៃជួល/ថ្លៃប្រើប្រាស់ផ្សេងៗ",
    "Contact Person": "អ្នកដែលត្រូវទំនាក់ទំនង",
    "Recruitment Agency Information": "ព័ត៌មានអំពីភ្នាក់ងារជ្រើសរើស",
    "Currently, the employer has received enough applicants for this job post and has moved your CV to the waitlist. You will be notified if the employer requires more applicants. If you prefer not to wait for their notification, please consider applying for another job.":
      "ឥឡូវនេះ និយោជកបានទទួលបេក្ខជនគ្រប់គ្រាន់សម្រាប់ការប្រកាសការងារនេះ ហើយបានបញ្ចូនប្រវត្តិរូប CV របស់អ្នកទៅក្នុងបញ្ជីរង់ចាំ អ្នកនឹងត្រូវបានជូនដំណឹងប្រសិនបើនិយោជកត្រូវការបេក្ខជនបន្ថែមទៀត ប្រសិនបើអ្នកមិនចង់រង់ចាំទេ សូមពិចារណាដាក់ពាក្យការងារផ្សេងទៀត",
    "This employer requested to view and check your documents {timeAgo}. They will be allowed to view them only, not download.":
      "និយោជកនេះកំពុងស្នើរសុំមើល និង ពិនិត្យឯកសាររបស់អ្នក {timeAgo}។ និយោជកនឹងត្រូវបានអនុញ្ញាតឲ្យមើលបានតែប៉ុណ្ណោះ មិនអាចទាញយកឯកសារនេះបានទេ",
    "However, you must first have uploaded the required documents on your resume page:":
      "ទោះជាយ៉ាងណាក៏ដោយ អ្នកត្រូវបញ្ជូលឯកសារដែលចាំចាច់នៅក្នុងទំព័រប្រវត្តិរូបសង្ខេបរបស់អ្នកជាមុនសិន",
    "Upload missing documents": "ដាក់បញ្ចូលឯកសារដែលខ្វះខាត",
    Reject: "បដិសេធ",
    Accept: "ទទួលយក",
    "Missing resume": "ប្រវត្ដិរូបសង្ខេបមិនពេញលេញ",
    "{clickHereLink} to create your GD resume":
      "{clickHereLink} ដើម្បីបង្កើតប្រវត្តិរូបសង្ខេបរបស់អ្នកនៅលើ សុបិន្ដ ឥស្សរា",
    "Click here": "សូមចុចទីនេះ",
    "Jobs for seekers in Myanmar/Cambodia/Nepal ":
      "ការងារសម្រាប់អ្នកស្វែងរកការងារនៅក្នុងប្រទេសមីយ៉ាន់ម៉ា/កម្ពុជា/នេប៉ាល់",
    "All jobs on Marketplace": "ការងារទាំងអស់នៅលើទីផ្សារការងារ",
    "Jobs for seekers in Thailand ": "ការងារសម្រាប់អ្នកស្វែងរកការងារនៅប្រទេសថៃ",
    "Please login to view the jobs..":
      "សូមចូលទៅគណនីដើម្បីពិនិត្យមើលការងារទាំងនេះ...",
    "Sorry, no recruitment agencies matched filter parameters, please try again.":
      "សូមអភ័យទោស! គ្មានភ្នាក់ងារជ្រើសរើសពលករត្រូវបានរកឃើញក្នុងតំណភ្ជាប់នេះទេ សូមព្យាយាមម្តងទៀត...",
    "Successfully applied": "បានដាក់ពាក្យស្នើសុំដោយជោគជ័យ",
    "Couldn't apply due to server error":
      "មិនអាចដាក់ពាក្យស្នើសុំបានទេ ដោយសារប្រព័ន្ធមានបញ្ហា",
    "Please create your CV first": "សូមបង្កើតប្រវត្តិរូបសង្ខេបរបស់អ្នកជាមុនសិន",
    Screening: "កំពុងពិនិត្យមើល",
    "Do you know someone working here?":
      "តើអ្នកមានស្គាល់នរណាម្នាក់ដែលធ្វើការនៅទីនេះដែរឬទេ?",
    "No, I don't know": "ទេ, ខ្ញុំមិនស្គាល់ទេ",
    "Yes, I know someone": "បាទ/ចាស៎, ខ្ញុំស្គាល់គេនៅទីនេះ",
    "After going through the job description, do you think you can do the job well?":
      "បន្ទាប់ពីបានមើលការពិពណ៌នាការងាររួចហើយ តើអ្នកគិតថា អ្នកអាចធ្វើការងារនេះបានដែរឬទេ?",
    "No, I am not sure": "ទេ, ខ្ញុំមិនប្រាកដទេថាអាចធ្វើបាន",
    "Yes, I think so": "បាទ/ចាស៎, ខ្ញុំគិតថា ខ្ញុំអាចធ្វើបាន",
    "This Employer/Recruiter might need to see your documents and information to confirm you are eligible for the job. ":
      "និយោជក/ភ្នាក់ងារជ្រើសរើស​ត្រូវការពិនិត្យមើលឯកសារដើម្បីបញ្ជាក់ថាអ្នកមានលក្ខណគ្រប់គ្រាន់សម្រាប់ការងារ",
    "No, I don't": "ទេ, ខ្ញុំមិនយល់ព្រមទេ",
    "Yes, I do": "បាទ/ចាស៎, ខ្ញុំអនុញ្ញាត",
    "No consent was given": "គ្មានការព្រមព្រៀងដែលត្រូវបានយល់ព្រមនោះទេ",
    "Sorry! Employers need to see your documents to confirm you are eligible for the job. The documents required by employers may vary, so you are encourage to share the document you have, so that the employer can check if you are eligible. Is that ok for you?":
      "អត់ទោស! និយោជកត្រូវការពិនិត្យមើលឯកសាររបស់អ្នក ដើម្បីបញ្ជាក់ថាអ្នកមានលក្ខខណៈគ្រប់គ្រាន់សម្រាប់ការងារ។ ឯកសារដែលទាមទារពីនិយោជកអាចមានច្រើនប្រភេទ ដូច្នេះយើងណែនាំអ្នកចែករំលែកនូវរាល់ឯកសារដែលអ្នកមាន នោះនិយោជកអាចឆែកមើល ថាតើអ្នកមានលក្ខខណៈគ្រប់គ្រាន់។ តើអ្នកយល់ព្រមទេ?",
    "If you have any questions, for those who are in Thailand, please call {phoneLink}, and for those in Myanmar, please call or send message to Viber No. {viberLink} at anytime, 24 hours a day, 7 days a week.":
      "ប្រសិនបើអ្នកមានសំនួរផ្សេងៗ សម្រាប់អ្នករស់នៅប្រទេសថៃ សូមទំនាក់ទំនងមក {phoneLink} ហើយអ្នកដែលរស់នៅប្រទេសមីយ៉ាន់ម៉ា សូមទំនាក់ទំនងតាមទូរសព្ទ ឬផ្ញើរសារតាមកម្មវិធីវ៉ាយប៊ {viberLink} បានគ្រប់ពេលវេលា 24ម៉ោង/ថ្ងៃ 7ថ្ងៃ/សប្ដាហ៍",
    "If you have any questions, please call {phoneLink} (For those who are in Thailand) or direct message to Facebook page {facebookLink} at anytime, 24 hours a day, 7 days a week.":
      "ប្រសិនបើអ្នកមានសំនួរផ្សេងៗ សូមទំនាក់ទំនងមកលេខ {phoneLink} (សម្រាប់អ្នករស់នៅប្រទេសថៃ) ឬ ផ្ញើរសារមកកាន់គេហៈទំព័រហ្វេសប៊ុក {facebookLink} បានគ្រប់ពេលវេលា 24ម៉ោង/ថ្ងៃ 7ថ្ងៃ/សប្ដាហ៍",
    "If you have any questions, please call {phoneLink}.  The call is free of charge and you can call at anytime, 24 hours a day, 7 days a week.":
      "ប្រសិនបើអ្នកមានចំងល់ផ្សេងៗ សូមទំនាក់ទំនងមក {phoneLink} ដោយឥតគិតថ្លៃ 24 ម៉ោងក្នុងមួយថ្ងៃ, 7 ថ្ងៃក្នុងមួយសប្ដាហ៍",
    "Do you need help in completing this resume?":
      "តើអ្នកត្រូវការជំនួយ ក្នុងការបំពេញប្រវត្តិរូបសង្ខេបដែរឬទេ?",
    "Uploaded Nepal Documents": "ដាក់បញ្ចូលឯកសារនេប៉ាល់",
    "Uploaded Myanmar Documents": "ឯកសារមីយ៉ាន់ម៉ាដែលត្រូវបានដាក់បញ្ចូល",
    "NRC Card": "អត្តសញ្ញាណបណ្ណ",
    "Uploaded Khmer Documents": "ឯកសារខ្មែរដែលត្រូវបានដាក់បញ្ចូល",
    "Uploaded Documents": "ឯកសារដែលបានដាក់បញ្ចូលរួចរាល់",
    "Cancellation letter": "លខិតលុបឈ្មោះចេញពីកន្លែងការងារ",
    Resume: "ប្រវត្ដិរូបសង្ខេប",
    "Basic Information": "ព័ត៌មានទូទៅ",
    "Full name": "ឈ្មោះពេញ",
    Birthday: "ថ្ងៃខែឆ្នាំកំណើត",
    Education: "ប្រវត្តិការសិក្សា",
    Major: "ជំនាញ",
    "Emergency Phone number": "លេខទូរស័ព្ទដែលអាចទាក់ទងបានក្នុងពេលមានអាសន្ន",
    "LINE id number": "លេខ ID កម្មវិធីឡាញ",
    "Viber id number": "លេខID កម្មវីវ៉ាយបើ",
    "Current Address": "អាសយដ្ឋានបច្ចុប្បន្ន",
    Documents: "ឯកសារ",
    "National ID": "លេខអត្តសញ្ញាណបណ្ណ",
    "Passport expires": "កាលបរិច្ឆេទផុតកំណត់ លិខិតឆ្លងដែន",
    "Certificate of Identity Number": "លេខវិញ្ញាបនបត្រសម្គាល់អត្តសញ្ញាណ",
    "Certificate of Identity expires":
      "កាលបរិច្ឆេទផុតកំណត់ វិញ្ញាបនបត្រសម្គាល់អត្តសញ្ញាណ",
    "Pink Card Number": "លេខបណ្ណស៊ីជម្ពូ",
    "Working Experience": "បទពិសោធន៍ការងារ",
    Title: "មុខតំណែង",
    Years: "ឆ្នាំ",
    "Languages Proficiency": "ភាសាដែលស្ទាត់ជំនាញ",
    "Language Skills": "ភាសាជំនាញ",
    "Read Level": "កម្រិតនៃការអាន",
    "Speak Level": "កម្រិតនៃការនិយាយ",
    "Passport Number": "លេខលិខិតឆ្លងដែន",
    optional: "ប្រសិនបើមាន",
    "Passport number": "លេខលិខិតឆ្លងដែន",
    "Enter your Passport Number as shown in the picture":
      "បញ្ចូលលេខលិខិតឆ្លងដែនរបស់អ្នក ដូចដែលបានបង្ហាញនៅក្នុងរូបថត",
    "Valid till": "ផុតសុពលភាពនៅថ្ងៃ",
    Month: "ខែ",
    Year: "ឆ្នាំ",
    "Day: please enter the number between 1 and 31":
      "ថ្ងៃទី៖ សូមបញ្ចូលលេខចន្លោះពី 1 ដល់ 31",
    "Month: please enter the number between 1 and 12":
      "ខែ៖ សូមបញ្ចូលលេខចន្លោះពី 1 ដល់ 12",
    "Year: please enter the number between 2020 and 2040":
      "នាំ៖ សូមបញ្ចូលឆ្នាំចន្លោះពី 2020 ដល់ 2040",
    "National ID Number": "លេខអត្ដសញ្ញាណប័ណ្ណ",
    "Enter your National Identification Card number":
      "បញ្ចូលលេខអត្តសញ្ញាណប័ណ្ណរបស់អ្នក",
    "Enter your National Identification Card Number as shown in the picture":
      "បញ្ចូលលេខអត្តសញ្ញាណប័ណ្ណរបស់អ្នក ដូចដែលបានបង្ហាញនៅក្នុងរូបថត",
    "Enter your NRC number": "បញ្ចូលលេខអត្តសញ្ញាណប័ណ្ណរបស់អ្នក",
    "Enter your NRC Number as shown in the picture (eg: 12/TaKaNa(N)123456)":
      "បញ្ចូលលេខអត្តសញ្ញាណប័ណ្ណរបស់អ្នក ដូចដែលបានបង្ហាញនៅក្នុងរូបថត (ឧទាហរណ៍៖ 210332783)",
    "Enter your Passport Number as shown in the picture (eg: MD123456)":
      "បញ្ចូលលេខលិខិតឆ្លងដែនរបស់អ្នក ដូចដែលបានបង្ហាញនៅក្នុងរូបថត (ឧទាហរណ៍៖ N01034234)",
    "CI Number": "លេខវិញ្ញាបនបត្រសម្គាល់អត្តសញ្ញាណ",
    "CI number": "លេខវិញ្ញាបនបត្រសម្គាល់អត្តសញ្ញាណ",
    "Enter your CI Number": "បញ្ចូលលេខវិញ្ញាបនបត្រសម្គាល់អត្តសញ្ញាណរបស់អ្នក",
    "Enter your Pink Card Number as shown in the picture, the pink card number mush have 13 digits. (eg: 00 1234 567890 1)":
      "បញ្ចូលលេខបណ្ណស៊ីជម្ពូររបស់អ្នក ដូចដែលបានបង្ហាញនៅក្នុងរូបថត, លេខបណ្ណស៊ីជម្ពូរត្រូវមាន ១៣ ខ្ទង់ (ឧទាហរណ៍៖ 00 1234 567890 1)",
    "Remove Passport": "លុបលិខិតឆ្លងដែន",
    "Remove visa": "លុបទិដ្ឋាការ/វីសា",
    "Remove work permit": "លុបបណ្ណអនុញ្ញាតការងារ",
    "Remove pink card": "លុបបណ្ណស៊ីជម្ពូរ",
    "Remove canc. letter": "លុបលខិតលុបឈ្មោះចេញពីកន្លែងការងាររងារ",
    "Uploaded Documents for Myanmar":
      "ឯកសារដែលបានដាក់បញ្ចូលរួចរាល់សម្រាប់ជនជាតិមីយ៉ាន់ម៉ា",
    "Remove NRC": "លុបអត្តសញ្ញាណប័ណ្ណ",
    "Remove Household List": "លុបសៀវភៅគ្រួសារ",
    "Remove Labour Card": "លុបបណ្ណការងារ",
    "Remove Covid Vaccine Certificate": "លុបប័ណ្ណចាក់វ៉ាក់សាំងកូវីត- ១៩",
    "Uploaded Documents for Nepal": "ដាក់បញ្ចូលឯកសារសម្រាប់នេប៉ាល់",
    "Uploaded Documents for Khmer": "ឯកសារដែលគួរដាក់បញ្ចូលប្រសិនបើមាន",
    "Remove Identification Card": "លុបអត្តសញ្ញាណប័ណ្ណ",
    "Remove Birth Certificate": "លុបសំបុត្របញ្ជាក់កំណើត",
    "Remove Family Record Book": "លុបសៀវភៅគ្រួសារ",
    "Remove Residence Book": "លុបសៀវភៅស្នាក់នៅ",
    "Please use one of the following formats: .jpeg, .jpg or .png":
      "សូមប្រើប្រាស់ទម្រង់មួយក្នុងចំណោមទម្រង់ទាំងនេះ៖  .jpeg, .jpg ឬ .png",
    "Couldn't update some of the documents":
      "មិនអាចធ្វើការផ្លាស់ប្ដូរ/ដាក់បន្ថែមនូវឯកសារមួយចំនួន",
    "Resume has been successfuly updated":
      "ប្រវត្ដិរូបសង្ខេបត្រូវបានកែតម្រូវដោយជោគជ័យ",
    "Couldn't update photo": "មិនអាចផ្លាស់ប្ដូររួបថតថ្មីបាន",
    "Resume has not been updated due to server error":
      "ប្រវត្ដិរូបសង្ខេបមិនអាចធ្វើការកែតម្រុវបានទេ ដោយសារប្រព័ន្ធមានបញ្ហា",
    "Resume has been successfuly created":
      "ប្រវត្ដិរូបសង្ខេបត្រូវបានបង្កើតដោយជោគជ័យ",
    "Resume has not been created due to server error":
      "ប្រវត្ដិរូបសង្ខេបមិនអាចបង្កើតបានទេ ដោយសារប្រព័ន្ធមានបញ្ហា",
    "Upload your photo": "ដាក់បញ្ចូលរូបថតរបស់អ្នក",
    "Enter your full name": "បញ្ចូលឈ្មោះពេញរបស់អ្នក",
    "Full name must be the same name in your NRC/Passport":
      "ឈ្មោះពេញត្រូវតែដូចនឹងឈ្មោះនៅក្នុងអត្តសញ្ញាណបណ្ណ/លិខិតឆ្លងដែន",
    "Full name must be the same name in your NRC/Passport/Pinkcard":
      "ឈ្មោះពេញត្រូវតែដូចនឹងឈ្មោះនៅក្នុងអត្តសញ្ញាណបណ្ណ/លិខិតឆ្លងដែន/បណ្ណស៊ីជម្ពូរ",
    "Please enter your full name": "សូមបញ្ចូលឈ្មោះពេញរបស់អ្នក",
    "Name can contain only letters": "ឈ្មោះអាចសរសេរបានតែជាអក្សរប៉ុណ្ណោះ",
    "Choose male or female": "ជ្រើសរើសភេទ ប្រុស ឬ ស្រី",
    "Select the highest level of education that you have successfully completed":
      "ជ្រើសរើសកម្រិតសិក្សាអប់រំដែលខ្ពស់បំផុតដែលអ្នកបានបញ្ចប់ដោយជោគជ័យ",
    "Please choose your education level":
      "សូមជ្រើសរើសកម្រិតសិក្សាអប់រំរបស់អ្នក",
    "Enter your major name": "បញ្ចូលជំនាញរបស់អ្នក",
    "Enter your major": "បញ្ចូលជំនាញរបស់អ្នក",
    "Please enter your major": "សូមបញ្ចូលជំនាញរបស់អ្នក",
    "Birthday (Gregorian calendar)": "ថ្ងៃខែឆ្នាំកំណើត",
    Date: "ថ្ងៃខែឆ្នាំ",
    "Please fill Gregorian day": "សូមបញ្ចូលថ្ងៃទី",
    "Please fill Gregorian Month": "សូមបញ្ចូលខែ",
    "Please fill Gregorian Year": "សូមបញ្ចូលឆ្នាំ",
    "Day: this month has less days": "ថ្ងៃ៖ ក្នុងខែនេះមានចំនួនថ្ងៃតិច",
    "Year: please enter the number between 1940 and 2005":
      "ឆ្នាំ៖ សូមបញ្ចូលឆ្នាំចន្លោះពី ១៩៤០ ដល់ ២០០៥",
    "Enter your email": "បញ្ចូលអ៊ីម៉ែលរបស់អ្នក",
    "Enter the email address you always use": "បញ្ចូលអ៊ីម៉ែលដែលអ្នកតែងតែប្រើ",
    "Please enter valid email address":
      "សូមបញ្ចូលអ៊ីម៉ែលដែលអ្នកកំពុងប្រើប្រាស់",
    "Pls add your country code in front of the no. For Eg. for 09765000150, pls put 959765000150.":
      "សូមបន្ថែមលេខកូដប្រទេសរបស់អ្នកនៅខាងមុខលេខទូរសព្ទ, ជាឧទាហរណ៍៖ នៅប្រទេសកម្ពុជាលេខទូរស័ព្ទ 0968356835, សូមវាយលេខ 855968356835",
    "Enter the phone number you always use":
      "បញ្ចូលលេខទូរស័ព្ទដែលអ្នកតែងតែប្រើ",
    "Enter your emergency phone number":
      "បញ្ចូលលេខទូរស័ព្ទដែលអាចទាក់ទងបានក្នុងពេលមានអាសន្ន",
    "Enter your Emergency Phone Number":
      "បញ្ចូលលេខទូរស័ព្ទដែលអាចទាក់ទងបានក្នុងពេលមានអាសន្ន",
    "Enter LINE id": "បញ្ចូលលេខសម្គាល់របស់ឡាញ",
    "Enter your LINE id number": "បញ្ចូលលេខសម្គាល់ឡាញរបស់អ្នក",
    "Enter your LINE id Number": "បញ្ចូលលេខសម្គាល់ឡាញរបស់អ្នក",
    "Please enter valid LINE id number":
      "សូមបញ្ចូលលេខសម្គាល់របស់ឡាញដែលមានសុពលភាព",
    "Enter your viber id number": "បញ្ចូលលេខសម្គាល់វ៉ាយប៊របស់អ្នក",
    "Enter your Viber Id Number": "បញ្ចូលលេខសម្គាល់វ៉ាយប៊របស់អ្នក",
    "Please enter valid viber id number":
      "សូមបញ្ចូលលេខសម្គាល់របស់វ៉ាយប៊ដែលមានសុពលភាព",
    "Select Country": "ជ្រើសរើសប្រទេស​",
    "Select the country you are living currently":
      "ជ្រើសរើសប្រទេសដែលអ្នកកំពុងរស់នៅសព្វថ្ងៃ",
    "State/Province": "ខេត្ត/ក្រុង",
    "Select State/Province": "ជ្រើសរើសខេត្ត/ក្រុង",
    "Select the state/province you are living currently":
      "ជ្រើសរើសខេត្ត/ក្រុងដែលអ្នកកំពុងរស់នៅសព្វថ្ងៃ",
    City: "ទីក្រុង",
    "Select City": "ជ្រើសរើសក្រុង",
    "Select the city you are living currently":
      "ជ្រើសរើសស្រុកដែលអ្នកកំពុងរស់នៅសព្វថ្ងៃ",
    "Country: please select the country": "ប្រទេស៖ សូមជ្រើសរើសប្រទេស",
    "State/Province: please select the state/province":
      "ខេត្ត/ក្រុង៖ សូមជ្រើសរើសខេត្ត/ក្រុង",
    "City: please select the city": "ស្រុក៖ សូមជ្រើសរើសស្រុក",
    "Address details": "អាសយដ្ឋានលម្អិត",
    "Please enter address details here": "សូមបញ្ចូលអាសយដ្ឋានលំអិតនៅទីនេះ",
    "Please enter the Address Details here (Room No, Street Name, Block Name, Township Name)":
      "សូមបញ្ចូលអាសយដ្ឋានលំអិតនៅទីនេះ (លេខបន្ទប់, ឈ្មោះផ្លូវ, ឈ្មោះតំបន់, ឈ្មោះទីក្រុង)",
    "Please enter your address details": "សូមបញ្ចូលអាសយដ្ឋានលំអិត",
    "Select Language": "ជ្រើសរើសភាសា",
    "Select the Language": "ជ្រើសរើសភាសា",
    "Select the Langauge": "ជ្រើសរើសភាសា",
    "Select Read Level": "ជ្រើសរើសកម្រិតនៃការអាន",
    "Select the Read Level of Language": "ជ្រើសរើសកម្រិតនៃការអានភាសា",
    "Select read level of Language": "ជ្រើសរើសកម្រិតនៃការអានភាសា",
    "Select Speak Level": "ជ្រើសរើសកម្រិតនៃការនិយាយ",
    "Select speak Level of Language": "ជ្រើសរើសកម្រិតនៃការនិយាយភាសា",
    "Select speak level of Language": "ជ្រើសរើសកម្រិតនៃការនិយាយភាសា",
    remove: "លុបចេញ",
    "Add Language Skill": "បន្ថែមភាសាជំនាញ",
    "Job Title": "មុខតំណែងនៃការងារ",
    "Enter your job title": "បញ្ចូលមុខតំណែងនៃការងាររបស់អ្នក",
    "Enter the job title of previous job or current job":
      "បញ្ចូលមុខតំណែងការងាររបស់អ្នកពីមុន ឬ ការងារដែលអ្នកកំពុងធ្វើការ",
    "Select the type of industry you worked in for this job":
      "ជ្រើសរើសប្រភេទឧស្សាហកម្មដែលអ្នកបានធ្វើការសម្រាប់ការងារនោះ",
    "Select industry": "ជ្រើសរើសឧស្សាហកម្ម",
    "Add new experience": "បន្ថែមបទពិសោធន៍ការងារថ្មីដែលអ្នកមាន",
    "Please fill in all the required fields and try again.":
      "សូមបំពេញនូវព័ត៌មានដែលតម្រូវឱ្យមានទាំងអស់ ហើយសាកល្បងម្តងទៀត",
    "Save CV": "រក្សាទុក ប្រវតិ្តរូបសង្ខេប",
    "Update required!": "តម្រូវឱ្យធ្វើបច្ចុប្បន្នភាព!",
    "Please {clickHere} to update your app in Google Play Store. Close and reopen the app once completed.":
      "សូម {ចុចទីនេះ} ដើម្បីធ្វើបច្ចុប្បន្នភាពកម្មវិធីរបស់អ្នកនៅក្នុង Google Play Store សូមបិទ និង បើកកម្មវិធីសារជាថ្មី បន្ទាប់ពីធ្វើបច្ចុប្បន្នភាពរួចរាល់",
    "click here": "សូមចុចទីនេះ",
    "Free hotline": "លេខទូរស័ព្ទជំនួយឥតគិតថ្លៃ",
    "Thailand Hotline (free)": "លេខទូរស័ព្ទជំនួយឥតគិតថ្លៃ នៅប្រទេសថៃ",
    "Yangon Hotline": "លេខទូរស័ព្ទជំនួយឥតគិតថ្លៃ នៅយ៉ាំងហ្កន (ភូមា)",
    "Nepal Hotline (free)": "លេខទូរស័ព្ទជំនួយជាភាសានេប៉ាល់ (ឥតគិតថ្លៃ)",
    Recruiters: "ភ្នាក់ងារជ្រើសរើស",
    Services: "សេវាគាំទ្រ",
    "Share a problem": "ចែករំលែកបញ្ហា",
    Language: "ភាសា",
    Marketplace: "ទីផ្សារការងារ",
    "GoldenDreams App": "កម្មវិធី សុបិន្ដ ឥស្សរា",
    Surveys: "ការស្ទង់មតិ",
    "Confirm Delete Your Profile?": "បញ្ជាក់តើអ្នកចង់លុបប្រវត្តិរូបមែនទេ?",
    "By selecting the delete profile option, you will be deleting all of your information on Golden Dreams. Once you confirm, you will not be able to recover any of this information. Are you sure you want to delete your profile?":
      "ដោយជ្រើសរើសជម្រើសលុបប្រវត្តិរូប អ្នកនឹងលុបព័ត៌មានទាំងអស់របស់អ្នកនៅក្នុងកម្មវិធី សុបិន្ត ឥស្សរា (Golden Dreams)។ នៅពេលដែលអ្នកបញ្ជាក់ អ្នកនឹងមិនអាចយកព័ត៌មាននេះមកវិញបានទេ។ តើអ្នកប្រាកដទេថាអ្នកចង់លុបប្រវត្តិរូបរបស់អ្នក?",
    "Yes, Delete my profile": "យល់ព្រម លុបប្រវត្តិរូបរបស់ខ្ញុ",
    Verified: "បានផ្ទៀងផ្ទាត់",
    Unverified: "មិនបានផ្ទៀងផ្ទាត់",
    "Profile strength:": "ព័ត៌មានប្រវត្តិរូបពេញលេញ:",
    Name: "ឈ្មោះ",
    Age: "អាយុ",
    "Enter your age": "បញ្ចូលអាយុរបស់អ្នក",
    "Please enter valid phone number!":
      "សូមបញ្ចូលលេខទូរស័ព្ទដែលអ្នកកំពុងប្រើប្រាស់!",
    "Phone Number": "លេខទូរស័ព្ទ",
    "Verify Phone": "បញ្ជាក់លេខទូរសព្ទ",
    "Preload media for offline use":
      "វីដេអូ​ ឯកសារបានទាញទុក​មុន អាចប្រើដោយគ្មានអ៊ីនធឺណេត",
    "No preload": "មិនបានទាញទុក​មុន",
    Audio: "សំលេង",
    "Audio + Video": "សំលេង និង វីដេអូ",
    "Delete Profile": "លុបប្រវត្តិរូប",
    "Privacy policy": "គោលការណ៍​ឯកជនភាព",
    "Member agreement": "កិច្ចព្រមព្រៀងសមាជិកភាព",
    Saving: "កំពុងរក្សាទុក",
    "Password must be at least 8 characters long and include both letters (a-z) and numbers (0-9).":
      "ពាក្យ​សម្ងាត់​ត្រូវ​មានចាប់ពី 8ខ្ទង់ឡើងទៅ ដែល​​យ៉ាង​ហោច​ណាស់មានជាតួអក្សរ (a-z) 1ខ្ទង់ និងជាលេខ (0-9)1ខ្ទង់",
    "Please Confirm your password": "សូមបញ្ជាក់លេខសម្ងាត់របស់អ្នក",
    "privacy policy": "គោលការណ៍​ឯកជនភាព",
    "member agreement": "កិច្ចព្រមព្រៀងសមាជិកភាព",
    "GOOGLE TEST SIGNUP": "បង្កើតការធ្វើតេស្ដ តាមប្រព័ន្ធ គូកល",
    "Facebook TEST SIGNUP": "បង្កើតការធ្វើតេស្ដ តាមប្រព័ន្ធ ហ្វេសប៊ុក",
    "Enter username or email you want to use":
      "បញ្ចូលឈ្មោះអ្នកប្រើប្រាស់ និងអ៊ីមែលដែលអ្នកចង់ប្រើ",
    "Please enter username or email you want to use":
      "សូមបញ្ចូល ឈ្មោះអ្នកប្រើប្រាស់ ​ឬ អ៊ីម៉ែល របស់អ្នក",
    "Enter your password": "បញ្ចូលលេខសំងាត់របស់អ្នក",
    "Password must be at least 8 characters long, requiring at least 1 letter (a-z) and 1 number (0-9).":
      "ពាក្យ​សម្ងាត់​ត្រូវ​មានចាប់ពី 8ខ្ទង់ឡើងទៅ ដែល​​យ៉ាង​ហោច​ណាស់មានជាតួអក្សរ (a-z) 1ខ្ទង់ និងជាលេខ(0-9) 1ខ្ទង់",
    "Please enter your password": "សូមបញ្ចូលលេខសំងាត់របស់អ្នក",
    "Confirm Password": "បញ្ជាក់លេខសម្ងាត់",
    "Please confirm your password":
      "សូមបញ្ចូលលេខសម្ងាត់ពីមុនរបស់អ្នកទីនេះម្តងទៀត",
    "Show Passwords": "បង្ហាញលេខសម្ងាត់ដែលបានបញ្ចូល",
    Register: "ចុះឈ្មោះ",
    "By clicking register, you agree to our {privacy_policy} and {member_agreement}.":
      "ពេលដែលអ្នកចុច ចុះឈ្មោះ គឺអ្នកយល់ព្រមទៅនឹង {privacy_policy} និង {member_agreement}",
    or: "ឬ",
    "Continue with Facebook": "បន្ដចុះឈ្មោះតាមប្រព័ន្ធ ហ្វេសប៊ុក",
    "Continue with Google": "បន្ដចុះឈ្មោះតាមអ៊ីម៉ែលអ្នកមានស្រាប់",
    "Resume has been successfuly deleted":
      "ប្រវត្ដិរូបសង្ខេបត្រូវបានលុបចោលជាស្ថាពរ",
    "Resume has not been deleted due to server error":
      "ប្រវត្ដិរូបសង្ខេបមិនអាចលុបចោលបាន ដោយសារប្រព័ន្ធមានបញ្ហា",
    "Please use one of the following formats: .jpeg, .jpg, .png, .pdf, .doc, .docx, .xls or .xlsx":
      "សូមប្រើប្រាស់ទម្រង់មួយក្នុងចំណោមទម្រង់ខាងក្រោម៖ .jpeg, .jpg, .png, .pdf, .doc, .docx, .xls or .xlsx",
    "Resume has been successfuly uploaded":
      "ប្រវត្ដិរូបសង្ខេបត្រូវបានដាក់បញ្ចូលដោយជោគជ័យ",
    "Resume has not been uploaded due to server error":
      "ប្រវត្ដិរូបមិនអាចដាក់បញ្ចូលបាន ដោយសារប្រព័ន្ធមាននញ្ហា",
    "Confirm deletion": "បញ្ជាក់ការលុប",
    "Are you sure you would like to delete your CV?":
      "តើអ្នកពិតជាចង់លុបប្រវត្តិរូបសង្ខេបរបស់អ្នកមែនទេ?",
    "Are you sure you would like to delete your uploaded CV?":
      "តើអ្នកពិតជាចង់លុបប្រវត្តិរូបសង្ខេបរបស់អ្នក ដែលអ្នកបានដាក់បញ្ចូលរួចហើយមែនទេ?",
    "Golden Dreamer CV": "ប្រវត្ដិរូបនៅក្នុង សុបិន្ដ ឥស្សរា",
    View: "ពិនិត្យមើល",
    Edit: "កែតម្រូវ",
    "We encourage you to create a Golden Dreamer CV to show your educational background, work experience, and skills you've mastered.":
      "យើងសូមលើកទឹកចិត្តអ្នកឱ្យបង្កើតប្រវត្តិរូបនៅក្នុងកម្មវិធី សុបិន្ត ឥស្សរា ដោយមានបង្ហាញពីប្រវត្តិការសិក្សា បទពិសោធន៍ការងារ និងជំនាញដែលអ្នកមានច្បាស់លាស់",
    "It is a short document that gives you a chance to show a prospective employer the best of what you've got. It highlights your skills and experience and shows them you're the right person for the job. It takes only a few minutes to create one.":
      "វាគឺជាឯកសារខ្លីមួយដែលផ្តល់ឱកាសឲ្យអ្នកបង្ហាញពីចំនុចល្អដែលអ្នកមានទៅកាន់និយោជក។ វាបង្ហាញអំពីជំនាញ និង បទពិសោធន៍របស់អ្នក ដែលបញ្ជាក់ថាអ្នកជាមនុស្សដែលមានលក្ខណសម្បត្ដិល្អសម្រាប់ការងារនេះ។ អ្នកគ្រាន់តែចំណាយពេលប៉ុន្មាននាទីប៉ុណ្ណោះដើម្បីបង្កើតវា។",
    "Once you have created your CV, you can use it to apply for many job opportunities. Good luck with your job application!":
      "នៅពេលអ្នកបានបង្កើតប្រវត្ដិរូបរបស់អ្នករួចហើយ អ្នកអាចប្រើវាដើម្បីដាក់ពាក្យសម្រាប់សុំការងារជាច្រើនទៀតបាន។ សូមសំណាងល្អជាមួយការដាក់ពាក្យស្នើរសុំការងាររបស់អ្នក!",
    "Start creating my CV": "ចាប់ផ្តើមបង្កើតប្រវត្ដិរូបរបស់ខ្ញុំ",
    "Upload custom CV": "បញ្ចូលប្រវត្តិរូបផ្ទាល់ខ្លួន",
    "Please choose a CV file": "សូមជ្រើសរើសឯកសារប្រវត្តិរូប",
    "CV name": "ឈ្មោះ ប្រវត្តិរូប",
    "Enter name of your CV": "បញ្ចូលឈ្មោះសម្រាប់ប្រវត្តិរូបរបស់អ្នក",
    "Please enter name of your CV": "សូមបញ្ចូលឈ្មោះសម្រាប់ប្រវត្តិរូបរបស់អ្នក",
    "{appName} would like to send you notifications for the latest news and updates. Notifications may include alerts, sounds and icon badges.":
      "{appName} សុបិន្ត ឥស្សរា នឹងផ្ញើរសារជូនដំណឹងដល់អ្នកអំពីព័ត៌មានថ្មីៗ និង ការធ្វើកំណែរទម្រង់ផ្សេងៗ។ ការជូនដំណឹងមាន សញ្ញាដាស់តឿន ជាសម្លេង និង ផ្ទាំងរូបភាពផ្សេងៗ។",
    "Golden Dreams": "សុបិន្ដ ឥស្សរា",
    Block: "បិទការបង្ហាញ",
    Allow: "អនុញ្ញាត",
    "Document removal confirmation": "ការបញ្ជាក់ពីការលុបឯកសារ",
    "Are you sure you would like to remove this documents?":
      "តើអ្នកពិតជាចង់លុបឯកសារនេះ?",
    "Yes, sure": "បាទ/ចាស៎, ប្រាកដណាស់",
    "Confirmation !": "បញ្ជាក់ការលុប!",
    "Are you sure you want to remove ?": "តើអ្នកពិតជាចង់លុប?",
    "Protected view": "បានការពារក្នុងការចូលមើល",
    "Please login or register to continue":
      "សូមចូលទៅកាន់គណនី ឬ ចុះឈ្មោះដើម្បីបន្ត",
    "Forgot Password?": "ភ្លេចលេខសម្ងាត់?",
    "New to golden dreams?":
      "តើអ្នកជាអ្នកប្រើប្រាស់ថ្មីក្នុងកម្មវិធី សុបិន្ដ ឥស្សរាមែនទេ?",
    "Click here to register?": "សូមចុចទីនេះ ដើម្បីចុះឈ្មោះ?",
    "Continue browsing as guest": "បន្ដចូលមើលក្នុងនាមជាភ្ញៀវ",
    "Click here to register": "សូមចុចទីនេះ ដើម្បីចុះឈ្មោះ",
    "Recruiters Listing": "បញ្ជីឈ្មោះភ្នាក់ជ្រើសរើសទទួលពាក្យការងារមុន",
    "Employers Listing": "បញ្ជីឈ្មោះនិយោជកទទួលពាក្យការងារមុន",
    Applications: "ពាក្យស្នើសុំ",
    "Sorry, no news matched filter parameters, please try again.":
      "សូមអភ័យទោស!  គ្មានព័ត៌មានដែលបានរកឃើញក្នុងតំណភ្ជាប់នេះទេ សូមព្យាយាមម្តងទៀត...",
    "No notifications": "គ្មានការជូនដំណឹង",
    "Please complete the form!": "សូមបំពេញនូវពាក្យបំពេញនេះ!",
    "Password reset link sent successfully!":
      "តំណភ្ជាប់កំណត់លេខសម្ងាត់ឡើងវិញត្រូវបានផ្ងើដោយជោគជ័យ!",
    "Bad Request: Please check the information you provided!":
      "ការស្នើសុំមិនត្រឹមត្រូវ សូមពិនិត្យមើលព័ត៌មានដែលបានផ្តល់!",
    "Not Found: The requested phone number was not found!":
      "រកមិនឃើញ: លេខទូរសព្ទដែលបានស្នើសុំមិនត្រូវបានរកឃើញ!",
    "Internal Server Error: Please try again later!":
      "បញ្ហាបច្ទេកទេសក្នុងប្រព័ន្ធ: សូមព្យាយាមម្តងទៀតនៅពេលក្រោយ!",
    "An error occurred: ": "មានបញ្ហាបច្ចេកទេសមួយកើតឡើង",
    "Network error: Please check your internet connection!":
      "បញ្ហាប្រព័ន្ធទំនាក់ទំនង: សូមពិនិត្យមើលសេវាអ៊ីនធើណែតរបស់អ្នក!",
    "Error: ": "បញ្ហបច្ចេកទេស",
    "Forgot Password": "ភ្លេចលេខសម្ងាត់",
    "Enter your phone number to reset":
      "បញ្ចូលលេខទូរស័ព្ទរបស់អ្នកដើម្បីកំណត់ឡើងវិញ",
    "This feature will be available in Nepal Soon!":
      "មុខងារនេះនឹងមានក្នុងប្រទេសនេប៉ាល់ឆាប់ៗនេះ!",
    "Processing...": "កំពុងដំណើរការបន្តអាជ្ញាប័ណ្ណ...",
    "Send Password Reset Link": "ផ្ងើតំណភ្ជាប់កំណត់លេខសម្ងាត់ឡើងវិញ",
    "Passwords do not match.": "លេខសម្ងាត់មិនត្រូវគ្នា​",
    "Password reset successfully!": "ការកំណត់លេខសម្ងាត់ជោគជ័យ!",
    "Token expired / Bad Request!": "ថូខឹនផុតកំណត់ / សំណើរមិនត្រឹមត្រូវ!",
    "No users found to reset password!":
      "រកមិនឃើញអ្នកប្រើសម្រាប់កំណត់លេខសម្ងាត់!",
    "There was an error resetting your password.":
      "មានបញ្ហាបច្ចេកទេសក្នុងការកំណត់លេខសម្ងាត់របស់អ្នកឡើងវិញ",
    "Failed to reset password!": "ការកំណត់លេខសម្ងាត់ឡើងវិញមិនជោគជ័យ!",
    "Reset Password": "កំណត់លេខសម្ងាត់ឡើងវិញ",
    "New Password": "លេខសម្ងាត់ថ្មី",
    "Enter new password": "បញ្ចូលលេខសម្ងាត់ថ្មីរបស់អ្នក",
    "Please enter a new password.": "សូមបញ្ចូលលេខសម្ងាត់ថ្មី",
    "Confirm new password": "បញ្ជាក់លេខសម្ងាត់ថ្មី",
    "Please confirm your new password.": "សូមបញ្ជាក់លេខសម្ងាត់ថ្មី",
    "{number} responses": "{number}  ឆ្លើយតប",
    "Would you like to participate in the poll with the topic?":
      "តើអ្នកចង់ចូលរួមបង្កើតការស្ទង់មតិជាមួយប្រធានបទណាមួយដែរឬទេ?",
    "I don't want to answer": "ខ្ញុំមិនចង់ឆ្លើយតបទេ",
    "I want to answer": "ខ្ញុំចង់ឆ្លើយតប",
    "Today's community poll": "បណ្ដុំនៃការស្ទង់មតិថ្ងៃនេះ",
    "Add a reply...": "សរសេរសារឆ្លើយតប..​.",
    "Problem Description": "ការពិពណ៌នាអំពីបញ្ហា",
    "Please describe the issue you are facing":
      "សូមពណ៌នាអំពីបញ្ហាដែលអ្នកកំពុងជូបប្រទះ",
    "Please describe your issue": "សូមពណ៌នាអំពីបញ្ហារបស់អ្នក",
    "Submit a problem": "ចែករំលែកបញ្ហា",
    "Waiting for Issara's reply": "កំពុងរង់ចាំការឆ្លើយតបពី ឥស្សរា",
    "Waiting for worker's reply": "កំពុងរង់ចាំការឆ្លើយតបពីពលករ",
    "Issara is waiting for your reply. Click the message to see the reply.":
      "ស្ថាប័ន ឥស្សរា កំពុងរង់ចាំការឆ្លើយតបរបស់អ្នក, សូមចុចនៅលើសារ ដើម្បីមើលការឆ្លើយតប",
    Resolved: "បានដោះស្រាយហើយ",
    "Last update: {date}": "ការធ្វើបច្ចុប្បន្នភាពចុងក្រោយបង្អស់ {date}",
    "Mark as resolved": "កំណត់សម្គាល់ថាបានដោះស្រាយហើយ",
    "Created: {date}": "ត្រូវបានបង្កើតនៅ {date}",
    "Show resolved": "បើកបង្ហាញអំពីអ្វីដែលបានដោះស្រាយហើយ",
    "Please login to share a problem..":
      "សូមចូលទៅកាន់គណនី ដើម្បីចែករំលែកអំពីបញ្ហា..",
    "You haven't shared any problems yet. Please click share a problem on the right top corner to share one.":
      'អ្នកមិនទាន់បានចែករំលែកនូវបញ្ហាណាមួយនៅឡើយទេ សូមចុច "ចែករំលែកបញ្ហា" នៅជ្រុងខាងលើផ្នែកខាងស្តាំ ដើម្បីធ្វើការចែករំលែក',
    Profile: "ព័ត៌មានអំពីអ្នក",
    "Are you sure want to withdraw all of your applications ? The recruiters will no longer see your applications in their list.":
      "តើអ្នកពិតជាចង់ដករាល់ពាក្យស្នើសុំរបស់អ្នកមែនទេ? អ្នកជ្រើសរើសនឹងមិនឃើញពាក្យស្នើសុំការងាររបស់អ្នកទៀតនោះទេ។",
    "Yes, withdraw all": "បាទ/ចាស៎ ដកពាក្យស្នើសុំទាំងអស់",
    "Are you sure want to extend all of your applications ? Your application expiry date will extend by 30 days.":
      "តើអ្នកពិតជាចង់បន្តពាក្យស្នើសុំរបស់អ្នកទាំងអស់មែនទេ? កាលបរិច្ឆេទពាក្យស្នើសុំរបស់អ្នកនឹងត្រូវបន្ត 30 ថ្ងៃទៀត។ ",
    "Yes, extend all": "បាទ/ចាស៎ បន្តសុពលភាពទាំងអស់",
    "You already sent your cv to this Recruitment Agency":
      "អ្នកបានដាក់បញ្ជូនប្រវត្តិរូបសង្ខេបរបស់អ្នកទៅទីភ្នាក់ងារជ្រើសរើសនេះ",
    "Recruitment Agency viewed your application":
      "ទីភ្នាក់ងារជ្រើសរើសបានពិនិត្យមើលពាក្យស្នើសុំរបស់អ្នក",
    "Recruitment Agency Downloaded your resume":
      "ទីភ្នាក់ងារជ្រើសរើសបានទាញយកប្រវត្តិរូបសង្ខេបរបស់អ្នក",
    "The Recruitment Agency moved your application to the General Waitlist.":
      "ទីភ្នាក់ងារជ្រើសរើសបានបញ្ចូលពាក្យស្នើសុំរបស់អ្នកទៅក្នុងបញ្ជីឈ្មោះរង់ចាំទូរទៅ",
    "Recruitment Agency Shortlisted your application":
      "ទីភ្នាក់ងារជ្រើសរើសបានសម្រាំងរើសយកពាក្យស្នើសុំរបស់អ្នក",
    "Please select permission for your resume & enter the remark ":
      "សូមជ្រើសរើសការអនុញ្ញាតសម្រាប់ប្រវត្តិរូបសង្ខេបរបស់អ្នក និង បញ្ចូលកំណត់សម្គាល់",
    "Allow to view documents": "អនុញ្ញាតអោយពិនិត្យមើលឯកសារ",
    "Are you sure want to withdraw this application? Your application will no longer see in the {recruiter_name}'s list":
      "តើអ្នកពិតជាចង់ដកពាក្យស្នើសុំនេះមែនទេ? ពាក្យស្នើសុំរបស់អ្នកនឹងមិនមាននៅក្នុងបញ្ជី {recruiter_name} ទៀតទេ",
    "Withdrawing Application": "ដកពាក្យស្នើសុំ",
    "Recruiter Detail": "ព័ត៌មានលំអិតអំពីភ្នាក់ងារជ្រើសរើស",
    "Date of Registration": "កាលបរិច្ឆេទនៃការចុះបញ្ជី",
    "Business Images": "រូបថតរបស់ក្រុមហ៊ុន",
    "RA Business Images": "រូបថតក្រុមហ៊ុនរបស់ទីភ្នាក់ងារជ្រើសរើសពលករ",
    "You cannot send your resume to this recruiter as you are not residing in same country with this recruiter.":
      "អ្នកមិនអាចបញ្ជូនប្រវត្តិរូបទៅកាន់ភ្នាក់ងារជ្រើសរើសនេះបានទេ ដោយសារអាស័យដ្ធានបច្ចុប្បន្នរបស់អ្នកមិននៅក្នុងប្រទេសដូចភ្នាក់ងារជ្រើសរើសនេះ.",
    Processing: "កំពុងដំណើរការបន្តអាជ្ញាប័ណ្ណ",
    "This recruitment agency has experience sending workers to":
      "ភ្នាក់ងារជ្រើសរើសនេះធ្លាប់បានបញ្ចូនពលករទៅ",
    comments: "ការផ្តល់មតិយោបល់",
    "Internal server error occur": "ប្រព័ន្ធផ្ទុកទិន្នន័យមានកំពុងមានបញ្ហា",
    "Name of recruitment agency": "ឈ្មោះភ្នាក់ងារជ្រើសរើសពលករ",
    "Registration number": "អត្តលេខចុះឈ្មោះ",
    "Date of registration": "កាលបរិច្ឆេទនៃការចុះឈ្មោះ",
    Username: "ឈ្មោះអ្នកប្រើប្រាស់",
    Registration: "ការចុះឈ្មោះ",
    "Register to apply": "ចុះឈ្មោះដើម្បីដាក់ពាក្យ",
    Jobseeker: "អ្នកស្វែងរកការងារ",
    "Recruitment Agency": "ភ្នាក់ងារជ្រើសរើសពលករ",
    Employer: "និយោជក",
    "Service Providers": "អ្នកផ្ដល់សេវា",
    "Sorry, no service providers matched filter parameters, please try again.":
      "សូមអភ័យទោស! គ្មានអ្នកផ្ដល់សេវាត្រូវបានរកឃើញនៅក្នុងតំណភ្ជាប់នេះទេ សូមព្យាយាមម្តងទៀត...",
    "We've recieved your problem": "យើងទទួលបានបញ្ហាដែលអ្នកបានចែករំលែកហើយ",
    "We'll get back to you as soon as possible":
      "យើងនឹងឆ្លើយតបទៅអ្នកក្នុងពេលឆាប់ៗនេះ",
    "Please enter your name": "សូមបញ្ចូលឈ្មោះរបស់អ្នក",
    "Please enter your phone number": "សូមបញ្ចូលលេខទូរស័ព្ទរបស់អ្នក",
    "Member Agreement": "កិច្ចព្រមព្រៀងសមាជិកភាព",
    "Privacy Policy": "គោលការណ៍​​ឯកជនភាព",
    "Would you like to participate in the survey?":
      "តើអ្នកចង់ចូលរួមនៅក្នុងការស្ទង់មតិដែរឬទេ?",
    "Thank you for participating in the survey.":
      "សូមអរគុណសម្រាប់ការចូលរួមក្នុងការស្ទង់មតិនេះ",
    "The form couldn't be submitted due to the error":
      "ទម្រង់នេះមិនអាចត្រូវបានដាក់បញ្ជូនទេ ដោយសារប្រព័ន្ធមានបញ្ហា",
    "The answer to this question is required.":
      "អ្នកចាំចាច់ត្រូវឆ្លើយនូវសំនួរនេះ",
    "Please answer all the questions that require at least one answer":
      "សូមឆ្លើយនូវសំនួរដែលចាំបាច់ទាំងអស់ យ៉ាងហោចណាស់ឲ្យបានមួយសំនួរ",
    "Submit answers": "ដាក់បញ្ជួនចម្លើយ",
    "Sorry, There are no surveys!": "សូមអធ្យាស្រ័យ មិនមានការស្រង់មតិទេ!",
  },
  options: {},
  "mm-u": {
    "refresh the page": "တစ်ချက်ပြန်နှိပ်ပါ။  ",
    "Ooops, something went wrong... Please try to {refresh}":
      "အိုး..တစ်ခုခုမှားနေလို့ တစ်ချက်ပြန်နှိပ်ပေးပါ။ {refresh}",
    Male: "ကျား",
    Female: "မ",
    "Prefer not to say": "မဖော်ပြချင်ပါ။",
    "Non-Binary": " ကျား/မ သတ်မှတ်ခြင်းမရှိပါ",
    "Passport Include": "ပါတ်စ်စပို့ ပုံတွဲထား",
    "Passport Not Include": "ပါတ်စ်စပို့ပုံမတွဲထားသူ ",
    Myanmar: "မြန်မာ",
    Thailand: "ထိုင်း",
    Cambodia: "ကမ္ဘောဒီးယား",
    Laos: "လာအို",
    Nepal: "နီပေါ",
    Indonesia: "အင်ဒိုနီးရှား",
    Indian: "အိန္ဒိယ ",
    Bangladeshi: "ဘင်္ဂလားဒေ့ရှ် ",
    Others: "အခြား",
    Sunday: "တနင်္ဂနွေ",
    Monday: "တနင်္လာ",
    Tuesday: "အင်္ဂါ",
    Wednesday: "ဗုဒ္ဓဟူး",
    Thursday: "ကြာသပတေး",
    Friday: "သောကြာ",
    Saturday: "စနေ",
    "It Will Vary": "အမျိုးမျိုးပြောင်းလဲနိုင်သည်",
    Monthly: "လခစား",
    "Daily Wage Rate": "နေ့စားခ",
    "Once A Month": "တစ်လ တစ်ကြိမ်",
    "Twice A Month": "တစ်လနှစ်ကြိမ်",
    "Every Week": "တစ်ပတ်တစ်ကြိမ်",
    "Every 10 days": "၁၀ရက်တစ်ကြိမ်",
    Other: "အခြား",
    Regularly: "ပုံမှန်",
    "From time to time": "တစ်ခါတစ်ရံ",
    Infrequently: "ကြာကြာနေမှ တစ်ခါ",
    Never: "မရှိပါ",
    Day: "နေ့",
    Swing: "အလုပ်ဆိုင်း ပုံမှန်မဟုတ်",
    Night: "ညဆိုင်း",
    Rotating: "အလှည့်ကျ",
    Variable: "အလုပ်ဆိုင်းအမျိုးမျိုးဖြစ်နိုင်သည်",
    "Full-time": "အချိန်ပြည့်",
    "Part-time": "အချိန်ပိုင်း",
    "Moved General Waitlist":
      "ရှိပြီးသားစောင့်ဆိုင်းစာရင်းများကို ဖယ်ထားပြီးပါပြီ။",
    Waitlisted: "စောင့်ဆိုင်းစာရင်းမှာ ရောက်ရှိနေပါပြီ။",
    "Contract Signed": "စာချုပ်မှာလက်မှတ်ထိုးပြီးသွားပါပြီ။",
    Accepted: "အလုပ်လျှောက်လွှာကိုလက်ခံလိုက်ပြီ",
    Applied: "အလုပ်လျှောက်ထားပြီးပြီ",
    Downloaded: "ဒေါင်းလုတ်ဆွဲပြီးပြီ",
    Offered: "အလုပ်ကမ်းလှမ်းလိုက်ပြီ",
    Rejected: "သင့်ကို အလုပ်မခန့်ပါ",
    Shortlisted: "သင့်ကိုပထမအဆင့်​ရွေးချယ်ထားလိုက်ပါပြီ",
    Viewed: "သင့်လျှောက်လွှာကို ကြည့်လိုက်ပါပြီ",
    "Documents Requested":
      "အလုပ်ရှင်မှ သင့်ရဲ့စာရွက်စာတမ်းအထောက်အထားများကိုကြည့်လိုပါတယ်",
    "Documents request accepted":
      "အလုပ်ရှင်မှ သင့်ရဲ့စာရွက်စာတမ်းအထောက်အထားများကိုကြည့်ရန်တောင်းဆိုသည်ကို လက်ခံထားပါတယ်",
    "Documents request rejected":
      "အလုပ်ရှင်မှ သင့်ရဲ့စာရွက်စာတမ်းအထောက်အထားများကိုကြည့်ရန်တောင်းဆိုသည်ကို ငြင်းပယ်ထားပါတယ်",
    "HR Assistant": "HR လက်ထောက်",
    "HR Interpreter": "HR စကားပြန်",
    "HR Manager": "HR မန်နေဂျာ",
    "HR Officer": "HR အရာရှိ",
    "Candidate pays fees in advance, then is reimbursed by company after starting work":
      "အလုပ်သမားက ကုန်ကျစရိတ် အရင်ကျခံရမည်။ ပြီးမှ အလုပ်ရှင်က ကုန်ကျစရိတ် ပြန်ထုတ်ပေးမည်။",
    "Company pays all fees in advance":
      "အလုပ်ရှင်က ကုန်ကျစရိတ်ကို ကြိုတင်ကျခံပေးမည်။",
    "India (+91)": "အိန္ဒိယ (+၉၁)",
    "Myanmar (+95)": "မြန်မာ (+၉၅)",
    "Nepal (+977)": "နီပေါl (+၉၇၇)",
    "Thailand (+66)": "ထိုင်း  (+၆၆)",
    "Malaysia (+60)": "မလေးရှား (+၆၀)",
    "Cambodia (+855)": "ကမ္ဘောဒီးယား  (+၈၅၅)",
    "A Little Bit": "အနည်းငယ်",
    Moderately: "အလယ်အလတ်",
    "Very Well": "ကောင်းကောင်း",
    None: "မပြောတတ်ပါ",
    Some: "နည်းနည်းပြောတတ်ပါသည်",
    Proficient: "ကောင်းကောင်းပြောတတ်ပါသည်",
    English: "အင်္ဂလိပ် ဘာသာ",
    Thai: "ထိုင်း ဘာသာ",
    "Bahasa Malaysia": "မလေးရှားဘာသာ",
    Hindi: "ဟိန္ဒီ",
    "Cannot read": "မဖတ်တတ်ပါ",
    APPLICABLE: "အလုပ်လျှောက်လွှာ‌ပို့ရန်ကြည့်မည်",
    APPLIED: "လျှောက်ထားပြီး",
    VIEWED: "ကြည့်ရှုပြီးပြီ",
    DOWNLOADED: "အေဂျင်စီမှ မိမိလျှောက်လွှာဖောင်ကို ကြည့်ပြီးပါပြီ",
    SHORTLISTED: "ပထမဦးစားပေးအဆင့်မှာရွေးချယ်ထားပါသည်",
    OFFERED: "အလုပ်ကမ်းလှမ်းထားသည်",
    ACCEPTED: "အလုပ်ကမ်းလှမ်းချက်ကို လက်ခံမည်",
    REJECTED_BY_BUSINESS: "အလုပ်ရှင်(အေဂျင်စီ)မှ ငြင်းပယ်ထားပါသည်",
    REJECTED_BY_APPLICANT: "လျှောက်လွှာတင်ထားသူမှ ငြင်းပယ်ထားပါသည် ",
    CONTRACT_SIGNED: "အလုပ်ခန့်အပ်ခြင်းစာချုပ်လက်မှတ်ထိုးထားပြီးပြီ ",
    APPLICANT_NA: "လျှောက်လွှာတင်ထားသူ_မတွေ့ရှိပါ",
    WAITLISTED: "စောင့်ဆိုင်းစာရင်းမှာရွေးချယ်ထားပါသည်",
    MOVED_GENERAL_WAITLIST: "အထွေထွေစောင့်ဆိုင်းစာရင်းမှာရွှေ့ဆိုင်းထားပါသည်",
    "STATUS N/A": "လက်ရှိအခြေအနေ-မရှိ ",
    "Cannot speak": "မရေးတတ်ပါ",
    "Network Error!": "အမှားတစ်စုံတစ်ရာဖြစ်သွား",
    "User with this username already exists":
      "နာမည်တူအကောင့်ရှိပြီးသားဖြစ်နေလို့ တခြားနာမည်နဲ့ အကောင့်ဖွင့်ပေးပါနော်။ (သို့မဟုတ်) လက်ရှိဖွင့်လိုသည့်အကောင့်နာမည်နဲ့မတူအောင် နံပါတ်တစ်ခုခုထပ်ထည့ပါ။",
    "You are trying to login with Business User Account !":
      "လင့်မှားဝင်ခြင်းဖြစ်သည်။လင့်မှန်/မမှန်ပြန်ဆန်းစစ်ကြည့်ပါ။",
    "There was an authentication problem":
      "တောင်းပန်ပါတယ်။ အင်တာနက်လိုင်းကျနေပါသဖြင့် အကောင့်ကိုအတည်ပြုရန်မအောင်မြင်ပါ။ လိုင်းကောင်းသည့်အခါနောက်ထပ်တစ်ခါထပ်ကြိုးစားကြည့်ပါနော်။ ",
    Applicable: "အလုပ်လျှောက်လွှာပေးပို့ရန်နှိပ်ပါ",
    "Not Applicable": "လျှောက်ထားခွင့်မရှိပါ",
    "N/A": "အချက်အလက်ရှာမတွေ့ပါ",
    "Click here for Registration.": "အကောင့်ဖွင့်ရန် ဤနေရာကို နှိပ်ပါ",
    "No Focal Person contact provided":
      "ဆက်သွယ်ရမည့်တာ၀န်ခံနာမည် မဖော်ပြထားပါ။",
    "Some features might not work in offline mode":
      "အချို့လုပ်ဆောင်ချက်များအတွက် အင်တာနက်ချိတ်ဆက်ရန် လိုသည်။",
    "New version is available!": "ဗားရှင်းအသစ်သုံးလို့ရပါပြီ!",
    "Please click refresh the app button to switch to a newer version.":
      "ဗားရှင်းအသစ်ပြောင်းသုံးရန် ပြန်လည်စတင်မည် ကိုနိုပ်ပေးပါ",
    "Updating..": "လုပ်ဆောင်နေသည် ...",
    "Refresh the app": "ပြန်လည်စတင်မည်",
    Polls: "စစ်တမ်းအဖြေ",
    News: "သတင်းများ",
    Home: "ပင်မ စာမျက်နှာ",
    Guide: "လမ်းညွှန်ချက်များ",
    Groups: "မေးမယ်၊ ဖြေမယ်၊ ရှယ်ကြမယ်",
    Like: "ကြိုက်သည်",
    Notifications: "အသိပေးခြင်း",
    moderator: "ရွှေအိပ်မက်",
    Anonymous: "အမည်မဖော်လိုသူ",
    "Delete comment": "ကွန့်မန့်ဖျက်မည်",
    "Unhide comment": "ကွန်မန့်ပြန်ဖော်မည်",
    "Hide comment": "ကွန်မန့် ဖျောက်ထားမယ်",
    Reply: "စာပြန်မည်",
    Report: "ရီပို့ထုရန်",
    "Report inappropriate comment": "မသင့်တော်သည့် ကွန်မန့်များအား ရီပို့ထုရန်",
    "Text of a comment:": "ရေးထားသောကွန်မန့်",
    "Please write a reason...": "အကြောင်းပြချက်ရေးပါ...",
    Close: "ပိတ်မည်",
    "Replying to {username}": "{username} ကိုစာပြန်မည်",
    "Write a reply...": "ပြန်စာရေးပါ...",
    "Write a comment...": "ကွန်မန့်ရေးမည်...",
    "Post a comment": "ကွန်မန့်တင်မည်",
    Comments: "ကွန်မန့်များ",
    "Go to Last Comment": "နောက်ဆုံး ကွန့်မန့် ကိုကြည့်မည်",
    "Fetching comments ...": "ကွန်မန့် အချက်အလက်ရယူနေပါသည်...",
    "Be a first person to comment on this post":
      "ဒီပို့စ်(အကြောင်းအရာ)မှာ ပထမဦးဆုံးသူအနေဖြင့် မှတ်ချက်ပေးမည်။",
    "Commented: ": "ကွန်မန့်ပေးပြီးပြီ: ",
    "Post comment": "ကွန့်မန့် တင်ပြမည် ",
    "replied to {recipient}": "မှ {recipient} ထံသို့ စာပြန်နေသည်",
    Discuss: "ဆွေးနွေးရန်",
    new: "အသစ်",
    "Published: {date}": "တင်ခဲ့သည့်ရက်စွဲ - {date}",
    Jan: "ဇန်နဝါရီ",
    Feb: "ဖေဖေါ်ဝါရီ",
    Mar: "မတ်",
    Apr: "ဧပြီ",
    May: "မေ",
    Jun: "ဇွန်",
    Jul: "ဇူလိုင်",
    Aug: "သြဂုတ်",
    Sep: "စက်တင်ဘာ",
    Oct: "အောက်တိုဘာ",
    Nov: "နိုဝင်ဘာ",
    Dec: "ဒီဇင်ဘာ",
    Save: "သိမ်းဆည်းမည်",
    Back: "နောက်ဆုတ်မည်",
    "Overall:": "စုစုပေါင်းရမှတ်",
    Operational: "ဤအေဂျင်စီကို ဆက်သွယ်၍ ပြည်ပအလုပ်အကိုင် စုံစမ်းနိုင်ပါသည်။",
    "Temporarily Suspended":
      "ဤအေဂျင်စီ၏ လုပ်ငန်းလိုင်စင်ကို ယာယီ ပိတ်သိမ်းထားပါသည်။",
    "from: {date}": "{date} မှစ၍",
    "to: {date}": "{date} အထိ ပိတ်သိမ်းသည်။",
    "Permanently Blacklisted":
      "ဤအေဂျင်စီ၏ လုပ်ငန်းလိုင်စင်ကို ရာသက်ပန် ပိတ်သိမ်းထားပါသည်။",
    "License is not extended":
      "ဤအေဂျင်စီသည် လုပ်ငန်းလိုင်စင်ကို သက်တမ်းတိုးထားခြင်း မရှိပါ။",
    "Thai License Number": "ထိုင်းလိုင်စင် အမှတ် -",
    "License number": "လိုင်စင် အမှတ် -",
    Email: "အီးမေးလ်",
    "Your application will expire at":
      "သင့်အလုပ်လျှောက်လွှာသက်တမ်းကုန်ဆုံးမည့်ရက်မှာ",
    "Recruitment Agencies": "အလုပ်ရှာဖွေရေးအေဂျင်စီများ",
    Employers: "အလုပ်ရှင်များ",
    "Employer Roster": "အလုပ်ကြိုတင်လျှောက်ထားနိုင်သည့် အလုပ်ရှင်များစာရင်း ",
    "Recruiter Roster": "အေဂျင်စီစာရင်းများ",
    "Reset Filter": "အချက်အလက် အားလုံးကြည့်မည်",
    "Search...": "ရှာဖွေမည်....",
    Filter: "လိုချင်သည့်အချက်သာ ရွေးချယ်ရှာဖွေမည်",
    "Select Industry": "လုပ်ငန်းအမျိုးအစားရွေးပေးပါ ",
    "All industries": "စက်ရုံအမျိုးအစားအားလုံး",
    "{type} country": "{type} နိုင်ငံရွေးပေးပါ  ",
    "Sort By": "အမျိုးအစား စိစစ်ခြင်း ",
    "Rating: Highest": "အမှတ်ပေးမှု-အမြင့်ဆုံး",
    "Rating: Lowest": "အမှတ်ပေးမှု-အနိမ့်ဆုံး",
    Popularity: "ကြည့်ရှုသူအများဆုံး",
    "Order By": "အစဥ်လိုက်",
    "Posted: Newest": "နောက်ဆုံးရ အလုပ်ကြော်ငြာများ",
    "Posted: Oldest": "ယခင် အလုပ်ကြော်ငြာများ",
    cm: "စင်တီမီတာ",
    ft: "ပေ",
    in: "လက်မ",
    kg: "ကီလိုဂရမ်",
    lbs: "ပေါင်",
    "Recruitment closed!": "လူပြည့်သွားလို့ အလုပ်​ခေါ်တာပိတ်လိုက်ပါပြီ!",
    "Recruitment Expired!": "လူပြည့်သွားလို့ အလုပ်​ခေါ်တာပိတ်လိုက်ပါပြီ! ",
    "{salary} {currency}/month": "{salary} {currency}/လခ",
    "{daily_wage_rate} {currency}/day": "{daily_wage_rate} {currency}/နေ့စားခ",
    "Have Employer Pays Policy":
      "အလုပ်ရှင်မှ အလုပ်ရှာဖွေရေးကုန်ကျစရိတ်ကျခံပါမည်",
    "Only job seekers in Myanmar/Cambodia/Nepal can apply":
      "မြန်မာ/ကမ္ဘောဒီးယားမှ အလုပ်ရှာသူများသာ လျှောက်နိုင်သောအလုပ်များ",
    "Only job seekers in Thailand can apply":
      "ထိုင်းရောက်နေသူများသာ လျှောက်နိုင်သောအလုပ်များ",
    "Recruitment Agency Job": "အလုပ်ရှာဖွေရေးအေဂျင်စီမှ တင်ပေးသည့် အလုပ်များ",
    "Employer Job": "အလုပ်ရှင်မှ ခေါ်သောအလုပ်များ",
    "Only job seekers in {country} can apply":
      " {country} နိုင်ငံရောက်နေသူများသာ လျှောက်နိုင်သောအလုပ်များ",
    Send: "စာပို့မည်",
    "Add & Verify Phone Number!":
      "ဖုန်းနံပါတ်ထည့်ပြီး မှန်ကန်ကြောင်းအတည်ပြုမည်",
    VERIFICATION: " မှန်ကန်ကြောင်းအတည်ပြုစစ်ဆေးခြင်း",
    "Update & Verify Phone Number!":
      "ဖုန်းနံပါတ်အပ်ဒိတ်လုပ်ပြီးမှန်ကန်ကြောင်း အတည်ပြုမည်",
    "{minutes} minute(s) and {seconds} second(s)":
      "{minutes} မိနစ်၊ {seconds} စက္ကန့်",
    "This user has already been verified with the provided phone number.":
      "သည်ဖုန်းနံပါတ်က အခြား အကောင့်  တစ်ခု နဲ့ ဘယ်ရီဖိုင်း လုပ်ထား ပြီးသားပါ ",
    "Error sending OTP!":
      " OTPကုတ် ပို့ဆောင်နေစဥ်အတွင်းအမှားတစ်စုံတစ်ရာဖြစ်သွားသည်",
    "Verification Failed! The number you entered has already been verified with another user account. Check the number again.":
      "အတည်ပြုခြင်းမအောင်မြင်ပါ။ ထည့်ထားသည့်ဖုန်းနံပါတ်မှာ အခြားအကောင့်တစ်ခုခုမှ အတည်ပြုထားပြီးသားဖြစ်ပါတယ်။ ဖုန်းနံပါတ်မှန်/မမှန်ပြန်စစ်ကြည့်ပါ။",
    "Please enter valid OTP!": "မှန်ကန်တဲ့ OTP code ကုတ်ပြန်ထည့်ပါ!",
    "Your phone number is successfully verified !":
      "သင့်ဖုန်းနံပါတ်မှန်ကန်ကြောင်းအတည်ပြုပြီးပါပြီ!",
    "OTP confirmation failed !": "OTP အတည်ပြုခြင်းမအောင်မြင်ပါ!",
    "Please complete the form !":
      "ကျေးဇူးပြုပြီး အဆင့်များကို ပြီးဆုံးအောင် လုပ်ဆောင်ပါ။ ",
    "Please Complete These Steps!":
      "ကျေးဇူးပြုပြီး အဆင့်များကို ပြီးဆုံးအောင် လုပ်ဆောင်ပါ။ ",
    Gender: "ကျား/ မ",
    Nationality: "နိုင်ငံသား",
    Country: "နိုင်ငံ",
    "Congratulations your profile is updated!":
      "ဂုဏ်ယူပါတယ်... သင့်ပရိုဖိုင်ကို အပ်ဒိတ်လုပ်ပြီးပါပြီ။",
    "Phone number": "ဖုန်းနံပါတ်",
    required: "မဖြစ်မနေဖြည့်ပေးရန်လိုအပ်ပါတယ်",
    Select: "နိုင်ငံရွေးပေးပါ  ",
    "Select the country code": "နိုင်ငံကုတ် ရွေးပေးပါ",
    "Please select a country code": "ကျေးဇူးပြု၍ နိုင်ငံကုတ် ရွေးပေးပါ",
    "Please remove any leading zeros when entering your phone number. For example, if your phone number is 0827474736, enter it as 827474736.":
      "သင့်ဖုန်းနံပါတ်ကို ရိုက်ထည့်သောအခါ အရှေ့က သုညများကို ဖယ်ရှားပါ။ ဥပမာအားဖြင့်၊ သင့်ဖုန်းနံပါတ်သည် 0827474736 ဖြစ်ပါက၊  827474736 ကိုသာ ရိုက်ထည့်ပါ။",
    "Enter your phone number": "ဖုန်းနံပါတ် ရိုက်ထည့်ပါ",
    "Enter the phone number you always use. Do not include the country code, such as (+95, +66, +977, +855, +60), here again.":
      "သင်အမြဲအသုံးပြုနေသည့် ဖုန်းနံပါတ်ကို ထည့်ပါ။ ဤနေရာတွင် (+95၊ +66၊ +977၊ +855၊ +60) ကဲ့သို့သော နိုင်ငံကုဒ်ကို ထပ်မံမထည့်ပါနှင့်။  ",
    "Please enter valid phone number":
      " ခေါ်ဆိုလို့ရတဲ့ ဖုန်းနံပါတ်အမှန် ရိုက်ထည့်ပါ",
    "Enter OTP:": "OTPထည့်ပါ:",
    "Enter 6 digits OTP": "OTPကုတ်နံပါတ် ၆ လုံးထည့်ပါ",
    "Enter OTP": "OTPထည့်ပါ",
    "Your OTP will expire in {expiryTime}":
      "OTP ကုတ်ထည့်ရန် အချိန်{expiryTime}သာလိုတော့သည်",
    "Please select gender": "ကျား/မ ရွေးပေးပါ",
    "Please select nationality": "ကျေးဇူးပြု၍ နိုင်ငံသား(လူမျိုး) ရွေးပေးပါ",
    "Please select Country of residence":
      "ကျေးဇူးပြု၍ မိမိလက်ရှိနေထိုင်သည့်နိုင်ငံ ရွေးပေးပါ",
    "Country of residence": "လက်ရှိနေထိုင်သည့်နိုင်ငံ",
    "Get OTP": "OTP ရယူမည်",
    "Update & Get OTP": "အပ်ဒိတ်လုပ်ပြီး OTP ပြန်ရယူမည်",
    "Add & Get OTP": "ဖုန်းနံပါတ်တပ်ဖြည့်ပြီး OTP ပြန်ရယူမည်",
    "Add Phone Number": "ဖုန်းနံပါတ်ထည့်မည်",
    "Confirm OTP": " OTP အတည်ပြုပါမည်",
    "Resend OTP": "OTP ပြန်ပို့မည်",
    Previous: "အနောက်သို့",
    Next: "ရှေ့သို့",
    Submit: "တင်ပြမည်",
    "Submit Resume": "အလုပ်လျှောက်လွှာပို့မည်",
    "Permission & Remark":
      "အချက်အလက်များ ကြည့်ရှုခွင့်ပြုခြင်း နှင့် မှတ်ချက်ရှိရင်ချရေးရန်",
    "This Recruiter might need to see your documents and information to confirm you are eligible for the job.":
      "ဒီအလုပ်ခေါ်စာအတွက်ကိုက်ညှီမှုရှိ/မရှိစစ်ကြည့်ရန် အေဂျင်စီ မှသင်တွဲထားသည့်အထောက်အထားများနှင့် ကိုယ်ရေးအချက်အလက်များ ကို ကြည့်ရှုရန်လိုအပ်ပါတယ်။",
    "This Employer might need to see your documents and information to confirm you are eligible for the job.":
      "ဒီအလုပ်ခေါ်စာအတွက်ကိုက်ညှီမှုရှိ/မရှိစစ်ကြည့်ရန် အလုပ်ရှင်မှသင်တွဲထားသည့်အထောက်အထားများနှင့် ကိုယ်ရေးအချက်အလက်များ ကို ကြည့်ရှုရန်လိုအပ်ပါတယ်။",
    "You can delete your documents and any personal information anytime if you desire to do so. Do you understand this and consent to letting them look at your resume and documents?":
      "ထိုအချက်အလက်တွေကို ဘယ်အချိန်ဖြစ်ဖြစ် ပြန်ဖျက်နိုင်ပါတယ်။ ဖြည့်ထားသည့် အချက်လက်များနှင့် စာရွက်စာတမ်းတွေကို ကြည့်ခွင့်ပေးဖို့ သဘောတူရင်အမှန်ခြစ်ကလေးနှိပ်ပေးပါနော်။ ",
    Remark: "မှတ်ချက်",
    "Enter remark": "မှတ်ချက်ရေးရန်",
    Cancel: "မလုပ်တော့ပါ",
    "Submitting Resume": "အလုပ်လျှောက်လွှာပို့မည် ",
    "Confirmation!": "အတည်ပြုခြင်း! ",
    "Are you sure want to withdraw this application? Your application will no longer be seen in {business_name}'s list.":
      "သင့်ရဲ့အလုပ်လျှောက်လွှာကို ရုတ်သိမ်းဖို့ သေချာပြီလား? {business_name}' အလုပ်ရှင် မှ သင့်အလုပ်လျှောက်လွှာကို မြင်ရတော့မှာ မဟုတ်ဘူးနော်။     ",
    "Withdrawing...": "လုပ်ဆောင်နေပါသည်",
    "Yes, withdraw": "ဟုတ်ပါတယ်။ ရုတ်သိမ်းပါမယ်။",
    "Ask a question": "မေးခွန်းမေးရန်",
    "Your question goes here...": "မေးခွန်း ရိုက်ထည့်ပါ",
    "Submit a question": "မေးခွန်းမေးမည်",
    "Successfully gave the ratings!": "အမှတ်ပေးတာ အောင်မြင်သွားပါပြီ။",
    "Loading...": "လုပ်ဆောင်နေသည် ...",
    "Submit rating": "အမှတ်ပေးမည်",
    more: "ပိုမိုသိလိုပါက..",
    "Share App": "အပ်ပလီကေးရှင်းကို ရှယ်ရန်",
    Share: "ရှယ်ရန်",
    Reactions: "ရီအက်ပေးမည်",
    "Username or email": "အကောင့်အမည် (သို့မဟုတ်) အီးမေးလ်",
    Password: "လျှို့ဝှက်ကုတ်",
    "Show Password": "ဖြည့်ထားသည့်လျှို့ဝှက်ကုတ်တူ/မတူကြည့်မည်",
    Login: "ဝင်ရန်",
    "Successfully sent the resume":
      "အလုပ်လျှောက်လွှာကိုအောင်မြင်စွာပို့လိုက်ပါပြီ",
    "Couldn't sent the resume due to server error":
      "လိုင်းကျနေပါသဖြင့် အလုပ်အလျှောက်လွှာပို့လို့မရဖြစ်သွားပါသည်",
    "Successfully extended the application !":
      "သင့်ရဲ့အလုပ်လျှောက်လွှာအားလုံးကို သက်တမ်းတိုးထားပြီးပါပြီ။",
    "You already sent your cv to this employer":
      "ဒီအလုပ်ရှင်ထံသို့ သင့်ရဲ့အလုပ်လျှောက်လွှာပို့ထားပြီးပါပြီ။",
    "Withdraw application": "အလုပ်လျှောက်လွှာ ပြန်ရုတ်သိမ်းမည်။",
    "Extending...": "လုပ်ဆောင်နေသည် ...",
    "Extend application": "အလုပ်လျှောက်လွှာဖောင် သက်တမ်းတိုးမည်။",
    "Employer viewed your application":
      "အလုပ်ရှင်မှ သင့်ရဲ့အလုပ်လျှောက်လွှာကို ကြည့်ပြီးပါပြီ။  ",
    "Employer Downloaded your resume":
      "အလုပ်ရှင်မှ သင့်ရဲ့အလုပ်လျှောက်လွှာကို ဒေါင်းလုတ်ဆွဲထားပြီးပါပြီ။",
    "Employer Moved your application to General Waitlist. Please wait Until he/she will shortlist you again for future job post.":
      "လက်ရှိခေါ်တဲ့အလုပ်မှာ လူပြည့်သွားပြီဖြစ်တဲ့အတွက် အလုပ်ရှင်က သင့်အလုပ်လျှောက်လွှာကို နောက်ထပ်ခေါ်ဆိုမယ့် ရာထူး‌အတွက် သိမ်းဆည်းပေးထားပါတယ်။ အလုပ်ရှင်ကနေ မဆက်သွယ်လာမချင်း အလုပ်သစ်ရှာနိုင်သလို စောင့်ဆိုင်းလို့လည်းရပါတယ်။",
    "Employer Shortlisted your application":
      "အလုပ်ရှင်မှ သင့်ရဲ့အလုပ်လျှောက်လွှာဖောင်ကို ပထမအဆင့်ဦးစားပေးစာရင်းမှာ ထည့်သွင်းထားပါတယ်။",
    "You cannot send your resume to this employer as you are not residing in same country with this employer":
      "ဒီအလုပ်ရှင် တည်ရှိရာနိုင်ငံ မှာနေထိုင်တာမဟုတ်သည့်အတွက် သင့်အလုပ်လျှောက်လွှာ ပေးပို့လို့ မရပါ။",
    "Successfully withdrew the application !":
      "အလုပ်လျှောက်လွှာကို ရုတ်သိမ်းလိုက်ပါပြီ။",
    "Couldn't withdraw the application due to server error":
      "လိုင်းကျနေပါသဖြင့် အလုပ်လျှောက်လွှာရုတ်သိမ်းခြင်းမအောင်မြင်သေးပါ။",
    "Employer Detail": "လုပ်ငန်းရှင်အချက်အလက်အသေး",
    Description: "လုပ်ငန်းအကြောင်း",
    "Registered:": "မှတ်ပုံတင်ပြီး",
    "License number:": "လိုင်စင် အမှတ် -",
    "Valid to:": "လိုင်စင်သက်တမ်း ကုန်ဆုံးမည့် ရက်စွဲ -",
    "Contact Information": "ဆက်သွယ်ရန်",
    "Rated by ({number}) viewers": " ({number}) ယောက် အမှတ်ပေးထားပါတယ်။",
    Address: "လိပ်စာ",
    "You can not sent your resume for this employer":
      "အလုပ်ရှင်ထံကိုအလုပ်လျှောက်လွှာပေးပို့လို့မရပါ",
    "Click here to create a resume":
      "အလုပ်လျှောက်လွှာပေးပို့ရန် ဤနေရာကို နှိပ်ပါ",
    "Loading..": "လုပ်ဆောင်နေသည် ...",
    "Sorry, no employers matched filter parameters, please try again.":
      "ဝမ်းနည်းပါတယ် သင်ရှာဖွေသော အချက်နှင့်ကိုက်ညီသည့် အလုပ်ရှင်အမည် စာရင်းထဲတွင် မရှိပါ",
    "Industries:": "စက်ရုံအမျိုးအစားများ -",
    "Job Opportunities:": "အလုပ်အကိုင် အခွင့်အလမ်းများ",
    "{employer_name} has listed ({number_of_jobs}) job opportunities at marketplace":
      "{employer_name} မှ အလုပ်အကိုင်အခွင့်အလမ်း ({number_of_jobs}) ခု အလုပ်ရှာဖွေရာနေရာတွင် တင်ထားပါသည်",
    Rating: "ရမှတ်များ",
    "View rating": "ရမှတ်ကြည့်ရန်",
    "Give rating": "အမှတ်ပေးရန်",
    "Please login to write a comment..": "ကွန်မန့်ရေးရန် အကောင့်သို့ဝင်ပါ..",
    "Change in CMS": "CMS မှာ",
    "Write a comment here...": "ဒီနေရာမှာကွန်မန့်ရေးပါ...",
    "Comment Deleted !": "ကွန်မန့်ဖျက်လိုက်ပြီ!",
    "Delete Comment": "ကွန်မန့်ဖျက်မည်",
    "Are you sure you want to delete this comment?":
      "ဒီကွန်မန့်ကိုဖျက်မှာ တစ်ကယ်သေချာပါပြီလား",
    Delete: "ဖျက်မည်",
    "Edit post": "ပို့ပြင်မည်",
    "Comment Editor": "ကွန်မန့်ပြင်သူ",
    "Update your comment": "ကွန်မန့်အသစ်ပြင်မည်",
    "Write your updated comment here ...":
      "သင့်အသစ်ပြင်ဆင်ထားသော ကွန်မန့်ကို ဤနေရာတွင် ရေးပါ......",
    "Save Changes": "ပြောင်းလဲချက်ကိုအတည်ပြုမည်",
    "Delete post": "ပို့စ်ဖျက်မည်",
    "Delete Post": "ပိုစ့်ဖျက်မည်",
    "Delete your post?": "ပိုစ့်ဖျက်မည်",
    "Are you sure you want to delete this post?":
      "ဒီပိုစ့်ကို ပြန်ဖျက်ဖို့ တစ်ကယ်သေချာပြီလား",
    like: "ကြိုက်သည်",
    s: "များ",
    "Like List": "ကြိုက်နှစ်သက်မှုလုပ်ခဲ့သည့်စာရင်း",
    "Post Detail": "ပို့စ် အသေးစိတ်",
    "Go back Top": "အပေါ်ဘက်သို့",
    "Successfully updated the post !":
      "ပိုစ့်အပ်ဒိတ်လုပ်ခြင်း အောင်မြင်သွားပါပြီ။",
    "Post Editor": "ပိုစ့်ပြုပြင်သူ",
    "Edit your post": "ပိုစ့်ပြင်မည်",
    "Caption:": "ခေါင်းစဥ်:",
    "Please write a caption": "ခေါင်းစဥ်ရေးပါ...",
    "Article:": "အကြောင်းအရာ:",
    "Please write an article ...": "အကြောင်းအရာရေးပါ...",
    "Please choose an image to update": "အပ်ဒိတ်လုပ်ရန်ပုံတစ်ပုံ ရွေးချယ်ပါ",
    Update: "အပ်ဒိတ်လုပ်ဆောင်နေသည် ...",
    "Edit Post ": "ပိုစ့်ပြင်မည်",
    "Edit Post": "ပိုစ့်ပြင်မည်",
    "...See more": ".........ထပ်ကြည့်မည်",
    "Group Forum": "မေးမယ်၊ဖြေမယ်၊ရှယ်ကြမယ် ",
    "No posts found!": "မည်သည့်ပိုစ်(အကြောင်းအရာ) မှရှာမတွေ့ပါ။",
    "Please login or register new account":
      "အကောင့်သို့ဝင်ပါ (သို့) အကောင့်အသစ်ဖွင့်ပါ",
    "Please select image! Image size should be less than 5MB":
      "ဓါတ်ပုံရွေးပါ။ ဓါတ်ပုံဆိုက်သည် ၅မက်ဂါဗိုက်ထက်ကြီးလို့မရပါ။",
    "Successfully posted !": "ပို့စ်တင်ပြီးပြီ",
    "What would you like to discuss?": "ဘယ်အကြောင်းအရာကို ဆွေးနွေးချင်တာလဲ? ",
    "Write your discussion here": "ဆွေးနွေးချင်သည့်အကြောင်းအရာကို ဒီမှာရေးပါ",
    "Write discussion here...": "ဆွေးနွေးချင်သည့်အကြောင်းအရာကို ဒီမှာရေးပါ....",
    "Choose an image": "ပုံတစ်ပုံရွေးပါ",
    "Selected Image": "ရွေးချယ်ထားသောပုံ",
    "Remove Image": "ပုံကိုပြန်ဖျက်မည်",
    Posting: "ပို့စ် တင်နေသည်",
    Post: "ပို့စ်တင်မည်",
    Topics: "ဆွေးနွေးမည့် ခေါင်းစဉ်",
    "All countries": "နိုင်ငံအားလုံး",
    Sort: "အမျိုးအစား စိစစ်ခြင်း",
    "A to Z": "က မှ အ",
    "Z to A": "အ မှ က",
    "{number} found": "စုစုပေါင်း {number} ခု",
    Search: "ရှာဖွေရန်",
    "Loading ...": "လုပ်ဆောင်နေဆဲ",
    "Sorry, no groups matched filter parameters, please try again.":
      "ဝမ်းနည်းပါတယ် သင်ရှာဖွေသော အချက်နှင့်ကိုက်ညီသည့် အချက်အလက် စာရင်းထဲတွင် မရှိပါ",
    "Please login to write an answer..": "ဖြေဆိုရန်အတွက် အကောင့်သို့ဝင်ပါ",
    "Pending verification": "အတည်ပြုရန် စိစစ်နေသည်..",
    Guides: "အလုပ်သမားအခွင့်အရေး",
    "All categories": "အမျိုးအစားအားလုံး",
    Category: "အမျိုးအစား",
    "Date: Newest": "နေ့စွဲ-အသစ်များ",
    "Date: Oldest": "နေ့စွဲ-အဟောင်းများ",
    "Sorry, no guides matched filter parameters, please try again.":
      "ဝမ်းနည်းပါတယ် သင်ရှာဖွေသော အချက်နှင့်ကိုက်ညီသည့် အချက်အလက် စာရင်းထဲတွင် မရှိပါ",
    "Welcome, {name}": "ရွှေအိပ်မက်က ကြိုဆိုပါတယ်..{name}။",
    "new user": "အကောင့်အသစ်",
    guest: "ယာယီ အသုံးပြုသူ",
    "Enable notifications:":
      "ထူးခြားမှုရှိတိုင်း အသိပေးချက် (နိုတီ) များရယူလိုခြင်းရှိ/မရှိ ",
    Off: "မယူပါ",
    On: "ဖွင့်မည်",
    Logout: "အကောင့်မှ ထွက်ရန်",
    "Job Applications": "လျှောက်ထားသောအလုပ်များ",
    "Sorry, no job applications found!":
      "၀မ်းနည်းပါတယ်၊ ဘယ်အလုပ်လျှောက်လွှာဖောင်မှ ရှာမတွေ့ပါ။",
    Jobs: "အလုပ်များ",
    Industry: "လုပ်ငန်း",
    "Most Popular": "လူကြည့် အများဆုံး",
    "Most Applied": "လျှောက်ထားမှု အများဆုံး",
    "New Jobs Available !": "ဗားရှင်းအသစ်သုံးလို့ရပါပြီ!",
    "There are some jobs available at the marketplace now {provinceAndIndustry}, have you checked them out?":
      "အလုပ်ရှာဖွေရာနေရာမှာ {provinceAndIndustry} အတွက် အလုပ်တွေခေါ်နေပါတယ်၊ အဲ့အလုပ်တွေကိုကြည့်ပြီးသွားပြီလား။",
    Check: "ကြည့်မယ်",
    "Job Application Confirmation !": "အလုပ်လျှောက်ကြောင်းအတည်ပြုခြင်း!",
    "Are you sure you want to apply for this {employer} Company for this position?":
      " {employer} ကုမ္ပဏီမှ ခေါ်ယူနေသည့် ဒီအလုပ်နေရာအတွက် သင်အလုပ်လျှောက်ချင်တာတစ်ကယ်သေချာပါရဲ့လား။",
    "Yes, Sure": "ဟုတ်ကဲ့ သေချာပါတယ်",
    NRC: "မှတ်ပုံတင်",
    "Household List": "အိမ်ထောင်စုစာရင်း",
    "Passport (PJ)": "PJ ပတ်စပို့ ",
    "Labour Card": "အလုပ်သမားကတ်ပြား",
    "Covid Vaccine Certificate": "ကိုဗစ်-၁၉ ကာကွယ်ဆေးထိုးကတ်ပြား",
    Passport: "ပတ်စပို့",
    "Identification Card": "သက်သေခံကတ်ပြား",
    "Birth Certificate": "မွေးစာရင်း",
    "Family Record Book": "မိသားစုမှတ်တမ်းစာအုပ်",
    "Residence Book": "နေထိုင်ကြောင်းအထောက်အထားစာအုပ်",
    "Work Permit": "ဝေါ့ခ်ပါမစ်",
    Visa: "ဗီဇာ",
    "Pink Card": "ပန်းရောင်ကတ်",
    "Cancellation Letter": "အလုပ်ပယ်ဖျက်စာ",
    "Successfully accepted job offer":
      "အလုပ်ရှင်၏ အလုပ်ကမ်းလှမ်းခန့်အပ်စာကို လက်ခံပြီးပြီ",
    "Couldn't accept due to server error":
      "လိုင်းကျနေပါသဖြင့် အလုပ်လက်ခံမှု မအောင်မြင်ပါ",
    "Network error, please try again later":
      "ဆာဗာအတွင်းအမှားတစ်စုံတစ်ရာဖြစ်သွားသည် - ကျေးဇူးပြု၍ နောက်တစ်ခါ ထပ်ကြိုးစားပါ။ ",
    "Unexpected error, please try again":
      "ဆာဗာအတွင်းအမှားတစ်စုံတစ်ရာဖြစ်သွားသည် - ကျေးဇူးပြု၍ နောက်တစ်ခါ ထပ်ကြိုးစားပါ။ ",
    "Loading…": "လုပ်ဆောင်နေဆဲ",
    Apply: "အလုပ်လျှောက်မည်",
    "Sorry you cannot apply for this job. This job is for the jobseekers who live in {country}.":
      "ဝမ်းနည်းပါတယ်..ဒီအလုပ်ကိုလျှောက်လို့မရဘူးနော်။ အခုအလုပ်က {country} နိုင်ငံရောက်နေသူတွေအတွက်ပါ။",
    "Sorry you cannot apply job now as current jobs are for those in Thailand.":
      "တောင်းပန်ပါတယ်..မြန်မာပြည်ကသူတွေလျှောက်လို့မရသေးပါဘူးနော်။ အခုအလုပ်က ထိုင်းရောက်နေသူတွေအတွက်ပါ။",
    "Click here to create a resume to apply for this job":
      "အလုပ်လျှောက်ရန်အတွက် အလုပ်လျှောက်လွှာဖြည့်ရန် ဤနေရာကို နှိပ်ပါ။",
    "Successfully rejected job offer": "အလုပ်ကမ်းလှမ်းစာကို ငြင်းပယ်ပြီးပြီ",
    "Couldn't reject due to server error":
      "လိုင်းကျနေပါသဖြင့် ငြင်းပယ်မှု မအောင်မြင်ပါ",
    "Confirm Reject Job Offer": "အလုပ်ကမ်းလှမ်းစာကိုငြင်းပယ်ရန် အတည်ပြုပါသည်",
    "Are you sure you would like to reject the job offer? This action cannot be undone later.?":
      "အလုပ်ကမ်းလှမ်းစာကို ငြင်းပယ်ရန်သေချာပြီလား၊ နောက်မှ ဤဆုံးဖြတ်ချက်ကို ပြန်ပြင်၍မရပါ။",
    Confirm: "ငြင်းပယ်ရန် အတည်ပြုပါသည်",
    "Rejection Reason": "အလုပ်အတွက်မရွေးချယ်ရသည့်အကြောင်းအရင်း",
    Details: "အသေးစိတ်",
    Posted: "အလုပ်လျှောက်လွှာဖွင့်ရက်",
    "Closing date": "အလုပ်လျှောက်လွှာပိတ်ရက်",
    "Employer Pays Policy": "အလုပ်ရှင်မှ အလုပ်ရှာဖွေရေးကုန်ကျစရိတ်ကျခံပါမည်",
    "Employer does not have Employer Pays Policy":
      "အလုပ်ရှင်က အလုပ်ရှာဖွေရေးကုန်ကျစရိတ်ကို မကျခံပါ",
    "Employer Size": "လုပ်ငန်းအရွယ်အစား",
    "Nationalities of Workers working at this workplace":
      "လာရောက်လုပ်ကိုင်ကြသည့် နိုင်ငံအလိုက်လုပ်သားများ",
    "Number of Workers Needed": "လိုအပ်သော လုပ်သားဦးရေ",
    "Number of Male Headcount Needed": "လိုအပ်သော အမျိုးသားလုပ်သားဦးရေ",
    "Number of Female Headcount Needed": "လိုအပ်သော အမျိုးသမီးလုပ်သားဦးရေ",
    "Available Shifts": "အလုပ်ဆိုင်းများ",
    "Overtime Expectation": "အချိန်ပို(အိုတီ)ရနိုင်ခြေ",
    "Minimum Working Hours": "တစ်ပတ်အတွင်း အနည်းဆုံးအလုပ်ချိန်",
    "Day of Week Off": "အလုပ်ပိတ်ရက်",
    "Job Description": "လုပ်ရမည့်အလုပ်များ",
    "Job Requirements": "အလုပ်အတွက် လိုအပ်ချက်များ",
    "Job Industry": "လုပ်ငန်းအမျိုးအစား",
    "Job Position Category": "အလုပ်ရာထူး အမျိုးအစား",
    "Job Position Details": "အလုပ်ရာထူး အသေးစိတ်",
    "Terms of Payment": "လုပ်အားခပေးချေမှု",
    "Timing of Payment": "လုပ်အားခပေးချေသည့်အချိန်",
    "Timing of Payment Other": "လုပ်အားခပေးချေသည့် အခြားအချိန်",
    Salary: "လုပ်ခ/လစာ",
    "{amount} {currency}/month": "{amount} {currency}(၁)လ",
    "{amount} {currency}/day": "{amount} {currency}(၁)ရက်",
    "Job Type": "အလုပ်သမားအမျိုးအစား",
    "Contract Duration": "စာချုပ်သက်တမ်း",
    "{duration} months": "{duration} လ",
    "Regular OT Rate": "ပုံမှန် OT ကြေး",
    "{amount} {currency}/hour": "{amount} {currency}(၁)နာရီ",
    "Holiday OT Rate": "အစိုးရရုံးပိတ်ရက်အိုတီကြေး ",
    "Holiday Rate": "အစိုးရရုံးပိတ်ရက်/နားရက် လုပ်အားခ",
    "ER information": "ကျင့်ဝတ်နှင့်အညီလုပ်သားစုဆောင်းခြင်း",
    "Ethical recruitment policy":
      "ကျင့်ဝတ်နှင့်ညီသော လုပ်သားစုဆောင်းမှု ရှိပါသည်",
    "Not Have Ethical recruitment policy":
      "ကျင့်ဝတ်နှင့်ညီသော လုပ်သားစုဆောင်းမှုမရှိပါ",
    "RA has Ethical recruitment policy":
      "အေဂျင်စီတွင် ကျင့်ဝတ်နှင့်ညီသော လုပ်သားစုဆောင်းမှုစနစ်ရှိပါသည်",
    "RA has not the Ethical recruitment policy":
      "အေဂျင်စီတွင် ကျင့်ဝတ်နှင့်ညီသော လုပ်သားစုဆောင်းမှုစနစ်မရှိပါ",
    "Employer pays policy": "အလုပ်ရှင်က အလုပ်ရှာဖွေရေးကုန်ကျစရိတ် ကျခံပေးပါသည်",
    "Not have Employer pays policy":
      "အလုပ်ရှင်က အလုပ်ရှာဖွေရေးကုန်ကျစရိတ် ကျခံပေးခြင်းမရှိပါ",
    "Recruitment Information": "အလုပ်သမားခေါ်ယူမှုဆိုင်ရာ အချက်အလက်များ",
    "Not have Ethical recruitment policy":
      "ကျင့်ဝတ်နှင့်ညီသော လုပ်သားစုဆောင်းမှုမရှိပါ",
    "The workers have to pay visa fee": "ဗီဇာခကို အလုပ်သမားဘက်မှ ကျခံရပါမည်",
    "Employer pays for the visa fees":
      "ဗီဇာလျှောက်ခ ကုန်ကျစရိတ်ကို အလုပ်ရှင်မှ ကျခံပေးပါသည်",
    "The workers have to pay work permit fee":
      "ဝေါ့ခ်ပါမစ်ခ အလုပ်သမားဘက်မှ ကျခံရပါမည်",
    "Employer pays for the work permit fees":
      "ဝေါ့ခ်ပါမစ်အတွက်ကုန်ကျစရိတ်ကို အလုပ်ရှင်မှ ကျခံပေးပါသည်",
    "The workers have to pay medical checkup fee":
      "ရောဂါ(၆)မျိုးဆေးစစ်ခကို အလုပ်သမားမှကျခံရပါမည်",
    "Employer pays for the medical checkup fees":
      "ရောဂါ(၆)မျိုးဆေးစစ်ခကို အလုပ်ရှင်မှ ကျခံပေးပါသည်",
    "Skill Training Information": "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်း",
    "Employer pays Cost of skill-training":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းကုန်ကျစရိတ်ကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay Cost of skill-training":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းကုန်ကျစရိတ်ကို အလုပ်သမားမှကျခံရပါမည်",
    "{amount} {currency}": "{amount} {currency} ",
    "The employer provides accommodation during skill-training of Workers":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်နေစဉ် နေစရိတ်ကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay accommodations fee during skill-training of Workers":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်နေစဉ် နေစရိတ်ကို အလုပ်သမားမှကျခံရပါမည်",
    "The employer provides meals during skill-training of Workers":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်နေစဉ် စားစရိတ်ကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay meals fee during skill-training of Workers":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်နေစဉ် စားစရိတ်ကို အလုပ်သမားမှကျခံရပါမည်",
    "Based on individual cost/spending.":
      "ကိုယ့်အသုံးစွဲမှုပေါ်မူတည်ပြီးကုန်ကျသလောက်စိုက်ရန်",
    "The employer pays transportation costs to and from for attending skill training":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်ရန်အတွက် အသွားအပြန်သွားစရိတ်ကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay transportation costs to and from for attending skill training":
      "ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်ရန်အတွက် အသွားအပြန်သွားစရိတ်ကို အလုပ်သမားမှကျခံရပါမည်",
    "Costs For Pre-Departure": "ပြည်ပမထွက်ခွာမီ ကုန်ကျစရိတ်",
    "Employer pays Cost of pre-departure orientation training":
      "အေဂျင်စီမှပေးသည့် ပြည်ပမထွက်ခွာမီလမ်းညွှန်သင်တန်းအတွက်ကုန်ကျစရိတ်ကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay Cost of pre-departure orientation training":
      "အေဂျင်စီမှပေးသည့် ပြည်ပမထွက်ခွာမီလမ်းညွှန်သင်တန်းအတွက်ကုန်ကျစရိတ်ကို အလုပ်သမားမှကျခံရပါမည်",
    "Employer provides accommodation during pre-departure orientation training":
      "အေဂျင်စီမှပေးသည့် ပြည်ပမထွက်ခွာမီလမ်းညွှန်သင်တန်းတက်နေစဉ် နေရာထိုင်ခင်းအတွက် အလုပ်ရှင်မှစီစဉ်ပေးပါသည်",
    "The workers need to pay accommodations fee during pre-departure orientation training":
      "အေဂျင်စီမှပေးသည့် ပြည်ပမထွက်ခွာမီလမ်းညွှန်သင်တန်းတက်နေစဉ် နေရာထိုင်ခင်းအတွက် အလုပ်သမားမှကျခံရပါမည်",
    "Employer provides meals during pre-departure orientation training":
      "အေဂျင်စီမှပေးသည့် ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်နေစဉ် စားသောက်ရေးအတွက် အလုပ်ရှင်မှစီစဉ်ပေးပါသည်",
    "The workers need to pay meals fee during pre-departure orientation training":
      "အေဂျင်စီမှပေးသည့် ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်နေစဉ် စားသောက်ရေးအတွက် အလုပ်သမားမှကျခံရပါမည်",
    "Employer pays transportation costs to and from pre-departure orientation training":
      "အေဂျင်စီမှပေးသည့် ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်ဖို့ လမ်းခရီးစရိတ်ကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay transportation costs to and from pre-departure orientation training":
      "အေဂျင်စီမှပေးသည့် ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်ဖို့ လမ်းခရီးစရိတ်ကို အလုပ်သမားမှကျခံရပါမည်",
    "Additional information for Pre-departure orientation course":
      "အေဂျင်စီမှပေးသည့် ပြည်ပမထွက်ခွာမီလမ်းညွှန်သင်တန်းအကြောင်း အခြားအချက်အလက်များ",
    "Costs and Fees for recruitment in the origin country":
      "မိခင်နိုင်ငံတွင်း လုပ်သားခေါ်ယူစုဆောင်းမှုစရိတ်များ",
    "Employer pays recruitment service fee to RA and sub-agent":
      "အေဂျင်စီနှင့် လက်အောက်ခံကိုယ်စားလှယ်များအတွက် လုပ်သားခေါ်ယူစုဆောင်းမှုဝန်ဆောင်ခကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay recruitment service fee to RA and sub-agent":
      "အေဂျင်စီနှင့် လက်အောက်ခံကိုယ်စားလှယ်များအတွက် လုပ်သားခေါ်ယူစုဆောင်းမှုဝန်ဆောင်ခကို အလုပ်သမားမှကျခံရပါမည်",
    "Employer pays travel costs for a job interview":
      "အလုပ်အင်တာဗျူးဖြေရန် သွားရသည့်လမ်းခရီးကုန်ကျစရိတ်ကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay travel costs for a job interview":
      "အလုပ်အင်တာဗျူးဖြေရန် သွားရသည့်လမ်းခရီးကုန်ကျစရိတ်ကို အလုပ်သမားမှကျခံရပါမည်",
    "Employer pays accommodation costs for contract signing":
      "အလုပ်စာချုပ်ချုပ်ဆိုရန်သွားရောက်ရသည့်အခါ နေရာထိုင်ခင်းစရိတ်ကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay accommodation costs for contract signing":
      "အလုပ်စာချုပ်ချုပ်ဆိုရန်သွားရောက်ရသည့်အခါ နေရာထိုင်ခင်းစရိတ်ကို အလုပ်သမားမှကျခံရပါမည်",
    "Employer pays transportation cost to and from for contract signing":
      "အလုပ်စာချုပ်ချုပ်ဆိုရန်သွားရောက်ရသည့်အခါ လမ်းခရီးစရိတ်ကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay transportation cost to and from for contract signing":
      "အလုပ်စာချုပ်ချုပ်ဆိုရန်သွားရောက်ရသည့်အခါ လမ်းခရီးစရိတ်ကို အလုပ်သမားမှကျခံရပါမည်",
    "Employer provides meals for contract signing":
      "အလုပ်စာချုပ်ချုပ်ဆိုရန်သွားရောက်ရသည့်အခါ စားသောက်ရေးစရိတ်ကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay meals fee for contract signing":
      "အလုပ်စာချုပ်ချုပ်ဆိုရန်သွားရောက်ရသည့်အခါ စားသောက်ရေးစရိတ်ကို အလုပ်သမားမှကျခံရပါမည်",
    "Employer pays medical check-up fee":
      "ရောဂါ(၆)မျိုးဆေးစစ်ခကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay medical check-up fee":
      "ရောဂါ(၆)မျိုးဆေးစစ်ခကို အလုပ်သမားမှကျခံရပါမည်",
    "Employer pays passport application fee":
      "ပတ်စပို့လျှောက်သည့် တရားဝင်ကုန်ကျစရိတ်ကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay passport application fee":
      "ပတ်စပို့လျှောက်သည့် တရားဝင်ကုန်ကျစရိတ်ကို အလုပ်သမားမှကျခံရပါမည်",
    "Employer pays Overseas Worker Identification Card (OWIC) fee":
      "ပြည်ပအလုပ်ကိုင်အလုပ်သမားသတ်သေခံကတ်ပြား လျှောက်သည့်ကုန်ကျစရိတ်ကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay Overseas Worker Identification Card (OWIC) fee":
      "ပြည်ပအလုပ်ကိုင်အလုပ်သမားသတ်သေခံကတ်ပြား လျှောက်သည့်ကုန်ကျစရိတ်ကို အလုပ်သမားမှကျခံရပါမည်",
    "Employer pays Transportation costs to the border or airport":
      "လေဆိပ် (သို့မဟုတ်) နယ်စပ်ထိသွားရမည့် လမ်းခရီးကုန်ကျစရိတ်ကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay Transportation costs to the border or airport":
      "လေဆိပ် (သို့မဟုတ်) နယ်စပ်ထိသွားရမည့် လမ်းခရီးကုန်ကျစရိတ်ကို အလုပ်သမားမှကျခံရပါမည်",
    "Costs and fees for recruitment in the destination country":
      "သွားရောက်အလုပ်လုပ်မည့်နိုင်ငံတွင်း လုပ်သားခေါ်ယူစုဆောင်းမှု စရိတ်များ",
    "Employer pays Transportation cost from the Thai border to the workplace (or to the destination country)":
      "ထိုင်းနယ်စပ်မှ သူုနိုင်ငံထဲ (သို့) အလုပ်လုပ်ရမည့်စက်ရုံထိ လမ်းခရီးကုန်ကျစရိတ်ကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay Transportation cost from the Thai border to the workplace (or to the destination country)":
      "ထိုင်းနယ်စပ်မှ သူုနိုင်ငံထဲ (သို့) အလုပ်လုပ်ရမည့်စက်ရုံထိ လမ်းခရီးကုန်ကျစရိတ်ကို အလုပ်သမားမှကျခံရပါမည်",
    "Employer pays Visa fee": "ဗီဇာကြေးကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay Visa fee": "ဗီဇာကြေးကိုအလုပ်သမားမှကျခံရပါမည်",
    "Employer pays Work permit": "ဝေါ့ခ်ပါမစ်ကြေးကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay Work permit":
      "ဝေါ့ခ်ပါမစ်ကြေးကို အလုပ်သမားမှကျခံရပါမည်",
    "Employer pays Medical check-up fee (if workers have to take another check up)":
      "တကယ်လို့နောက်တစ်ကြိမ်ဆေးစစ်ဖို့လိုလာပါက ဆေးစစ်ခကို အလုပ်ရှင်မှကျခံပေးပါသည်",
    "The workers need to pay Medical check-up fee (if workers have to take another check up)":
      "တကယ်လို့နောက်တစ်ကြိမ်ဆေးစစ်ဖို့လိုလာပါက ဆေးစစ်ခကို အလုပ်သမားမှကျခံရပါမည်",
    "Other Costs": "အခြားစရိတ်များ",
    "Information on repatriation": "နေရပ်ပြန်ရေးဆိုင်ရာ အချက်အလက်",
    Benefits: "ခံစားခွင့်များ",
    "Paid annual leave per year":
      "လုပ်အားခခံစားခွင့်ရှိသည့် နှစ်ပြည့်လုပ်သက်ခွင့်(ဖတ်လော)",
    "Paid sick leave per year":
      "လုပ်အားခခံစားခွင့်ရှိသည့် တစ်နှစ်စာ နာမကျန်းခွင့်",
    "Paid business leave per year":
      "လုပ်အားခခံစားခွင့်ရှိသည့် တစ်နှစ်စာ လုပ်ငန်းဆိုင်ရာခွင့်",
    "Paid national holidays per year":
      "လုပ်အားခခံစားခွင့်ရှိသည့် တစ်နှစ်စာ အစိုးရပိတ်ရက်များ",
    "Social security benefits are provided as required by law":
      "လူမှုဖူလုံရေး ခံစားခွင့် ကို ဥပဒေအတိုင်း ခံစားခွင့်ရှိသည်။",
    "Social security and maternity leave benefits are provided as required by law":
      "လူမှုဖူလုံရေး (ပကန်စန်ခုမ်) နှင့် မီးဖွားခွင့်ရက်များကို ဥပဒေအတိုင်း ခံစားခွင့်ရှိသည်။",
    "Social security leave benefits are not provided":
      "လူမှုဖူလုံရေးမှ သတ်မှတ်သည့် ခွင့်ရက်များကိုမပေးပါ။",
    "Social security and maternity leave benefits are not provided":
      "လူမှုဖူလုံရေး (ပကန်စန်ခုမ်) နှင့် မီးဖွားခွင့်ရက်များကိုမပေးပါ။",
    "Any additional leave detail": "အခြားသီးသန့် ခွင့်ရက်များ အသေးစိတ်",
    "Other Benefits": " အခြားခံစားခွင့်များ",
    "Benefits Details": "ခံစားခွင့်များအသေးစိတ်",
    "Other Information": "အခြားအချက်အလက်များ",
    "Accommodation on-site is available": "အလုပ်ရှင်က နေစရာပေးပါမည်",
    "No information is provided on whether Accommodation on-site or not":
      "အလုပ်ရှင်က နေစရာပေး/မပေး စသည့် ချက်အလက်များ ‌မဖော်ပြထားပါ။",
    "Family members can live together": "မိသားစုဝင်များလည်း နေထိုင်နိုင်သည်",
    "No information is provided on whether Family members can live together or not":
      "မိသားစုနဲ့အတူတူ နေထိုင် လို့ရ/မရ စသည့် အချက်အလက်များ မဖော်ပြထားပါ။",
    "Rent & Utilites are auto deducted from the payroll":
      "အခန်းခ၊ ရေခ၊ မီးခကို လစာထဲက ဖြတ်မည်",
    "No information is provided for a deduction on Rent & Utilities":
      " အခန်းခ၊ ရေခ၊ မီးခ ဖြတ်တောက်မှု နှင့်ဆိုင်သော အချက်အလက်များ ‌မဖော်ပြထားပါ။",
    "Safe functioning grievance mechanism":
      "အလုပ်သမားပြဿနာများကို လုံခြုံထိရောက်စွာ ဖြေရှင်းပေးသည်",
    "No Information is provided  for grievance mechanism":
      "ပြဿနာဖြေရှင်းမှုနှင့်ဆိုင်သော အချက်အလက်များ ‌မဖော်ပြထားပါ။",
    "Probation period": "အစမ်းခန့် ကာလ",
    "No Information is provided for probation period":
      "အစမ်းခန့် ကာလ နှင့်ဆိုင်သော အချက်အလက်များ ‌မဖော်ပြထားပါ။",
    "Accommodation Details": "အလုပ်သမားအဆောင်နှင့်ဆိုင်သော အသေးစိတ်အချက်အလက်",
    "Grievance Mechanism Details": "အလုပ်သမားပြဿနာဖြေရှင်းမှု အသေးစိတ်",
    "Probation Period Details": "အစမ်းခန့်ကာလ အသေးစိတ်",
    "Rent/utilites deduction details":
      "အဆောင်ခ၊ ရေ/မီး လစာမှ ဖြတ်တောက်မှုအသေးစိတ်",
    "Contact Person": "ဆက်သွယ်ရမည့်တာဝန်ခံံ",
    "Recruitment Agency Information": "အလုပ်ရှာဖွေရေးအေဂျင်စီ အချက်အလက်များ",
    "Currently, the employer has received enough applicants for this job post and has moved your CV to the waitlist. You will be notified if the employer requires more applicants. If you prefer not to wait for their notification, please consider applying for another job.":
      "လက်ရှိခေါ်တဲ့အလုပ်မှာ လူပြည့်သွားပြီဖြစ်တဲ့အတွက် အလုပ်ရှင်က သင့်အလုပ်လျှောက်လွှာကို စောင့်ဆိုင်းစာရင်းမှာ သိမ်းဆည်းပေးထားပါတယ်။ လက်ရှိအလုပ်ခေါ်စာမှာ အကြောင်းတစ်ခုခုကြောင့် နောက်ထပ်အလုပ်သမားလိုအပ်လျှင် အလုပ်ရှင်ကနေ အလုပ်ခန့်ဖို့ဆက်သွယ်လာပါမယ်။ အလုပ်ရှင် မဆက်သွယ်လာမချင်း အလုပ်သစ်ရှာနိုင်သလို စောင့်ဆိုင်းလို့လည်းရပါတယ်။ ",
    "This employer requested to view and check your documents {timeAgo}. They will be allowed to view them only, not download.":
      "{timeAgo}က ဒီအလုပ်ရှင်မှ သင့်ရဲ့အထောက်အထားစာရွက်စာတမ်းများကိုကြည့်လိုကြောင်းတောင်းဆိုထားပါတယ်၊ သင့်စာရွက်စာတမ်းတွေကို ကြည့်ရုံသက်သက်သာဖြစ်ပြီး အလုပ်ရှင်မှ ဒေါင်းလုတ်ဆွဲနိုင်မည်မဟုတ်ပါ။ ",
    "However, you must first have uploaded the required documents on your resume page:":
      "အလုပ်ရှင်ဖက်က စာရွက်စာတမ်း​တွေကိုကြည့်ဖို့သဘောတူတယ်ဆိုရင် အလုပ်လျှောက်လွှာ (CV ​ဖောင်)တင်တဲ့​နေရာမှာ အထောက်အထားစာရွက်စာတမ်း​တွေကို အရင်တင်ထား​ပေးပါ။",
    "Upload missing documents": "လိုအပ်သည့်စာရွက်စာတမ်းများကို တင်မည်",
    Reject: "အလုပ်ကမ်းလှမ်းစာကို ငြင်းပယ်မည်",
    Accept: "အလုပ်ကမ်းလှမ်းစာကို လက်ခံမည်",
    "Missing resume": "အလုပ်လျှောက်ရန် CV ဖောင် အရင်ဖြည့်ပါ",
    "{clickHereLink} to create your GD resume":
      "CV ဖောင်ဖြည့်ရန် {clickHereLink}နှိပ်ပါ",
    "Click here": "ဤနေရာကို နှိပ်ပါ",
    "Jobs for seekers in Myanmar/Cambodia/Nepal ":
      "မြန်မာ/ကမ္ဘောဒီးယား/နီပေါ မှ အလုပ်ရှာသူများအတွက် အလုပ်များ",
    "All jobs on Marketplace": "အလုပ်ရှာဖွေရာနေရာတွင်တင်ထားသောအလုပ်များအားလုံး",
    "Jobs for seekers in Thailand ": "ထိုင်းရောက်နေသူများအတွက် အလုပ်များ",
    "Please login to view the jobs..":
      "အလုပ်ခေါ်စာကြည့်ရန်  အကောင့်သို့ဝင်ပါ..",
    "Sorry, no recruitment agencies matched filter parameters, please try again.":
      "ဝမ်းနည်းပါတယ် သင်ရှာဖွေသော အချက်နှင့်ကိုက်ညီသည့် အေဂျင်စီ အမည် စာရင်းထဲတွင် မရှိပါ",
    "Successfully applied": "လျှောက်ထားပြီးပြီ",
    "Couldn't apply due to server error":
      "လိုင်းကျနေပါသဖြင့် အလုပ်လျှောက်ခြင်းမအောင်မြင်ပါ။",
    "Please create your CV first": "ကျေးဇူးပြု၍ CVဖောင်အရင်ဖြည့်ပေးပါ",
    Screening: "အခြားသိလိုသည့်အချက်များ",
    "Do you know someone working here?":
      "အခုလျှောက်တဲ့အလုပ်မှာ လုပ်နေတဲ့ဆွေမျိုး၊သူငယ်ချင်း၊ အသိမိတ်ဆွေရှိပါသလား။",
    "No, I don't know": "အသိမရှိပါ",
    "Yes, I know someone": "ဟုတ်ကဲ့..အသိရှိပါတယ်",
    "After going through the job description, do you think you can do the job well?":
      "အလုပ်နဲ့ပတ်သက်တဲ့အချက်အလက်​တွေကို​သေ​သေချာချာဖတ်ပြီးသွားပြီဆို​တော့ ဒီအလုပ်ကို​ကောင်းကောင်းလုပ်နိုင်မယ်လို့ ထင်သလား။",
    "No, I am not sure": "သိပ်မသေချာပါ။",
    "Yes, I think so": "ဟုတ်ကဲ..လုပ်နိုင်မယ်လို့ထင်ပါတယ်",
    "This Employer/Recruiter might need to see your documents and information to confirm you are eligible for the job. ":
      "ဒီအလုပ်ခေါ်စာအတွက်ကိုက်ညှီမှုရှိ/မရှိစစ်ကြည့်ရန် အလုပ်ရှင်/ အေဂျင်စီမှ သင်တွဲထားသည့်အထောက်အထားများနှင့် ကိုယ်ရေးအချက်အလက်များ ကို ကြည့်ရှုရန်လိုအပ်ပါတယ်။ ",
    "No, I don't": "ခွင့်မပြုပါ",
    "Yes, I do": "လက်ခံခွင့်ပြုပါတယ်",
    "No consent was given": "သဘောမတူပါ",
    "Sorry! Employers need to see your documents to confirm you are eligible for the job. The documents required by employers may vary, so you are encourage to share the document you have, so that the employer can check if you are eligible. Is that ok for you?":
      "ဝမ်းနည်းပါတယ်! သင်ဟာအလုပ်အတွက်သင့်​တော်တဲ့သူဖြစ်တယ်ဆိုတာကိုအတည်ပြုဖို့အတွက် အလုပ်ရှင်က စာရွက်စာတမ်း​တွေကိုကြည့်ဖို့လိုအပ်ပါတယ်။ အလုပ်ရှင်တစ်​ယောက်နဲ့တစ်ယောက်ကြည့်ချင်တဲ့စာရွက်စာတမ်း​တွေလည်းမတူနိုင်လို့ သင့်မှာရှိတဲ့ အထောက်အထားစာရွက်စာတမ်း​တွေကို တင်ပေးထားတယ်ဆိုရင် သူတို့လိုတာကိုကြည့်လို့ရပါလိမ့်မယ်။ စာရွက်စာတမ်းတင်ဖို့အဆင်ပြေလား။",
    "If you have any questions, for those who are in Thailand, please call {phoneLink}, and for those in Myanmar, please call or send message to Viber No. {viberLink} at anytime, 24 hours a day, 7 days a week.":
      "တစ်ခုခု မေးစရာရှိပါက ထိုင်းနိုင်ငံမှ အသုံးပြုသူများအနေဖြင့် ဖုန်းနံပါတ် {phoneLink} နှင့် မြန်မာနိုင်ငံမှ အသုံးပြုသူများအနေဖြင့် ဘိုင်ဘာနံပါတ် {viberLink} သို့ ဖုန်းဆက် (သို့) စာပို့ပြီး အချိန်မရွေးဆက်သွယ်လို့ရပါတယ်။",
    "If you have any questions, please call {phoneLink} (For those who are in Thailand) or direct message to Facebook page {facebookLink} at anytime, 24 hours a day, 7 days a week.":
      "တစ်ခုခု မေးစရာရှိပါက ထိုင်းနိုင်ငံမှ အသုံးပြုသူများအနေဖြင့် ဖုန်းနံပါတ် {phoneLink} နှင့် ကမ္ဘောဒီးယား နိုင်ငံမှ အသုံးပြုသူများအနေဖြင့် ဖေ့စဘုတ်စာမျက်နှာ {facebookLink} သို့ စာပို့ပြီး အချိန်မရွေးဆက်သွယ်လို့ရပါတယ်။",
    "If you have any questions, please call {phoneLink}.  The call is free of charge and you can call at anytime, 24 hours a day, 7 days a week.":
      "တစ်ခုခု မေးစရာရှိပါက {phoneLink} ကို ဆက်သွယ်ပေးပါနော်။ ဖုန်းခေါ်ဆိုသည့်အခါ အခမဲ့ဖြစ်ပြီး တစ်ပတ်၇ရက် ပိတ်ရက်မရှိ၊ ၂၄နာရီအတွင်းအချိန်မရွေးခေါ်ဆိုနိုင်ပါတယ်။",
    "Do you need help in completing this resume?":
      "အလုပ်လျှောက်လွှာ CVကို ပြည့်ပြည့်စုံစုံဖြည့်ရန် အကူအညီလိုအပ်ပါသလား။",
    "Uploaded Nepal Documents":
      "တင်ထားပြီးသော နီပေါ စာရွက်စာတမ်းအထောက်အထားများ",
    "Uploaded Myanmar Documents":
      "တင်ထားပြီးသည့် မြန်မာ စာရွက်စာတမ်းအထောက်အထားများ",
    "NRC Card": "မှတ်ပုံတင်ကတ်ပြား",
    "Uploaded Khmer Documents":
      "တင်ထားပြီးသည့် ကမ္ဘောဒီးယားစာရွက်စာတမ်းအထောက်အထားများ",
    "Uploaded Documents": "တင်ထားပြီးသည့် စာရွက်စာတမ်းအထောက်အထားများ",
    "Cancellation letter": "အလုပ်ပယ်ဖျက်စာ",
    Resume:
      "အလုပ်ကြိုတင်လျှောက်ထားနိုင်ရန်အတွက် အလုပ်လျှောက်လွှာဖောင် (CV) ဖြည့်ရန်/ပြင်ရန်",
    "Basic Information": "ကိုယ်ရေးအချက်အလက်",
    "Full name": "အမည်အပြည့်အစုံ",
    Birthday: "မွေးနေ့",
    Education: "ပညာအရည်အချင်း",
    Major: "အထူးပြု ဘာသာ",
    "Emergency Phone number": "အရေးပေါ်ဖုန်းနံပါတ်",
    "LINE id number": "လိုင်းစိမ်းနံပါတ်",
    "Viber id number": "ဘိုင်ဘာနံပါတ်",
    "Current Address": "လက်ရှိနေရပ်လိပ်စာ",
    Documents: "စာရွက်စာတမ်းအထောက်အထားများ",
    "National ID": "မှတ်ပုံတင်နံပါတ်",
    "Passport expires": "ပတ်စပို့ သက်တမ်းကုန်ဆုံးရက်",
    "Certificate of Identity Number": "စီအိုင်စာအုပ်နံပါတ်",
    "Certificate of Identity expires": "စီအိုင်စာအုပ် သက်တမ်းကုန်ဆုံးရက်",
    "Pink Card Number": "ပန်းရောင်ကတ်နံပါတ်",
    "Working Experience": "အလုပ်အတွေ့အကြုံ",
    Title: "ရာထူး",
    Years: "နှစ်",
    "Languages Proficiency": "ဘာသာစကားကျွမ်းကျင်မှု",
    "Language Skills": "ဘာသာစကားပြောဆိုတတ်ခြင်း",
    "Read Level": "စာဖတ်နိုင်စွမ်းရည်",
    "Speak Level": "အပြောစွမ်းရည်",
    "Passport Number": "ပါတ်စ်စပို့ နံပါတ်",
    optional: "မဖြစ်မနေဖြည့်ရန်မလိုပါ",
    "Passport number": "ပါတ်စ်စပို့ နံပါတ်",
    "Enter your Passport Number as shown in the picture":
      "နမူနာပုံမှာဝိုင်းပြထားတဲ့​နေရာမှာရှိတဲ့ ပါတ်စပို့နံပါတ်ကိုရေးဖြည့်​ပေးပါ။ (ဥပမာ MD123456)",
    "Valid till": "သက်တမ်း နောက်ဆုံးနေ့",
    Month: "လ",
    Year: "နှစ်",
    "Day: please enter the number between 1 and 31":
      "၁ မှ ၃၁ အတွင်းရှိ ဂဏန်းရိုက်ထည့်ပါ",
    "Month: please enter the number between 1 and 12":
      "၁ မှ ၁၂  အတွင်းရှိ ဂဏန်းရိုက်ထည့်ပါ",
    "Year: please enter the number between 2020 and 2040":
      " ခုနှစ်-၂၀၂၀ မှ ၂၀၄၀ အတွင်းရှိ ဂဏန်းရိုက်ထည့်ပါ",
    "National ID Number": "မှတ်ပုံတင်နံပါတ်",
    "Enter your National Identification Card number":
      "အမျိုးသားသတ်သေခံကတ်နံပါတ်ဖြည့်ပေးပါ",
    "Enter your National Identification Card Number as shown in the picture":
      "နမူနာပုံမှာပြထားတဲ့အတိုင်း အမျိုးသားသတ်သေခံကတ်နံပါတ်ကိုရေးဖြည့်​ပေးပါ။ ",
    "Enter your NRC number": "မှတ်ပုံတင်နံပါတ်ဖြည့်ပေးပါ",
    "Enter your NRC Number as shown in the picture (eg: 12/TaKaNa(N)123456)":
      "နမူနာပုံမှာဝိုင်းပြထားတဲ့​နေရာမှာရှိတဲ့မှတ်ပုံတင်နံပါတ်ကို ​ဖြည့်ပေးပါ (ဥပမာ ၁၂/တကန(နိုင်)၁၂၃၄၅၆)",
    "Enter your Passport Number as shown in the picture (eg: MD123456)":
      "နမူနာပုံမှာဝိုင်းပြထားတဲ့​နေရာမှာရှိတဲ့ ပါတ်စပို့နံပါတ်ကိုရေးဖြည့်​ပေးပါ။ (ဥပမာ MD123456)",
    "CI Number": "စီအိုင်စာအုပ်နံပါတ်",
    "CI number": "စီအိုင်စာအုပ်နံပါတ်",
    "Enter your CI Number": "စီအိုင်စာအုပ်နံပါတ် ဖြည့်ပေးပါ",
    "Enter your Pink Card Number as shown in the picture, the pink card number mush have 13 digits. (eg: 00 1234 567890 1)":
      "နမူနာပုံမှာဝိုင်းပြထားတဲ့​နေရာမှာရှိတဲ့ ပန်းရောင်ကဒ်နံပါတ်ကိုရေးဖြည့်​ပေးပါ။  ဂဏန်း ၁၃လုံးရှိရပါမယ်။ (ဥပမာ ၀၀ ၁၂၃၄ ၅၆၇၈၉၀ ၁)",
    "Remove Passport": "ပတ်စပို့ကိုပြန်ဖျက်မည်",
    "Remove visa": "ဗီဇာပြန်ဖျက်မည်",
    "Remove work permit": "ဝေါ့ခ်ပါမစ်ပြန်ဖျက်မည်",
    "Remove pink card": "ပန်းရောင်ကတ် ပြန်ဖျက်မည်",
    "Remove canc. letter": "အလုပ်ပယ်ဖျက်စာပြန်ဖျက်မည်",
    "Uploaded Documents for Myanmar":
      "မြန်မာအတွက်တင်ထားပြီးသည့် စာရွက်စာတမ်းအထောက်အထားများ",
    "Remove NRC": "မှတ်ပုံတင်ပြန်ဖျက်မည်",
    "Remove Household List": "အိမ်ထောင်စုစာရင်းပြန်ဖျက်မည်",
    "Remove Labour Card": "အလုပ်သမားကတ် ပြန်ဖျက်မည်",
    "Remove Covid Vaccine Certificate":
      "ကိုဗစ်-၁၉ ကာကွယ်ဆေးထိုးကတ်ပြားကိုပြန်ဖျက်မည်",
    "Uploaded Documents for Nepal":
      "ကမ္ဘောဒီးယားအတွက်တင်ထားပြီးသည့် စာရွက်စာတမ်းအထောက်အထားများ ",
    "Uploaded Documents for Khmer":
      "ကမ္ဘောဒီးယားအတွက်တင်ထားပြီးသည့် စာရွက်စာတမ်းအထောက်အထားများ",
    "Remove Identification Card": "သက်သေခံကတ်ပြားကို ပြန်ဖျက်မည်",
    "Remove Birth Certificate": "မွေးစားရင်း ပြန်ဖျက်မည်",
    "Remove Family Record Book": "မိသားစုမှတ်တမ်းစာအုပ်ပြန်ဖျက်မည်",
    "Remove Residence Book": "နေထိုင်ကြောင်းအထောက်အထားစာအုပ်ကိုပြန်ဖျက်မည်",
    "Please use one of the following formats: .jpeg, .jpg or .png":
      "jpeg, jpg, png ပုံများသာ လက်ခံသည်",
    "Couldn't update some of the documents":
      "စာရွက်စာတမ်းအချို့တင်လို့မရဖြစ်သွားပါသည်",
    "Resume has been successfuly updated": "CV ဖောင် ဖြည့်စွက်ပြင်ဆင်ပြီးပါပြီ",
    "Couldn't update photo": "ဓာတ်ပုံထည့်မှု မအောင်မြင်ပါ",
    "Resume has not been updated due to server error":
      "လိုင်းကျနေပါသဖြင့် CV ဖောင်ပြင်ဆင်ဖြည့်စွက်ခြင်း မအောင်မြင်ပါ",
    "Resume has been successfuly created":
      "CV ဖောင်ကိုအောင်မြင်စွာဖြည့်စွက် ပြီးပါပြီ",
    "Resume has not been created due to server error":
      "လိုင်းကျနေပါသဖြင့် CVဖောင်ဖြည့်စွက်တင်သွင်းမှု မအောင်မြင်ပါ",
    "Upload your photo": "သင့်ဓာတ်ပုံတင်ပေးပါ",
    "Enter your full name": "အမည်အပြည့်အစုံ ရိုက်ထည့်ပါ",
    "Full name must be the same name in your NRC/Passport":
      "မှတ်ပုံတင်/ ပါတ်စပို့ ကဒ်ထဲက နာမည်အတိုင်းဖြည့်ပေးပါ။ ",
    "Full name must be the same name in your NRC/Passport/Pinkcard":
      "မှတ်ပုံတင်/ ပါတ်စပို့/ ပန်းရောင်ကဒ်ထဲက နာမည်အတိုင်းဖြည့်ပေးပါ။ ",
    "Please enter your full name": "အမည်အပြည့်အစုံ ရိုက်ထည့်ပေးပါ",
    "Name can contain only letters": "အမည်ကို အင်္ဂလိပ်အက္ခရာဖြင့်ရေးပါ",
    "Choose male or female": "ကျား/မ ရွေးပေးပါ",
    "Select the highest level of education that you have successfully completed":
      "တက်ရောက်ခဲ့သည့် အမြင့်ဆုံးပညာအရည်အချင်းကို ရွေးပေးပါ။",
    "Please choose your education level": "ပညာအရည်အချင်း ရွေးချယ်ပေးပါ",
    "Enter your major name": "အထူးပြုဘာသာရိုက်ထည့်ပါ",
    "Enter your major": "အထူးပြုဘာသာရိုက်ထည့် ပါ",
    "Please enter your major": "ကျေးဇူးပြု၍အထူးပြုဘာသာရိုက်ထည့်ပေးပါ",
    "Birthday (Gregorian calendar)":
      "မွေးနေ့ (အင်္ဂလိပ်ခုနှစ်အတိုင်းဖြည့်ပေးပါရန်)",
    Date: "နေ့",
    "Please fill Gregorian day": "အင်္ဂလိပ်ခုနှစ်အတိုင်း မွေးရက်ကို ဖြည့်ပေးပါ",
    "Please fill Gregorian Month": "အင်္ဂလိပ်ခုနှစ်အတိုင်း မွေးလကို ဖြည့်ပေးပါ",
    "Please fill Gregorian Year":
      "အင်္ဂလိပ်ခုနှစ်အတိုင်း မွေးနှစ်ကို ဖြည့်ပေးပါ",
    "Day: this month has less days": "ဤလ သည်ရက်မစုံပါ",
    "Year: please enter the number between 1940 and 2005":
      "ခုနှစ်- ၁၉၄၀ မှ ၂၀၀၅ အတွင်းရှိ ဂဏန်းရိုက်ထည့်ပါ",
    "Enter your email": "အီးမေးရိုက်ထည့်ပါ",
    "Enter the email address you always use":
      "အမြဲသုံးနေကျ အီးမေးလ်လိပ်စာ ဖြည့်ပေးပါ။",
    "Please enter valid email address": "အီးမေးလ်အမှန်ဖြည့်ပေးပါ",
    "Pls add your country code in front of the no. For Eg. for 09765000150, pls put 959765000150.":
      "ဖုန်းနံပါတ်ရှေ့မှာ မြန်မာနိုင်ငံရဲ့နံပါတ် 95 ကိုထည့်ရပါမယ်။ ဥပမာ- ကိုယ့်နံပါတ်က 09765000150 ဆိုရင် 959765000150 လို့ဖြည့်ပေးပါ",
    "Enter the phone number you always use":
      "အမြဲသုံးနေကျ ဖုန်းနံပါတ် ဖြည့်ပေးပါ",
    "Enter your emergency phone number": "အရေးပေါ်ဖုန်းနံပါတ် ရိုက်ထည့်ပါ",
    "Enter your Emergency Phone Number":
      "အရေးပေါ်ဆက်သွယ်ရမည့် ဖုန်းနံပါတ်ဖြည့်ပေးပါ",
    "Enter LINE id": "လိုင်းစိမ်းနံပါတ်ဖြည့်ပါ",
    "Enter your LINE id number": "လိုင်းစိမ်းနံပါတ်ဖြည့်ပေးပါ",
    "Enter your LINE id Number": "လိုင်းစိမ်းနံပါတ်ဖြည့်ပေးပါ",
    "Please enter valid LINE id number":
      "ကျေးဇူးပြု၍ သင့်ရဲ့လိုင်းစိမ်းနံပါတ်အမှန်ဖြည့်ပေးပါ",
    "Enter your viber id number": "သင့်ရဲ့ ဘိုင်ဘာနံပါတ်ဖြည့်ပေးပါ",
    "Enter your Viber Id Number": "သင့်ရဲ့ ဘိုင်ဘာနံပါတ်ဖြည့်ပေးပါ",
    "Please enter valid viber id number":
      "ကျေးဇူးပြု၍ သင့်ရဲ့ ဗိုင်ဗာနံပါတ်အမှန် ဖြည့်ပေးပါ",
    "Select Country": "နိုင်ငံရွေးပေးပါ  ",
    "Select the country you are living currently":
      "လက်ရှိနေထိုင်နေသော နိုင်ငံကိုဖြည့်ပေးပါ",
    "State/Province": "ပြည်နယ်/တိုင်း",
    "Select State/Province": "ပြည်နယ်/တိုင်း ကို ရွေးပါ",
    "Select the state/province you are living currently":
      "လက်ရှိနေထိုင်နေသော ပြည်နယ်/ တိုင်း ကိုရွေးပေးပါ",
    City: "မြို့",
    "Select City": "မြို့ရွေးပေးပါ ",
    "Select the city you are living currently":
      "လက်ရှိနေထိုင်နေသော မြို့ကိုဖြည့်ပေးပါ",
    "Country: please select the country":
      "နိုင်ငံ၊ ကျေးဇူးပြု၍ နိုင်ငံကိုရွေးပါ",
    "State/Province: please select the state/province":
      "ပြည်နယ်/ တိုင်း: ကျေးဇူးပြု၍ ပြည်နယ်/တိုင်း ကိုရွေးပါ ",
    "City: please select the city": "မြို့၊ ကျေးဇူးပြု၍ မြို့ကိုရွေးပါ",
    "Address details": "လိပ်စာ အပြည့် အစုံ",
    "Please enter address details here":
      "ကျေးဇူးပြု၍ လိပ်စာအသေးစိတ်ကိုဒီမှာထည့်ပါ",
    "Please enter the Address Details here (Room No, Street Name, Block Name, Township Name)":
      "ကျေးဇူးပြု၍ လိပ်စာအသေးစိတ်ကိုဒီမှာထည့်ပါ (အခန်းအမှတ်၊ လမ်းအမည်၊ ရပ်ကွက်အမည်၊ မြို့နယ်အမည်)",
    "Please enter your address details":
      "ကျေးဇူးပြု၍ သင်၏လိပ်စာအသေးစိတ်ကိုထည့်ပါ",
    "Select Language": "ဘာသာစကားကိုရွေးပါ",
    "Select the Language": "ဘာသာစကားကိုရွေးပါ",
    "Select the Langauge": "ဘာသာစကားကိုရွေးပါ",
    "Select Read Level": "စာဖတ်စွမ်းရည်ကိုရွေးပါ",
    "Select the Read Level of Language": "စာဖတ်နိုင်မှုစွမ်းရည်ကိုရွေးပါ",
    "Select read level of Language": "စာဖတ်နိုင်မှုစွမ်းရည်ကိုရွေးပါ",
    "Select Speak Level": "အပြောစွမ်းရည်ကို ရွေးပါ",
    "Select speak Level of Language": "ဘာသာစကား ပြောဆိုနိင်မှုကို ရွေးပါ",
    "Select speak level of Language": "ဘာသာစကား ပြောဆိုနိင်မှုကို ရွေးပါ",
    remove: "ဖျက်မည်",
    "Add Language Skill": "ဘာသာစကားတတ်ကျွမ်းမှုကို ထပ်ထည့်မည်",
    "Job Title": "အလုပ်အကိုင်အမည်",
    "Enter your job title": "သင်၏အလုပ်အကိုင်အမည်ကိုဖြည့်ပေးပါ",
    "Enter the job title of previous job or current job":
      "အရင်က ဘာအလုပ်လုပ်ခဲ့တာလဲဆိုတာရေးပေးပါ",
    "Select the type of industry you worked in for this job":
      "အရင်အလုပ်ရဲ့လုပ်ငန်းအမျိုးအစားကို ရွေးပေးပါ။",
    "Select industry": "လုပ်ငန်းအမျိုးအစားရွေးပေးပါ",
    "Add new experience": "အတွေ့အကြုံအသစ်ဖြည့်စွက်ပါ",
    "Please fill in all the required fields and try again.":
      "ဖြည့်စွက်စရာအားလုံး ဖြည့်စွက်ပြီး ထပ်မံကြိုးစားပါ။",
    "Save CV": "အလုပ်လျှောက်လွှာ CV သိမ်းဆည်းမည်",
    "Update required!":
      "​ရွှေအိပ်မက်အပလီ​ကေးရှင်းကို အဆင့်မြှင့်ရန် (updateလုပ်ရန်)လိုပါတယ်!",
    "Please {clickHere} to update your app in Google Play Store. Close and reopen the app once completed.":
      "​Google Play Store တွင် ရွှေအိပ်မက်အပလီ​ကေးရှင်းကို အဆင့်မြှင့်ရန် (updateလုပ်ရန်)  {clickHere} ကိုနှိပ်ပါ၊ update လုပ်ပြီးသွားတဲ့အခါ ရွှေအိပ်မက်ကိုပိတ်ပြီးပြန်ဖွင့်ပေးပါ.",
    "click here": "ဤနေရာကို နှိပ်ပါ",
    "Free hotline": "အခမဲ့ Hotline ဖုန်း",
    "Thailand Hotline (free)": "အခမဲ့ Hotline ဖုန်း (အစ်ဆာရာထိုင်းရုံး)",
    "Yangon Hotline": "Hotline ဖုန်း (အစ်ဆာရာမြန်မာရုံး)",
    "Nepal Hotline (free)": "အခမဲ့ Hotline ဖုန်း (နီပေါ)",
    Recruiters: "အေဂျင်စီများ",
    Services: "လူမှုရေးအဖွဲ့များ",
    "Share a problem": "အခက်အခဲတင်ပြရန်",
    Language: "ဘာသာစကား",
    Marketplace: "အလုပ်ရှာဖွေရာနေရာ",
    "GoldenDreams App": "ရွှေအိပ်မက် ဖုန်းအပလီကေးရှင်း",
    Surveys: "စစ်တမ်းများ",
    "Confirm Delete Your Profile?":
      "ပရိုဖိုင်မှ အချက်အလက်များကို ဖျက်ရန် အတည်ပြုသည်",
    "By selecting the delete profile option, you will be deleting all of your information on Golden Dreams. Once you confirm, you will not be able to recover any of this information. Are you sure you want to delete your profile?":
      "‘ပရိုဖိုင်မှ အချက်အလက်များကို ဖျက်မည်’ ခလုတ်ကို နှိပ်လိုက်တာနဲ့  ရွှေအိပ်မက်မှာထည့်ထားသည့် သင့်အချက်အလက်များအားလုံးကို အပလီကေးရှင်းမှ ဖျက်ပစ်ပါမည်။ သင့်အနေနဲ့ အတည်ပြုပြီးတာနဲ့ အချက်အလက်များကို ပြန်လည်ရယူနိုင်မည်မဟုတ်ပါ။ သင့်ပရိုဖိုင်ကို ဖျက်လိုသည်မှာ သေချာပါသလား။",
    "Yes, Delete my profile": "ပရိုဖိုင်မှ အချက်အလက်များကို ဖျက်ပါမည်။",
    Verified: "အတည်ပြုထားပြီးပြီ",
    Unverified: "အတည်မပြုရသေးပါ",
    "Profile strength:": "ပရိုဖိုင် ( ကိုယ်ရေး အချက် အလက်)  ပြီးပြည့်စုံမှု",
    Name: "အမည်",
    Age: "အသက်",
    "Enter your age": "အသက်ရိုက်ထည့်ပါ",
    "Please enter valid phone number!":
      "ဆက်သွယ်နိုင်သောဖုန်းနံပါတ်အမှန် ရိုက်ထည့်ပါ",
    "Phone Number": "ဖုန်းနံပါတ်",
    "Verify Phone": "ဖုန်းနံပါတ်အတည်ပြုမည်",
    "Preload media for offline use":
      "အသံဖိုင်နှင့်ဗီဒီယိုများကိုအင်တာနက်မလိုဘဲအသုံးပြုရန်ဒေါင်းထားမည်။",
    "No preload": "မလုပ်ပါ",
    Audio: "အသံဖိုင်",
    "Audio + Video": "အသံ+ဗီဒီယိုဖိုင်",
    "Delete Profile": "ပရိုဖိုင်မှ အချက်အလက်များကို ဖျက်မည်",
    "Privacy policy": "ကိုယ်​ရေးကိုယ်တာအချက်အလက်လုံခြုံ​ရေးဆိုင်ရာမူဝါဒ",
    "Member agreement": "သဘောတူညီမှု",
    Saving: "သိမ်းဆည်းနေသည်",
    "Password must be at least 8 characters long and include both letters (a-z) and numbers (0-9).":
      "လျှို့၀ှက်ကုတ်သည် အနည်းဆုံး ၈ လုံး ရှိရမည်ဖြစ်ပြီး အင်္ဂလိပ်စာလုံး (‌ a မှ z) နှင့် နံပါတ် (0 မှ 9) နှစ်မျိုးစလုံး ပါရှိရမည်။",
    "Please Confirm your password": "ကျေးဇူးပြု ၍ လျှို့ဝှက်ကုတ်ပြန်အတည်ပြုပါ။",
    "privacy policy": "ကိုယ်​ရေးကိုယ်တာအချက်အလက်လုံခြုံ​ရေးဆိုင်ရာမူဝါဒ",
    "member agreement": "သဘောတူညီမှု",
    "GOOGLE TEST SIGNUP": "ဂူဂဲလ်ဖြင့် အစမ်းသုံးရန် ဝင်မည်",
    "Facebook TEST SIGNUP": "ဖေ့စ်ဘုတ်ဖြင့် အစမ်းသုံးရန် ဝင်မည်",
    "Enter username or email you want to use":
      "ဖွင့်လိုသည့် အကောင့်အမည် (သို့မဟုတ်) အီးမေးလ်",
    "Please enter username or email you want to use":
      "အကောင့်အမည် (သို့မဟုတ်) အီးမေးလ်ဖြည့်ပေးပါ",
    "Enter your password": "လျှို့ဝှက်ကုတ် ရိုက်ထည့်ပါ",
    "Password must be at least 8 characters long, requiring at least 1 letter (a-z) and 1 number (0-9).":
      "လျှို့ဝှက်ကုတ်သည် အနည်းဆုံး ၈ လုံး ရှိရမည်ဖြစ်ပြီး အင်္ဂလိပ်စာလုံး (‌ a မှ z) နှင့် နံပါတ် (0 မှ 9) နှစ်မျိုးစလုံး ပါရှိရမည်။",
    "Please enter your password": "လျှို့ဝှက်ကုတ် ရိုက်ထည့်ပါ",
    "Confirm Password": "လျှို့ဝှက်ကုတ်ပြန်အတည်ပြုပါ။",
    "Please confirm your password":
      "အပေါ်ကဖြည့်ထားသည့်လျှို့ဝှက်ကုတ်အတိုင်းပြန်ထည့်ပါ။",
    "Show Passwords": "ဖြည့်ထားသည့်လျှို့ဝှက်ကုတ်တူ/မတူကြည့်မည်",
    Register: "အကောင့်ဖွင့်ရန်အတည်ပြုမည်",
    "By clicking register, you agree to our {privacy_policy} and {member_agreement}.":
      "အကောင့်ဖွင့်လိုက်သည်နှင့် {privacy_policy} နှင့် {member_agreement}ကို နားလည်လက်ခံပြီးဖြစ်သည်။",
    or: "သို့မဟုတ်",
    "Continue with Facebook": "ဖေ့စ်ဘုတ်သုံး၍ ဝင်ရန်",
    "Continue with Google":
      "လက်ရှိဖုန်းမှာ သုံးနေသည့် ဂူဂဲလ်အီးမေးလ်ကို သုံး၍ ဝင်ရန် ",
    "Resume has been successfuly deleted":
      "အလုပ်လျှောက်လွှာ အောင်မြင်စွာ ပယ်ဖျက်ပြီးပါပြီ",
    "Resume has not been deleted due to server error":
      "လိုင်းကျနေပါသဖြင့် အလုပ်လျှာက်လွှာပယ်ဖျက်ခြင်း မအောင်မြင်ပါ",
    "Please use one of the following formats: .jpeg, .jpg, .png, .pdf, .doc, .docx, .xls or .xlsx":
      "jpeg, jpg, png, pdf, doc, docx, xls  နှင့် xlsx ဖိုင်များကိုသာ အသုံးပြုပါ",
    "Resume has been successfuly uploaded":
      "အလုပ်လျှောက်လွှာကိုတင်ထားလိုက်ပါပြီ",
    "Resume has not been uploaded due to server error":
      "လိုင်းကျနေပါသဖြင့် အလုပ်လျှောက်လွှာတင်သွင်းမှု မအောင်မြင်ပါ",
    "Confirm deletion": "ပါယ်ဖျက်မှုကို အတည်ပြုသည်",
    "Are you sure you would like to delete your CV?":
      "သင်၏ အလုပ်လျှောက်လွှာကိုပါယ်ဖျက်ဖို့ သေချာပြီလား",
    "Are you sure you would like to delete your uploaded CV?":
      "သင်တင်ထားသော အလုပ်လျောက်လွှာကို ပယ်ဖျက်ဖို့ သေချာပြီလား",
    "Golden Dreamer CV": "ရွှေအိပ်မက်အလုပ်လျှောက်လွှာ",
    View: "ကြည့်မည်",
    Edit: "ပြန်ပြင်ရန်",
    "We encourage you to create a Golden Dreamer CV to show your educational background, work experience, and skills you've mastered.":
      "သင့်ရဲ့ ပညာအရည်အချင်း၊ အလုပ်အတွေ့အကြုံ ၊ ကျွမ်းကျင်မှု တွေကို ​ဖော်ပြ​ပေးမယ့် အလုပ်လျှောက်လွှာတစ်ခု ဖန်တီးဖို့ တိုက်တွန်းပါရစေ။",
    "It is a short document that gives you a chance to show a prospective employer the best of what you've got. It highlights your skills and experience and shows them you're the right person for the job. It takes only a few minutes to create one.":
      "သင့် ပညာအရည်အချင်း၊ အ​တွေ့အကြုံနဲ့ ကျွမ်းကျင်မှု​တွေကို သင်အလုပ်​လျှောက်မယ့်အလုပ်ရှင်ကို ပြည့်ပြည့်စုံစုံတင်ပြနိုင်ပြီး သင်ဟာ သူတို့​ခေါ်​နေတဲ့အလုပ်အတွက် အသင့်တော်ဆုံးသူဖြစ်တယ်ဆိုတာကို ထောက်ခံ​ပေးမယ့်အထောက်အထားလေးပါ။ လျှောက်လွှာဖြည့်ရတာလည်း မိနစ်အနည်းငယ်ပဲကြာမှာပါ။ ​လျှောက်လွှာဖြည့်ဖို့ အကူအညီလိုရင်လည်း အစ်ဆာရာကိုအချိန်မ​ရွေးဆက်သွယ်ပါ​လိုက်နော်။",
    "Once you have created your CV, you can use it to apply for many job opportunities. Good luck with your job application!":
      "အလုပ်လျှောက်လွှာတစ်ခုတစ်ခါပြင်ထားလိုက်တာနဲ့ ​နောက်အလုပ်တွေကိုလည်း ဒီပြင်ထားတဲ့ အလုပ်လျှောက်လွှာနဲ့ လျှောက်ထားလိုက်ရုံပါပဲ။ အလုပ်လျှောက်တဲ့အခါတိုင်းကံကောင်းပါစေ​နော်။",
    "Start creating my CV": "အလုပ်​လျှောက်လွှာဖြည့်ပါမယ်",
    "Upload custom CV": "အလုပ်လျှောက်လွှာ တင်သွင်းမည်",
    "Please choose a CV file": "အလုပ်လျှောက်လွှာဖိုင် ရွေးချယ်ပါ",
    "CV name": "အလုပ်လျောက်လွှာအမည်",
    "Enter name of your CV": "အလုပ်လျှောက်လွှာ အမည် ရိုက်ထည့်ပါ",
    "Please enter name of your CV": "အလုပ်လျှောက်လွှာ အမည် ရိုက်ထည့်ပေးပါ",
    "{appName} would like to send you notifications for the latest news and updates. Notifications may include alerts, sounds and icon badges.":
      "ရွှေအိပ်မက်မှာ သတင်းနဲ့အချက်အလက်အသစ်တွေတင်တိုင်းသိစေဖို့ အသံနဲ့ ပုံလေးတွေပါတဲ့ နိုတီလေးတွေပို့ပေးချင်ပါတယ်။",
    "Golden Dreams": "ရွှေအိပ်မက်",
    Block: "ခွင့်မပြုပါ",
    Allow: "ခွင့်ပြုမယ်",
    "Document removal confirmation":
      "စာရွက်စာတမ်းများ ပြန်ဖျက်ခြင်းကို အတည်ပြုခြင်း",
    "Are you sure you would like to remove this documents?":
      "ဒီစာရွက်စာတမ်းများကိုပြန်ဖျက်ဖို့ သင်သေချာပါရဲ့လား",
    "Yes, sure": "ဟုတ်ကဲ့ သေချာပါတယ်",
    "Confirmation !": "အတည်ပြုခြင်း!",
    "Are you sure you want to remove ?": "ပြန်ဖျက်ဖို့ တစ်ကယ်သေချာပါပြီလား",
    "Protected view": "ကြည့်ရှုခွင့် မပြုပါ။",
    "Please login or register to continue":
      "ဆက်လက်လုပ်ဆောင်ရန် အကောင့်သို့ဝင်ပါ..",
    "Forgot Password?": "“လျှို့ဝှက်ကုတ်” မမှတ်မိတော့ရင် ဒီမှာနှိပ်ပါ။",
    "New to golden dreams?": '"ရွှေအိပ်မက်" အကောင့် မဖွင့်ထားရသေးရင်....',
    "Click here to register?": "အကောင့်ဖွင့်ရန် ဤနေရာကို နှိပ်ပါ",
    "Continue browsing as guest": "ယာယီ အသုံးပြုသူအဖြစ် ဆက်လက်လုပ်ဆောင်မည်",
    "Click here to register": "အကောင့်ဖွင့်ရန် ဤနေရာကို နှိပ်ပါ",
    "Recruiters Listing": "အလုပ်ကြိုတင်လျှောက်ထားနိုင်သည့် အေဂျင်စီများစာရင်း",
    "Employers Listing": "အလုပ်ကြိုတင်လျှောက်ထားနိုင်သည့် အလုပ်ရှင်များစာရင်း",
    Applications: "လျှောက်ထားသောအလုပ်များ",
    "Sorry, no news matched filter parameters, please try again.":
      "သင်၏ ရှာဖွေမှု မအောင်မြင်ပါ။ ထပ်ကြိုးစားပါ။",
    "No notifications": "မည်သည့်အသိပေးခြင်းမှမရှိပါ",
    "Please complete the form!":
      "ကျေးဇူးပြုပြီး အဆင့်များကို ပြီးဆုံးအောင် လုပ်ဆောင်ပါ။  ",
    "Password reset link sent successfully!":
      "လျှို့၀ှက်ကုတ်အသစ်ပြန်သတ်မှတ်ရန်လင့်ပို့ထားပြီးပါပြီ! ",
    "Bad Request: Please check the information you provided!":
      "တောင်းဆိုမှုမမှန်ကန်ပါ- ကျေးဇူးပြု၍ သင်ပေးထားသော အချက်အလက်ကို စစ်ဆေးပါ။",
    "Not Found: The requested phone number was not found!":
      "ရှာမတွေ့ပါ- သတ်မှတ်ထားသည့် ဖုန်းနံပါတ်ကို ရှာမတွေ့ပါ။",
    "Internal Server Error: Please try again later!":
      "ဆာဗာအတွင်းအမှားတစ်စုံတစ်ရာဖြစ်သွားသည် - ကျေးဇူးပြု၍ နောက်တစ်ခါ ထပ်ကြိုးစားပါ။",
    "An error occurred: ": "အမှားတစ်စုံတစ်ရာဖြစ်သွားသည် ",
    "Network error: Please check your internet connection!":
      "အင်တာနက်လိုင်းကျနေပါသဖြင့်မအောင်မြင်ပါ - သင့်အင်တာနက်ချိတ်ဆက်မှုကို ပြန်စစ်ဆေးပါ။",
    "Error: ": "အမှား:",
    "Forgot Password": "လျှို့ဝှက်ကုတ်မမှတ်မိတော့ပါ။",
    "Enter your phone number to reset":
      "လျှို့၀ှက်ကုတ် အသစ်ပြန်သတ်မှတ်ရန် ဖုန်းနံပါတ်  ရိုက်ထည့်ပါ",
    "This feature will be available in Nepal Soon!":
      "နီပေါဘာသာဖြင့်မကြာခင်သုံးလို့ရမည်! ",
    "Processing...": "လုပ်ဆောင်နေသည်...",
    "Send Password Reset Link":
      "လျှို့ဝှက်ကုတ်အသစ်ပြန်သတ်မှတ်ရန်လင့်ပို့ပေးပါ။",
    "Passwords do not match.":
      "လျှို့ဝှက်ကုတ်၂ခုမတူညီပါ (အပေါ်ကအတိုင်းဖြည့်ပါ) ",
    "Password reset successfully!":
      "လျှို့ဝှက်ကုတ်အသစ် အောင်မြင်စွာ ပြန်သတ်မှတ်ပြီးပါပြီ။   ",
    "Token expired / Bad Request!": "သတ်မှတ်ချိန်ကုန်ဆုံးပြီ။ ",
    "No users found to reset password!":
      "လျှို့ဝှက်ကုတ် ပြန်သတ်မှတ်လိုသူများ  ရှာမတွေ့ပါ။",
    "There was an error resetting your password.":
      "လျှို့ဝှက်ကုတ် ပြန်သတ်မှတ်ရန် ပြင်ဆင်နေစဥ်မှာ အမှားအယွင်းတစ်စုံတစ်ရာ ဖြစ်သွားပါသည်။ ",
    "Failed to reset password!":
      "လျှို့ဝှက်ကုတ် ပြန်သတ်မှတ်ရန် ကြိုးပမ်းမှု မအောင်မြင်ပါ။ ",
    "Reset Password": "လျှို့ဝှက်ကုတ် ပြန်သတ်မှတ်မည်",
    "New Password": "လျှို့ဝှက်ကုတ် အသစ်",
    "Enter new password": "လျှို့ဝှက်ကုတ် အသစ် ပြန်ရိုက်ထည့်ပါ",
    "Please enter a new password.":
      "ကျေးဇူးပြု၍ လျှို့ဝှက်ကုတ် အသစ် ပြန်ရိုက်ထည့်ပါ",
    "Confirm new password": "လျှို့ဝှက်ကုတ် အသစ် ပြန်အတည်ပြုပါ။",
    "Please confirm your new password.":
      "ကျေးဇူးပြု၍လျှို့ဝှက်ကုတ် အသစ်ပြန်အတည်ပြုပါ။",
    "{number} responses": "ဖြေဆိုသူ {number} ဦး ",
    "Would you like to participate in the poll with the topic?":
      "စစ်တမ်းလေးများကို ဖြေကြည့်ချင်ပါသလား။",
    "I don't want to answer": "မဖြေလိုပါ",
    "I want to answer": "ဖြေချင်ပါတယ်",
    "Today's community poll": "ယနေ့ စစ်တမ်း",
    "Add a reply...": "ပြန်စာရေးရန်",
    "Problem Description": "သင်ကြုံ​တွေ့​နေရသည့်အခက်အခဲ",
    "Please describe the issue you are facing":
      "သင်ကြုံ​တွေ့​နေရသည့်အခက်အခဲကိုရေး​ပေးပါ",
    "Please describe your issue": "သင်၏ အခက်အခဲကို ဖော်ပြပါ",
    "Submit a problem": "အခက်အခဲတင်ပြမည်",
    "Waiting for Issara's reply": "အစ်ဆာရာ၏ ပြန်စာကို စောင့်နေသည်",
    "Waiting for worker's reply": "သင်၏ပြန်စာကို စောင့်နေသည်",
    "Issara is waiting for your reply. Click the message to see the reply.":
      "သင်ကို့စာပြန်ထားသည်။ ပြန်စာကိုကြည့်ရန် ပို့ထားသည့်စာတန်းကိုနှိပ်ပါ။",
    Resolved: "စာပို့မှု ပြီးဆုံးပြီ",
    "Last update: {date}": "စာပို့ထားသည့်ရက်စွဲ - {date}",
    "Mark as resolved": "ဖြေရှင်းပြီးကြောင်း မှတ်သားရန်",
    "Created: {date}": "စာပို့သည့်ရက်စွဲ - {date}",
    "Show resolved": "ဖြေရှင်းပြီးကြောင်း ပြသည်",
    "Please login to share a problem..": "အခက်အခဲတင်ပြရန် အကောင့်သို့ဝင်ပါ..",
    "You haven't shared any problems yet. Please click share a problem on the right top corner to share one.":
      "သင်ယခုထိ အခက်အခဲတင်ပြမှု တစ်စုံတစ်ရာ မရှိသေးပါ။ ညာဖက် ထိပ်ဒေါင့်မှာရှိတဲ့ အခက်အခဲတင်ပြရန် နေရာ ကို နှိပ်ပြီးတင်ပြနိုင်ပါတယ်။",
    Profile: "ကိုယ်ရေးအချက်အလက်",
    "Are you sure want to withdraw all of your applications ? The recruiters will no longer see your applications in their list.":
      "သင်ရဲ့အလုပ်လျှောက်လွှာကို ဖျက်သိမ်းမှာ သေချာပြီလား? ‌‌အေဂျင်စီကနေ အလုပ်ခေါ်စာရှိလို့ ခေါ်တဲ့အခါကျရင် သင့်အလုပ်လျှောက်လွှာကို မမြင်နိုင်တော့ဘူးနော်။",
    "Yes, withdraw all": "ဟုတ်ပါတယ်။ ပြန်ရုတ်သိမ်းပါမည်။",
    "Are you sure want to extend all of your applications ? Your application expiry date will extend by 30 days.":
      "သင့်အလုပ်ခေါ်စာအားလုံးကို သက်တမ်းတိုးရန် သေချာပြီလား? အလုပ်လျှောက်လွှာသက်တမ်းအား ရက် ၃၀ အထိ ထပ်ပြီးတိုးသွားမယ်နော်။",
    "Yes, extend all": "ဟုတ်ပါတယ်။ အားလုံးကို သက်တမ်းတိုးပါမယ်။",
    "You already sent your cv to this Recruitment Agency":
      " ဒီအေဂျင်စီထံသို့ သင့်ရဲ့အလုပ်လျှောက်လွှာပို့ထားပြီးပါပြီ။",
    "Recruitment Agency viewed your application":
      "အေဂျင်စီမှ သင့်ရဲ့အလုပ်လျှောက်လွှာကို ကြည့်ပြီးပါပြီ။",
    "Recruitment Agency Downloaded your resume":
      "အေဂျင်စီမှ သင့်ရဲ့အလုပ်လျှောက်လွှာကို ဒေါင်းလုတ်ဆွဲထားပြီးပါပြီ။",
    "The Recruitment Agency moved your application to the General Waitlist.":
      "အေဂျင်စီမှ သင့်ရဲ့ အလုပ်လျှောက်လွှာဖောင်ကို စောင့်ဆိုင်းစာရင်းမှာ ရွှေ့ထားပြီးပါပြီ။",
    "Recruitment Agency Shortlisted your application":
      "အေဂျင်စီမှ သင့်ရဲ့အလုပ်လျှောက်လွှာဖောင်ကို ပထမအဆင့်ဦးစားပေးစာရင်းမှာ ထည့်သွင်းထားပါတယ်။",
    "Please select permission for your resume & enter the remark ":
      "ဒီအလုပ်ခေါ်စာအတွက်ကိုက်ညှီမှုရှိ/မရှိစစ်ကြည့်ရန် အေဂျင်စီမှသင်တွဲထားသည့်အထောက်အထားများကို ကြည့်ရှုရန်လိုအပ်ပါသည်။ ",
    "Allow to view documents":
      "အထောက်အထားများ ကြည့်ရှုခွင့်ပြုရန် လေးထောင့်ပုံလေးထဲကိုတစ်ချက်နှိပ်ပေးပါ။",
    "Are you sure want to withdraw this application? Your application will no longer see in the {recruiter_name}'s list":
      "သင့်ရဲ့အလုပ်လျှောက်လွှာကို ရုတ်သိမ်းဖို့ သေချာပြီလား? {recruiter_name} အေဂျင်စီမှ သင့်အလုပ်လျှောက်လွှာကို မြင်ရတော့မှာ မဟုတ်ဘူးနော်။   ",
    "Withdrawing Application": " အလုပ်လျှောက်လွှာကိုရုတ်သိမ်းနေပါ",
    "Recruiter Detail": "အေဂျင်စီအကြောင်းအသေးစိတ်",
    "Date of Registration": "လုပ်ငန်းမှတ်ပုံတင်ခဲ့သည့်ရက်စွဲ",
    "Business Images": "အလုပ်ရုံ/လုပ်ငန်းခွင်မှ ဓါတ်ပုံများ",
    "RA Business Images": "အေဂျင်စီလုပ်ငန်းခွင်မှဓါတ်ပုံများ",
    "You cannot send your resume to this recruiter as you are not residing in same country with this recruiter.":
      "ဒီအေဂျင်စီ တည်ရှိရာနိုင်ငံ မှာနေထိုင်တာမဟုတ်သည့်အတွက် သင့်အလုပ်လျှောက်လွှာ ပေးပို့လို့ မရပါ။",
    Processing: "လိုင်စင်သစ်လျှောက်ထားဆဲ",
    "This recruitment agency has experience sending workers to":
      "ဒီအေဂျင်စီမှ ပြည်ပလုပ်သား ပို့ဆောင်ပေးဖူးသည့် နိုင်ငံများ  ",
    comments: "ကွန်မန့်များ",
    "Internal server error occur": "အမှားအယွင်းတစ်စုံတစ်ရာဖြစ်သွားသည်  ",
    "Name of recruitment agency": "အလုပ်ရှာဖွေရေးအေဂျင်စီ အမည်",
    "Registration number": "လုပ်ငန်းမှတ်ပုံတင် အမှတ်",
    "Date of registration": "အမည်စာရင်းသွင်းသည့် နေ့စွဲ -",
    Username: "အကောင့်အမည်",
    Registration: "စာရင်းသွင်းမည်",
    "Register to apply": "အသုံးပြုရန် စာရင်းသွင်းမည်",
    Jobseeker: "အလုပ်ရှာဖွေသူ",
    "Recruitment Agency": "အလုပ်ရှာဖွေရေးအေဂျင်စီ",
    Employer: "အလုပ်ရှင်",
    "Service Providers": "လူမှုကူညီရေးအဖွဲ့များ",
    "Sorry, no service providers matched filter parameters, please try again.":
      "ဝမ်းနည်းပါတယ် သင်ရှာဖွေသောလူမှုကူညီရေး အဖွဲ့  အမည်နှင့် တူသော လူမှုကူညီရေး အဖွဲ့  စာရင်းထဲတွင် မရှိပါ ထပ်မံကြိုးစားပါ",
    "We've recieved your problem": "သင်၏ တင်ပြချက်ကို ရရှိသည်။",
    "We'll get back to you as soon as possible": "မကြာမီ အကြောင်းပြန်ပါမည်။",
    "Please enter your name": "အမည်အပြည့်အစုံ ရိုက်ထည့်ပါ",
    "Please enter your phone number": "ကျေးဇူးပြု၍ သင်၏ ဖုန်းနံပါတ်ကို ရေးပါ",
    "Member Agreement": "အသင်းဝင်ဖြစ်ခွင့် သဘောတူညီချက်",
    "Privacy Policy": "ကိုယ်ရေးကိုယ်တာအချက်အလက်လုံခြုံရေးဆိုင်ရာမူဝါဒ",
    "Would you like to participate in the survey?":
      "စစ်တမ်းလေးများကို ဖြေကြည့်ချင်ပါသလား။",
    "Thank you for participating in the survey.":
      "စစ်တမ်းတွင် ပါဝင်ဖြေဆိုပေးသည့်အတွက် ကျေးဇူးတင်ပါတယ် ",
    "The form couldn't be submitted due to the error":
      "လိုင်းကျနေပါသဖြင့် ဖောင်တင်၍မရပါ",
    "The answer to this question is required.":
      "ယခုမေးခွန်းအတွက် အဖြေလိုအပ်ပါတယ် ",
    "Please answer all the questions that require at least one answer":
      "အနည်းဆုံးအဖြေတစ်ခု ဖြေဆိုရန်လိုအပ်သည့် မေးခွန်းများအားလုံးကိုဖြေပေးပါ",
    "Submit answers": "မိမိအဖြေများကိုပို့မည်",
    "Sorry, There are no surveys!":
      "၀မ်းနည်းပါတယ်။ မည်သည့်စစ်တမ်းမှ ရှာမတွေ့ပါ။",
  },
  "mm-z": {
    "refresh the page": "တစ္ခ်က္ျပန္ႏွိပ္ပါ။",
    "Ooops, something went wrong... Please try to {refresh}":
      "အိုး..တစ္ခုခုမွားေနလို႔ တစ္ခ်က္ျပန္ႏွိပ္ေပးပါ။ {refresh}",
    Male: "က်ား",
    Female: "မ",
    "Prefer not to say": " မေဖာ္ျပလိုပါ",
    "Non-Binary": "က်ား/မ ျဖစ္ျခင္း မေဖာ္ျပလိုပါ",
    "Passport Include": "ပတ္စပို႔တြဲတင္ထားသူမ်ား ",
    "Passport Not Include": "ပတ်စပို့တွဲမတင်ထားသူများ",
    Myanmar: "ျမန္မာ",
    Thailand: "ထိုင္း",
    Cambodia: "ကေမာၻဒီးယား",
    Laos: "လာအို",
    Nepal: " နီေပါ",
    Indonesia: "အင္ဒိုနီးရွား",
    Indian: "အိႏၵိယ ",
    Bangladeshi: "ဘဂၤလားေဒ့ရွ္",
    Others: "အျခား",
    Sunday: "တနဂၤေႏြ",
    Monday: "တနလၤာ",
    Tuesday: "အဂၤါ",
    Wednesday: "ဗုဒၶဟူး",
    Thursday: "ၾကာသပေတး",
    Friday: "ေသာၾကာ",
    Saturday: "စေန",
    "It Will Vary": "အမ်ိဳးမ်ိဳးေျပာင္းလဲႏိုင္သည္",
    Monthly: "လခစား",
    "Daily Wage Rate": "ေန႔စားခ",
    "Once A Month": "တစ္လ တစ္ႀကိမ္",
    "Twice A Month": "တစ္လႏွစ္ႀကိမ္",
    "Every Week": "တစ္ပတ္တစ္ႀကိမ္",
    "Every 10 days": "၁၀ရက္တစ္ႀကိမ္",
    Other: "အျခား",
    Regularly: "ပုံမွန္",
    "From time to time": "တစ္ခါတစ္ရံ",
    Infrequently: "ၾကာၾကာေနမွ တစ္ခါ",
    Never: "မရွိပါ",
    Day: "ေန႔",
    Swing: "အလုပ္ဆိုင္း ပုံမွန္မဟုတ္",
    Night: "ညဆိုင္း",
    Rotating: "အလွည့္က်",
    Variable: "အလုပ္ဆိုင္းအမ်ိဳးမ်ိဳးျဖစ္ႏိုင္သည္",
    "Full-time": "အခ်ိန္ျပည့္",
    "Part-time": "အခ်ိန္ပိုင္း",
    "Moved General Waitlist": "အေထြေထြေစာင့္ဆိုင္းစာရင္းမွာ ေ႐ႊ႕မည္",
    Waitlisted: "ေစာင့္ဆိုင္းစာရင္းမွာ ေရာက္ရွိေနပါၿပီ။",
    "Contract Signed": "စာခ်ဳပ္မွာလက္မွတ္ထိုးၿပီးသြားပါၿပီ။",
    Accepted: "အလုပ္ေလွ်ာက္လႊာကိုလက္ခံလိုက္ၿပီ",
    Applied: "အလုပ္ေလွ်ာက္ထားၿပီးၿပီ",
    Downloaded: "ေဒါင္းလုတ္ဆြဲၿပီးၿပီ",
    Offered: "အလုပ္ကမ္းလွမ္းလိုက္ၿပီ",
    Rejected: "သင့္ကို အလုပ္မခန႔္ပါ",
    Shortlisted: "သင့္ကိုပထမအဆင့္​ေ႐ြးခ်ယ္ထားလိုက္ပါၿပီ",
    Viewed: "သင့္ေလွ်ာက္လႊာကို ၾကည့္လိုက္ပါၿပီ",
    "Documents Requested":
      "အလုပ္ရွင္မွ သင့္ရဲ႕စာ႐ြက္စာတမ္းအေထာက္အထားမ်ားကိုၾကည့္လိုပါတယ္",
    "Documents request accepted":
      "အလုပ္ရွင္မွ သင့္ရဲ႕စာ႐ြက္စာတမ္းအေထာက္အထားမ်ားကိုၾကည့္ရန္ေတာင္းဆိုသည္ကို လက္ခံထားပါတယ္",
    "Documents request rejected":
      "အလုပ္ရွင္မွ သင့္ရဲ႕စာ႐ြက္စာတမ္းအေထာက္အထားမ်ားကိုၾကည့္ရန္ေတာင္းဆိုသည္ကို ျငင္းပယ္ထားပါတယ္",
    "HR Assistant": "HR လက္ေထာက္",
    "HR Interpreter": "HR စကားျပန္",
    "HR Manager": "HR မန္ေနဂ်ာ",
    "HR Officer": "HR အရာရွိ",
    "Candidate pays fees in advance, then is reimbursed by company after starting work":
      "အလုပ္သမားက ကုန္က်စရိတ္ အရင္က်ခံရမည္။ ၿပီးမွ အလုပ္ရွင္က ကုန္က်စရိတ္ ျပန္ထုတ္ေပးမည္။",
    "Company pays all fees in advance":
      "အလုပ္ရွင္က ကုန္က်စရိတ္ကို ႀကိဳတင္က်ခံေပးမည္။",
    "India (+91)": "အိႏၵိယ (+၉၁)",
    "Myanmar (+95)": "ျမန္မာ (+၉၅)",
    "Nepal (+977)": "နီေပါ (+၉၇၇)",
    "Thailand (+66)": "ထိုင္း (+၆၆)",
    "Malaysia (+60)": "မေလးရွား (+၆၀)",
    "Cambodia (+855)": "ကေမာၻဒီးယား (+၈၅၅)",
    "A Little Bit": "အနည္းငယ္",
    Moderately: "အတန္အသင့္",
    "Very Well": "ေကာင္းေကာင္း",
    None: "မေျပာတတ္ပါ",
    Some: "နည္းနည္းေျပာတတ္ပါသည္",
    Proficient: "ေကာင္းေကာင္းေျပာတတ္ပါသည္",
    English: "အဂၤလိပ္",
    Thai: "ထိုင္း ဘာသာစကား",
    "Bahasa Malaysia": "  ဘဟာဆာ မေလးရွား",
    Hindi: "ဟိႏၵီ",
    "Cannot read": "မဖတ္တတ္ပါ",
    APPLICABLE: "အလုပ္ေလွ်ာက္လႊာ‌ပို႔ရန္ၾကည့္မည္",
    APPLIED: "ေလွ်ာက္ထားသူမ်ား ",
    VIEWED: "အလုပ္ေလွ်ာက္လႊာၾကည့္ထားၿပီးသူမ်ား",
    DOWNLOADED: "ေဒါင္းလုတ္ဆြဲထားၿပီးသူမ်ား ",
    SHORTLISTED: "ပထမဦးစားေပးအဆင့္မွာ ေ႐ြးခ်ယ္ထားထားသူမ်ား ",
    OFFERED: "အလုပ္ကမ္းလွမ္းထားသူမ်ား",
    ACCEPTED: "အလုပ္ကမ္းလွမ္းခ်က္ကို လက္ခံထားသူမ်ား ",
    REJECTED_BY_BUSINESS: "အလုပ္ရွင္မွ ျငင္းပယ္ထားသူမ်ား ",
    REJECTED_BY_APPLICANT: "အလုပ္ကမ္းလွမ္းခ်က္ကို ျငင္းပယ္ထားသူမ်ား ",
    CONTRACT_SIGNED: "စာခ်ဳပ္ခ်ဳပ္ထားသူမ်ား",
    APPLICANT_NA: "အလုပ္ေလွ်ာက္လႊာ_ဘာ မွမေတြ႕ရွိပါ",
    WAITLISTED: "ေစာင့္ဆိုင္းစာရင္းမွာေ႐ႊ႕ထားသူမ်ား ",
    MOVED_GENERAL_WAITLIST: "အေထြေထြေစာင့္ဆိုင္းစာရင္းမွာ ေ႐ႊ႕ထားသူမ်ား",
    "STATUS N/A": "အေျခအေန_ ဘာမွမေတြ႕ရွိပါ",
    "Cannot speak": "  မေျပာတတ္ပါ",
    "Network Error!": "အမွားတစ္စုံတစ္ရာျဖစ္သြားသည္။",
    "User with this username already exists":
      "နာမည္တူအေကာင့္ရွိၿပီးသားျဖစ္ေနလို႔ တျခားနာမည္နဲ႔ အေကာင့္ဖြင့္ေပးပါေနာ္။",
    "You are trying to login with Business User Account !":
      "လင့္မွားဝင္ျခင္းျဖစ္သည္။လင့္မွန္/မမွန္ျပန္ဆန္းစစ္ၾကည့္ပါ။",
    "There was an authentication problem":
      "ေတာင္းပန္ပါတယ္။ အင္တာနက္လိုင္းက်ေနပါသျဖင့္ အေကာင့္အတည္ျပဳျခင္းမေအာင္ျမင္ပါ။ လိုင္းေကာင္းသည့္အခါ ေနာက္တစ္ခါထပ္ႀကိဳးစားၾကည့္ပါေနာ္။",
    Applicable: "အလုပ္ေလွ်ာက္လႊာေဖာင္ေပးပို႔ရန္ႏွိပ္ပါ ",
    "Not Applicable": "အလုပ္ေလွ်ာက္လႊာေဖာင္ေပးပို႔၍မရပါ ",
    "N/A": "အခ်က္အလက္ရွာမေတြ႕ပါ",
    "Click here for Registration.": "အေကာင့္ဖြင့္ရန္ ဤေနရာကိုႏွိပ္ပါ",
    "No Focal Person contact provided":
      "ဆက္သြယ္ရမည့္တာ၀န္ခံနာမည္ မေဖာ္ျပထားပါ။",
    "Some features might not work in offline mode":
      "အခ်ိဳ႕လုပ္ေဆာင္ခ်က္မ်ားအတြက္ အင္တာနက္ခ်ိတ္ဆက္ရန္ လိုသည္။",
    "New version is available!": "ဗားရွင္းအသစ္သုံးလို႔ရပါၿပီ!",
    "Please click refresh the app button to switch to a newer version.":
      "ဗားရွင္းအသစ္ေျပာင္းသုံးရန္ ျပန္လည္စတင္မည္ ကိုႏိုပ္ေပးပါ",
    "Updating..": "လုပ္ေဆာင္ေနသည္ ...",
    "Refresh the app": "ျပန္လည္စတင္မည္",
    Polls: "စစ္တမ္းအေျဖ",
    News: "သတင္းမ်ား",
    Home: " ပင္မစာမ်က္ႏွာ",
    Guide: "လမ္းၫႊန္ခ်က္မ်ား",
    Groups: "ေမးမယ္၊ ေျဖမယ္၊ ရွယ္ၾကမယ္",
    Like: "ႀကိဳက္သည္",
    Notifications: "အသိေပးျခင္း",
    moderator: "ေ႐ႊအိပ္မက္",
    Anonymous: "အမည္မေဖာ္လိုသူ",
    "Delete comment": "ကြန္႔မန္႔ဖ်က္မည္",
    "Unhide comment": "ကြန္မန္႔ ၀ွက္ထားတာကို ျပန္ေဖာ္မည္။",
    "Hide comment": "ကြန္မန္႔ ၀ွက္မည္။",
    Reply: "စာျပန္မည္",
    Report: "ရီပို႔ထုရန္",
    "Report inappropriate comment": "မသင့္ေတာ္သည့္ ကြန္မန႔္မ်ားအား ရီပို႔ထုရန္",
    "Text of a comment:": "ေရးထားေသာကြန္မန႔္",
    "Please write a reason...": "အေၾကာင္းျပခ်က္ေရးပါ...",
    Close: "ပိတ္မည္",
    "Replying to {username}": "{username} ကိုစာျပန္မည္",
    "Write a reply...": "ျပန္စာေရးပါ...",
    "Write a comment...": "ကြန္မန္႔ေရးမည္...",
    "Post a comment": "ကြန္မန္႔တင္မည္",
    Comments: "ကြန္မန႔္မ်ား",
    "Go to Last Comment": "ေနာက္ဆုံး ကြန္႔မန္႔ ကိုၾကည့္မည္",
    "Fetching comments ...": "ကှနျမနျ့ အခကြျအလကျရယူနပေါသညျ...",
    "Be a first person to comment on this post":
      "ဒီပို႔စ္(အေၾကာင္းအရာ)မွာ ပထမဦးဆုံးသူအေနျဖင့္ မွတ္ခ်က္ေပးမည္။",
    "Commented: ": "ကြန္မန႔္ေပးၿပီးၿပီ: ",
    "Post comment": "ကြန္႔မန္႔ တင္ျပမည္ ",
    "replied to {recipient}": "မွ {recipient} ထံသို႔ စာျပန္ေနသည္",
    Discuss: "ေဆြးေႏြးရန္",
    new: "အသစ္",
    "Published: {date}": "တင္ခဲ့သည့္ရက္စြဲ - {date}",
    Jan: "ဇန္နဝါရီ",
    Feb: "ေဖေဖၚဝါရီ",
    Mar: "မတ္",
    Apr: "ဧၿပီ",
    May: "ေမ",
    Jun: "ဇြန္",
    Jul: "ဇူလိုင္",
    Aug: "ၾသဂုတ္",
    Sep: "စက္တင္ဘာ",
    Oct: "ေအာက္တိုဘာ",
    Nov: "ႏိုဝင္ဘာ",
    Dec: "ဒီဇင္ဘာ",
    Save: "သိမ္းဆည္းမည္",
    Back: "ေနာက္ဆုတ္မည္",
    "Overall:": "စုစုေပါင္းရမွတ္",
    Operational: "ဤေအဂ်င္စီကို ဆက္သြယ္၍ ျပည္ပအလုပ္အကိုင္ စုံစမ္းႏိုင္ပါသည္။",
    "Temporarily Suspended":
      "ဤေအဂ်င္စီ၏ လုပ္ငန္းလိုင္စင္ကို ယာယီ ပိတ္သိမ္းထားပါသည္။",
    "from: {date}": "{date} မွစ၍",
    "to: {date}": "{date} အထိ ပိတ္သိမ္းသည္။",
    "Permanently Blacklisted":
      "ဤေအဂ်င္စီ၏ လုပ္ငန္းလိုင္စင္ကို ရာသက္ပန္ ပိတ္သိမ္းထားပါသည္။",
    "License is not extended":
      "ဤေအဂ်င္စီသည္ လုပ္ငန္းလိုင္စင္ကို သက္တမ္းတိုးထားျခင္း မရွိပါ။",
    "Thai License Number": "ထိုင္းလိုင္စင္ အမွတ္ -",
    "License number": "လိုင္စင္ အမွတ္ -",
    Email: "အီးေမးလ္",
    "Your application will expire at":
      "သင့္အလုပ္ေလွ်ာက္လႊာသက္တမ္းကုန္ဆုံးမည့္ရက္မွာ",
    "Recruitment Agencies": "အလုပ္ရွာေဖြေရးေအဂ်င္စီမ်ား",
    Employers: "အလုပ္ရွင္မ်ား",
    "Employer Roster": "အလုပ္ႀကိဳတင္ေလွ်ာက္ထားႏိုင္သည့္ အလုပ္ရွင္မ်ားစာရင္း ",
    "Recruiter Roster": "အလုပ္ႀကိဳတင္ေလွ်ာက္ထားႏိုင္သည့္ ေအဂ်င္စီမ်ားစာရင္း",
    "Reset Filter": "အခ်က္အလက္အားလုံး ၾကည့္မည္",
    "Search...": "ရွာေဖြမည္....",
    Filter: "လိုခ်င္သည့္အခ်က္သာ ေ႐ြးခ်ယ္ရွာေဖြမည္",
    "Select Industry": "လုပ္ငန္းအမ်ိဳးအစားေ႐ြးေပးပါ ",
    "All industries": "စက္႐ုံအမ်ိဳးအစားအားလုံး",
    "{type} country": "{type} ႏိုင္ငံေ႐ြးေပးပါ ",
    "Sort By": "အမ်ိဳးအစား စိစစ္ျခင္း  ",
    "Rating: Highest": "အမွတ္ေပးမႈ-အျမင့္ဆုံး",
    "Rating: Lowest": "အမွတ္ေပးမႈ-အနိမ့္ဆုံး",
    Popularity: "ၾကည့္ရႈသူအမ်ားဆုံး",
    "Order By": "အစဥ္္လိုက္",
    "Posted: Newest": "ေနာက္ဆုံးရ အလုပ္ေၾကာ္ျငာမ်ား",
    "Posted: Oldest": "ယခင္ အလုပ္ေၾကာ္ျငာမ်ား",
    cm: "စင္တီမီတာ",
    ft: "ေပ",
    in: "လက္မ",
    kg: "ကီလိုဂရမ္",
    lbs: "ေပါင္",
    "Recruitment closed!": "လူျပည့္သြားလို႔ အလုပ္​ေခၚတာပိတ္လိုက္ပါၿပီ!",
    "Recruitment Expired!": "လူျပည့္သြားလို႔ အလုပ္​ေခၚတာပိတ္လိုက္ပါၿပီ! ",
    "{salary} {currency}/month": "{salary} {currency}/လခ",
    "{daily_wage_rate} {currency}/day": "{daily_wage_rate} {currency}/ေန႔စားခ",
    "Have Employer Pays Policy":
      "အလုပ္ရွင္မွ အလုပ္ရွာေဖြေရးကုန္က်စရိတ္က်ခံပါမည္",
    "Only job seekers in Myanmar/Cambodia/Nepal can apply":
      "ျမန္မာ/ကေမာၻဒီးယားမွ အလုပ္ရွာသူမ်ားသာ ေလွ်ာက္ႏိုင္ေသာအလုပ္မ်ား",
    "Only job seekers in Thailand can apply":
      "ထိုင္းေရာက္ေနသူမ်ားသာ ေလွ်ာက္ႏိုင္ေသာအလုပ္မ်ား",
    "Recruitment Agency Job": "အလုပ္ရွာေဖြေရးေအဂ်င္စီမွ တင္ေပးသည့္ အလုပ္မ်ား ",
    "Employer Job": "အလုပ္ရွင္မွ ေခၚေသာအလုပ္မ်ား",
    "Only job seekers in {country} can apply":
      "  {country} ႏိုင္ငံေရာက္ေနသူမ်ားသာ ေလွ်ာက္ႏိုင္ေသာအလုပ္မ်ား",
    Send: "စာပို႔မည္",
    "Add & Verify Phone Number!":
      "ဖုန္းနံပါတ္ထည့္ၿပီး မွန္ကန္ေၾကာင္းအတည္ျပဳမည္ ",
    VERIFICATION: " မွန္ကန္ေၾကာင္းအတည္ျပဳစစ္ေဆးျခင္း",
    "Update & Verify Phone Number!":
      "ဖုန္းနံပါတ္အပ္ဒိတ္လုပ္ၿပီးမွန္ကန္ေၾကာင္း အတည္ျပဳမည္",
    "{minutes} minute(s) and {seconds} second(s)":
      "{minutes} မိနစ္၊ {seconds} စကၠန႔္",
    "This user has already been verified with the provided phone number.":
      "သည္ဖုန္းနံပါတ္က အျခား အေကာင့္  တစ္ခု နဲ႔ ဘယ္ရီဖိုင္း လုပ္ထား ၿပီးသားပါ ",
    "Error sending OTP!":
      " OTPကုတ္ ပို႔ေဆာင္ေနစဥ္အတြင္းအမွားတစ္စုံတစ္ရာျဖစ္သြားသည္ ",
    "Verification Failed! The number you entered has already been verified with another user account. Check the number again.":
      "အတည္ျပဳျခင္းမေအာင္ျမင္ပါ။ ထည့္ထားသည့္ဖုန္းနံပါတ္မွာ အျခားအေကာင့္တစ္ခုခုမွ အတည္ျပဳထားၿပီးသားျဖစ္ပါတယ္။ ဖုန္းနံပါတ္မွန္/မမွန္ျပန္စစ္ၾကည့္ပါ။",
    "Please enter valid OTP!": "မွန္ကန္တဲ့ OTP code ကုတ္ျပန္ထည့္ပါ! ",
    "Your phone number is successfully verified !":
      "သင့္ဖုန္းနံပါတ္မွန္ကန္ေၾကာင္းအတည္ျပဳၿပီးပါၿပီ!",
    "OTP confirmation failed !": "OTP အတည္ျပဳျခင္းမေအာင္ျမင္ပါ!",
    "Please complete the form !":
      "ေက်းဇူးျပဳၿပီး အဆင့္မ်ားကို ၿပီးဆုံးေအာင္ လုပ္ေဆာင္ပါ။ ",
    "Please Complete These Steps!":
      "ေက်းဇူးျပဳၿပီး အဆင့္မ်ားကို ၿပီးဆုံးေအာင္ လုပ္ေဆာင္ပါ။ ",
    Gender: "က်ား/ မ",
    Nationality: "ႏိုင္ငံသား",
    Country: "ႏိုင္ငံ",
    "Congratulations your profile is updated!":
      "ဂုဏ္ယူပါတယ္... သင့္ပ႐ိုဖိုင္ကို အပ္ဒိတ္လုပ္ၿပီးပါၿပီ။",
    "Phone number": "ဖုန္းနံပါတ္",
    required: "မျဖစ္မေနျဖည့္ေပးရန္လိုအပ္ပါတယ္",
    Select: "ေ႐ြးခ်ယ္ပါ",
    "Select the country code": "ေ႐ြးခ်ယ္ပါ",
    "Please select a country code": "ေက်းဇူးျပဳ၍ ႏိုင္ငံကုတ္ ေ႐ြးေပးပါ",
    "Please remove any leading zeros when entering your phone number. For example, if your phone number is 0827474736, enter it as 827474736.":
      "သင့္ဖုန္းနံပါတ္ကို ႐ိုက္ထည့္ေသာအခါ အေရွ႕က သုညမ်ားကို ဖယ္ရွားပါ။ ဥပမာအားျဖင့္၊ သင့္ဖုန္းနံပါတ္သည္ 0827474736 ျဖစ္ပါက၊  827474736 ကိုသာ ႐ိုက္ထည့္ပါ။ ",
    "Enter your phone number": "ဖုန္းနံပါတ္ ႐ိုက္ထည့္ပါ",
    "Enter the phone number you always use. Do not include the country code, such as (+95, +66, +977, +855, +60), here again.":
      "သင္အၿမဲအသုံးျပဳေနသည့္ ဖုန္းနံပါတ္ကို ထည့္ပါ။ ဤေနရာတြင္ (+95၊ +66၊ +977၊ +855၊ +60) ကဲ့သို႔ေသာ ႏိုင္ငံကုဒ္ကို ထပ္မံမထည့္ပါႏွင့္။  ",
    "Please enter valid phone number": "ဖုန္းနံပါတ္အမွန္ ႐ိုက္ထည့္ပါ",
    "Enter OTP:": "OTPထည့္ပါ:",
    "Enter 6 digits OTP": "OTPကုတ္နံပါတ္ ၆ လုံးထည့္ပါ",
    "Enter OTP": "OTPထည့္ပါ",
    "Your OTP will expire in {expiryTime}":
      "OTP ကုတ်ထည့်ရန် အချိန်{expiryTime}သာလိုတော့သည်",
    "Please select gender": "က်ား/မ ေ႐ြးေပးပါ",
    "Please select nationality": "ေက်းဇူးျပဳ၍ ႏိုင္ငံသား(လူမ်ိဳး) ေ႐ြးေပးပါ",
    "Please select Country of residence":
      "ေက်းဇူးျပဳ၍ မိမိလက္ရွိေနထိုင္သည့္ႏိုင္ငံ ေ႐ြးေပးပါ",
    "Country of residence": "လက္ရွိေနထိုင္သည့္ႏိုင္ငံ",
    "Get OTP": "OTP ရယူမည္",
    "Update & Get OTP": "အပ္ဒိတ္လုပ္ၿပီး OTP ျပန္ရယူမည္",
    "Add & Get OTP": "ဖုန္းနံပါတ္တပ္ျဖည့္ၿပီး OTP ျပန္ရယူမည္",
    "Add Phone Number": "ဖုန္းနံပါတ္ထည့္မည္",
    "Confirm OTP": " OTP အတည္ျပဳပါမည္",
    "Resend OTP": "OTP ျပန္ပို႔မည္",
    Previous: "အေရွ႕သို႔",
    Next: "ေနာက္သို႔",
    Submit: "တင္ျပမည္",
    "Submit Resume": "အလုပ္ေလွ်ာက္လႊာပို႔မည္",
    "Permission & Remark":
      "အခ်က္အလက္မ်ား ၾကည့္ရႈခြင့္ျပဳျခင္း ႏွင့္ မွတ္ခ်က္ရွိရင္ခ်ေရးရန္",
    "This Recruiter might need to see your documents and information to confirm you are eligible for the job.":
      "ဒီအလုပ္ေခၚစာအတြက္ကိုက္ညႇီမႈရွိ/မရွိစစ္ၾကည့္ရန္ ေအဂ်င္စီ မွသင္တြဲထားသည့္အေထာက္အထားမ်ားႏွင့္ ကိုယ္ေရးအခ်က္အလက္မ်ား ကို ၾကည့္ရႈရန္လိုအပ္ပါသည္။ ",
    "This Employer might need to see your documents and information to confirm you are eligible for the job.":
      "ဒီအလုပ္ေခၚစာအတြက္ကိုက္ညႇီမႈရွိ/မရွိစစ္ၾကည့္ရန္ အလုပ္ရွင္မွသင္တြဲထားသည့္အေထာက္အထားမ်ားႏွင့္ ကိုယ္ေရးအခ်က္အလက္မ်ား ကို ၾကည့္ရႈရန္လိုအပ္ပါသည္။",
    "You can delete your documents and any personal information anytime if you desire to do so. Do you understand this and consent to letting them look at your resume and documents?":
      "ထိုအခ်က္အလက္ေတြကို ဘယ္အခ်ိန္ျဖစ္ျဖစ္ ျပန္ဖ်က္ႏိုင္ပါတယ္။ ျဖည့္ထားသည့္ အခ်က္လက္မ်ားႏွင့္ စာ႐ြက္စာတမ္းေတြကို ၾကည့္ခြင့္ေပးဖို႔ သေဘာတူရင္အမွန္ျခစ္ကေလးႏွိပ္ေပးပါေနာ္။ ",
    Remark: "မွတ္ခ်က္ရွိရင္ေရးေပးပါေနာ္  ",
    "Enter remark": "မွတ္ခ်က္ေရးရန္",
    Cancel: "မေသခ်ာပါ",
    "Submitting Resume": "အလုပ္ေလွ်ာက္လႊာပို႔မည္ ",
    "Confirmation!": "အတည္ျပဳျခင္း! ",
    "Are you sure want to withdraw this application? Your application will no longer be seen in {business_name}'s list.":
      "သင့္ရဲ႕အလုပ္ေလွ်ာက္လႊာကို ႐ုတ္သိမ္းဖို႔ ေသခ်ာၿပီလား? {recruiter_name} ေအဂ်င္စီမွ သင့္အလုပ္ေလွ်ာက္လႊာကို ျမင္ရေတာ့မွာ မဟုတ္ဘူးေနာ္။     ",
    "Withdrawing...": "႐ုတ္သိမ္း (ဖ်က္သိမ္း) ေနပါသည္",
    "Yes, withdraw": "ဟုတ္ပါတယ္။ ႐ုတ္သိမ္းပါမယ္။",
    "Ask a question": "ေမးခြန္းေမးရန္",
    "Your question goes here...": "ေမးခြန္း ႐ိုက္ထည့္ပါ",
    "Submit a question": "ေမးခြန္းေမးမည္",
    "Successfully gave the ratings!": "အမွတ္ေပးတာ ေအာင္ျမင္သြားပါၿပီ။",
    "Loading...": "လုပ္ေဆာင္ေနသည္ ...",
    "Submit rating": "အမွတ္ေပးမည္",
    more: "ပိုမိုသိလိုပါက..",
    "Share App": "အပ္ပလီေကးရွင္းကို ရွယ္ရန္",
    Share: "ရွယ္ရန္",
    Reactions: "ရီအက္ေပးမည္",
    "Username or email": "အေကာင့္အမည္ (သို႔မဟုတ္) အီးေမးလ္",
    Password: "လွ်ိဳ႕ဝွက္ကုတ္",
    "Show Password": "ျဖည့္ထားသည့္လွ်ိဳ႕ဝွက္ကုတ္တူ/မတူၾကည့္မည္",
    Login: "ဝင္ရန္",
    "Successfully sent the resume":
      "အလုပ္ေလွ်ာက္လႊာကိုေအာင္ျမင္စြာပို႔လိုက္ပါၿပီ",
    "Couldn't sent the resume due to server error":
      "လိုင္းက်ေနပါသျဖင့္ အလုပ္အေလွ်ာက္လႊာပို႔လို႔မရျဖစ္သြားပါသည္",
    "Successfully extended the application !":
      "သင့္ရဲ႕အလုပ္ေလွ်ာက္လႊာအားလုံးကို သက္တမ္းတိုးထားၿပီးပါၿပီ။",
    "You already sent your cv to this employer":
      "ဒီအလုပ္ရွင္ဆီကို  သင့္ရဲ႕အလုပ္ေလွ်ာက္လႊာပို႔ထားၿပီးပါၿပီ။",
    "Withdraw application": "အလုပ္ေလွ်ာက္လႊာ ျပန္႐ုတ္သိမ္းမည္။",
    "Extending...": "လုပ္ေဆာင္ေနသည္ ... ",
    "Extend application": "အလုပ္ေလွ်ာက္လႊာေဖာင္ သက္တမ္းတိုးမည္။",
    "Employer viewed your application":
      "အလုပ္ရွင္က သင့္ရဲ႕အလုပ္ေလွ်ာက္လႊာကို ၾကည့္ၿပီးပါၿပီ။",
    "Employer Downloaded your resume":
      "အလုပ္ရွင္ကသင့္ရဲ႕အလုပ္ေလွ်ာက္လႊာကို ေဒါင္းလုတ္ဆြဲထားၿပီးပါၿပီ။",
    "Employer Moved your application to General Waitlist. Please wait Until he/she will shortlist you again for future job post.":
      "လက္ရွိေခၚတဲ့အလုပ္မွာ လူျပည့္သြားၿပီျဖစ္တဲ့အတြက္ အလုပ္ရွင္က သင့္အလုပ္ေလွ်ာက္လႊာကို ေနာက္ထပ္ေခၚဆိုမယ့္ ရာထူး‌အတြက္ သိမ္းဆည္းေပးထားပါတယ္။ အလုပ္ရွင္ကေန မဆက္သြယ္လာမခ်င္း အလုပ္သစ္ရွာႏိုင္သလို ေစာင့္ဆိုင္းလို႔လည္းရပါတယ္။ ",
    "Employer Shortlisted your application":
      "အလုပ္ရွင္က သင့္ရဲ႕အလုပ္ေလွ်ာက္လႊာေဖာင္ကို ပထမအဆင့္ဦးစားေပးစာရင္းမွာ ထည့္သြင္းထားပါတယ္။",
    "You cannot send your resume to this employer as you are not residing in same country with this employer":
      " ဒီအလုပ္ရွင္ တည္ရွိရာႏိုင္ငံ မွာေနထိုင္တာမဟုတ္သည့္အတြက္ သင့္အလုပ္ေလွ်ာက္လႊာ ေပးပို႔လို႔ မရပါ။",
    "Successfully withdrew the application !":
      "အလုပ္ေလွ်ာက္လႊာကို ႐ုတ္သိမ္းလိုက္ပါၿပီ။",
    "Couldn't withdraw the application due to server error":
      "လိုင္းက်ေနပါသျဖင့္ အလုပ္ေလွ်ာက္လႊာ႐ုတ္သိမ္းျခင္းမေအာင္ျမင္ေသးပါ။",
    "Employer Detail": "အလုပ္ရွင္အခ်က္အလက္အေသးစိတ္",
    Description: "လုပ္ငန္းအေၾကာင္း",
    "Registered:": "မွတ္ပုံတင္ၿပီး ",
    "License number:": "လိုင္စင္ အမွတ္ -",
    "Valid to:": "လိုင္စင္သက္တမ္း ကုန္ဆုံးမည့္ ရက္စြဲ -",
    "Contact Information": "ဆက္သြယ္ရန္",
    "Rated by ({number}) viewers": " ({number}) ေယာက္ အမွတ္ေပးထားပါတယ္။",
    Address: "လိပ္စာ",
    "You can not sent your resume for this employer":
      "အလုပ္ရွင္ထံကိုအလုပ္ေလွ်ာက္လႊာေပးပို႔လို႔မရပါ",
    "Click here to create a resume":
      "အလုပ္ေလွ်ာက္ရန္အတြက္ အလုပ္ေလွ်ာက္လႊာျဖည့္ရန္ ဤေနရာကို ႏွိပ္ပါ။",
    "Loading..": "လုပ္ေဆာင္ေနသည္ ...",
    "Sorry, no employers matched filter parameters, please try again.":
      "သင္၏ ရွာေဖြမႈ မေအာင္ျမင္ပါ။ ထပ္ႀကိဳးစားပါ။",
    "Industries:": "စက္႐ုံအမ်ိဳးအစားမ်ား -",
    "Job Opportunities:": "အလုပ္အကိုင္ အခြင့္အလမ္းမ်ား",
    "{employer_name} has listed ({number_of_jobs}) job opportunities at marketplace":
      "{employer_name} မွ အလုပ္အကိုင္အခြင့္အလမ္း ({number_of_jobs}) ခု အလုပ္ရွာေဖြရာေနရာတြင္ တင္ထားပါသည္",
    Rating: "ရမွတ္မ်ား",
    "View rating": "ရမွတ္ၾကည့္ရန္",
    "Give rating": "အမွတ္ေပးရန္",
    "Please login to write a comment..": "ကြန္မန႔္ေရးရန္ အေကာင့္သို႔ဝင္ပါ..",
    "Change in CMS": "CMS မွာေျပာင္းမည္",
    "Write a comment here...": "ဒီေနရာမွာကြန္မန႔္ေရးပါ...",
    "Comment Deleted !": "ကြန္မန္႔ဖ်က္လိုက္ၿပီ!",
    "Delete Comment": "ကြန္႔မန္႔ဖ်က္မည္",
    "Are you sure you want to delete this comment?":
      "ဒီကြန္မန္႔ကိုဖ်က္မွာ တစ္ကယ္ေသခ်ာပါၿပီလား",
    Delete: "ဖ်က္သိမ္းမည္",
    "Edit post": "ပိုစ့္ျပင္မည္",
    "Comment Editor": "ကြန္မန႔္ျပင္သူ",
    "Update your comment": "ကြန္မန္႔အသစ္ျပင္မည္",
    "Write your updated comment here ...":
      "သင့္အသစ္ျပင္ဆင္ထားေသာ ကြန္မန္႔ကို ဤေနရာတြင္ ေရးပါ......",
    "Save Changes": "ေျပာင္းလဲခ်က္ကိုအတည္ျပဳမည္",
    "Delete post": "ပို႔စ္ဖ်က္မည္",
    "Delete Post": "ပိုစ့္ဖ်က္မည္",
    "Delete your post?": "ပိုစ့္ဖ်က္မည္",
    "Are you sure you want to delete this post?":
      "ဒီပိုစ့္ကို ျပန္ဖ်က္ဖို႔ တစ္ကယ္ေသခ်ာၿပီလား",
    like: "ႀကိဳက္သည္",
    s: "များ",
    "Like List": "ႀကိဳက္ႏွစ္သက္မႈလုပ္ခဲ့သည့္စာရင္း",
    "Post Detail": "ပို႔စ္ အေသးစိတ္",
    "Go back Top": "အေပၚဘက္သို႔ ",
    "Successfully updated the post !":
      "ပိုစ့္အပ္ဒိတ္လုပ္ျခင္း ေအာင္ျမင္သြားပါၿပီ။",
    "Post Editor": "ပိုစ့္ျပဳျပင္သူ",
    "Edit your post": "ပိုစ့္ျပင္မည္",
    "Caption:": "ေခါင္းစဥ္:",
    "Please write a caption": "ေခါင္းစဥ္ေရးပါ...",
    "Article:": "အေၾကာင္းအရာ:",
    "Please write an article ...": "အေၾကာင္းအရာေရးပါ...",
    "Please choose an image to update": "အပ္ဒိတ္လုပ္ရန္ပုံတစ္ပုံ ေ႐ြးခ်ယ္ပါ ",
    Update: "လုပ္ေဆာင္ေနသည္ ... ",
    "Edit Post ": "ျပန္ျပင္ရန္  ",
    "Edit Post": "ျပန္ျပင္ရန္  ",
    "...See more": ".........ထပ္ၾကည့္မည္",
    "Group Forum": "ေမးမယ္၊ေျဖမယ္၊ရွယ္ၾကမယ္ ",
    "No posts found!": "မည္သည့္ပိုစ္(အေၾကာင္းအရာ) မွရွာမေတြ႕ပါ။",
    "Please login or register new account":
      "အေကာင့္သို႔ဝင္ပါ (သို႔) အေကာင့္အသစ္ဖြင့္ပါ",
    "Please select image! Image size should be less than 5MB":
      "ဓါတ္ပုံေ႐ြးပါ။ ဓါတ္ပုံဆိုက္သည္ ၅မက္ဂါဗိုက္ထက္ႀကီးလို႔မရပါ။ ",
    "Successfully posted !": "ပို႔စ္တင္ၿပီးၿပီ",
    "What would you like to discuss?": "ဘယ္အေၾကာင္းအရာကို ေဆြးေႏြးခ်င္တာလဲ? ",
    "Write your discussion here": "ေဆြးေႏြးခ်င္သည့္အေၾကာင္းအရာကို ဒီမွာေရးပါ",
    "Write discussion here...": "ေဆြးေႏြးခ်င္သည့္အေၾကာင္းအရာကို ဒီမွာေရးပါ....",
    "Choose an image": "ပုံေ႐ြးတင္မည္",
    "Selected Image": "ေ႐ြးခ်ယ္ထားေသာပုံ",
    "Remove Image": "ပုံကိုျပန္ဖ်က္မည္",
    Posting: "ပို႔စ္ တင္ေနသည္",
    Post: "ပို႔စ္တင္မည္",
    Topics: "ေဆြးေႏြးမည့္ ေခါင္းစဥ္",
    "All countries": "ႏိုင္ငံအားလုံး",
    Sort: "အမ်ိဳးအစား စိစစ္ျခင္း",
    "A to Z": "က မွ အ",
    "Z to A": "အ မွ က",
    "{number} found": "စုစုေပါင္း {number} ခု",
    Search: "ရွာေဖြရန္...",
    "Loading ...": "လုပ္ေဆာင္ေနဆဲ",
    "Sorry, no groups matched filter parameters, please try again.":
      "သင္၏ ရွာေဖြမႈ မေအာင္ျမင္ပါ။ ထပ္ႀကိဳးစားပါ။",
    "Please login to write an answer..": "ေျဖဆိုရန္အတြက္ အေကာင့္သို႔ဝင္ပါ",
    "Pending verification": "အတည္ျပဳရန္ စိစစ္ေနသည္..",
    Guides: "အလုပ္သမားအခြင့္အေရး",
    "All categories": "အမ်ိဳးအစားအားလုံး",
    Category: "အမ်ိဳးအစား",
    "Date: Newest": "ေန႔စြဲ-အသစ္မ်ား",
    "Date: Oldest": "ေန႔စြဲ-အေဟာင္းမ်ား",
    "Sorry, no guides matched filter parameters, please try again.":
      "သင္၏ ရွာေဖြမႈ မေအာင္ျမင္ပါ။ ထပ္ႀကိဳးစားပါ။",
    "Welcome, {name}": "ေ႐ႊအိပ္မက္က ႀကိဳဆိုပါတယ္..{name}။",
    "new user": "အေကာင့္အသစ္",
    guest: "ယာယီ အသုံးျပဳသူ",
    "Enable notifications:":
      "ထူးျခားမႈရွိတိုင္း အသိေပးခ်က္ (ႏိုတီ) မ်ားရယူလိုျခင္းရွိ/မရွိ  ",
    Off: "မယူပါ",
    On: "ဖြင့္မည္",
    Logout: "အေကာင့္မွ ထြက္ရန္",
    "Job Applications": "ေလွ်ာက္ထားေသာအလုပ္မ်ား",
    "Sorry, no job applications found!":
      "၀မ္းနည္းပါတယ္..အလုပ္ေလွ်ာက္လႊာရွာမေတြ႕ပါ",
    Jobs: "အလုပ္မ်ား",
    Industry: "လုပ္ငန္း",
    "Most Popular": "လူၾကည့္ အမ်ားဆုံး",
    "Most Applied": "ေလွ်ာက္ထားမႈ အမ်ားဆုံး",
    "New Jobs Available !": "ဗားရွင္းအသစ္သုံးလို႔ရပါၿပီ!",
    "There are some jobs available at the marketplace now {provinceAndIndustry}, have you checked them out?":
      "အလုပ္ရွာေဖြရာေနရာမွာ {provinceAndIndustry} အတြက္ အလုပ္ေတြေခၚေနပါတယ္၊ အဲ့အလုပ္ေတြကိုၾကည့္ၿပီးသြားၿပီလား။",
    Check: "ၾကည့္မယ္",
    "Job Application Confirmation !": "အလုပ္ေလွ်ာက္ေၾကာင္းအတည္ျပဳျခင္း!",
    "Are you sure you want to apply for this {employer} Company for this position?":
      " {employer} ကုမၸဏီမွ ေခၚယူေနသည့္ ဒီအလုပ္ေနရာအတြက္ သင္အလုပ္ေလွ်ာက္ခ်င္တာတစ္ကယ္ေသခ်ာပါရဲ႕လား။",
    "Yes, Sure": "ဟုတ္ကဲ့ ေသခ်ာပါတယ္",
    NRC: "မွတ္ပုံတင္",
    "Household List": "အိမ္ေထာင္စုစာရင္း",
    "Passport (PJ)": "PJ ပတ္စပို႔ ",
    "Labour Card": "အလုပ္သမားကတ္ျပား",
    "Covid Vaccine Certificate": "ကိုဗစ္-၁၉ ကာကြယ္ေဆးထိုးကတ္ျပား",
    Passport: "ပတ္စပို႔",
    "Identification Card": "သက္ေသခံကတ္ျပား",
    "Birth Certificate": "ေမြးစာရင္း",
    "Family Record Book": "မိသားစုမွတ္တမ္းစာအုပ္",
    "Residence Book": "ေနထိုင္ေၾကာင္းအေထာက္အထားစာအုပ္",
    "Work Permit": "ေဝါ့ခ္ပါမစ္",
    Visa: "ဗီဇာ",
    "Pink Card": "ပန္းေရာင္ကတ္",
    "Cancellation Letter": "အလုပ္ပယ္ဖ်က္စာ",
    "Successfully accepted job offer":
      "အလုပ္ရွင္၏ အလုပ္ကမ္းလွမ္းခန႔္အပ္စာကို လက္ခံၿပီးၿပီ",
    "Couldn't accept due to server error":
      "လိုင္းက်ေနပါသျဖင့္ အလုပ္လက္ခံမႈ မေအာင္ျမင္ပါ",
    "Network error, please try again later":
      "ဆာဗာအတြင္းအမွားတစ္စုံတစ္ရာျဖစ္သြားသည္ - ေက်းဇူးျပဳ၍ ေနာက္တစ္ခါ ထပ္ႀကိဳးစားပါ။ ",
    "Unexpected error, please try again":
      "ဆာဗာအတြင္းအမွားတစ္စုံတစ္ရာျဖစ္သြားသည္ - ေက်းဇူးျပဳ၍ ေနာက္တစ္ခါ ထပ္ႀကိဳးစားပါ။ ",
    "Loading…": "လုပ္ေဆာင္ေနဆဲ",
    Apply: "အလုပ္ေလွ်ာက္မည္",
    "Sorry you cannot apply for this job. This job is for the jobseekers who live in {country}.":
      "ေတာင္းပန္ပါတယ္..ျမန္မာျပည္ကသူေတြေလွ်ာက္လို႔မရေသးပါဘူးေနာ္။ အခုအလုပ္က   {country} ႏိုင္ငံေရာက္ေနသူေတြအတြက္ပါ။ ",
    "Sorry you cannot apply job now as current jobs are for those in Thailand.":
      "ေတာင္းပန္ပါတယ္..ျမန္မာျပည္ကသူေတြေလွ်ာက္လို႔မရေသးပါဘူးေနာ္။ အခုအလုပ္က ထိုင္းေရာက္ေနသူေတြအတြက္ပါ။",
    "Click here to create a resume to apply for this job":
      "အေဆာင္ခ၊ ေရ/မီး လစာမွ ျဖတ္ေတာက္မႈအေသးစိတ္",
    "Successfully rejected job offer": "အလုပ္ကမ္းလွမ္းစာကို ျငင္းပယ္ၿပီးၿပီ",
    "Couldn't reject due to server error":
      "လိုင္းက်ေနပါသျဖင့္ ျငင္းပယ္မႈ မေအာင္ျမင္ပါ",
    "Confirm Reject Job Offer": "အလုပ္ကမ္းလွမ္းစာကိုျငင္းပယ္ရန္ အတည္ျပဳပါသည္",
    "Are you sure you would like to reject the job offer? This action cannot be undone later.?":
      "အလုပ္ကမ္းလွမ္းစာကို ျငင္းပယ္ရန္ေသခ်ာၿပီလား၊ ေနာက္မွ ဤဆုံးျဖတ္ခ်က္ကို ျပန္ျပင္၍မရပါ။",
    Confirm: "ျငင္းပယ္ရန္ အတည္ျပဳပါသည္",
    "Rejection Reason": "အလုပ္အတြက္မေ႐ြးခ်ယ္ရသည့္အေၾကာင္းအရင္း",
    Details: "အေသးစိတ္",
    Posted: "အလုပ္ေလွ်ာက္လႊာဖြင့္ရက္",
    "Closing date": "အလုပ္ေလွ်ာက္လႊာပိတ္ရက္",
    "Employer Pays Policy": "အလုပ္ရွင္မွ အလုပ္ရွာေဖြေရးကုန္က်စရိတ္က်ခံပါမည္",
    "Employer does not have Employer Pays Policy":
      "အလုပ္ရွင္က အလုပ္ရွာေဖြေရးကုန္က်စရိတ္ကို မက်ခံပါ",
    "Employer Size": "လုပ္ငန္းအ႐ြယ္အစား",
    "Nationalities of Workers working at this workplace":
      "လာေရာက္လုပ္ကိုင္ၾကသည့္ ႏိုင္ငံအလိုက္လုပ္သားမ်ား",
    "Number of Workers Needed": "လိုအပ္ေသာ လုပ္သားဦးေရ",
    "Number of Male Headcount Needed": "လိုအပ္ေသာ အမ်ိဳးသားလုပ္သားဦးေရ",
    "Number of Female Headcount Needed": "လိုအပ္ေသာ အမ်ိဳးသမီးလုပ္သားဦးေရ",
    "Available Shifts": "အလုပ္ဆိုင္းမ်ား",
    "Overtime Expectation": "အခ်ိန္ပို(အိုတီ)ရႏိုင္ေျခ",
    "Minimum Working Hours": "တစ္ပတ္အတြင္း အနည္းဆုံးအလုပ္ခ်ိန္",
    "Day of Week Off": "အလုပ္ပိတ္ရက္",
    "Job Description": "လုပ္ရမည့္အလုပ္မ်ား",
    "Job Requirements": "အလုပ္အတြက္ လိုအပ္ခ်က္မ်ား",
    "Job Industry": "လုပ္ငန္းအမ်ိဳးအစား",
    "Job Position Category": "အလုပ္ရာထူး အမ်ိဳးအစား",
    "Job Position Details": "အလုပ္ရာထူး အေသးစိတ္",
    "Terms of Payment": "လုပ္အားခေပးေခ်မႈ",
    "Timing of Payment": "လုပ္အားခေပးေခ်သည့္အခ်ိန္",
    "Timing of Payment Other": "လုပ္အားခေပးေခ်သည့္ အျခားအခ်ိန္",
    Salary: "လုပ္ခ/လစာ",
    "{amount} {currency}/month": "{amount} {currency}(၁)လ",
    "{amount} {currency}/day": "{amount} {currency}(၁)ရက္",
    "Job Type": "အလုပ္သမားအမ်ိဳးအစား",
    "Contract Duration": "စာခ်ဳပ္သက္တမ္း",
    "{duration} months": "{duration} လ",
    "Regular OT Rate": "ပုံမွန္ OT ေၾကး",
    "{amount} {currency}/hour": "{amount} {currency}(၁)နာရီ",
    "Holiday OT Rate": "အစိုးရ႐ုံးပိတ္ရက္အိုတီေၾကး ",
    "Holiday Rate": "အစိုးရ႐ုံးပိတ္ရက္/နားရက္ လုပ္အားခ",
    "ER information": "က်င့္ဝတ္ႏွင့္အညီလုပ္သားစုေဆာင္းျခင္း",
    "Ethical recruitment policy":
      "က်င့္ဝတ္ႏွင့္ညီေသာ လုပ္သားစုေဆာင္းမႈစနစ္ရွိပါသည္",
    "Not Have Ethical recruitment policy":
      "က်င့္ဝတ္ႏွင့္ညီေသာ လုပ္သားစုေဆာင္းမႈစနစ္မရွိပါ",
    "RA has Ethical recruitment policy":
      "ေအဂ်င္စီတြင္ က်င့္ဝတ္ႏွင့္ညီေသာ လုပ္သားစုေဆာင္းမႈစနစ္ရွိပါသည္",
    "RA has not the Ethical recruitment policy":
      "ေအဂ်င္စီတြင္ က်င့္ဝတ္ႏွင့္ညီေသာ လုပ္သားစုေဆာင္းမႈစနစ္မရွိပါ",
    "Employer pays policy": "အလုပ္ရွာေဖြေရးကုန္က်စရိတ္ အလုပ္ရွင္က က်ခံေပးပါသည္",
    "Not have Employer pays policy":
      "အလုပ္ရွာေဖြေရးကုန္က်စရိတ္ အလုပ္ရွင္က က်ခံေပးျခင္းမရွိပါ",
    "Recruitment Information": "အလုပ္သမားေခၚယူမႈဆိုင္ရာ အခ်က္အလက္မ်ား",
    "Not have Ethical recruitment policy":
      "က်င့္ဝတ္ႏွင့္ညီေသာ လုပ္သားစုေဆာင္းမႈစနစ္မရွိပါ",
    "The workers have to pay visa fee":
      "ဗီဇာခ ကုန္က်စရိတ္ကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Employer pays for the visa fees":
      "ဗီဇာခ ကုန္က်စရိတ္ကို အလုပ္ရွင္မွကုန္က်ခံေပးပါသည္",
    "The workers have to pay work permit fee":
      " ေဝါ့ခ္ပါမစ္ခကို အလုပ္သမားမွကုန္က်ခံရမည္",
    "Employer pays for the work permit fees":
      " ေဝါ့ခ္ပါမစ္ခကို အလုပ္ရွင္ကက်ခံေပးပါသည္",
    "The workers have to pay medical checkup fee":
      "ေရာဂါ(၆)မ်ိဳးေဆးစစ္ခကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Employer pays for the medical checkup fees":
      "ေရာဂါ(၆)မ်ိဳးေဆးစစ္ခကို အလုပ္ရွင္မွကုန္က်ခံေပးပါသည္",
    "Skill Training Information": "ျပည္ပအလုပ္အကိုင္လမ္းၫႊန္သင္တန္း",
    "Employer pays Cost of skill-training":
      "ျပည္ပအလုပ္အကိုင္လမ္းၫႊန္သင္တန္းကုန္က်စရိတ္ကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay Cost of skill-training":
      "ျပည္ပအလုပ္အကိုင္လမ္းၫႊန္သင္တန္းကုန္က်စရိတ္ကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "{amount} {currency}": "{amount} {currency}",
    "The employer provides accommodation during skill-training of Workers":
      "ျပည္ပအလုပ္အကိုင္လမ္းၫႊန္သင္တန္းတက္ေနစဥ္ ေနစရိတ္ကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay accommodations fee during skill-training of Workers":
      "ျပည္ပအလုပ္အကိုင္လမ္းၫႊန္သင္တန္းတက္ေနစဥ္ ေနစရိတ္ကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "The employer provides meals during skill-training of Workers":
      "ျပည္ပအလုပ္အကိုင္လမ္းၫႊန္သင္တန္းတက္ေနစဥ္ စားစရိတ္ကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay meals fee during skill-training of Workers":
      "ျပည္ပအလုပ္အကိုင္လမ္းၫႊန္သင္တန္းတက္ေနစဥ္ စားစရိတ္ကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Based on individual cost/spending.":
      "ကိုယ့္သုံးစြဲမႈေပၚမူတည္ၿပီးကုန္က်သေလာက္စိုက္ရန္",
    "The employer pays transportation costs to and from for attending skill training":
      "ျပည္ပအလုပ္အကိုင္လမ္းၫႊန္သင္တန္းတက္ရန္အတြက္ အသြားအျပန္သြားစရိတ္ကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay transportation costs to and from for attending skill training":
      "ျပည္ပအလုပ္အကိုင္လမ္းၫႊန္သင္တန္းတက္ရန္အတြက္ အသြားအျပန္သြားစရိတ္ကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Costs For Pre-Departure": "ျပည္ပမထြက္ခြာမီ ကုန္က်စရိတ္",
    "Employer pays Cost of pre-departure orientation training":
      "ေအဂ်င္စီမွေပးသည့္ ျပည္ပမထြက္ခြာမီလမ္းၫႊန္သင္တန္းအတြက္ကုန္က်စရိတ္ကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay Cost of pre-departure orientation training":
      "ေအဂ်င္စီမွေပးသည့္ ျပည္ပမထြက္ခြာမီလမ္းၫႊန္သင္တန္းအတြက္ကုန္က်စရိတ္ကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Employer provides accommodation during pre-departure orientation training":
      "ေအဂ်င္စီမွေပးသည့္ ျပည္ပမထြက္ခြာမီလမ္းၫႊန္သင္တန္းတက္ေနစဥ္ ေနရာထိုင္ခင္းအတြက္ အလုပ္ရွင္မွစီစဥ္ေပးပါသည္",
    "The workers need to pay accommodations fee during pre-departure orientation training":
      "ေအဂ်င္စီမွေပးသည့္ ျပည္ပမထြက္ခြာမီလမ္းၫႊန္သင္တန္းတက္ေနစဥ္ ေနရာထိုင္ခင္းအတြက္ အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Employer provides meals during pre-departure orientation training":
      "ေအဂ်င္စီမွေပးသည့္ ျပည္ပအလုပ္အကိုင္လမ္းၫႊန္သင္တန္းတက္ေနစဥ္ စားေသာက္ေရးအတြက္ အလုပ္ရွင္မွစီစဥ္ေပးပါသည္",
    "The workers need to pay meals fee during pre-departure orientation training":
      "ေအဂ်င္စီမွေပးသည့္ ျပည္ပအလုပ္အကိုင္လမ္းၫႊန္သင္တန္းတက္ေနစဥ္ စားေသာက္ေရးအတြက္ အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Employer pays transportation costs to and from pre-departure orientation training":
      "ေအဂ်င္စီမွေပးသည့္ ျပည္ပအလုပ္အကိုင္လမ္းၫႊန္သင္တန္းတက္ဖို႔ လမ္းခရီးစရိတ္ကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay transportation costs to and from pre-departure orientation training":
      "ေအဂ်င္စီမွေပးသည့္ ျပည္ပအလုပ္အကိုင္လမ္းၫႊန္သင္တန္းတက္ဖို႔ လမ္းခရီးစရိတ္ကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Additional information for Pre-departure orientation course":
      "ေအဂ်င္စီမွေပးသည့္ ျပည္ပမထြက္ခြာမီလမ္းၫႊန္သင္တန္းအေၾကာင္း အျခားအခ်က္အလက္မ်ား",
    "Costs and Fees for recruitment in the origin country":
      "မိခင္ႏိုင္ငံတြင္း လုပ္သားေခၚယူစုေဆာင္းမႈစရိတ္မ်ား",
    "Employer pays recruitment service fee to RA and sub-agent":
      "ေအဂ်င္စီႏွင့္ လက္ေအာက္ခံကိုယ္စားလွယ္မ်ားအတြက္ လုပ္သားေခၚယူစုေဆာင္းမႈဝန္ေဆာင္ခကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay recruitment service fee to RA and sub-agent":
      "ေအဂ်င္စီႏွင့္ လက္ေအာက္ခံကိုယ္စားလွယ္မ်ားအတြက္ လုပ္သားေခၚယူစုေဆာင္းမႈဝန္ေဆာင္ခကိုအလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Employer pays travel costs for a job interview":
      "အလုပ္အင္တာဗ်ဴးေျဖရန္ သြားရသည့္လမ္းခရီးကုန္က်စရိတ္ကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay travel costs for a job interview":
      "အလုပ္အင္တာဗ်ဴးေျဖရန္ သြားရသည့္လမ္းခရီးကုန္က်စရိတ္ကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Employer pays accommodation costs for contract signing":
      "အလုပ္စာခ်ဳပ္ခ်ဳပ္ဆိုရန္သြားေရာက္ရသည့္အခါ ေနရာထိုင္ခင္းစရိတ္ကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay accommodation costs for contract signing":
      "အလုပ္စာခ်ဳပ္ခ်ဳပ္ဆိုရန္သြားေရာက္ရသည့္အခါ ေနရာထိုင္ခင္းစရိတ္ကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Employer pays transportation cost to and from for contract signing":
      "အလုပ္စာခ်ဳပ္ခ်ဳပ္ဆိုရန္သြားေရာက္ရသည့္အခါ လမ္းခရီးစရိတ္ကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay transportation cost to and from for contract signing":
      "အလုပ္စာခ်ဳပ္ခ်ဳပ္ဆိုရန္သြားေရာက္ရသည့္အခါ လမ္းခရီးစရိတ္ကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Employer provides meals for contract signing":
      "အလုပ္စာခ်ဳပ္ခ်ဳပ္ဆိုရန္သြားေရာက္ရသည့္အခါ စားေသာက္ေရးစရိတ္ကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay meals fee for contract signing":
      "အလုပ္စာခ်ဳပ္ခ်ဳပ္ဆိုရန္သြားေရာက္ရသည့္အခါ စားေသာက္ေရးစရိတ္ကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Employer pays medical check-up fee":
      "ေရာဂါ(၆)မ်ိဳးေဆးစစ္ခကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay medical check-up fee":
      "ေရာဂါ(၆)မ်ိဳးေဆးစစ္ခကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Employer pays passport application fee":
      "ပတ္စပို႔ေလွ်ာက္သည့္ တရားဝင္ကုန္က်စရိတ္ကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay passport application fee":
      "ပတ္စပို႔ေလွ်ာက္သည့္ တရားဝင္ကုန္က်စရိတ္ကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Employer pays Overseas Worker Identification Card (OWIC) fee":
      "ျပည္ပအလုပ္ကိုင္အလုပ္သမားသတ္ေသခံကတ္ျပား ေလွ်ာက္သည့္ကုန္က်စရိတ္ကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay Overseas Worker Identification Card (OWIC) fee":
      "ျပည္ပအလုပ္ကိုင္အလုပ္သမားသတ္ေသခံကတ္ျပား ေလွ်ာက္သည့္ကုန္က်စရိတ္ကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Employer pays Transportation costs to the border or airport":
      "ေလဆိပ္ (သို႔မဟုတ္) နယ္စပ္ထိသြားရမည့္ လမ္းခရီးကုန္က်စရိတ္ကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay Transportation costs to the border or airport":
      "ေလဆိပ္ (သို႔မဟုတ္) နယ္စပ္ထိသြားရမည့္ လမ္းခရီးကုန္က်စရိတ္ကို အလုပ္သမားမွက်ခံရပါမည္",
    "Costs and fees for recruitment in the destination country":
      "သြားေရာက္အလုပ္လုပ္မည့္ႏိုင္ငံတြင္း လုပ္သားေခၚယူစုေဆာင္းမႈ စရိတ္မ်ား",
    "Employer pays Transportation cost from the Thai border to the workplace (or to the destination country)":
      "ထိုင္းနယ္စပ္မွ သူုႏိုင္ငံထဲ (သို႔) အလုပ္လုပ္ရမည့္စက္႐ုံထိ လမ္းခရီးကုန္က်စရိတ္ကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay Transportation cost from the Thai border to the workplace (or to the destination country)":
      "ထိုင္းနယ္စပ္မွ သူုႏိုင္ငံထဲ (သို႔) အလုပ္လုပ္ရမည့္စက္႐ုံထိ လမ္းခရီးကုန္က်စရိတ္ကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Employer pays Visa fee": "ဗီဇာေၾကးကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay Visa fee": "ဗီဇာေၾကးကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Employer pays Work permit": "ေဝါ့ခ္ပါမစ္ေၾကးကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay Work permit":
      "ေဝါ့ခ္ပါမစ္ေၾကးကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Employer pays Medical check-up fee (if workers have to take another check up)":
      "တကယ္လို႔ေနာက္တစ္ႀကိမ္ေဆးစစ္ဖို႔လိုလာပါက ေဆးစစ္ခကို အလုပ္ရွင္မွက်ခံေပးပါသည္",
    "The workers need to pay Medical check-up fee (if workers have to take another check up)":
      "တကယ္လို႔ေနာက္တစ္ႀကိမ္ေဆးစစ္ဖို႔လိုလာပါက ေဆးစစ္ခကို အလုပ္သမားမွကုန္က်ခံရပါမည္",
    "Other Costs": "အျခားစရိတ္မ်ား",
    "Information on repatriation": "ေနရပ္ျပန္ေရးဆိုင္ရာ အခ်က္အလက္",
    Benefits: "ခံစားခြင့္မ်ား",
    "Paid annual leave per year":
      "လုပ္အားခခံစားခြင့္ရွိသည့္ ႏွစ္ျပည့္လုပ္သက္ခြင့္(ဖတ္ေလာ)",
    "Paid sick leave per year":
      "လုပ္အားခခံစားခြင့္ရွိသည့္ တစ္ႏွစ္စာ နာမက်န္းခြင့္",
    "Paid business leave per year":
      "လုပ္အားခခံစားခြင့္ရွိသည့္ တစ္ႏွစ္စာ လုပ္ငန္းဆိုင္ရာခြင့္",
    "Paid national holidays per year":
      "လုပ္အားခခံစားခြင့္ရွိသည့္ တစ္ႏွစ္စာ အစိုးရပိတ္ရက္မ်ား",
    "Social security benefits are provided as required by law":
      "လူမႈဖူလုံေရး ခံစားခြင့္ ကို ဥပေဒအတိုင္း ခံစားခြင့္ရွိသည္။",
    "Social security and maternity leave benefits are provided as required by law":
      "လူမႈဖူလုံေရး (ပကန္စန္ခုမ္) ႏွင့္ မီးဖြားခြင့္ရက္မ်ားကို ဥပေဒအတိုင္း ခံစားခြင့္ရွိသည္။",
    "Social security leave benefits are not provided":
      "လူမႈဖူလုံေရးမွ သတ္မွတ္သည့္ ခြင့္ရက္မ်ားကိုမေပးပါ။",
    "Social security and maternity leave benefits are not provided":
      "လူမႈဖူလုံေရး (ပကန္စန္ခုမ္) ႏွင့္ မီးဖြားခြင့္ရက္မ်ားကို မေပးပါ",
    "Any additional leave detail": "အျခားသီးသန႔္ ခြင့္ရက္မ်ား အေသးစိတ္",
    "Other Benefits": " အျခားခံစားခြင့္မ်ား",
    "Benefits Details": "ခံစားခြင့္မ်ားအေသးစိတ္",
    "Other Information": "အျခားအခ်က္အလက္မ်ား",
    "Accommodation on-site is available": "အလုပ္ရွင္က ေနစရာေပးပါမည္",
    "No information is provided on whether Accommodation on-site or not":
      "အလုပ္ရွင္က ေနစရာေပး/မေပး စသည့္ ခ်က္အလက္မ်ား ‌မေဖာ္ျပထားပါ။",
    "Family members can live together": "မိသားစုဝင္မ်ားလည္း ေနထိုင္ႏိုင္သည္",
    "No information is provided on whether Family members can live together or not":
      "မိသားစုနဲ႔အတူတူ ေနထိုင္ လို႔ရ/မရ စသည့္ အခ်က္အလက္မ်ား မေဖာ္ျပထားပါ။",
    "Rent & Utilites are auto deducted from the payroll":
      "အခန္းခ၊ ေရခ၊ မီးခကို လစာထဲက ျဖတ္မည္",
    "No information is provided for a deduction on Rent & Utilities":
      " အခန္းခ၊ ေရခ၊ မီးခ ျဖတ္ေတာက္မႈ ႏွင့္ဆိုင္ေသာ အခ်က္အလက္မ်ား ‌မေဖာ္ျပထားပါ။",
    "Safe functioning grievance mechanism":
      "အလုပ္သမားျပႆနာမ်ားကို လုံၿခဳံထိေရာက္စြာ ေျဖရွင္းေပးသည္",
    "No Information is provided  for grievance mechanism":
      "ျပႆနာေျဖရွင္းမႈႏွင့္ဆိုင္ေသာ အခ်က္အလက္မ်ား ‌မေဖာ္ျပထားပါ။",
    "Probation period": "အစမ္းခန႔္ ကာလ",
    "No Information is provided for probation period":
      "အစမ္းခန႔္ ကာလ ႏွင့္ဆိုင္ေသာ အခ်က္အလက္မ်ား ‌မေဖာ္ျပထားပါ။",
    "Accommodation Details": "အလုပ္သမားအေဆာင္ႏွင့္ဆိုင္ေသာ အေသးစိတ္အခ်က္အလက္",
    "Grievance Mechanism Details": "အလုပ္သမားျပႆနာေျဖရွင္းမႈ အေသးစိတ္",
    "Probation Period Details": "အစမ္းခန႔္ကာလ အေသးစိတ္",
    "Rent/utilites deduction details":
      "အေဆာင္ခ၊ ေရ/မီး လစာမွ ျဖတ္ေတာက္မႈအေသးစိတ္",
    "Contact Person": "ဆက္သြယ္ရမည့္တာဝန္ခံံ",
    "Recruitment Agency Information": "အလုပ္ရွာေဖြေရးေအဂ်င္စီ အခ်က္အလက္မ်ား",
    "Currently, the employer has received enough applicants for this job post and has moved your CV to the waitlist. You will be notified if the employer requires more applicants. If you prefer not to wait for their notification, please consider applying for another job.":
      "လက္ရွိေခၚတဲ့အလုပ္မွာ လူျပည့္သြားၿပီျဖစ္တဲ့အတြက္ အလုပ္ရွင္က သင့္အလုပ္ေလွ်ာက္လႊာကို ေစာင့္ဆိုင္းစာရင္းမွာ သိမ္းဆည္းေပးထားပါတယ္။ လက္ရွိအလုပ္ေခၚစာမွာ အေၾကာင္းတစ္ခုခုေၾကာင့္ ေနာက္ထပ္အလုပ္သမားလိုအပ္လွ်င္ အလုပ္ရွင္ကေန အလုပ္ခန႔္ဖို႔ဆက္သြယ္လာပါမယ္။ အလုပ္ရွင္ မဆက္သြယ္လာမခ်င္း အလုပ္သစ္ရွာႏိုင္သလို ေစာင့္ဆိုင္းလို႔လည္းရပါတယ္။",
    "This employer requested to view and check your documents {timeAgo}. They will be allowed to view them only, not download.":
      "{timeAgo}က ဒီအလုပ္ရွင္မွ သင့္ရဲ႕အေထာက္အထားစာ႐ြက္စာတမ္းမ်ားကိုၾကည့္လိုေၾကာင္းေတာင္းဆိုထားပါတယ္၊ သင့္စာ႐ြက္စာတမ္းေတြကို ၾကည့္႐ုံသက္သက္သာျဖစ္ၿပီး အလုပ္ရွင္မွ ေဒါင္းလုတ္ဆြဲႏိုင္မည္မဟုတ္ပါ။ ",
    "However, you must first have uploaded the required documents on your resume page:":
      "အလုပ္ရွင္ဖက္က စာ႐ြက္စာတမ္း​ေတြကိုၾကည့္ဖို႔သေဘာတူတယ္ဆိုရင္ အလုပ္ေလွ်ာက္လႊာ (CV ​ေဖာင္)တင္တဲ့​ေနရာမွာ အေထာက္အထားစာ႐ြက္စာတမ္း​ေတြကို အရင္တင္ထား​ေပးပါ။",
    "Upload missing documents": "လိုအပ္သည့္စာ႐ြက္စာတမ္းမ်ားကို တင္မည္",
    Reject: "အလုပ္ကမ္းလွမ္းစာကို ျငင္းပယ္မည္",
    Accept: "အလုပ္ကမ္းလွမ္းစာကို လက္ခံမည္",
    "Missing resume": "အလုပ္ေလွ်ာက္ရန္ CV ေဖာင္ အရင္ျဖည့္ပါ",
    "{clickHereLink} to create your GD resume":
      "CV ေဖာင္ျဖည့္ရန္ {clickHereLink}ႏွိပ္ပါ",
    "Click here": "ဤေနရာကို ႏွိပ္ပါ",
    "Jobs for seekers in Myanmar/Cambodia/Nepal ":
      "ျမန္မာ/ကေမာၻဒီးယား/နီေပါ မွ အလုပ္ရွာသူမ်ားအတြက္ အလုပ္မ်ား",
    "All jobs on Marketplace": "အလုပ္ရွာေဖြရာေနရာတြင္တင္ထားေသာအလုပ္မ်ားအားလုံး",
    "Jobs for seekers in Thailand ": "ထိုင္းေရာက္ေနသူမ်ားအတြက္ အလုပ္မ်ား",
    "Please login to view the jobs..": "အလုပ္ေခၚစာၾကည့္ရန္  အေကာင့္သို႔ဝင္ပါ..",
    "Sorry, no recruitment agencies matched filter parameters, please try again.":
      "ဝမ္းနည္းပါတယ္ သင္ရွာေဖြေသာ ေအဂ်င္စီ အမည္ႏွင့္ တူေသာ ေအဂ်င္စီ စာရင္းထဲတြင္ မရွိပါ",
    "Successfully applied": "ေလွ်ာက္ထားၿပီးၿပီ",
    "Couldn't apply due to server error":
      "လိုင္းက်ေနပါသျဖင့္ အလုပ္ေလွ်ာက္ျခင္းမေအာင္ျမင္ပါ။",
    "Please create your CV first": "ေက်းဇူးျပဳ၍ CVေဖာင္အရင္ျဖည့္ေပးပါ",
    Screening: "အျခားသိလိုသည့္အခ်က္မ်ား",
    "Do you know someone working here?":
      "အခုေလွ်ာက္တဲ့အလုပ္မွာ လုပ္ေနတဲ့ေဆြမ်ိဳး၊သူငယ္ခ်င္း၊ အသိမိတ္ေဆြရွိပါသလား။",
    "No, I don't know": "အသိမရွိပါ",
    "Yes, I know someone": "ဟုတ္ကဲ့..အသိရွိပါတယ္",
    "After going through the job description, do you think you can do the job well?":
      "အလုပ္နဲ႔ပတ္သက္တဲ့အခ်က္အလက္​ေတြကို​ေသ​ေသခ်ာခ်ာဖတ္ၿပီးသြားၿပီဆို​ေတာ့ ဒီအလုပ္ကို​ေကာင္းေကာင္းလုပ္ႏိုင္မယ္လို႔ ထင္သလား။",
    "No, I am not sure": "သိပ္မေသခ်ာပါ။",
    "Yes, I think so": "ဟုတ္ကဲ..လုပ္ႏိုင္မယ္လို႔ထင္ပါတယ္",
    "This Employer/Recruiter might need to see your documents and information to confirm you are eligible for the job. ":
      "ဒီအလုပ္ေခၚစာအတြက္ကိုက္ညႇီမႈရွိ/မရွိစစ္ၾကည့္ရန္ အလုပ္ရွင္/ ေအဂ်င္စီမွ သင္တြဲထားသည့္အေထာက္အထားမ်ားႏွင့္ ကိုယ္ေရးအခ်က္အလက္မ်ား ကို ၾကည့္ရႈရန္လိုအပ္ပါတယ္။ ",
    "No, I don't": "ခြင့္မျပဳပါ",
    "Yes, I do": "လက္ခံခြင့္ျပဳပါတယ္",
    "No consent was given": "သေဘာမတူပါ",
    "Sorry! Employers need to see your documents to confirm you are eligible for the job. The documents required by employers may vary, so you are encourage to share the document you have, so that the employer can check if you are eligible. Is that ok for you?":
      "ဝမ္းနည္းပါတယ္! သင္ဟာအလုပ္အတြက္သင့္​ေတာ္တဲ့သူျဖစ္တယ္ဆိုတာကိုအတည္ျပဳဖို႔အတြက္ အလုပ္ရွင္က စာ႐ြက္စာတမ္း​ေတြကိုၾကည့္ဖို႔လိုအပ္ပါတယ္။ အလုပ္ရွင္တစ္​ေယာက္နဲ႔တစ္ေယာက္ၾကည့္ခ်င္တဲ့စာ႐ြက္စာတမ္း​ေတြလည္းမတူႏိုင္လို႔ သင့္မွာရွိတဲ့ အေထာက္အထားစာ႐ြက္စာတမ္း​ေတြကို တင္ေပးထားတယ္ဆိုရင္ သူတို႔လိုတာကိုၾကည့္လို႔ရပါလိမ့္မယ္။ စာ႐ြက္စာတမ္းတင္ဖို႔အဆင္ေျပလား။",
    "If you have any questions, for those who are in Thailand, please call {phoneLink}, and for those in Myanmar, please call or send message to Viber No. {viberLink} at anytime, 24 hours a day, 7 days a week.":
      "တစ္ခုခု ေမးစရာရွိပါက ထိုင္းႏိုင္ငံမွ အသုံးျပဳသူမ်ားအေနျဖင့္ ဖုန္းနံပါတ္ {phoneLink}  ႏွင့္ ျမန္မာႏိုင္ငံမွ အသုံးျပဳသူမ်ားအေနျဖင့္ ဘိုင္ဘာနံပါတ္ {viberLink}  သို႔ ဖုန္းဆက္ (သို႔) စာပို႔ၿပီး အခ်ိန္မေ႐ြးဆက္သြယ္လို႔ရပါတယ္။",
    "If you have any questions, please call {phoneLink} (For those who are in Thailand) or direct message to Facebook page {facebookLink} at anytime, 24 hours a day, 7 days a week.":
      "တစ္ခုခု ေမးစရာရွိပါက ထိုင္းႏိုင္ငံမွ အသုံးျပဳသူမ်ားအေနျဖင့္ ဖုန္းနံပါတ္ {phoneLink} ႏွင့္ ကေမာၻဒီးယား ႏိုင္ငံမွ အသုံးျပဳသူမ်ားအေနျဖင့္ ေဖ့စဘုတ္စာမ်က္ႏွာ {facebookLink}  သို႔ စာပို႔ၿပီး အခ်ိန္မေ႐ြးဆက္သြယ္လို႔ရပါတယ္။",
    "If you have any questions, please call {phoneLink}.  The call is free of charge and you can call at anytime, 24 hours a day, 7 days a week.":
      "တစ္ခုခု ေမးစရာရွိပါက {phoneLink} ကို ဆက္သြယ္ေပးပါေနာ္။ ဖုန္းေခၚဆိုသည့္အခါ အခမဲ့ျဖစ္ၿပီး တစ္ပတ္၇ရက္ ပိတ္ရက္မရွိ၊ ၂၄နာရီအတြင္းအခ်ိန္မေ႐ြးေခၚဆိုႏိုင္ပါတယ္။",
    "Do you need help in completing this resume?":
      "အလုပ္ေလွ်ာက္လႊာ CVကို ျပည့္ျပည့္စုံစုံျဖည့္ရန္ အကူအညီလိုအပ္ပါသလား။",
    "Uploaded Nepal Documents":
      "တင္ထားၿပီးေသာ နီေပါ စာ႐ြက္စာတမ္းအေထာက္အထားမ်ား",
    "Uploaded Myanmar Documents":
      "တင္ထားၿပီးသည့္ ျမန္မာ စာ႐ြက္စာတမ္းအေထာက္အထားမ်ား",
    "NRC Card": "မွတ္ပုံတင္ကတ္ျပား",
    "Uploaded Khmer Documents":
      "တင္ထားၿပီးသည့္ ကေမာၻဒီးယားစာ႐ြက္စာတမ္းအေထာက္အထားမ်ား",
    "Uploaded Documents": "တင္ထားၿပီးသည့္ စာ႐ြက္စာတမ္းအေထာက္အထားမ်ား",
    "Cancellation letter": "အလုပ္ပယ္ဖ်က္စာ",
    Resume:
      "အလုပ္ႀကိဳတင္ေလွ်ာက္ထားႏိုင္ရန္အတြက္ အလုပ္ေလွ်ာက္လႊာေဖာင္ (CV) ျဖည့္ရန္/ျပင္ရန္",
    "Basic Information": "ကိုယ္ေရးအခ်က္အလက္",
    "Full name": "အမည္အျပည့္အစုံ",
    Birthday: "ေမြးေန႔",
    Education: "ပညာအရည္အခ်င္း",
    Major: "အထူးျပဳ ဘာသာ",
    "Emergency Phone number": "အေရးေပၚဖုန္းနံပါတ္",
    "LINE id number": "လိုင္းစိမ္းနံပါတ္",
    "Viber id number": "ဘိုင္ဘာနံပါတ္",
    "Current Address": "လက္ရွိေနရပ္လိပ္စာ",
    Documents: "စာ႐ြက္စာတမ္းအေထာက္အထားမ်ား",
    "National ID": "မွတ္ပုံတင္နံပါတ္",
    "Passport expires": "ပတ္စပို႔ သက္တမ္းကုန္ဆုံးရက္",
    "Certificate of Identity Number": "စီအိုင္စာအုပ္နံပါတ္",
    "Certificate of Identity expires": "စီအိုင္စာအုပ္ သက္တမ္းကုန္ဆုံးရက္",
    "Pink Card Number": "ပန္းေရာင္ကတ္နံပါတ္",
    "Working Experience": "အလုပ္အေတြ႕အႀကဳံ",
    Title: "ရာထူး",
    Years: "ႏွစ္",
    "Languages Proficiency": "ဘာသာစကားကြၽမ္းက်င္မႈ",
    "Language Skills": "ဘာသာစကားေျပာဆိုတတ္ျခင္း",
    "Read Level": "စာဖတ္နိင္မႈ",
    "Speak Level": "အေျပာစြမ္းရည္",
    "Passport Number": "ပါတ္စ္စပို႔ နံပါတ္",
    optional: "မျဖစ္မေနျဖည့္ရန္မလိုပါ",
    "Passport number": "ပါတ္စ္စပို႔ နံပါတ္",
    "Enter your Passport Number as shown in the picture":
      "နမူနာပုံမွာဝိုင္းျပထားတဲ့​ေနရာမွာရွိတဲ့ ပါတ္စပို႔နံပါတ္ကိုေရးျဖည့္​ေပးပါ။ (ဥပမာ MD123456)",
    "Valid till": "သက္တမ္း ေနာက္ဆုံးေန႔",
    Month: "လ",
    Year: "ႏွစ္",
    "Day: please enter the number between 1 and 31":
      "၁ မွ ၃၁ အတြင္းရွိ ဂဏန္း႐ိုက္ထည့္ပါ",
    "Month: please enter the number between 1 and 12":
      "၁ မွ ၁၂  အတြင္းရွိ ဂဏန္း႐ိုက္ထည့္ပါ",
    "Year: please enter the number between 2020 and 2040":
      " ခုႏွစ္-၂၀၂၀ မွ ၂၀၄၀ အတြင္းရွိ ဂဏန္း႐ိုက္ထည့္ပါ",
    "National ID Number": "မွတ္ပုံတင္နံပါတ္",
    "Enter your National Identification Card number":
      "အမ်ိဳးသားသတ္ေသခံကတ္နံပါတ္ျဖည့္ေပးပါ",
    "Enter your National Identification Card Number as shown in the picture":
      "နမူနာျပထားတဲ့ပုံထဲကအတိုင္း အမ်ိဳးသားသတ္ေသခံကတ္နံပါတ္ကိုေရးျဖည့္​ေပးပါ",
    "Enter your NRC number": "မွတ္ပုံတင္နံပါတ္ျဖည့္ေပးပါ",
    "Enter your NRC Number as shown in the picture (eg: 12/TaKaNa(N)123456)":
      "နမူနာပုံမွာဝိုင္းျပထားတဲ့​ေနရာမွာရွိတဲ့မွတ္ပုံတင္နံပါတ္ကို ​ျဖည့္ေပးပါ (ဥပမာ ၁၂/တကန(ႏိုင္)၁၂၃၄၅၆)",
    "Enter your Passport Number as shown in the picture (eg: MD123456)":
      "နမူနာပုံမွာဝိုင္းျပထားတဲ့​ေနရာမွာရွိတဲ့ ပါတ္စပို႔နံပါတ္ကိုေရးျဖည့္​ေပးပါ။ (ဥပမာ MD123456)",
    "CI Number": "စီအိုင္စာအုပ္နံပါတ္",
    "CI number": "စီအိုင္စာအုပ္နံပါတ္",
    "Enter your CI Number": "စီအိုင္စာအုပ္နံပါတ္ ျဖည့္ေပးပါ",
    "Enter your Pink Card Number as shown in the picture, the pink card number mush have 13 digits. (eg: 00 1234 567890 1)":
      "နမူနာပုံမွာဝိုင္းျပထားတဲ့​ေနရာမွာရွိတဲ့ ပန္းေရာင္ကဒ္နံပါတ္ကိုေရးျဖည့္​ေပးပါ။  ဂဏန္း ၁၃လုံးရွိရပါမယ္။ (ဥပမာ ၀၀ ၁၂၃၄ ၅၆၇၈၉၀ ၁)",
    "Remove Passport": "ပတ္စပို႔ကိုျပန္ဖ်က္မည္",
    "Remove visa": "ဗီဇာျပန္ဖ်က္မည္",
    "Remove work permit": "ေဝါ့ခ္ပါမစ္ျပန္ဖ်က္မည္",
    "Remove pink card": "ပန္းေရာင္ကတ္ ျပန္ဖ်က္မည္",
    "Remove canc. letter": "အလုပ္ပယ္ဖ်က္စာျပန္ဖ်က္မည္",
    "Uploaded Documents for Myanmar":
      "ျမန္မာအတြက္တင္ထားၿပီးသည့္ စာ႐ြက္စာတမ္းအေထာက္အထားမ်ား",
    "Remove NRC": "မွတ္ပုံတင္ျပန္ဖ်က္မည္",
    "Remove Household List": "အိမ္ေထာင္စုစာရင္းျပန္ဖ်က္မည္",
    "Remove Labour Card": "အလုပ္သမားကတ္ ျပန္ဖ်က္မည္",
    "Remove Covid Vaccine Certificate":
      "ကိုဗစ္-၁၉ ကာကြယ္ေဆးထိုးကတ္ျပားကိုျပန္ဖ်က္မည္",
    "Uploaded Documents for Nepal":
      "ကေမာၻဒီးယားအတြက္တင္ထားၿပီးသည့္ စာ႐ြက္စာတမ္းအေထာက္အထားမ်ား ",
    "Uploaded Documents for Khmer":
      "ကေမာၻဒီးယားအတြက္တင္ထားၿပီးသည့္ စာ႐ြက္စာတမ္းအေထာက္အထားမ်ား",
    "Remove Identification Card": "သက္ေသခံကတ္ျပားကို ျပန္ဖ်က္မည္",
    "Remove Birth Certificate": "ေမြးစားရင္း ျပန္ဖ်က္မည္",
    "Remove Family Record Book": "မိသားစုမွတ္တမ္းစာအုပ္ျပန္ဖ်က္မည္",
    "Remove Residence Book": "ေနထိုင္ေၾကာင္းအေထာက္အထားစာအုပ္ကိုျပန္ဖ်က္မည္",
    "Please use one of the following formats: .jpeg, .jpg or .png":
      "jpeg, jpg, png ပုံမ်ားသာ လက္ခံသည္",
    "Couldn't update some of the documents":
      "စာ႐ြက္စာတမ္းအခ်ိဳ႕တင္လို႔မရျဖစ္သြားပါသည္",
    "Resume has been successfuly updated": "CV ေဖာင္ ျဖည့္စြက္ျပင္ဆင္ၿပီးပါၿပီ",
    "Couldn't update photo": "ဓာတ္ပုံထည့္မႈ မေအာင္ျမင္ပါ",
    "Resume has not been updated due to server error":
      "လိုင္းက်ေနပါသျဖင့္ CV ေဖာင္ျပင္ဆင္ျဖည့္စြက္ျခင္း မေအာင္ျမင္ပါ",
    "Resume has been successfuly created":
      "CV ေဖာင္ကိုေအာင္ျမင္စြာျဖည့္စြက္ ၿပီးပါၿပီ",
    "Resume has not been created due to server error":
      "လိုင္းက်ေနပါသျဖင့္ CVေဖာင္ျဖည့္စြက္တင္သြင္းမႈ မေအာင္ျမင္ပါ",
    "Upload your photo": "သင့္ဓာတ္ပုံတင္ေပးပါ",
    "Enter your full name": "အမည္အျပည့္အစုံ ႐ိုက္ထည့္ပါ",
    "Full name must be the same name in your NRC/Passport":
      "မွတ္ပုံတင္/ ပါတ္စပို႔ ထဲက နာမည္အတိုင္းျဖည့္ေပးပါ။ ",
    "Full name must be the same name in your NRC/Passport/Pinkcard":
      "မွတ္ပုံတင္/ ပါတ္စပို႔/ ပန္းေရာင္ကဒ္ထဲက နာမည္အတိုင္းျဖည့္ေပးပါ။ ",
    "Please enter your full name": "အမည္အျပည့္အစုံ ႐ိုက္ထည့္ေပးပါ",
    "Name can contain only letters": "အမည္ကို အဂၤလိပ္အကၡရာျဖင့္ေရးပါ",
    "Choose male or female": "က်ား/မ ေ႐ြးေပးပါ",
    "Select the highest level of education that you have successfully completed":
      "တက္ေရာက္ခဲ့သည့္ အျမင့္ဆုံးပညာအရည္အခ်င္းကို ေ႐ြးေပးပါ။",
    "Please choose your education level": "ပညာအရည္အခ်င္း ေ႐ြးခ်ယ္ေပးပါ",
    "Enter your major name": "အထူးျပဳဘာသာ႐ိုက္ထည့္ပါ",
    "Enter your major": "အထူးျပဳဘာသာ႐ိုက္ထည့္ ပါ",
    "Please enter your major": "ေက်းဇူးျပဳ၍အထူးျပဳဘာသာ႐ိုက္ထည့္ေပးပါ",
    "Birthday (Gregorian calendar)":
      "ေမြးေန႔ (အဂၤလိပ္ခုႏွစ္အတိုင္းျဖည့္ေပးပါရန္)",
    Date: "ေန႔",
    "Please fill Gregorian day": "အဂၤလိပ္ခုႏွစ္အတိုင္း ေမြးရက္ကို ျဖည့္ေပးပါ",
    "Please fill Gregorian Month": "အဂၤလိပ္ခုႏွစ္အတိုင္း ေမြးလကို ျဖည့္ေပးပါ",
    "Please fill Gregorian Year": "အဂၤလိပ္ခုႏွစ္အတိုင္း ေမြးႏွစ္ကို ျဖည့္ေပးပါ",
    "Day: this month has less days": "ဤလ သည္ရက္မစုံပါ",
    "Year: please enter the number between 1940 and 2005":
      "ခုႏွစ္- ၁၉၄၀ မွ ၂၀၀၅ အတြင္းရွိ ဂဏန္း႐ိုက္ထည့္ပါ",
    "Enter your email": "အီးေမး႐ိုက္ထည့္ပါ",
    "Enter the email address you always use":
      "အၿမဲသုံးေနက် အီးေမးလ္လိပ္စာ ျဖည့္ေပးပါ။",
    "Please enter valid email address": "အီးေမးလ္အမွန္ျဖည့္ေပးပါ",
    "Pls add your country code in front of the no. For Eg. for 09765000150, pls put 959765000150.":
      "ဖုန္းနံပါတ္ေရွ႕မွာ ျမန္မာႏိုင္ငံရဲ႕နံပါတ္ 95 ကိုထည့္ရပါမယ္။ ဥပမာ- ကိုယ့္နံပါတ္က 09765000150 ဆိုရင္ 959765000150 လို႔ျဖည့္ေပးပါ",
    "Enter the phone number you always use":
      "အၿမဲသုံးေနက် ဖုန္းနံပါတ္ ျဖည့္ေပးပါ",
    "Enter your emergency phone number": "အေရးေပၚဖုန္းနံပါတ္ ႐ိုက္ထည့္ပါ",
    "Enter your Emergency Phone Number":
      "အေရးေပၚဆက္သြယ္ရမည့္ ဖုန္းနံပါတ္ျဖည့္ေပးပါ",
    "Enter LINE id": "လိုင္းစိမ္းနံပါတ္ျဖည့္ပါ",
    "Enter your LINE id number": "လိုင္းစိမ္းနံပါတ္ျဖည့္ေပးပါ",
    "Enter your LINE id Number": "လိုင္းစိမ္းနံပါတ္ျဖည့္ေပးပါ",
    "Please enter valid LINE id number":
      "ေက်းဇူးျပဳ၍ သင့္ရဲ႕လိုင္းစိမ္းနံပါတ္အမွန္ျဖည့္ေပးပါ",
    "Enter your viber id number": "သင့္ရဲ႕ ဘိုင္ဘာနံပါတ္ျဖည့္ေပးပါ",
    "Enter your Viber Id Number": "သင့္ရဲ႕ ဘိုင္ဘာနံပါတ္ျဖည့္ေပးပါ",
    "Please enter valid viber id number":
      "ေက်းဇူးျပဳ၍ သင့္ရဲ႕ ဗိုင္ဗာနံပါတ္အမွန္ ျဖည့္ေပးပါ",
    "Select Country": "ႏိုင္ငံေ႐ြးေပးပါ  ",
    "Select the country you are living currently":
      "လက္ရွိေနထိုင္ေနေသာ ႏိုင္ငံကိုျဖည့္ေပးပါ",
    "State/Province": "ျပည္နည္/တိုင္း",
    "Select State/Province": "ျပည္နည္/တိုင္း ေ႐ြးခ်ယ္ပါ ",
    "Select the state/province you are living currently":
      "လက္ရွိေနထိုင္သည့္ ျပည္နည္/တိုင္း ေ႐ြးခ်ယ္ပါ",
    City: "ၿမိဳ႕",
    "Select City": "မြို့ ရွေးချယ်ပါ",
    "Select the city you are living currently":
      "လက္ရွိေနထိုင္ေနေသာ ၿမိဳ႕ကိုျဖည့္ေပးပါ",
    "Country: please select the country":
      "ႏိုင္ငံ၊ ေက်းဇူးျပဳ၍ ႏိုင္ငံကိုေ႐ြးပါ",
    "State/Province: please select the state/province":
      "ျပည္နည္/တိုင္း: ေက်းဇူးျပဳၿပီး ျပည္နည္/တိုင္း‌ ေ႐ြးေပးပါ",
    "City: please select the city": "ၿမိဳ႕၊ ေက်းဇူးျပဳ၍ ၿမိဳ႕ကိုေ႐ြးပါ",
    "Address details": "လိပ္စာ အျပည့္ အစုံ",
    "Please enter address details here":
      "ေက်းဇူးျပဳ၍ လိပ္စာအေသးစိတ္ကိုဒီမွာထည့္ပါ",
    "Please enter the Address Details here (Room No, Street Name, Block Name, Township Name)":
      "ေက်းဇူးျပဳ၍ လိပ္စာအေသးစိတ္ကိုဒီမွာထည့္ပါ (အခန္းအမွတ္၊ လမ္းအမည္၊ ရပ္ကြက္အမည္၊ ၿမိဳ႕နယ္အမည္)",
    "Please enter your address details":
      "ေက်းဇူးျပဳ၍ သင္၏လိပ္စာအေသးစိတ္ကိုထည့္ပါ",
    "Select Language": "ဘာသာစကားကိုေ႐ြးပါ",
    "Select the Language": "ဘာသာစကားကိုေ႐ြးပါ",
    "Select the Langauge": "ဘာသာစကားကိုေ႐ြးပါ",
    "Select Read Level": "စာဖတ္ႏိုင္မႈစြမ္းရည္ကိုေ႐ြးပါ",
    "Select the Read Level of Language": "စာဖတ္ႏိုင္မႈစြမ္းရည္ကိုေ႐ြးပါ",
    "Select read level of Language": "စာဖတ္ႏိုင္မႈစြမ္းရည္ကိုေ႐ြးပါ",
    "Select Speak Level": "အေျပာစြမ္းရည္ကို ေ႐ြးပါ",
    "Select speak Level of Language": "ဘာသာစကား ေျပာဆိုနိင္မႈကို ေ႐ြးပါ",
    "Select speak level of Language": "ဘာသာစကား ေျပာဆိုနိင္မႈကို ေ႐ြးပါ",
    remove: "ဖ်က္မည္",
    "Add Language Skill": "ဘာသာစကားတတ္ကြၽမ္းမႈကို ထပ္ထည့္မည္",
    "Job Title": "အလုပ္အကိုင္အမည္",
    "Enter your job title": "သင္၏အလုပ္အကိုင္အမည္ကိုျဖည့္ေပးပါ",
    "Enter the job title of previous job or current job":
      "အရင္က ဘာအလုပ္လုပ္ခဲ့တာလဲဆိုတာေရးေပးပါ",
    "Select the type of industry you worked in for this job":
      "အရင္အလုပ္ရဲ႕လုပ္ငန္းအမ်ိဳးအစားကို ေ႐ြးေပးပါ။",
    "Select industry": "လုပ္ငန္းအမ်ိဳးအစားေ႐ြးေပးပါ",
    "Add new experience": "အေတြ႕အႀကဳံအသစ္ျဖည့္စြက္ပါ",
    "Please fill in all the required fields and try again.":
      "ျဖည့္စြက္စရာအားလုံး ျဖည့္စြက္ၿပီး ထပ္မံႀကိဳးစားပါ။",
    "Save CV": "အလုပ္ေလွ်ာက္လႊာ CV သိမ္းဆည္းမည္",
    "Update required!":
      "​ေ႐ႊအိပ္မက္အပလီ​ေကးရွင္းကို အဆင့္ျမႇင့္ရန္ (updateလုပ္ရန္)လိုပါတယ္!",
    "Please {clickHere} to update your app in Google Play Store. Close and reopen the app once completed.":
      "​Google Play Store တြင္ ေ႐ႊအိပ္မက္အပလီ​ေကးရွင္းကို အဆင့္ျမႇင့္ရန္ (updateလုပ္ရန္)  {clickHere} ကိုႏွိပ္ပါ၊ update လုပ္ၿပီးသြားတဲ့အခါ ေ႐ႊအိပ္မက္ကိုပိတ္ၿပီးျပန္ဖြင့္ေပးပါ.",
    "click here": "ဤေနရာကို ႏွိပ္ပါ",
    "Free hotline": "အခမဲ့ Hotline ဖုန္း",
    "Thailand Hotline (free)": "အခမဲ့ Hotline ဖုန္း (အစ္ဆာရာထိုင္း႐ုံး)",
    "Yangon Hotline": "Hotline ဖုန္း (အစ္ဆာရာျမန္မာ႐ုံး)",
    "Nepal Hotline (free)": "အခမဲ့ Hotline ဖုန္း (နီေပါ)",
    Recruiters: "ေအဂ်င္စီမ်ား",
    Services: "လူမႈေရးအဖြဲ႕မ်ား",
    "Share a problem": "အခက္အခဲတင္ျပရန္",
    Language: "ဘာသာစကား",
    Marketplace: "အလုပ္ရွာေဖြရာေနရာ",
    "GoldenDreams App": "ေ႐ႊအိပ္မက္ ",
    Surveys: "စစ္တမ္းမ်ား",
    "Confirm Delete Your Profile?":
      "ပ႐ိုဖိုင္မွ အခ်က္အလက္မ်ားကို ဖ်က္ရန္ အတည္ျပဳသည္",
    "By selecting the delete profile option, you will be deleting all of your information on Golden Dreams. Once you confirm, you will not be able to recover any of this information. Are you sure you want to delete your profile?":
      "‘ပ႐ိုဖိုင္မွ အခ်က္အလက္မ်ားကို ဖ်က္မည္’ ခလုတ္ကို ႏွိပ္လိုက္တာနဲ႔  ေ႐ႊအိပ္မက္မွာထည့္ထားသည့္ သင့္အခ်က္အလက္မ်ားအားလုံးကို အပလီေကးရွင္းမွ ဖ်က္ပစ္ပါမည္။ သင့္အေနနဲ႔ အတည္ျပဳၿပီးတာနဲ႔ အခ်က္အလက္မ်ားကို ျပန္လည္ရယူႏိုင္မည္မဟုတ္ပါ။ သင့္ပ႐ိုဖိုင္ကို ဖ်က္လိုသည္မွာ ေသခ်ာပါသလား။",
    "Yes, Delete my profile": "ပ႐ိုဖိုင္မွ အခ်က္အလက္မ်ားကို ဖ်က္ပါမည္။",
    Verified: "အတည္ျပဳထားၿပီးၿပီ",
    Unverified: "အတည္မျပဳရေသးပါ",
    "Profile strength:": "ပ႐ိုဖိုင္ ( ကိုယ္ေရး အခ်က္ အလက္)  ၿပီးျပည့္စုံမႈ",
    Name: "အမည္",
    Age: "အသက္",
    "Enter your age": "အသက္႐ိုက္ထည့္ပါ",
    "Please enter valid phone number!":
      "ဆက္သြယ္ႏိုင္ေသာဖုန္းနံပါတ္အမွန္ ႐ိုက္ထည့္ပါ",
    "Phone Number": "ဖုန္းနံပါတ္",
    "Verify Phone": "ဖုန္းနံပါတ္ အတည္ျပဳမည္ ",
    "Preload media for offline use":
      "အသံဖိုင္ႏွင့္ဗီဒီယိုမ်ားကိုအင္တာနက္မလိုဘဲအသုံးျပဳရန္ေဒါင္းထားမည္။",
    "No preload": "မလုပ္ပါ",
    Audio: "အသံဖိုင္",
    "Audio + Video": "အသံ+ဗီဒီယိုဖိုင္",
    "Delete Profile": "ပ႐ိုဖိုင္မွ အခ်က္အလက္မ်ားကို ဖ်က္မည္",
    "Privacy policy": "ကိုယ္​ေရးကိုယ္တာအခ်က္အလက္လုံၿခဳံ​ေရးဆိုင္ရာမူဝါဒ",
    "Member agreement": "သေဘာတူညီမႈ",
    Saving: "သိမ္းဆည္းေနသည္",
    "Password must be at least 8 characters long and include both letters (a-z) and numbers (0-9).":
      "လွ်ိဳ႕၀ွက္ကုတ္သည္ အနည္းဆုံး ၈ လုံး ရွိရမည္ျဖစ္ၿပီး အဂၤလိပ္စာလုံး (‌ a မွ z) ႏွင့္ နံပါတ္ (0 မွ 9) ႏွစ္မ်ိဳးစလုံး ပါရွိရမည္။",
    "Please Confirm your password": "ေက်းဇူးျပဳၿပီး လွ်ိဳ႕၀ွက္ကုတ္အတည္ျပဳပါ",
    "privacy policy": "ကိုယ္​ေရးကိုယ္တာအခ်က္အလက္လုံၿခဳံ​ေရးဆိုင္ရာမူဝါဒ",
    "member agreement": "သေဘာတူညီမႈ",
    "GOOGLE TEST SIGNUP": "ဂူဂဲလ္ျဖင့္ အစမ္းသုံးရန္ ဝင္မည္",
    "Facebook TEST SIGNUP": "ေဖ့စ္ဘုတ္ျဖင့္ အစမ္းသုံးရန္ ဝင္မည္",
    "Enter username or email you want to use":
      "ဖြင့္လိုသည့္ အေကာင့္အမည္ (သို႔မဟုတ္) အီးေမးလ္",
    "Please enter username or email you want to use":
      "ဖြင့္လိုသည့္ အေကာင့္အမည္ (သို႔မဟုတ္) အီးေမးလ္ျဖည့္ေပးပါ",
    "Enter your password": "လွ်ိဳ႕ဝွက္ကုတ္ ႐ိုက္ထည့္ပါ",
    "Password must be at least 8 characters long, requiring at least 1 letter (a-z) and 1 number (0-9).":
      "လွ်ိဳ႕၀ွက္ကုတ္သည္ အနည္းဆုံး ၈ လုံး ရွိရမည္ျဖစ္ၿပီး အဂၤလိပ္စာလုံး (‌ a မွ z) ႏွင့္ နံပါတ္ (0 မွ 9) ႏွစ္မ်ိဳးစလုံး ပါရွိရမည္။",
    "Please enter your password": "လွ်ိဳ႕ဝွက္ကုတ္ ႐ိုက္ထည့္ပါ",
    "Confirm Password": "လြ္ိဳ႕၀ြကၠဳတ္်ပႏ္အတၫ္်ပဳပါ။",
    "Please confirm your password":
      "အေပၚကျဖည့္ထားသည့္လွ်ိဳ႕၀ွက္ကုတ္အတိုင္း ေနာက္တစ္ခါ ထပ္ထည့္ပါ။",
    "Show Passwords": "ျဖည့္ထားသည့္လွ်ိဳ႕ဝွက္ကုတ္တူ/မတူၾကည့္မည္",
    Register: "အေကာင့္ဖြင့္ရန္အတည္ျပဳမည္",
    "By clicking register, you agree to our {privacy_policy} and {member_agreement}.":
      "အေကာင့္ဖြင့္လိုက္သည္ႏွင့္ {privacy_policy} ႏွင့္ {member_agreement}ကို နားလည္လက္ခံၿပီးျဖစ္သည္။",
    or: "သို႔မဟုတ္",
    "Continue with Facebook": "ေဖ့စ္ဘုတ္သုံး၍ ဝင္ရန္",
    "Continue with Google": "ဂူဂဲလ္သုံး၍ ဝင္ရန္",
    "Resume has been successfuly deleted":
      "အလုပ္ေလွ်ာက္လႊာ ေအာင္ျမင္စြာ ပယ္ဖ်က္ၿပီးပါၿပီ",
    "Resume has not been deleted due to server error":
      "လိုင္းက်ေနပါသျဖင့္ အလုပ္လွ်ာက္လႊာပယ္ဖ်က္ျခင္း မေအာင္ျမင္ပါ",
    "Please use one of the following formats: .jpeg, .jpg, .png, .pdf, .doc, .docx, .xls or .xlsx":
      "jpeg, jpg, png, pdf, doc, docx, xls  ႏွင့္ xlsx ဖိုင္မ်ားကိုသာ အသုံးျပဳပါ",
    "Resume has been successfuly uploaded":
      "အလုပ္ေလွ်ာက္လႊာကိုတင္ထားလိုက္ပါၿပီ",
    "Resume has not been uploaded due to server error":
      "လိုင္းက်ေနပါသျဖင့္ အလုပ္ေလွ်ာက္လႊာတင္သြင္းမႈ မေအာင္ျမင္ပါ",
    "Confirm deletion": "ပါယ္ဖ်က္မႈကို အတည္ျပဳသည္",
    "Are you sure you would like to delete your CV?":
      "သင္၏ အလုပ္ေလွ်ာက္လႊာကိုပါယ္ဖ်က္ဖို႔ ေသခ်ာၿပီလား",
    "Are you sure you would like to delete your uploaded CV?":
      "သင္တင္ထားေသာ အလုပ္ေလ်ာက္လႊာကို ပယ္ဖ်က္ဖို႔ ေသခ်ာၿပီလား",
    "Golden Dreamer CV": "ေ႐ႊအိပ္မက္အလုပ္ေလွ်ာက္လႊာ",
    View: "ၾကည့္မည္",
    Edit: "ျပန္ျပင္ရန္",
    "We encourage you to create a Golden Dreamer CV to show your educational background, work experience, and skills you've mastered.":
      "သင့္ရဲ႕ ပညာအရည္အခ်င္း၊ အလုပ္အေတြ႕အႀကဳံ ၊ ကြၽမ္းက်င္မႈ ေတြကို ​ေဖာ္ျပ​ေပးမယ့္ အလုပ္ေလွ်ာက္လႊာတစ္ခု ဖန္တီးဖို႔ တိုက္တြန္းပါရေစ။",
    "It is a short document that gives you a chance to show a prospective employer the best of what you've got. It highlights your skills and experience and shows them you're the right person for the job. It takes only a few minutes to create one.":
      "သင့္ ပညာအရည္အခ်င္း၊ အ​ေတြ႕အႀကဳံနဲ႔ ကြၽမ္းက်င္မႈ​ေတြကို သင္အလုပ္​ေလွ်ာက္မယ့္အလုပ္ရွင္ကို ျပည့္ျပည့္စုံစုံတင္ျပႏိုင္ၿပီး သင္ဟာ သူတို႔​ေခၚ​ေနတဲ့အလုပ္အတြက္ အသင့္ေတာ္ဆုံးသူျဖစ္တယ္ဆိုတာကို ေထာက္ခံ​ေပးမယ့္အေထာက္အထားေလးပါ။ ေလွ်ာက္လႊာျဖည့္ရတာလည္း မိနစ္အနည္းငယ္ပဲၾကာမွာပါ။ ​ေလွ်ာက္လႊာျဖည့္ဖို႔ အကူအညီလိုရင္လည္း အစ္ဆာရာကိုအခ်ိန္မ​ေ႐ြးဆက္သြယ္ပါ​လိုက္ေနာ္။",
    "Once you have created your CV, you can use it to apply for many job opportunities. Good luck with your job application!":
      "အလုပ္ေလွ်ာက္လႊာတစ္ခုတစ္ခါျပင္ထားလိုက္တာနဲ႔ ​ေနာက္အလုပ္ေတြကိုလည္း ဒီျပင္ထားတဲ့ အလုပ္ေလွ်ာက္လႊာနဲ႔ ေလွ်ာက္ထားလိုက္႐ုံပါပဲ။ အလုပ္ေလွ်ာက္တဲ့အခါတိုင္းကံေကာင္းပါေစ​ေနာ္။",
    "Start creating my CV": "အလုပ္​ေလွ်ာက္လႊာျဖည့္ပါမယ္",
    "Upload custom CV": "အလုပ္ေလွ်ာက္လႊာ တင္သြင္းမည္",
    "Please choose a CV file": "အလုပ္ေလွ်ာက္လႊာဖိုင္ ေ႐ြးခ်ယ္ပါ",
    "CV name": "အလုပ္ေလ်ာက္လႊာအမည္",
    "Enter name of your CV": "အလုပ္ေလွ်ာက္လႊာ အမည္ ႐ိုက္ထည့္ပါ",
    "Please enter name of your CV": "အလုပ္ေလွ်ာက္လႊာ အမည္ ႐ိုက္ထည့္ေပးပါ",
    "{appName} would like to send you notifications for the latest news and updates. Notifications may include alerts, sounds and icon badges.":
      "ေ႐ႊအိပ္မက္မွာ သတင္းနဲ႔အခ်က္အလက္အသစ္ေတြတင္တိုင္းသိေစဖို႔ အသံနဲ႔ ပုံေလးေတြပါတဲ့ ႏိုတီေလးေတြပို႔ေပးခ်င္ပါတယ္။",
    "Golden Dreams": "ေ႐ႊအိပ္မက္",
    Block: "ခြင့္မျပဳပါ",
    Allow: "ခြင့္ျပဳမယ္",
    "Document removal confirmation":
      "စာ႐ြက္စာတမ္းမ်ား ျပန္ဖ်က္ျခင္းကို အတည္ျပဳျခင္း",
    "Are you sure you would like to remove this documents?":
      "ဒီစာ႐ြက္စာတမ္းမ်ားကိုျပန္ဖ်က္ဖို႔ သင္ေသခ်ာပါရဲ႕လား",
    "Yes, sure": "ဟုတ္ကဲ့ ေသခ်ာပါတယ္",
    "Confirmation !": "အတည္ျပဳျခင္း!",
    "Are you sure you want to remove ?": "ျပန္ဖ်က္ဖို႔ တစ္ကယ္ေသခ်ာပါၿပီလား",
    "Protected view": "ၾကည့္ရႈခြင့္ မျပဳပါ။",
    "Please login or register to continue":
      "ဆက္လက္လုပ္ေဆာင္ရန္ အေကာင့္သို႔ဝင္ပါ..",
    "Forgot Password?": "“လွ်ိဳ႕ဝွက္ကုတ္” မမွတ္မိေတာ့ရင္ ဒီမွာႏွိပ္ပါ။",
    "New to golden dreams?": "အခုမွ ေ႐ႊအိပ္မက္ စသုံးမွာလား။",
    "Click here to register?": "အမည္စာရင္းသြင္းရန္ ဤေနရာကို ႏွိပ္ပါ",
    "Continue browsing as guest": "ယာယီ အသုံးျပဳသူအျဖစ္ ဆက္လက္လုပ္ေဆာင္မည္",
    "Click here to register": "အမည္စာရင္းသြင္းရန္ ဤေနရာကို ႏွိပ္ပါ",
    "Recruiters Listing": "အလုပ္ႀကိဳတင္ေလွ်ာက္ထားႏိုင္သည့္ ေအဂ်င္စီမ်ားစာရင္း ",
    "Employers Listing": "အလုပ္ႀကိဳတင္ေလွ်ာက္ထားႏိုင္သည့္ အလုပ္ရွင္မ်ားစာရင္း",
    Applications: "ေလွ်ာက္ထားေသာအလုပ္မ်ား",
    "Sorry, no news matched filter parameters, please try again.":
      "သင္၏ ရွာေဖြမႈ မေအာင္ျမင္ပါ။ ထပ္ႀကိဳးစားပါ။",
    "No notifications": "မည္သည့္အသိေပးျခင္းမွမရွိပါ",
    "Please complete the form!":
      "ေက်းဇူးျပဳၿပီး အဆင့္မ်ားကို ၿပီးဆုံးေအာင္ လုပ္ေဆာင္ပါ။  ",
    "Password reset link sent successfully!":
      "လွ်ိဳ႕၀ွက္ကုတ္အသစ္ျပန္သတ္မွတ္ရန္လင့္ပို႔ထားၿပီးပါၿပီ!",
    "Bad Request: Please check the information you provided!":
      "ေတာင္းဆိုမႈမမွန္ကန္ပါ- ေက်းဇူးျပဳ၍ သင္ေပးထားေသာ အခ်က္အလက္ကို စစ္ေဆးပါ။ ",
    "Not Found: The requested phone number was not found!":
      "ရှာမတွေ့ပါ- တောင်းဆိုထားသောဖုန်းနံပါတ်ကို ရှာမတွေ့ပါ။",
    "Internal Server Error: Please try again later!":
      "ဆာဗာအတြင္းအမွားတစ္စုံတစ္ရာျဖစ္သြားသည္ - ေက်းဇူးျပဳ၍ ေနာက္တစ္ခါ ထပ္ႀကိဳးစားပါ။",
    "An error occurred: ": "အမွားတစ္စုံတစ္ရာျဖစ္သြားသည္",
    "Network error: Please check your internet connection!":
      "အင္တာနက္လိုင္းက်ေနပါသျဖင့္မေအာင္ျမင္ပါ - သင့္အင္တာနက္ခ်ိတ္ဆက္မႈကို ျပန္စစ္ေဆးပါ။",
    "Error: ": "အမွား:",
    "Forgot Password": "လွ်ိဳ႕၀ွက္ကုတ္မမွတ္မိေတာ့ပါ။",
    "Enter your phone number to reset":
      "လျှို့၀ှက်ကုတ် အသစ်ပြန်သတ်မှတ်ရန် ဖုန်းနံပါတ်  ရိုက်ထည့်ပါ",
    "This feature will be available in Nepal Soon!":
      "This feature will be available in Nepal Soon!  ",
    "Processing...": "လုပ္ေဆာင္ေနသည္...",
    "Send Password Reset Link":
      "လွ်ိဳ႕၀ွက္ကုတ္အသစ္ျပန္သတ္မွတ္ရန္လင့္ပို႔ေပးပါ။",
    "Passwords do not match.": "လွ်ိဳ႕၀ွက္ကုတ္၂ခုမတူညီပါ (အေပၚကအတိုင္းျဖည့္ပါ)",
    "Password reset successfully!":
      "လွ်ိဳ႕ဝွက္ကုတ္အသစ္ ေအာင္ျမင္စြာ ျပန္သတ္မွတ္ၿပီးပါၿပီ။ ",
    "Token expired / Bad Request!": "သသတ္မွတ္ခ်ိန္ကုန္ဆုံးၿပီ။  ",
    "No users found to reset password!":
      "လွ်ိဳ႕ဝွက္ကုတ္ ျပန္သတ္မွတ္လိုသူမ်ား  ရွာမေတြ႕ပါ။ ",
    "There was an error resetting your password.":
      "လွ်ိဳ႕ဝွက္ကုတ္ ျပန္သတ္မွတ္ရန္ ျပင္ဆင္ေနစဥ္မွာ အမွားအယြင္းတစ္စုံတစ္ရာ ျဖစ္သြားပါသည္။ ",
    "Failed to reset password!":
      "လွ်ိဳ႕ဝွက္ကုတ္ ျပန္သတ္မွတ္ရန္ ႀကိဳးပမ္းမႈ မေအာင္ျမင္ပါ။  ",
    "Reset Password": "လွ်ိဳ႕ဝွက္ကုတ္ ျပန္သတ္မွတ္မည္ ",
    "New Password": "လွ်ိဳ႕ဝွက္ကုတ္ အသစ္",
    "Enter new password": "ေက်းဇူးျပဳၿပီး လွ်ိဳ႕ဝွက္ကုတ္ အသစ္ ျပန္႐ိုက္ထည့္ပါ ",
    "Please enter a new password.":
      "ေက်းဇူးျပဳၿပီး လွ်ိဳ႕ဝွက္ကုတ္ အသစ္ ျပန္႐ိုက္ထည့္ပါ ",
    "Confirm new password": "လွ်ိဳ႕ဝွက္ကုတ္ အသစ္ျပန္အတည္ျပဳပါ။",
    "Please confirm your new password.":
      "ေက်းဇူးျပဳ၍ လွ်ိဳ႕ဝွက္ကုတ္ အသစ္ျပန္အတည္ျပဳပါ။ ",
    "{number} responses": "ေျဖဆိုသူ {number} ဦး",
    "Would you like to participate in the poll with the topic?":
      "စစ္တမ္းေလးမ်ားကို ေျဖၾကည့္ခ်င္ပါသလား။",
    "I don't want to answer": "မေျဖလိုပါ",
    "I want to answer": "ေျဖခ်င္ပါတယ္",
    "Today's community poll": "ယေန႔ စစ္တမ္း",
    "Add a reply...": "ျပန္စာေရးရန္",
    "Problem Description": "သင္ႀကဳံ​ေတြ႕​ေနရသည့္အခက္အခဲ",
    "Please describe the issue you are facing":
      "သင္ႀကဳံ​ေတြ႕​ေနရသည့္အခက္အခဲကိုေရး​ေပးပါ",
    "Please describe your issue": "သင္၏ အခက္အခဲကို ေဖာ္ျပပါ",
    "Submit a problem": "အခက္အခဲတင္ျပမည္",
    "Waiting for Issara's reply": "အစ္ဆာရာ၏ ျပန္စာကို ေစာင့္ေနသည္",
    "Waiting for worker's reply": "သင္၏ျပန္စာကို ေစာင့္ေနသည္",
    "Issara is waiting for your reply. Click the message to see the reply.":
      "သင္ကို႔စာျပန္ထားသည္။ ျပန္စာကိုၾကည့္ရန္ ပို႔ထားသည့္စာတန္းကိုႏွိပ္ပါ။",
    Resolved: "စာပို႔မႈ ၿပီးဆုံးၿပီ",
    "Last update: {date}": "စာပို႔ထားသည့္ရက္စြဲ - {date}",
    "Mark as resolved": "ေျဖရွင္းၿပီးေၾကာင္း မွတ္သားရန္",
    "Created: {date}": "စာပို႔သည့္ရက္စြဲ - {date}",
    "Show resolved": "ေျဖရွင္းၿပီးေၾကာင္း ျပသည္",
    "Please login to share a problem..": "အခက္အခဲတင္ျပရန္ အေကာင့္သို႔ဝင္ပါ..",
    "You haven't shared any problems yet. Please click share a problem on the right top corner to share one.":
      "သင္ယခုထိ အခက္အခဲတင္ျပမႈ တစ္စုံတစ္ရာ မရွိေသးပါ။ ညာဖက္ ထိပ္ေဒါင့္မွာရွိတဲ့ အခက္အခဲတင္ျပရန္ ေနရာ ကို ႏွိပ္ၿပီးတင္ျပႏိုင္ပါတယ္။",
    Profile: "ကိုယ္ေရးအခ်က္အလက္",
    "Are you sure want to withdraw all of your applications ? The recruiters will no longer see your applications in their list.":
      "သင္ရဲ႕အလုပ္ေလွ်ာက္လႊာကို ဖ်က္သိမ္းမွာ ေသခ်ာၿပီလား? ‌‌ေအဂ်င္စီကေန အလုပ္ေခၚစာရွိလို႔ ေခၚတဲ့အခါက်ရင္ သင့္အလုပ္ေလွ်ာက္လႊာကို မျမင္ရေတာ့ဘူးေနာ္။",
    "Yes, withdraw all": "ဟုတ္ပါတယ္။ ျပန္႐ုတ္သိမ္းပါမည္။",
    "Are you sure want to extend all of your applications ? Your application expiry date will extend by 30 days.":
      "သင့္အလုပ္ေခၚစာအားလုံးကို သက္တမ္းတိုးရန္ ေသခ်ာၿပီလား? အလုပ္ေလွ်ာက္လႊာသက္တမ္းအား ရက္ ၃၀ အထိ ထပ္ၿပီးတိုးသြားမယ္ေနာ္။",
    "Yes, extend all": "ဟုတ္ပါတယ္။ အားလုံးကို သက္တမ္းတိုးပါမယ္။",
    "You already sent your cv to this Recruitment Agency":
      " ဒီေအဂ်င္စီထံသို႔ သင့္ရဲ႕အလုပ္ေလွ်ာက္လႊာပို႔ထားၿပီးပါၿပီ။",
    "Recruitment Agency viewed your application":
      "ေအဂ်င္စီမွ သင့္ရဲ႕အလုပ္ေလွ်ာက္လႊာကို ၾကည့္ၿပီးပါၿပီ။",
    "Recruitment Agency Downloaded your resume":
      "ေအဂ်င္စီမွ သင့္ရဲ႕အလုပ္ေလွ်ာက္လႊာကို ေဒါင္းလုတ္ဆြဲထားၿပီးပါၿပီ။",
    "The Recruitment Agency moved your application to the General Waitlist.":
      "ေအဂ်င္စီမွ သင့္ရဲ႕ အလုပ္ေလွ်ာက္လႊာေဖာင္ကို ေစာင့္ဆိုင္းစာရင္းမွာ ေ႐ႊ႕ထားၿပီးပါၿပီ။",
    "Recruitment Agency Shortlisted your application":
      "ေအဂ်င္စီမွ သင့္ရဲ႕အလုပ္ေလွ်ာက္လႊာေဖာင္ကို ပထမအဆင့္ဦးစားေပးစာရင္းမွာ ထည့္သြင္းထားပါတယ္။",
    "Please select permission for your resume & enter the remark ":
      "ဒီအလုပ္ေခၚစာအတြက္ကိုက္ညႇီမႈရွိ/မရွိစစ္ၾကည့္ရန္ ေအဂ်င္စီမွသင္တြဲထားသည့္အေထာက္အထားမ်ားကို ၾကည့္ရႈရန္လိုအပ္ပါသည္။",
    "Allow to view documents": "စာ႐ြက္စာတမ္းမ်ားကိုၾကည့္ခြင့္ေပးမည္",
    "Are you sure want to withdraw this application? Your application will no longer see in the {recruiter_name}'s list":
      "သင့္ရဲ႕အလုပ္ေလြ္ာကႅႊာကို ႐ုတ္သိမ္းဖို႔ ေသခ္ာၿပီလား? {recruiter_name} ေအဂ္ငၥီမြ သင့္အလုပ္ေလြ္ာကႅႊာကို ်မင္ေရတာ့မြာ မဟုတၻဴးေနာ္။ ",
    "Withdrawing Application": "႐ုတ္သိမ္းထားေသာ အလုပ္ေလွ်ာက္လႊာမ်ား",
    "Recruiter Detail": "ေအဂ်င္စီအေၾကာင္းအေသးစိတ္",
    "Date of Registration": "လုပ္ငန္းမွတ္ပုံတင္ခဲ့သည့္ရက္စြဲ",
    "Business Images": "အလုပ္႐ုံ/လုပ္ငန္းခြင္မွ ဓါတ္ပုံမ်ား",
    "RA Business Images": "ေအဂ်င္စီလုပ္ငန္းခြင္မွဓါတ္ပုံမ်ား",
    "You cannot send your resume to this recruiter as you are not residing in same country with this recruiter.":
      "ဒီေအဂ်င္စီ တည္ရွိရာႏိုင္ငံ မွာေနထိုင္တာမဟုတ္သည့္အတြက္ သင့္အလုပ္ေလွ်ာက္လႊာ ေပးပို႔လို႔ မရပါ။",
    Processing: "လိုင္စင္သစ္ေလွ်ာက္ထားဆဲ",
    "This recruitment agency has experience sending workers to":
      "ဒီေအဂ်င္စီမွ ျပည္ပလုပ္သား ပို႔ေဆာင္ေပးလ်က္ရွိသည့္ႏိုင္ငံမ်ား",
    comments: "ကြန္မန႔္ ",
    "Internal server error occur": "အမွားအယြင္းတစ္စုံတစ္ရာျဖစ္သြားသည္ ",
    "Name of recruitment agency": "အလုပ္ရွာေဖြေရးေအဂ်င္စီ အမည္",
    "Registration number": "လုပ္ငန္းမွတ္ပုံတင္ အမွတ္",
    "Date of registration": "အမည္စာရင္းသြင္းသည့္ ေန႔စြဲ -",
    Username: "အေကာင့္အမည္",
    Registration: "စာရင္းသြင္းမည္",
    "Register to apply": "အသုံးျပဳရန္ စာရင္းသြင္းမည္",
    Jobseeker: "အလုပ္ရွာေဖြသူ",
    "Recruitment Agency": "အလုပ္ရွာေဖြေရးေအဂ်င္စီ",
    Employer: "အလုပ္ရွင္",
    "Service Providers": "လူမႈကူညီေရးအဖြဲ႕မ်ား",
    "Sorry, no service providers matched filter parameters, please try again.":
      "ဝမ္းနည္းပါတယ္ သင္ရွာေဖြေသာလူမႈကူညီေရး အဖြဲ႕  အမည္ႏွင့္ တူေသာ လူမႈကူညီေရး အဖြဲ႕  စာရင္းထဲတြင္ မရွိပါ ထပ္မံႀကိဳးစားပါ",
    "We've recieved your problem": "သင္၏ တင္ျပခ်က္ကို ရရွိသည္။",
    "We'll get back to you as soon as possible": "မၾကာမီ အေၾကာင္းျပန္ပါမည္။",
    "Please enter your name": "အမည္အျပည့္အစုံ ႐ိုက္ထည့္ပါ",
    "Please enter your phone number": "ေက်းဇူးျပဳ၍ သင္၏ ဖုန္းနံပါတ္ကို ေရးပါ",
    "Member Agreement": "အသင္းဝင္ျဖစ္ခြင့္ သေဘာတူညီခ်က္",
    "Privacy Policy": "ကိုယ္ေရးကိုယ္တာအခ်က္အလက္လုံၿခဳံေရးဆိုင္ရာမူဝါဒ",
    "Would you like to participate in the survey?":
      "စစ္တမ္းေလးမ်ားကို ေျဖၾကည့္ခ်င္ပါသလား။",
    "Thank you for participating in the survey.":
      "စစ္တမ္းတြင္ ပါဝင္ေျဖဆိုေပးသည့္အတြက္ ေက်းဇူးတင္ပါတယ္",
    "The form couldn't be submitted due to the error":
      "လိုင္းက်ေနပါသျဖင့္ ေဖာင္တင္၍မရပါ",
    "The answer to this question is required.":
      "ယခုေမးခြန္းအတြက္ အေျဖလိုအပ္ပါတယ္",
    "Please answer all the questions that require at least one answer":
      "အနည္းဆုံးအေျဖတစ္ခု ေျဖဆိုရန္လိုအပ္သည့္ ေမးခြန္းမ်ားအားလုံးကိုေျဖေပးပါ",
    "Submit answers": "မိမိအေျဖမ်ားကိုပို႔မည္",
    "Sorry, There are no surveys!":
      "၀မ္းနည္းပါတယ္။ မည္သည့္စစ္တမ္းမွ ရွာမေတြ႕ပါ။ ",
  },
  ne: {
    "refresh the page": "पृष्ठ रिफ्रेसर गर्नुहोस्",
    "Ooops, something went wrong... Please try to {refresh}":
      "ओहो, केहि गडबड भयो …. कृपया पुन प्रयास गर्नुहोस्{refresh}",
    Male: "पुरुष",
    Female: "महिला",
    "Prefer not to say": "म भन्न चाहान्",
    "Non-Binary": "गैर-द्विआधारी (गैर-बाइनरी)",
    "Passport Include": "पासपोर्ट समावेश गरियोको",
    "Passport Not Include": "पासपोर्ट समावेश  नगरियोको",
    Myanmar: "मियनमार",
    Thailand: "थाइलेण्ड",
    Cambodia: "कम्बोडिया",
    Laos: "लाओस",
    Nepal: "नेपाल",
    Indonesia: "इन्डोनेसिया",
    Indian: "भारतीय",
    Bangladeshi: "बंगलादेशी",
    Others: "अन्य",
    Sunday: "आइतबार",
    Monday: "सोमबार",
    Tuesday: "मंगलबार",
    Wednesday: "बुधबार",
    Thursday: "बिहिबार",
    Friday: "शुक्रबार",
    Saturday: "शनिबार",
    "It Will Vary": "फरक हुनेछ",
    Monthly: "मासिक",
    "Daily Wage Rate": "दैनिक ज्याला दर",
    "Once A Month": "महिनाको एक पटक",
    "Twice A Month": "महिनााको दुइ पटक",
    "Every Week": "प्रत्येक हप्ता",
    "Every 10 days": "हरेक दश दिन",
    Other: "अन्य",
    Regularly: "नियमित",
    "From time to time": "समय समयमा",
    Infrequently: "बारम्बार",
    Never: "कहिले पनि नहुने",
    Day: "दिन",
    Swing: "स्विङ",
    Night: "राति",
    Rotating: "आलोपालो",
    Variable: "वदली रहने",
    "Full-time": "पुरा समय",
    "Part-time": "आंशिक",
    "Moved General Waitlist": "प्रतीक्षा सूचीमा पठाउनुहोस्",
    Waitlisted: "प्रतीक्षा सूचीमा",
    "Contract Signed": "सम्झौतामा हस्ताक्षर गरियो",
    Accepted: "स्वीकार गरेको",
    Applied: "आवेदन गरेको",
    Downloaded: "डाउनलोड भएको",
    Offered: "प्रस्ताव गरेको",
    Rejected: "अस्विकृत",
    Shortlisted: "छानिएका",
    Viewed: "हेरेको",
    "Documents Requested": "कागजात मागिएको",
    "Documents request accepted": "कागजात स्वीकृत भयो",
    "Documents request rejected": "कागजात अस्वीकृत भयो",
    "HR Assistant": "मानव संशाधन सहायक",
    "HR Interpreter": "मानव संंशाधन अनुवादक",
    "HR Manager": "मानव संशाधन प्रबन्धक",
    "HR Officer": "मानव संशाधन अधिकृत",
    "Candidate pays fees in advance, then is reimbursed by company after starting work":
      "कामदारले अग्रिम रुपमा शुल्क तिर्ने र काम गर्न शुरु गरे पछि कम्पनीले फिर्ता दिने",
    "Company pays all fees in advance": "कम्पनीले शुरुमै  सबै शुल्क तिरिदिने",
    "India (+91)": "भारत (+९१)",
    "Myanmar (+95)": "म्यानमार (+९५)",
    "Nepal (+977)": "नेपाल (+९७७)",
    "Thailand (+66)": "थाइल्याण्ड (+६६)",
    "Malaysia (+60)": "मलेसिया (+६०)",
    "Cambodia (+855)": "कम्बोडिया (+८५५)",
    "A Little Bit": "अलिकति",
    Moderately: "मध्यम रूपमा",
    "Very Well": "धेरै राम्रो",
    None: "कुनै पनि होइन",
    Some: "केहि",
    Proficient: "सिपालु",
    English: "अंग्रेजी",
    Thai: "थाइ",
    "Bahasa Malaysia": "भाषा मलेसिया",
    Hindi: "हिन्दी",
    "Cannot read": "पढ्न सक्दैनन्",
    APPLICABLE: "आबेदन पठाउना यहाँ क्लिक गर्नुहोस्",
    APPLIED: "आवेदन दिएको ",
    VIEWED: "हेरेको",
    DOWNLOADED: "डाउनलोड भएको",
    SHORTLISTED: "छानिएको",
    OFFERED: "प्रस्ताव गरेको",
    ACCEPTED: "स्वीकार गरेको",
    REJECTED_BY_BUSINESS: "व्यवसाय द्वारा अस्वीकृत",
    REJECTED_BY_APPLICANT: "आवेदक द्वारा अस्वीकृत",
    CONTRACT_SIGNED: "सम्झौतामा हस्ताक्षर भयो",
    APPLICANT_NA: "आवेदक NA",
    WAITLISTED: "प्रतीक्षा सूचीमा",
    MOVED_GENERAL_WAITLIST: "प्रतीक्षा सूचीमा पठाउनुहोस्",
    "STATUS N/A": "स्थिति N/A",
    "Cannot speak": "बोल्न सकिन्न",
    "Network Error!": "नेटवर्कमा गडबडी !",
    "User with this username already exists":
      "यो युजर नाम भएको युजर पहिले नै दर्ता छ",
    "You are trying to login with Business User Account !":
      "तपाईं व्यापारि प्रयोगकर्ताको खाता बाट लग इन गर्न प्रयास गर्दै हुनुहुन्छ!",
    "There was an authentication problem": " प्रमाणीकरण समस्या थियो",
    Applicable: "लागू हुन्छ",
    "Not Applicable": "लागू हुँदैन",
    "N/A": "लागू हुँदैन",
    "Click here for Registration.": "दर्ताको लागी यहाँ क्लिक गर्ने",
    "No Focal Person contact provided": "ठेगाना पाउन सकिएन",
    "Some features might not work in offline mode":
      "के हि प्रणालीहरु इन्टरनेट नहुँदा नचल्न सक्छन्",
    "New version is available!": "नयाँ संस्करण उपलब्ध छ",
    "Please click refresh the app button to switch to a newer version.":
      "नयाँ संंस्करणका लागी कृपया रिफ्रश बटनमा थिच्नुहोला",
    "Updating..": "अपडेट भैरहेको छ...",
    "Refresh the app": "एपलाई रिफ्रेश गर्नु होला",
    Polls: "मत",
    News: "समाचार",
    Home: "मुख्य पेज",
    Guide: "निर्देशन",
    Groups: "समूह",
    Like: "मन पराउनु",
    Notifications: "जानकारि",
    moderator: "संचालक",
    Anonymous: "अज्ञात",
    "Delete comment": "कोमेन्ट डिलिट गर्नुहोस्",
    "Unhide comment": "कमेन्ट नलुकाउनुहोस्",
    "Hide comment": "कमेन्ट लुकाउनुहोस्",
    Reply: "उतर",
    Report: "उजुरी",
    "Report inappropriate comment": "अनावश्यक प्रतिक्रियाको उजुरी ",
    "Text of a comment:": "प्रतिक्रिया टेक्स्ट गर्नुहोस्",
    "Please write a reason...": "कृपया कारण लेख्नु होला",
    Close: "बन्द गर्नुहोस्",
    "Replying to {username}": "{युजरनेम} लाई जवाफ दिँदै",
    "Write a reply...": "जवाफ लेख्नुहोस्",
    "Write a comment...": "प्रतिक्रिया लेख्नुहोस",
    "Post a comment": "प्रतिक्रिया पोस्ट गर्नुहोस्",
    Comments: "प्रतिक्रिया",
    "Go to Last Comment": "अन्तिम कमेन्टमा जानुहोस्",
    "Fetching comments ...": "कमेन्ट प्राप्त गर्दै",
    "Be a first person to comment on this post":
      "यस पोस्टमा कमेन्ट गर्ने पहिलो व्यक्ति बन्नुहोस्",
    "Commented: ": "कमेन्ट गरे",
    "Post comment": "कमेन्ट पोस्ट गर्नुहोस्",
    "replied to {recipient}": "जवाफ दिइयो (प्रापक लाई)",
    Discuss: "छलफल",
    new: "नयाँ",
    "Published: {date}": "प्रकाशिकतः (मिति)",
    Jan: "जनवरि",
    Feb: "फेब्रुवरि",
    Mar: "मार्च",
    Apr: "अप्रिल",
    May: "मेइ",
    Jun: "जुन",
    Jul: "जुलाइ",
    Aug: "आगस्ट",
    Sep: "सेप्टेम्वर",
    Oct: "अक्टोवर",
    Nov: "नोभेम्वर",
    Dec: "डिसेम्वर",
    Save: "सेभ गर्नुहोस्",
    Back: "पछि जाने",
    "Overall:": "समग्रमाः",
    Operational: "संचालन सम्बन्धी",
    "Temporarily Suspended": "अस्थायीरुपमा निलम्बनमा परेका",
    "from: {date}": "मिति देखी",
    "to: {date}": "मिति सम्म",
    "Permanently Blacklisted": "स्थायीरुपमा ब्लाक लिस्टमा परेको",
    "License is not extended": "अनुमतीपत्र नविकरण नगरिएको",
    "Thai License Number": "मलेशियाको अनुमती पत्र नम्बर",
    "License number": "अनुमती पत्र नम्बर",
    Email: "इमेल",
    "Your application will expire at": "तपाईको आवेदनको म्याद सकिने मिती",
    "Recruitment Agencies": "म्यानपावर कम्पनीहरु",
    Employers: "रोजगाारदाता",
    "Employer Roster": "रोजगारदाता रोस्टर",
    "Recruiter Roster": "भर्ना एजेन्सी रोस्टर",
    "Reset Filter": "पुन: सेट गर्नुहोस् ",
    "Search...": "खोजी ….. ",
    Filter: "छनोट गर्नुहोस्",
    "Select Industry": "क्षेत्र  छान्नु होस्",
    "All industries": "सबै कम्पनीहरु",
    "{type} country": "देश चयन गर्नुहोस् ",
    "Sort By": "क्रमबद्ध गर्नुहोस्",
    "Rating: Highest": "मूल्याङकनः सबै भन्दा धेरै",
    "Rating: Lowest": "मूल्याङकनः सबैै भन्दा थोरै",
    Popularity: "लोकप्रियता",
    "Order By": "अर्डर बाइ",
    "Posted: Newest": "पोस्ट गरिएको: सबैभन्दा नयाँ",
    "Posted: Oldest": "पुरानो",
    cm: "सेन्टिमिटर",
    ft: "फिट",
    in: "इन्च",
    kg: "केजी",
    lbs: "पाउण्ड",
    "Recruitment closed!": "भर्ना प्रकृया बन्द",
    "Recruitment Expired!": "भर्ना म्याद सकियो",
    "{salary} {currency}/month": "{salary} {currency}/महिना",
    "{daily_wage_rate} {currency}/day": "{daily_wage_rate} {currency}/दिन",
    "Have Employer Pays Policy": "रोजगारदाताले नै शुल्क बेहोर्ने निती छ",
    "Only job seekers in Myanmar/Cambodia/Nepal can apply":
      "नेपालमियनमारकम्वोडिया का कामदारले मात्र आवेदन गर्न सक्ने",
    "Only job seekers in Thailand can apply":
      "थाइलेण्डमा रहेका कामदारले मात्र आवेदन गर्न सक्ने",
    "Recruitment Agency Job": "म्यनपावोर कम्पनीको रोजगारीको अवसर",
    "Employer Job": "रोजगारदाताको रोजगारीको अवसर",
    "Only job seekers in {country} can apply":
      "{country} बाट जागिर खोज्नेहरूले मात्र आवेदन दिन सक्छन",
    Send: "पठाउनुहोस",
    "Add & Verify Phone Number!":
      "आफ्नो फोन नम्बर राख्नुहोस् र आफ्नु नम्बर प्रमाणिकरण गर्नुहोस्!",
    VERIFICATION: "प्रमाणीकरण",
    "Update & Verify Phone Number!":
      "आफ्नो फोन नम्बर अपडेट र प्रमाणिकरण गर्नुहोस्!",
    "{minutes} minute(s) and {seconds} second(s)":
      "{minutes} मिनेट(हरू) र {seconds} सेकेन्ड(हरू)",
    "This user has already been verified with the provided phone number.":
      "यो प्रयोगकर्ता पहिले नै प्रदान गरिएको फोन नम्बरको साथ प्रमाणित गरिएको छ।",
    "Error sending OTP!": "OTP पठाउँन त्रुटि भयो!",
    "Verification Failed! The number you entered has already been verified with another user account. Check the number again.":
      "प्रमाणीकरण असफल भयो! तपाईंले हाल्नु भएको नम्बर पहिले नै अर्को प्रयोगकर्ताको खाताबाट प्रमाणित भइसकेको छ।",
    "Please enter valid OTP!": "कृपया मान्य OTP हाल्नु गर्नुहोस्!",
    "Your phone number is successfully verified !":
      "तपाईंको फोन नम्बर सफलतापूर्वक प्रमाणित भयो!",
    "OTP confirmation failed !": "OTP पुष्टि असफल भयो!",
    "Please complete the form !": "कृपया फारम पुरा भर्नुहोस्!",
    "Please Complete These Steps!": "कृपया यी चरणहरू पूरा गर्नुहोस्!",
    Gender: "लिङ्ग",
    Nationality: "राष्ट्रियता",
    Country: "देश",
    "Congratulations your profile is updated!":
      "बधाई छ तपाईको प्रोफाइल अपडेट भयो!",
    "Phone number": "फोन् नम्बर",
    required: "आवश्यक",
    Select: "छान्नु होस्",
    "Select the country code": "देशको कोड चयन गर्नुहोस्",
    "Please select a country code": "देशको कोड चयन गर्नुहोस्",
    "Please remove any leading zeros when entering your phone number. For example, if your phone number is 0827474736, enter it as 827474736.":
      "कृपया आफ्नो फोन नम्बर प्रविष्ट गर्दा अगाडिको शून्य हटाउनुहोस्। उदाहरण को लागी, यदि तपाईको फोन नम्बर ०८२७४७४७३६ हो भने, यसलाई ८२७४७४७३६ लेख्नुहोस् ।",
    "Enter your phone number": "तपाईँको फोन नम्बर हाल्नुहोस्",
    "Enter the phone number you always use. Do not include the country code, such as (+95, +66, +977, +855, +60), here again.":
      "तपाईंले सधैँ प्रयोग गर्ने फोन नम्बर राख्नुहोस्। यहाँ (+९५, +६६, +९७७, +८५५, +६०) जस्ता देशको कोड समावेश नगर्नुहोस्,",
    "Please enter valid phone number": "कृपया सही टेलिफोन् नम्बर हाल्नुहोस्",
    "Enter OTP:": "OTP हाल्नुहोस्:",
    "Enter 6 digits OTP": "६ अङ्कको OTP हाल्नुहोस्",
    "Enter OTP": "OTP हाल्नुहोस्:",
    "Your OTP will expire in {expiryTime}":
      "तपाईंको OTP {expiryTime} मा समाप्त हुनेछ",
    "Please select gender": "कृपया लिङ्ग चयन गर्नुहोस्",
    "Please select nationality": "कृपया राष्ट्रियता चयन गर्नुहोस्",
    "Please select Country of residence":
      "कृपया आफु बसिरहेको देश चयन गर्नुहोस्",
    "Country of residence": "बसोबास भएको देश",
    "Get OTP": "OTP प्राप्त गर्नुहोस्",
    "Update & Get OTP": "अपडेट गर्नुहोस् र OTP प्राप्त गर्नुहोस्",
    "Add & Get OTP": "फोन नम्बर थप्नुहोस्",
    "Add Phone Number": "फोन नम्बर हाल्नु होस्",
    "Confirm OTP": "OTP पुष्टि गर्नुहोस्",
    "Resend OTP": "OTP पुन: पठाउनुहोस्",
    Previous: "अघिल्लो",
    Next: "अर्को",
    Submit: "पठाउनुहोस्",
    "Submit Resume": "वायोडाटा पठाउनुहोस्",
    "Permission & Remark": "अनुमती र कैफीयत",
    "This Recruiter might need to see your documents and information to confirm you are eligible for the job.":
      "तपाईं यस कामको लागि योग्य हुनुहुन्छ भनी पुष्टि गर्न मेनपावर कम्पनीहरुले तपाईंको कागजातहरू हेर्न आवश्यक छ।",
    "This Employer might need to see your documents and information to confirm you are eligible for the job.":
      "तपाईं कामको लागि योग्य हुनुहुन्छ भनी पुष्टि गर्न रोजगारदाताहरूले तपाईंको कागजातहरू हेर्न आवश्यक छ। ",
    "You can delete your documents and any personal information anytime if you desire to do so. Do you understand this and consent to letting them look at your resume and documents?":
      "यदि तपाइँ त्यसो गर्न चाहानुहुन्छ भने तपाइँ तपाइँको कागजातहरू र कुनै पनि व्यक्तिगत जानकारी जुनवेलापनि मेटाउन सक्नुहुन्छ। के तपाइँ यो बुझ्नुहुन्छ र उनीहरूलाई तपाइँको बायोडाटा र कागजातहरू हेर्न दिन सहमत हुनुहुन्छ?",
    Remark: "टिप्पणि",
    "Enter remark": "टिप्पणि उल्लेख गर्नु होस्",
    Cancel: "रद्द गर्नुहोस",
    "Submitting Resume": "वायोडाटा पठाउदै",
    "Confirmation!": "पुष्टिकरण",
    "Are you sure want to withdraw this application? Your application will no longer be seen in {business_name}'s list.":
      "के तपाइँ यो आवेदन फिर्ता लिन निश्चित हुनुहुन्छ? तपाईंको आवेदन अब {रोजगारदाताको_नाम} को सूचीमा देखिने छैन।",
    "Withdrawing...": "फिर्ता लिदै...",
    "Yes, withdraw": "हो, फिर्ता लिनुहोस्",
    "Ask a question": "प्रश्न सोध्नुहोस",
    "Your question goes here...": "तपाईको प्रश्न यहाँ जान्छ",
    "Submit a question": "प्रश्न पठाउनुहोस",
    "Successfully gave the ratings!": "सफलतापूर्वक मूल्याङ्कन दिनुभयो!",
    "Loading...": "लाोडिङ भइरहेको छ...",
    "Submit rating": "मूल्याङकन गर्नु होस",
    more: " धेरै",
    "Share App": "एप साझा गर्नुहोस्",
    Share: "शेयर गर्नुहोस",
    Reactions: "प्रतिक्रियाहरू",
    "Username or email": "युजर नेम वा इमेल",
    Password: "पासवर्ड",
    "Show Password": "मैले हालेको पासवर्ड देखाउनुहोस्",
    Login: "लगइन्",
    "Successfully sent the resume": "सफलतापूर्वक वायोडाटा पठाइयो",
    "Couldn't sent the resume due to server error":
      "सर्वरको त्रुटिका कारणले वायोडाटा पठाउन सकिएन",
    "Successfully extended the application !":
      "सफलतापूर्वक आवेदन विस्तार गरियो",
    "You already sent your cv to this employer":
      "तपाईंले पहिले नै आफ्नो वायोडाटा यस रोजगारदातालाई पठाउनुभएको छ ",
    "Withdraw application": "आवेदन फिर्ता लिनुहोस्",
    "Extending...": "विस्तार गर्दै...",
    "Extend application": "आवेदन विस्तार गर्नुहोस्",
    "Employer viewed your application": "रोजगारदाताले तपाईंको आवेदन हेर्नुभयो",
    "Employer Downloaded your resume":
      "रोजगारदाताले तपाईंको बायोडाटा डाउनलोड गर्नुभयो",
    "Employer Moved your application to General Waitlist. Please wait Until he/she will shortlist you again for future job post.":
      "रोजगारदाताले तपाईको आवेदनलाई सामान्य प्रतीक्षा सूचीमा सार्नुभयो। कृपया पर्खनुहोस् जबसम्म उसले/उनले तपाईंलाई भविष्यको रोजगारको पोस्टको लागि पुन: सर्टलिस्ट गर्दैनन्।",
    "Employer Shortlisted your application":
      "रोजगारदाताले तपाइँको आवेदन सर्टलिस्ट गर्यो",
    "You cannot send your resume to this employer as you are not residing in same country with this employer":
      "तपाईले आफ्नो बायोडेटा यस रोजगारदाता कम्पनीलाई पठाउन सक्नुहुन्न किनकि तपाई यो रोजगारदाता कम्पनी रहेको देशमा हुनुहुन्न ।  ",
    "Successfully withdrew the application !": "आवेदन सफलतापूर्वक फिर्ता भयो!",
    "Couldn't withdraw the application due to server error":
      "सर्भरको कारणले आवेदन फिर्ता लिन सकिएन",
    "Employer Detail": "रोजगारदाता विवरण",
    Description: "विस्तृ्त विबरण",
    "Registered:": "दर्ता भएका",
    "License number:": "अनुमतीपत्र नम्बर",
    "Valid to:": "अनुमतीपत्र कायम रहने मिति",
    "Contact Information": "सम्पर्क सम्वन्धि जानकारी",
    "Rated by ({number}) viewers":
      " यति ({number}) दर्शकहरु बाट मुल्याङकन गरिएको",
    Address: "ठेगाना",
    "You can not sent your resume for this employer":
      "तपाईंले यो रोजगारदातालाई आफ्नो बायोडाटा पठाउन सक्नुहुन्न",
    "Click here to create a resume": "वायोडाटा वनाउन यहाँ क्लिक गर्नुहोस्",
    "Loading..": "लोडिङ भैरहेको छ...",
    "Sorry, no employers matched filter parameters, please try again.":
      "माफ गर्नुहोला, तपाईले खोजेको रोजगारदाता मिलेन, पुन प्रयास गर्नु होला",
    "Industries:": "उद्योगहरु.       कम्पनी",
    "Job Opportunities:": "रोजगारको अवसरहरु",
    "{employer_name} has listed ({number_of_jobs}) job opportunities at marketplace":
      "{employer_name} ले ({number_of_jobs}) रोजगारका अवसरहरु मार्केटप्लेसमा सुचिकृत गरेको छ",
    Rating: "मूल्याङकन",
    "View rating": "मूल्या्याङकन हेर्नुहोस",
    "Give rating": "मूल्याङकन गर्नुहोस",
    "Please login to write a comment..": "प्रतिक्रियाका लागि लगइन गर्नुहोस",
    "Change in CMS": "CMS मा परिवर्तन",
    "Write a comment here...": "यहाँ एक प्रतिक्रिया लेख्नुहोस् ",
    "Comment Deleted !": "कोमेन्ट डिलिट गरियो ",
    "Delete Comment": "कोमेन्ट डिलिट गर्नुहोस्",
    "Are you sure you want to delete this comment?":
      "के तपाइँ यो कमेन्ट डिलिट गर्न चाहानुहुन्छ ?",
    Delete: "मेटाउनुहोस्",
    "Edit post": "पोस्ट सम्पादन गर्नुहोस्",
    "Comment Editor": "कमेन्ट परिवर्तनकर्त",
    "Update your comment": "आफ्नो कमेन्ट अपडेट गर्नुहोस्",
    "Write your updated comment here ...":
      "तपाईंको अपडेट कमेन्ट यहाँ लेख्नुहोस्",
    "Save Changes": "परिवर्तनहरू सेभ गर्नुहोस",
    "Delete post": "पोस्ट डिलिट गर्नुहोस्",
    "Delete Post": "पोस्ट मेटाउनुहोस्",
    "Delete your post?": "तपाई यो पोस्ट डिलिट  गर्न चाहानु हुन्छ ?",
    "Are you sure you want to delete this post?":
      "के तपाइँ यो पोस्ट डिलिट गर्न निश्चित हुनुहुन्छ ?",
    like: "लाईक",
    s: "s",
    "Like List": "मनपर्ने सूची",
    "Post Detail": "पोस्टको विबरण",
    "Go back Top": "माथि जानुहोस् ",
    "Successfully updated the post !": "पोस्ट सफलतापूर्वक अपडेट गरियो ",
    "Post Editor": "पोस्ट परिवर्तन गर्ने",
    "Edit your post": "पोस्ट पुन परिवर्तन गर्नुहोस्",
    "Caption:": "शीर्षक",
    "Please write a caption": "कृपया शीर्षक लेख्नु होला",
    "Article:": "लेख",
    "Please write an article ...": "कृपया एक लेख लेख्नुहोस्",
    "Please choose an image to update": "कृपया आफ्नु फोटो अपडेट गर्नुहोस्",
    Update: "अपडेट ",
    "Edit Post ": "पोस्ट सम्पादन वा परिवर्तन् गर्नुहोस्",
    "Edit Post": "पोस्ट सम्पादन वा परिवर्तन् गर्नुहोस्",
    "...See more": "अझ धेरै हेर्नुहोस्",
    "Group Forum": "समूह मंच (फोरम)",
    "No posts found!": "कुनै पोस्ट भेटिएन!",
    "Please login or register new account":
      "कृपया लगइन गर्नुहोस् वा नयाँ खाता दर्ता गर्नुहोस्",
    "Please select image! Image size should be less than 5MB":
      "कृपया फोटो छानु होस् ! फोटो ५ MB भन्दा कम हुनुपर्छ",
    "Successfully posted !": "सफलतापूर्वक पोस्ट गरियो!",
    "What would you like to discuss?": "तपाईं  छलफल गर्न चाहनुहुन्छ?",
    "Write your discussion here": "तपाईंको छलफल यहाँ लेख्नुहोस्",
    "Write discussion here...": "छलफल यहाँ लेख्नुहोस्",
    "Choose an image": "फोटो छान्नुहोस्",
    "Selected Image": "छानिएको फोटो",
    "Remove Image": "फोटो हटाउनुहोस्",
    Posting: "पोस्ट गरिदै ",
    Post: "पोस्ट",
    Topics: "शिर्षक",
    "All countries": "सबै देशहरु",
    Sort: "छान्नुहोस",
    "A to Z": "A देखि Z सम्म",
    "Z to A": "Z देखि A सम्म",
    "{number} found": "{number} पाइयो",
    Search: "खोजी ",
    "Loading ...": "लोडिङ...",
    "Sorry, no groups matched filter parameters, please try again.":
      "माफ गर्नु्होला, तपाईले खोजेको समूह मिलेन, पुन प्रयास गर्नु होला",
    "Please login to write an answer..": "कृपया जवाफ लेख्न लग इन गर्नुहोस्।",
    "Pending verification": "प्रमाणिकरण विचाराधिन छ",
    Guides: "निर्देशनहरु",
    "All categories": "सबै श्रेणीहरु",
    Category: "श्रेणी",
    "Date: Newest": "मितिः सबै भन्दा नया",
    "Date: Oldest": "मितिः सबै भन्दा पुरानो",
    "Sorry, no guides matched filter parameters, please try again.":
      "माफ गर्नुहोला तपाईले खोजेको निर्देशनहरु मिलेन, पुन प्रयास गर्नुहोला",
    "Welcome, {name}": "स्वागतम, {name}",
    "new user": "नया प्रयोगकर्ता",
    guest: "अस्थायी प्रयोग कर्ता",
    "Enable notifications:": "सूचना सुचारु गर्नुहोस",
    Off: "बन्द",
    On: "खोल्नुहोस",
    Logout: "बाहिर निस्कनु",
    "Job Applications": "रोजगारको निवेदन",
    "Sorry, no job applications found!":
      "माफ गर्नुहोस्, कुनै रोजगारको आवेदन भेटिएन!",
    Jobs: "रोजगाारका अवसरहरु",
    Industry: "कम्पनी",
    "Most Popular": "धेरै मनपराईएको",
    "Most Applied": "धेरै आवेदन गरिएको",
    "New Jobs Available !": "नयाँ उपलब्ध रोजगारका अवसरहरु ",
    "There are some jobs available at the marketplace now {provinceAndIndustry}, have you checked them out?":
      "मार्केटप्लेसमा के हि रोजगारका अवसरहरु हाल  {provinceAndIndustry} उपलब्ध छन्, तपाईले हेर्नु भयो.        के तपाईँ निश्चित हुनुहुन्छ",
    Check: "जाँच गर्नुहोस",
    "Job Application Confirmation !": "रोजगारको निवेदनको पुष्टि",
    "Are you sure you want to apply for this {employer} Company for this position?":
      "यो {रोजगारदाता} कम्पनीको उक्त काम र पदमा  नेवेदन हाल्न चाहानु हुन्छ?",
    "Yes, Sure": "हो, अवश्य",
    NRC: "यन आर सि ",
    "Household List": "घरपरिवारको सूची",
    "Passport (PJ)": "राहदानी",
    "Labour Card": "श्रम परिचय पत्र",
    "Covid Vaccine Certificate": "कोभिड खोपको प्रमाण पत्र",
    Passport: "राहदानी",
    "Identification Card": "परिचय पत्र",
    "Birth Certificate": "जन्म परिचय पत्र।",
    "Family Record Book": "परिवार परिचय पत्र",
    "Residence Book": "बसोवास परिचय पत्र",
    "Work Permit": "श्रम अनुमति",
    Visa: "भिसा",
    "Pink Card": "पिंक कार्ड",
    "Cancellation Letter": "रद्द पत्र",
    "Successfully accepted job offer": "रोजगारको अवसर स्वीकार गरियो",
    "Couldn't accept due to server error":
      "सर्वरको समस्याको कारण स्वीकार गर्न सकिएन",
    "Network error, please try again later":
      "नेटवर्कमा समस्या छ: कृपया पुन: प्रयास गर्नुहोस्!",
    "Unexpected error, please try again":
      "अनअपेक्षित समस्या देखियो: कृपया पुन: प्रयास गर्नुहोस्!",
    "Loading…": "लोडिङ भैरहेको छ...",
    Apply: "निवेदन गर्नुहोस्",
    "Sorry you cannot apply for this job. This job is for the jobseekers who live in {country}.":
      "माफ गर्नु होला, यो कामको लागि तपाईँले नेवेदन गर्न सक्नु हुन्न I यो जागिर नेपालमा बाट जागिर खोज्नेहरूका लागि हो I\n ",
    "Sorry you cannot apply job now as current jobs are for those in Thailand.":
      "माफ गर्नु होला, यो कामको लागि तपाईँले नेवेदन गर्न सक्नु हुन्न",
    "Click here to create a resume to apply for this job":
      "वायोडाटा वनाउन र यो कामको लागि आवेदन दिनुहोस् यहाँ क्लिक गर्नुहोस्",
    "Successfully rejected job offer": "रोजगाारको अवसर अस्वीकार गरियो",
    "Couldn't reject due to server error": "सर्वरको कारण रद्द गर्न सकिएन",
    "Confirm Reject Job Offer": "रद्द्गरिएको सुनिश्चित गर्छु",
    "Are you sure you would like to reject the job offer? This action cannot be undone later.?":
      "तपाई यस रोजगारको अवसर रद्द गर्न सुनिश्चित हुनुहुन्छ? पछि पुन यस पेजमा फर्कन सकिन्न।",
    Confirm: "सुनिश्चित गर्छु।",
    "Rejection Reason": "रद्द गर्नुको कारण",
    Details: "बिस्तृत विवरण",
    Posted: "पोष्ट गरिएको",
    "Closing date": "बन्द हुने मिति",
    "Employer Pays Policy": "रोजगारदाताले नै सबै शुल्क बेहोर्ने निति छ",
    "Employer does not have Employer Pays Policy":
      "रोजगारदाताले सबै शुल्क बेहोर्ने निति छैन",
    "Employer Size": "रोजगारदाताको आकार",
    "Nationalities of Workers working at this workplace":
      "प्रवासी कामदारको राष्ट्रियता",
    "Number of Workers Needed": "माग गरिएको कामदारको संख्या",
    "Number of Male Headcount Needed": "माग गरिएको पुरुष कामदारको संख्या",
    "Number of Female Headcount Needed": "माग गरेएको महिला कामदारको संख्या",
    "Available Shifts": "कामको शिफ्ट",
    "Overtime Expectation": "ओभरटाइमको अपेक्षा",
    "Minimum Working Hours": "न्युनतम कार्य घण्टा",
    "Day of Week Off": "साप्ताहिक विदा",
    "Job Description": "रोजगारको विबरण",
    "Job Requirements":
      "रोजगारको आवश्यक योग्यता        कामको (आवश्यक योग्यता) ",
    "Job Industry": "रोजगारको क्षेत्र",
    "Job Position Category": "रोजगारको प्रकार",
    "Job Position Details": "पदको विबरण",
    "Terms of Payment": "तलब पाउने तरिका",
    "Timing of Payment": "तलब पाउनेे समय",
    "Timing of Payment Other": "अन्य रकम पाउने समय",
    Salary: "तलब",
    "{amount} {currency}/month": "{रकम} {मुद्रा} हरेक महिना",
    "{amount} {currency}/day": "{रकम} {मुद्रा}/हरेक दिन",
    "Job Type": "रोजगारको प्रकार",
    "Contract Duration": "करारको अवधि",
    "{duration} months": "{duration} महिना",
    "Regular OT Rate": "ओभर टाइमको ज्याला दर",
    "{amount} {currency}/hour": "{amount} {currency}/प्रति घण्टा",
    "Holiday OT Rate": "विदाको दिनको ओभर टाइम ज्याला दर",
    "Holiday Rate": "विदाको दर",
    "ER information": "नितिगत भर्ना प्रकृया सम्बन्धि सूचना",
    "Ethical recruitment policy": " नितिगत भर्ना प्रकृया अपनाएको ",
    "Not Have Ethical recruitment policy": "नितिगत भर्ना प्रकृया अपनाएको छैन",
    "RA has Ethical recruitment policy":
      "बेदेशिक रोजगार कम्पनी सँग नितिगत भर्ना प्रकृयाको निति छ",
    "RA has not the Ethical recruitment policy":
      "बेदेशिक रोजगार कम्पनी सँग नितिगत भर्ना प्रकृयाको निति छैन",
    "Employer pays policy": "रोजगारदाताले सबै शुल्क बेहोर्ने निति",
    "Not have Employer pays policy": "रोजगारदाताले सबै शुल्क बेहोर्ने निति छैन",
    "Recruitment Information": "भर्ना प्रकृया सम्बन्धि जानकारि",
    "Not have Ethical recruitment policy": "नितिगत भर्ना प्रकृयाको निति छैन",
    "The workers have to pay visa fee": "कामदारले भिसा शुल्क तिर्नु पर्छ",
    "Employer pays for the visa fees": "रोजगारदाताले भिसा शुल्क बेहोर्छ",
    "The workers have to pay work permit fee":
      "कामदाारले श्रम अनुमति शुल्क तिर्नु पर्छ",
    "Employer pays for the work permit fees":
      "रोजगारदाताले श्रम अुनमतिको शुल्क बेहोर्छ",
    "The workers have to pay medical checkup fee":
      "कामदारलेे स्वास्थ्य परिक्षणको शुल्क तिर्नु पर्छ",
    "Employer pays for the medical checkup fees":
      "रोजगारदाताले स्वास्थ्य परिक्षणको शुल्क बेहोर्छ",
    "Skill Training Information": "सीप तालीम सम्बन्धि जानकारि",
    "Employer pays Cost of skill-training":
      "रोजगारदाताले सीप तालीमको खर्च बेहोर्छ",
    "The workers need to pay Cost of skill-training": "कामदारले सीप तालीम",
    "{amount} {currency}": "{रकम} {मुद्रा}",
    "The employer provides accommodation during skill-training of Workers":
      "सीप तालीमको अवधिमा रोजगारदाताले आवास उपलब्ध गराउ छ",
    "The workers need to pay accommodations fee during skill-training of Workers":
      "सीप तालीमको अवधिमा कामदारले आवासको शुल्क तिर्नु पर्छ",
    "The employer provides meals during skill-training of Workers":
      "सीप तालीमको अवधिमा रोजगाारदाताले खानाको शुल्क बेहोर्ने",
    "The workers need to pay meals fee during skill-training of Workers":
      "सीप तालीमको अवधिमा कामदारलेे खानाको शुल्क तिर्नु पर्छ",
    "Based on individual cost/spending.": "व्यक्तिगत लागत/खर्च आधारित।",
    "The employer pays transportation costs to and from for attending skill training":
      "सीप तालीमको लागी जाने आउने यातायात खर्च रोजगारदाताले बेहोर्ने",
    "The workers need to pay transportation costs to and from for attending skill training":
      "सीप तालीमको अवधिमा जाने आउने यातायात खर्च कामदारले तिर्ने",
    "Costs For Pre-Departure": "प्रस्थानपुर्व हुने अभिमुखिकरण को खर्च",
    "Employer pays Cost of pre-departure orientation training":
      "प्रस्था्नपुर्व हुने अभिमुखीकरण तालीमको शुल्क रोजगारदाताले बेहोर्ने",
    "The workers need to pay Cost of pre-departure orientation training":
      "प्रस्था्नपुर्व हुने अभिमुखीकरण तालीमको शुल्क कामदारले बेहोर्ने",
    "Employer provides accommodation during pre-departure orientation training":
      "प्रस्थानपुुर्व हुने अभिमुखीकरण तालीम अवधिको आवास खर्च रोजगारदाताले बहोर्ने",
    "The workers need to pay accommodations fee during pre-departure orientation training":
      "प्रस्थानपुर्व हुुने अभिमुखीकरण तालीम अवधिको आवास खर्च कामदारले बेहोर्ने",
    "Employer provides meals during pre-departure orientation training":
      "प्रस्थानपुर्व हुनेे अभिमुखीकरण तालींम अवधिको खाना खर्च रोजगारदाताले बेहोर्ने",
    "The workers need to pay meals fee during pre-departure orientation training":
      "प्रस्थानपुर्व हुनेे अभिमुखीकरण तालींम अवधिको खाना खर्च कामदारले बेहोर्ने",
    "Employer pays transportation costs to and from pre-departure orientation training":
      "प्रस्थानपुर्व हुनेे अभिमुखीकरण तालींम अवधिको यातायात खर्च रोजगारदाताले बेहोर्ने",
    "The workers need to pay transportation costs to and from pre-departure orientation training":
      "प्रस्थानपुर्व हुनेे अभिमुखीकरण तालींम अवधिको यातायात खर्च कामदारले बेहोर्ने",
    "Additional information for Pre-departure orientation course":
      "प्रस्थानपुर्व हुनेे अभिमुखीकरण तालींम सम्बन्धि अतिरिक्त जानकारि",
    "Costs and Fees for recruitment in the origin country":
      "कामदार भर्ना प्रकृुयामा स्वदेशमा लाग्ने खर्च तथा शुल्क.        प्रक्रिया",
    "Employer pays recruitment service fee to RA and sub-agent":
      "कामदार भर्ना प्रकृयामा स्वदेशमा रोजगार कम्पनी र एजेण्टले लिने सेवा शुल्क रोजगारदाताले बेहोर्ने",
    "The workers need to pay recruitment service fee to RA and sub-agent":
      "कामदार भर्ना गर्दा स्वदेशमा रोजगार कम्पनी र एजेण्टले लिने सेवा शुल्क कामदारले बेहोर्ने",
    "Employer pays travel costs for a job interview":
      "रोजगारको अन्तर्वार्ताको लागि यात्रा खर्च रोजगारदाताले बेहोर्ने",
    "The workers need to pay travel costs for a job interview":
      "रोजगारको अन्तर्वार्ताको लागि यात्रा खर्च कामदारले बेहोर्ने",
    "Employer pays accommodation costs for contract signing":
      "रोजगारको करारमा सहि गर्न आउँदा आवास खर्च रोजगारदाताले बेहोर्ने",
    "The workers need to pay accommodation costs for contract signing":
      "रोजगारको करारमा सहि गर्न आउँदा आवास खर्च कामदारले बेहोर्ने",
    "Employer pays transportation cost to and from for contract signing":
      "रोजगारको करारमा सहि गर्न आउँदा यातायात खर्च रोजगारदाताले बेहोर्ने",
    "The workers need to pay transportation cost to and from for contract signing":
      "रोजगारको करारमा सहि गर्न आउँदा जाँदाको यातायात खर्च कामदारले बेहोर्ने",
    "Employer provides meals for contract signing":
      "रोजगारको करारमा सहि गर्न आउँदा खाना खर्च रोजगारदाताले बेहोर्ने",
    "The workers need to pay meals fee for contract signing":
      "रोजगारको करारमा सहि गर्न आउँदा खाना खर्च कामदारले बेहोर्ने",
    "Employer pays medical check-up fee":
      "स्वास्थ्य परिक्षण शुल्क रोजगारदाताले बेहोर्ने",
    "The workers need to pay medical check-up fee":
      "स्वास्थ्य परिक्षण शुल्क कामदारले बेहोर्ने",
    "Employer pays passport application fee":
      "राहदानीको शुल्क रोजगारदाताले बेहोर्ने",
    "The workers need to pay passport application fee":
      "राहदानीको शुल्क कामदारले बेहोर्ने",
    "Employer pays Overseas Worker Identification Card (OWIC) fee":
      "बैदेशिक रोजगार परिचय पत्र शुल्क रोजगारदाताले बेहोर्ने",
    "The workers need to pay Overseas Worker Identification Card (OWIC) fee":
      "बैदेशिक रोजगार परिचय पत्र शुल्क कामदारले बेहोर्ने",
    "Employer pays Transportation costs to the border or airport":
      "रोजगार दाताले सीमाना वा विमानस्थलमा लागेको यातायात खर्च तिर्नेछ",
    "The workers need to pay Transportation costs to the border or airport":
      "श्रमिकहरूले सीमा वा विमानस्थल सम्म जाने बाटो खर्च तिर्नु पर्ने ",
    "Costs and fees for recruitment in the destination country":
      "श्रमिक भर्ना प्रक्रियामा गन्तव्य मुलुकमा लाग्ने खर्च तथा शुल्क",
    "Employer pays Transportation cost from the Thai border to the workplace (or to the destination country)":
      "रोजगारदाताले थाई सिमानाबाट कार्यस्थल (वा गन्तव्य देशमा) बाटो खर्च तिर्छ।",
    "The workers need to pay Transportation cost from the Thai border to the workplace (or to the destination country)":
      "नेपाल वाट विदेश स्थित कार्य स्थल सम्म पुग्ने यातायात खर्च कामदारले बहोर्ने",
    "Employer pays Visa fee": "रोजगारदाताले भीषा शुल्क तिर्ने",
    "The workers need to pay Visa fee": "कामदारले भीषा शुल्क तिर्नु पर्ने",
    "Employer pays Work permit": "रोजगारदातालेे श्रम अनुमति शुल्क तिर्ने",
    "The workers need to pay Work permit":
      "कामदारलेे श्रम अनुमती शुल्क तिर्नु पर्ने",
    "Employer pays Medical check-up fee (if workers have to take another check up)":
      "स्वास्थ्य परिक्षण शुल्क रोजगारदाताले तिर्ने ",
    "The workers need to pay Medical check-up fee (if workers have to take another check up)":
      "स्वास्थ्य परिक्षण शुल्क कामदारले तिर्ने ",
    "Other Costs": "अन्य खर्चहरु",
    "Information on repatriation": "स्वदेश फिर्ती सम्वन्धि जानकारी",
    Benefits: "सुबिधाहरु",
    "Paid annual leave per year": "वार्षिक तलबी विदा",
    "Paid sick leave per year": "वार्षिक तलबी विरामी विदा",
    "Paid business leave per year": "वार्षिक तलबी कम्पनीले दिने विदा",
    "Paid national holidays per year": "वार्षिक तलबी सरकारी विदा",
    "Social security benefits are provided as required by law":
      "सामाजिक सुरक्षा अन्तरगत बिदाको सुविधा उपलब्ध गरिएको छ",
    "Social security and maternity leave benefits are provided as required by law":
      "सामाजिक सुरक्षा अन्तरगत बिदाको सुविधा उपलब्ध गरिएको छ",
    "Social security leave benefits are not provided":
      "सामाजिक सुरक्षा अन्तरगत बिदाको सुविधा प्रदान गरिएको छैन",
    "Social security and maternity leave benefits are not provided":
      "सामाजिक शुुरक्षा तथा मातृत्व विदा नपाइने",
    "Any additional leave detail": "अतिरिक्त छुट्टी विवरण",
    "Other Benefits": "अन्य सुबिधाहरु",
    "Benefits Details": "सुबिधा सम्बन्धि बिस्तृत जानकारी",
    "Other Information": "अन्य जानकारी",
    "Accommodation on-site is available": "कार्यस्थलमा आवास सुबिधा पाइने",
    "No information is provided on whether Accommodation on-site or not":
      "कार्यस्थलमा आवास सुबिधा नपाइने",
    "Family members can live together": "परिवारका सदस्यहरु सँगै बस्न पाइने",
    "No information is provided on whether Family members can live together or not":
      "परिवारका सदस्यहरु सँगै बस्न नपाइने",
    "Rent & Utilites are auto deducted from the payroll":
      "आवास शुल्क र सुबिधाहरुको शुल्क पाउने तलबबाट स्वत काटिने",
    "No information is provided for a deduction on Rent & Utilities":
      "आवास शुल्क र सुबिधाहरुको शुल्क पाउने तलबबाट नकाटिने",
    "Safe functioning grievance mechanism":
      "शुरक्षित रुपमा संचालनहुने गुनासो तथा समस्या सुन्ने संयन्त्र भएको",
    "No Information is provided  for grievance mechanism":
      "शुरक्षित रुपमा संचालनहुने गुनासो तथा समस्या सुन्ने संयन्त्र नभएको",
    "Probation period": "परिक्षणकाल हुने",
    "No Information is provided for probation period": "परिक्षणकाल नहुने ",
    "Accommodation Details": "आवास सम्बन्धि बिस्तृत जानकारी",
    "Grievance Mechanism Details":
      "गुनासो तथा समस्या सुन्ने संयन्त्र सम्बन्धि जानकारी",
    "Probation Period Details": "परिक्षणकाल सम्बन्धि जानकारी",
    "Rent/utilites deduction details": "भाडा / उपयोगिता कटौती विवरण",
    "Contact Person": "सम्पर्क ब्यक्ति",
    "Recruitment Agency Information": "भर्ना एजेन्सीको जानकारी",
    "Currently, the employer has received enough applicants for this job post and has moved your CV to the waitlist. You will be notified if the employer requires more applicants. If you prefer not to wait for their notification, please consider applying for another job.":
      "हाल, रोजगारदाताले यस रोजगारको लागि पर्याप्त आवेदकहरू प्राप्त गरेका छन् र तपाईंको वायोडाटा प्रतीक्षासूचीमा सारियो। यदि रोजगारदातालाई थप आवेदकहरू चाहिन्छ भने तपाईंलाई सूचित गरिनेछ। यदि तपाइँ तिनीहरूको सूचनाको लागि पर्खन चाहनुहुन्न भने, कृपया अर्को रोजगार अवसरको लागि आवेदन गर्ने विचार गर्नुहोस्।",
    "This employer requested to view and check your documents {timeAgo}. They will be allowed to view them only, not download.":
      "यो रोजगारदाताले तपाईको कागजात हेर्न अनुरोध गरेको (यति समय अघि)। उनिहरुलाई कागजात हेर्न मात्र अनुमती दिइने छ तर डाउनलोड गर्न नदिइने",
    "However, you must first have uploaded the required documents on your resume page:":
      "तर पनि, तपाइले पहिला आफ्ना आवश्यक कागजात वायोडाटाको पेजमा अनिबार्य अपलोड गर्नु पर्छ।",
    "Upload missing documents": "छुटेका कागजातहरु अपलोड गर्नुहोस्",
    Reject: "अस्वीकार गर्न",
    Accept: "स्वीकार गर्न",
    "Missing resume": "वायोडाटा हराएको",
    "{clickHereLink} to create your GD resume":
      "{clickHereLink} तपाईँको वायोडाटा गोल्डेन ड्रिमको लागि सिर्जना गर्न",
    "Click here": "यहाँ क्लिक गर्नुहोस्",
    "Jobs for seekers in Myanmar/Cambodia/Nepal ":
      "नेपाल बाट वैदेशिक रोजगाको अबसर खोज्नेहरुका लागि ",
    "All jobs on Marketplace": "मार्केटप्लेसमा भएका सबै रोजगारका अवसरहरु",
    "Jobs for seekers in Thailand ":
      "नेपालका प्रवासीकामदारहरुका लागि मलेशियामा रोजगारका अवसरहरु",
    "Please login to view the jobs..":
      "कृपया रोजगारहरूको अवसर हेर्न लगइन गर्नुहोस्",
    "Sorry, no recruitment agencies matched filter parameters, please try again.":
      "माफ गर्नु होला, खोजीएका म्यानपावर कम्पनीहरु छनोट गर्ने प्रकृयासँग मेल खाएन, पुन प्रयास गर्नु होला ",
    "Successfully applied": "नेवेदन सफल भयो",
    "Couldn't apply due to server error": "सर्भरको कारणले नेवेदन सफल हुन सकेन",
    "Please create your CV first": "कृपया आफ्नो वायोडाटा पहिले बनाउनुहोस",
    Screening: "छनोट भैरहेको छ",
    "Do you know someone working here?":
      "तपाईँ यहाँ काम गर्दै गरेको कसैलाई चिन्नु हुन्छ?",
    "No, I don't know": "चिन्दिन",
    "Yes, I know someone": "चिन्छु",
    "After going through the job description, do you think you can do the job well?":
      "काम सम्वन्धि विवरण हेरे पछि, तपाईँलाई काम गर्न सक्छु जस्तो लागो त?",
    "No, I am not sure": "अहँ, मैले निश्चित गर्न सकिन",
    "Yes, I think so": "हो, म गर्न सक्छु",
    "This Employer/Recruiter might need to see your documents and information to confirm you are eligible for the job. ":
      "तपाईं कामको लागि योग्य हुनुहुन्छ भनी पुष्टि गर्न रोजगारदाता् र मेनपावर कम्पनीहरुले तपाईंको कागजातहरू हेर्न आवश्यक पर्न सक्छ।",
    "No, I don't": "मेरो अनुमति छैन",
    "Yes, I do": "मरो अनुमति छ",
    "No consent was given": "अनुमति दिइएको थिएन",
    "Sorry! Employers need to see your documents to confirm you are eligible for the job. The documents required by employers may vary, so you are encourage to share the document you have, so that the employer can check if you are eligible. Is that ok for you?":
      "माफ गर्नु् होला! तपाईँ कामको लागि योग्य हो वा होइन भन्ने कुरा निश्चित गर्न तपाईँको कागजात रोजगारदाताले हर्न आवश्यक हुन्छ। त्यसैले रोजगारदाताहरुले खोज्ने कागजातहरु फरक हुन सक्छन्, त्यसैले रोजगारदाताहरुले खोजेका कागजातहरु उपलब्ध गराउन हामी तपाईँहरुलाई उत्प्रेरित गर्छौँ, के तपाईँलाई यो कुरा मञ्जुर छ?",
    "If you have any questions, for those who are in Thailand, please call {phoneLink}, and for those in Myanmar, please call or send message to Viber No. {viberLink} at anytime, 24 hours a day, 7 days a week.":
      "यदि तपाईहरु सँग के हि प्रश्नहरु छन् भने कृपया यस {phoneLink}, नम्बरमा २४ सै घण्टा सातै दिन् फोन गर्न सक्नु हुन्छ।",
    "If you have any questions, please call {phoneLink} (For those who are in Thailand) or direct message to Facebook page {facebookLink} at anytime, 24 hours a day, 7 days a week.":
      "यदि तपाईहरु सँग के हि प्रश्नहरु छन् भने कृपया यस {phoneLink} नम्बरमा वा फेसबुकमा वा वाट्सएपमा २४ सै घण्टा सातै दिन् फोन वा मेसेज गर्न सक्नु हुन्छ।",
    "If you have any questions, please call {phoneLink}.  The call is free of charge and you can call at anytime, 24 hours a day, 7 days a week.":
      "यदि तपाइँसँग कुनै प्रश्न छ भने, कृपया (+9779765415706) मा कल गर्नुहोस्। कल नि:शुल्क छ र तपाईंले कुनै पनि समय, दिनको २४ घण्टा, हप्ताको ७ दिन कल गर्न सक्नुहुन्छ। ",
    "Do you need help in completing this resume?":
      "यो वायोडाटा पूरा गर्न तपाईँलाई सहयोग आवश्यक पर्छ?",
    "Uploaded Nepal Documents": "नेपाली कागजातहरू अपलोड गरेँ",
    "Uploaded Myanmar Documents": "नेपालका कागजातहरु अपलोड गर्नु होस्",
    "NRC Card": "राष्ट्रिय परिचय पत्र ",
    "Uploaded Khmer Documents": "नेपालका कागजातहरु अपलोड गर्नु होस्",
    "Uploaded Documents": "कागजातहरु अपलोड गर्नु होस्",
    "Cancellation letter": "रद्द गरिएको चिठी",
    Resume: "वायोडाटा",
    "Basic Information": "अत्यावश्यक जानकारीहरु",
    "Full name": "पूरा नाम",
    Birthday: "जन्म मिती",
    Education: "शिक्षा",
    Major: "मूख्य विषय",
    "Emergency Phone number": "आकस्मिक फोन् नम्बर",
    "LINE id number": "तपाईको लाइन्(सामाजिक संजाल) नम्बर हाल्नुहोस् ",
    "Viber id number": "भाइबर आइडि नम्बर",
    "Current Address": "हालको ठेगाना",
    Documents: "कागजातहरु",
    "National ID": "राष्ट्रिय परिचय पत्र ",
    "Passport expires": "राहदानी समाप्ती मिती",
    "Certificate of Identity Number": "परिचय पत्र नम्बरको प्रमाण पत्र",
    "Certificate of Identity expires": "परिचय पत्र समाप्तीको प्रमाण पत्र",
    "Pink Card Number": "No need",
    "Working Experience": "कामको अनुभव",
    Title: "शिर्षक",
    Years: "वर्ष",
    "Languages Proficiency": "भाषा सम्बन्ध दक्षता",
    "Language Skills": "भाषा कौशल स्तर",
    "Read Level": "पढ्न सक्ने",
    "Speak Level": "बोल्न सक्ने",
    "Passport Number": "राहदानी नम्बर",
    optional: "ऐच्छिक",
    "Passport number": "राहदानी नम्बर",
    "Enter your Passport Number as shown in the picture":
      "फोटोमा देखिएको राहदानी नम्बर हाल्नु होस्",
    "Valid till": "राहदानी समाप्ती मिती",
    Month: "महिना",
    Year: "बर्ष",
    "Day: please enter the number between 1 and 31":
      "दिनः कृपया १ देखी ३१ बिचको नम्बर हाल्नु होस्",
    "Month: please enter the number between 1 and 12":
      "महिनाः कृपया १ देखी १२ बिचको नम्बर हाल्नु होस्",
    "Year: please enter the number between 2020 and 2040":
      "बर्षः कृपया २०२० देखी २०४० बिचको नम्बर हाल्नु होस्",
    "National ID Number": "राष्ट्रिय परिचय पत्र नम्बर",
    "Enter your National Identification Card number":
      "राष्ट्रिय परिचय पत्र नम्बर हाल्नु होस्",
    "Enter your National Identification Card Number as shown in the picture":
      "फोटोमा देखिएको राष्ट्रिय परिचय पत्र हाल्नु होस्",
    "Enter your NRC number": "No need",
    "Enter your NRC Number as shown in the picture (eg: 12/TaKaNa(N)123456)":
      "No need",
    "Enter your Passport Number as shown in the picture (eg: MD123456)":
      "फोटोमा देखिएको राहदानी नम्बर हाल्नु होस्",
    "CI Number": "No need",
    "CI number": "No need",
    "Enter your CI Number": "No need",
    "Enter your Pink Card Number as shown in the picture, the pink card number mush have 13 digits. (eg: 00 1234 567890 1)":
      "No Need for Nepal ",
    "Remove Passport": "राहादनी नम्बर हटउनुहोस",
    "Remove visa": "भीषा हटाउनुहोस",
    "Remove work permit": "श्रम अनुमति पत्र हटाउनुहोस्",
    "Remove pink card": "No need",
    "Remove canc. letter": "No need",
    "Uploaded Documents for Myanmar": "No need",
    "Remove NRC": "No need",
    "Remove Household List": "घरपरिवारको सूची हटाउनुहोस्",
    "Remove Labour Card": "श्रम कार्ड हटाउनुहोस्",
    "Remove Covid Vaccine Certificate":
      "कोभिड भ्याक्सिनको प्रमाण पत्र हटाउनुहोस्",
    "Uploaded Documents for Nepal": "नेपालका लागि अपलोड गरिएका कागजातहरू",
    "Uploaded Documents for Khmer": "नेपालको कागजात अपलोड गर्नुहोस्",
    "Remove Identification Card": "परिचय पत्र हटाउनुहोस्",
    "Remove Birth Certificate": "जन्म परिचय पत्र हटाउनुहोस्",
    "Remove Family Record Book": "पारिवार परिचय पत्र हटाउनुहोस्",
    "Remove Residence Book": "बसोबासको परिचय पत्र हटाउनुहोस्",
    "Please use one of the following formats: .jpeg, .jpg or .png":
      "यी मध्य एउटा फरम्याट प्रयोग गर्नु होस् ( .jpeg, .jpg or .png)",
    "Couldn't update some of the documents": "के ही कागजात अपडेट गर्न सकिएन",
    "Resume has been successfuly updated": "वायोडाटा अद्दावधिक सफल भयो",
    "Couldn't update photo": "फोटो अपडेट हुन सकेन",
    "Resume has not been updated due to server error":
      "सर्भरको कारणले वायोडाटा अद्दावधिक हुन सकेन",
    "Resume has been successfuly created": "वायोडाटा निर्माण सफल भयो",
    "Resume has not been created due to server error":
      "सर्भरको कारणले वायोडाटा निर्माण हुन सकेन",
    "Upload your photo": "तपाईको फोटो अपलोड गर्नु होस्",
    "Enter your full name": "तपाईको पूरा नाम हाल्नुहोस",
    "Full name must be the same name in your NRC/Passport":
      "पूरा नाम तपाईको राहदानी अनुसार हाल्नुहोस्",
    "Full name must be the same name in your NRC/Passport/Pinkcard":
      "पूरा नाम तपाईको राष्ट्रिय परिचय पत्र र राहदानी अनुसार हाल्नुहोस्",
    "Please enter your full name": "पूरा नाम हाल्नु होस्",
    "Name can contain only letters": "नाम हाल्दा अक्षर मात्र हाल्नुहोस्",
    "Choose male or female": "महिला वा पुरुष छान्नुहोस्",
    "Select the highest level of education that you have successfully completed":
      "पूरा गरेको सबै भन्दा माथिल्लो शैक्षिक तह छान्नु होस्",
    "Please choose your education level":
      "कृपया तपाईको शैक्षिक योग्यता छान्नु होस्",
    "Enter your major name": "आफ्नो नाम लेख्नु होस्",
    "Enter your major": "आफ्नो प्रमुख लेख्नु होस्",
    "Please enter your major": "कृपया आफ्नो प्रमुख लेख्नु होस्",
    "Birthday (Gregorian calendar)": "जन्म मिती (अंग्रजी पात्रो अनुसार)",
    Date: "मिती",
    "Please fill Gregorian day": "दिन (अंग्रेजी मिती अनुसार)",
    "Please fill Gregorian Month": "महिना (अंग्रेजी मिती अनुसार)",
    "Please fill Gregorian Year": "वर्ष (अंग्रेजी मिती अनुसार)",
    "Day: this month has less days": "दिन (यो महिनामा कम दिनहरु छन्)",
    "Year: please enter the number between 1940 and 2005":
      "वर्ष (सन् १९४० देखी २००५ बिचका नम्बरहरु हाल्नु होस्)",
    "Enter your email": "तपाईँको इमेल हाल्नु होस्",
    "Enter the email address you always use":
      "तपाईँले सधैँ प्रयोग गर्ने इमेल ठेगाना हाल्नु होस्",
    "Please enter valid email address": "कृपया सही इमेल ठेगाना हाल्नु होस्",
    "Pls add your country code in front of the no. For Eg. for 09765000150, pls put 959765000150.":
      "कृपया नम्बरको अगाडि देशको टेलिफोन कोड हाल्नुहोस्",
    "Enter the phone number you always use":
      "तपाईँलेे सधैँ प्रयोग गर्ने फोन नम्बर हाल्नुहोस्",
    "Enter your emergency phone number": "आकस्मिक टेलिफोन नम्बर हाल्नुहोस्",
    "Enter your Emergency Phone Number": "आकस्मिक टेलिफोन नम्बर हाल्नुहोस्",
    "Enter LINE id": "लाइन् परिचय नम्बर हाल्नुहोस्",
    "Enter your LINE id number": "तपाईको लाइन् परिचय नम्बर हाल्नुहोस्",
    "Enter your LINE id Number": "तपाईको असली लाइन् परिचय नम्बर हाल्नुहोस्",
    "Please enter valid LINE id number": "तपाईक परिचय नम्बर हाल्नुहोस्",
    "Enter your viber id number": "तपाईको भाइवर परिचय नम्बर हाल्नुहोस्",
    "Enter your Viber Id Number": "तपाईको भाइवर परिचय नम्बर हाल्नुहोस्",
    "Please enter valid viber id number":
      "तपाईको असली भाइवर परिचय नम्बर हाल्नुहोस्",
    "Select Country": "देश चयन गर्नुहोस्",
    "Select the country you are living currently":
      "तपाईँ हाल बस्दै आउनु भएको देशको नाम छान्नु होस्",
    "State/Province": "राज्यप्रदेश",
    "Select State/Province": "राज्य/प्रान्त छान्नुहोस्",
    "Select the state/province you are living currently":
      "तपाईँ हाल बस्दै आउनु भएको प्रदेशको नाम छान्नुहोस्",
    City: "शहर",
    "Select City": "शहर छान्नुहोस्",
    "Select the city you are living currently":
      "तपाईँ हाल बस्दै आउनु भएको शहरको नाम छान्नु होस्",
    "Country: please select the country": "देश कृपया देश छान्नु होस्",
    "State/Province: please select the state/province":
      "राज्य/प्रान्त: कृपया राज्य/प्रान्त छान्नुहोस् गर्नुहोस्",
    "City: please select the city": "कृपया शहर छान्नु होस्",
    "Address details": "ठेगाना सम्बन्धी विबरण",
    "Please enter address details here":
      "कृपया यहाँ ठेगाना सम्बन्धी विबरण भर्नु होस्",
    "Please enter the Address Details here (Room No, Street Name, Block Name, Township Name)":
      "कृपया यहाँ ठेगाना सम्बन्धी विबरण भर्नु होस्ः (कोठा नं., टोल वा मार्ग, ब्लक नं., शहरको नाम)",
    "Please enter your address details":
      "कृपया ठेगाना सम्बन्धी विबरण भर्नु होस्",
    "Select Language": "भाषा छान्नुहोस्",
    "Select the Language": "भाषा छान्नुहोस्",
    "Select the Langauge": "भाषा छान्नुहोस्",
    "Select Read Level": "पढ्न सक्ने भाषाको तह छान्नु होस्",
    "Select the Read Level of Language": "पढ्न सक्ने भाषाको तह छान्नु होस्",
    "Select read level of Language": "पढ्न सक्ने भाषाको तह छान्नु होस्",
    "Select Speak Level": "बोल्न सक्ने भाषाको तह छान्नु होस्",
    "Select speak Level of Language": "बोल्न सक्ने भाषाको तह छान्नु होस्",
    "Select speak level of Language": "बोल्न सक्ने भाषाको तह छान्नु होस्",
    remove: "हटाउनुहोस्",
    "Add Language Skill": "आफूूलाई आउने भाषा थप्नु होस्",
    "Job Title": "काम वा रोजगारको नाम वा शिर्षक",
    "Enter your job title": "काम वा रोजगारको नाम वा शिर्षक उल्लेख गर्नु होस्",
    "Enter the job title of previous job or current job":
      "पहिला गरेको वा हाल गर्दै गरेको काम वा रोजगारको नाम वा शिर्षक उल्लेख गर्नु होस्",
    "Select the type of industry you worked in for this job":
      "आफूले कुन क्षेत्रमा काम गरेको हो छान्नु होस्",
    "Select industry": "क्षेत्र (उद्योग) छान्नु होस्",
    "Add new experience": "पछिल्लो कामको अनुभव थप गर्नु होस्",
    "Please fill in all the required fields and try again.":
      "कृपया आवश्यक सबै स्थानहरु भर्नुहोस् र पुन प्रयास गर्नु होस्",
    "Save CV": "वायोडाटा सेभ गर्नु होस्",
    "Update required!": "विबरण अद्दावधिक गर्न आवश्यक छ!",
    "Please {clickHere} to update your app in Google Play Store. Close and reopen the app once completed.":
      "कृपया गुगल प्ले स्टोरमा एप अद्दावधिक गर्न {clickHere} गर्नु होला। जब अद्दावधिक पूरा हुन्छ बन्द गरेर फेरि खोल्नु होला।",
    "click here": "यहाँ क्लिक गर्नु होस्",
    "Free hotline": "निशुल्क हटलाइन्",
    "Thailand Hotline (free)": "थाइल्याण्ड हटलाइन (नि:शुल्क)",
    "Yangon Hotline": "याङ्गुन हटलाइन (नि:शुल्क)",
    "Nepal Hotline (free)": "नेपाल हटलाइन (निःशुल्क)",
    Recruiters: "म्यानपावर कम्पनी",
    Services: "सेवाहरु",
    "Share a problem": "समस्याहरु उल्लेख गर्नुहोस्",
    Language: "भाषा",
    Marketplace: "मार्केटप्लेस",
    "GoldenDreams App": "उज्वल सपना एप ",
    Surveys: "सर्वेक्षण",
    "Confirm Delete Your Profile?":
      "तपाइँको प्रोफाइल मेटाउन  निश्चित  गर्नुहुन्छ?",
    "By selecting the delete profile option, you will be deleting all of your information on Golden Dreams. Once you confirm, you will not be able to recover any of this information. Are you sure you want to delete your profile?":
      "डिलिट प्रोफाईल विकल्प चयन गरेर, तपाईंले गोल्डेन ड्रीम्समा रहेको आफ्नो सबै जानकारी मेटाउनुहुनेछ। एकचोटि तपाईंले पुष्टि गरेपछि, तपाईं यी कुनै पनि जानकारी पुन: प्राप्त गर्न सक्षम हुनुहुने छैन। के तपाइँ तपाइँको प्रोफाइल मेटाउन निश्चित हुनुहुन्छ?",
    "Yes, Delete my profile": "हो, मेरो प्रोफाइल मेटाउनुहोस्",
    Verified: "प्रमाणित",
    Unverified: "अप्रमाणित",
    "Profile strength:": "विस्तृत व्यक्तिगत विबरण",
    Name: "नाम",
    Age: "उमेर",
    "Enter your age": "तपाईको उमेर उल्लेख गर्नुहोस्",
    "Please enter valid phone number!": "कृपया आफ्नो टेलिफोन् नम्बर हाल्नुहोस्",
    "Phone Number": "फोन नम्बर",
    "Verify Phone": "फोन नम्बर प्रमाणित गर्नुहोस्",
    "Preload media for offline use":
      "अफलाइन प्रयोगको लागी पहिलेनै लोड गरिएका संचार माध्यम",
    "No preload": "पहिलेनै लोड गरिएको ",
    Audio: "सुन्नका लागी",
    "Audio + Video": "सुन्न र हेर्नका लागी",
    "Delete Profile": "प्रोफाइल मेटाउनुहोस्",
    "Privacy policy": "गोपनियता सम्बन्धि निती",
    "Member agreement": "सदस्य सम्झौता",
    Saving: "सुरक्षित गरिदै ",
    "Password must be at least 8 characters long and include both letters (a-z) and numbers (0-9).":
      "पासवर्ड कम्तिमा ८ वर्णहरूको हुनुपर्छ, जसमा कम्तिमा १ अक्षर (A देखि Z सम्म) र १ नम्बर (0 देखि 9 सम्म) अनिवार्य छ।",
    "Please Confirm your password": "कृपया आफ्नो पासवर्ड कन्फर्म गर्नुहोस्",
    "privacy policy": "गोपनियता सम्बन्धि निती",
    "member agreement": "सदस्य सम्झौता",
    "GOOGLE TEST SIGNUP": "गुगल परिक्षणका लागी दर्ता गर्ने",
    "Facebook TEST SIGNUP": "फेसबुक परिक्षणका लागी दर्ता गर्ने",
    "Enter username or email you want to use":
      "युजर नाम र इमेल उल्लेख गर्नु होस्",
    "Please enter username or email you want to use":
      "कृपया तपाईँको युजर नाम र इमेल उल्लेख गर्नु होस्",
    "Enter your password": "पासवर्ड हाल्नुहोस्",
    "Password must be at least 8 characters long, requiring at least 1 letter (a-z) and 1 number (0-9).":
      "पासवर्ड कम्तिमा ८ वर्णहरूको हुनुपर्छ, जसमा कम्तिमा १ अक्षर (A देखि Z सम्म) र १ नम्बर (0 देखि 9 सम्म) अनिवार्य छ।",
    "Please enter your password": "कृपया पासवर्ड हाल्नुहोस्",
    "Confirm Password": "पासवर्ड सुनिश्चित गर्नुहोस",
    "Please confirm your password":
      "कृपया यहाँ तपाईले अगी हालेको पासवर्ड हल्नुहोस",
    "Show Passwords": "पासवर्डहरू देखाउनुहोस्",
    Register: "दर्ता पुष्टि गर्नुहोस्",
    "By clicking register, you agree to our {privacy_policy} and {member_agreement}.":
      "दर्तामा क्लिक गरेर, तपाईँले हाम्रो {privacy_policy} र {member_agreement} स्वीकार गर्नु भयो।",
    or: "वा ",
    "Continue with Facebook": "फेेसबुक मार्फत जारि राख्नुहोस्",
    "Continue with Google": "गुगल मार्फत जारि राख्नुहोस्",
    "Resume has been successfuly deleted": "वायोडाटा सफलतापूर्वक मेटाइयो",
    "Resume has not been deleted due to server error":
      "सर्वरको कारणले वायोडाटा मेटाउन सकिएन",
    "Please use one of the following formats: .jpeg, .jpg, .png, .pdf, .doc, .docx, .xls or .xlsx":
      "तलका मध्ये एउटा फरम्याट प्रयोग गर्नुहोस्,  .jpeg, .jpg, .png, .pdf, .doc, .docx, .xls or .xlsx",
    "Resume has been successfuly uploaded": "वायोडाटा सफलतापूर्वक अपलोड भयो",
    "Resume has not been uploaded due to server error":
      "सर्वरको कारणले वायोडाटा अपलोड हुन सकेन",
    "Confirm deletion": "मेटाउने सम्बन्धी निश्चितता",
    "Are you sure you would like to delete your CV?":
      "तपाईँ वायोडाटा मेटाउने विचारमा स्पष्ट हुनु हुन्छ?",
    "Are you sure you would like to delete your uploaded CV?":
      "तपाईँ अपलोड गरिएको वायोडाटा मेटाउने विचारमा स्पष्ट हुनु हुन्छ?",
    "Golden Dreamer CV": "सुनौलो सपना देख्ने हरुको वायोडाटा",
    View: "हेर्नुहोस्",
    Edit: "संसोधन् वा परिवर्तन् गर्नुहोस्",
    "We encourage you to create a Golden Dreamer CV to show your educational background, work experience, and skills you've mastered.":
      "हामी तपाईँहरुलाई आफ्नो शैक्षिक पृष्ठभुमि, कामको अनुभवको, र सीपको बारेमा बताउन सुनौलो सपना देख्नेहरुका लागी एउटा वायोडाटा तयार गर्न अभिप्रेरित गर्दछौँ।",
    "It is a short document that gives you a chance to show a prospective employer the best of what you've got. It highlights your skills and experience and shows them you're the right person for the job. It takes only a few minutes to create one.":
      "यो एउटा संक्षिप्त विबरणहो जसले तपाईँको सम्भावित रोजगारदातालाई आफूले हासिल गरेको उपलब्धीहरुको बारेमा बताउने अवसर दिन्छ। यसले तपाईँहरुको सीप र अनुभवको जानकारी दिदै यस कामको लागि तपाईँ एउटा योग्य उम्मेद्वार भएको उजागर गर्छ। र यो बनाउन के ही बेर मात्र लाग्छ।",
    "Once you have created your CV, you can use it to apply for many job opportunities. Good luck with your job application!":
      "जब एक पटक तपाईँ यो वायोडाटा बनाउनु हुन्छ त्यस पछि यसलाई धेरै पटक रोजगारको निबेदन गर्न प्रयोग गर्न सक्नुहुन्छ। रोजगारको निवेदनहरुले सफलता प्राप्त गरुन् भन्ने शुभकामना दिन चाहान्छौँ।",
    "Start creating my CV": "मेरो वायोडाटा बनाउन शुरु गरौँ",
    "Upload custom CV": "तयारी वायोडाटा अपलोड गरौँ",
    "Please choose a CV file": "कृपया एउटा वायोडाटा छान्नुहोस्",
    "CV name": "वायोडाटाको नाम",
    "Enter name of your CV": "तपाईँको वायोडाटाको नाम हाल्नुहोस्",
    "Please enter name of your CV": "कृपया तपाईँको वायोडाटाको नाम हाल्नुहोस्",
    "{appName} would like to send you notifications for the latest news and updates. Notifications may include alerts, sounds and icon badges.":
      "{appName}ले तपाईँलाई ताजा समाचार र अद्दावधिक गरिएका सूचना हरु पठाउँछ। यस्ता सूचनाहरु अलर्ट, आवाज, वा ब्याजहरुका माध्यमबाट हुन सक्छन्।  ",
    "Golden Dreams": "गोल्डेन ड्रिमहरु",
    Block: "ब्लक गर्नुहोस्",
    Allow: "अनुमती दिनुहोस्",
    "Document removal confirmation": "कागजात हटाउने निश्चित गर्नुहोस्",
    "Are you sure you would like to remove this documents?":
      "तपाई यो कागजात हटाउने कुरामा निश्चित हुनुहुन्छ?",
    "Yes, sure": "हो, निश्रित छु",
    "Confirmation !": "निश्चित गर्छु",
    "Are you sure you want to remove ?": "तपाईँ हटाउन चाहेको निश्चित हो?",
    "Protected view": "शुरक्षित दृष्टिकोण",
    "Please login or register to continue":
      "कृपया अघि बढनको लागी लगइन् अथवा दर्ता गर्नु होस्",
    "Forgot Password?": "पासवर्ड विर्सनु भयो?",
    "New to golden dreams?": "गोल्डेन् ड्रिमहरुको लागी नयाँ हो?",
    "Click here to register?": "दर्ता गर्न यहाँ क्लिक गर्नुहोस्",
    "Continue browsing as guest":
      "नयाँ प्रयोग कर्ताको रुपमा लगातार खोज गरिरहनु होला",
    "Click here to register": "दर्ता गर्न यहाँ क्लिक गर्नुहोस्",
    "Recruiters Listing": "भर्ती एजेन्सी सूचीकरण",
    "Employers Listing": "रोजगारदाता सूची",
    Applications: "एप्लिकेशन्सहरु",
    "Sorry, no news matched filter parameters, please try again.":
      "माफ गर्नु होला, खोजीएका समाचाहरु छनोट गर्ने प्रकृया मेल खाएन, पुन प्रयास गर्नु होला ",
    "No notifications": "कुनै सूचना छैन",
    "Please complete the form!": "कृपया फारम पुरा भर्नुहोस्!",
    "Password reset link sent successfully!":
      "पासवर्ड रिसेट लिङ्क सफलतापूर्वक पठाइयो!",
    "Bad Request: Please check the information you provided!":
      "त्रुटि: कृपया तपाईंले प्रदान गर्नुभएको जानकारी जाँच गर्नुहोस्!",
    "Not Found: The requested phone number was not found!":
      " फेला परेन: अनुरोध गरिएको फोन नम्बर फेला परेन!",
    "Internal Server Error: Please try again later!":
      "आन्तरिक सर्भर त्रुटि: कृपया  पुन: प्रयास गर्नुहोस्!",
    "An error occurred: ": "त्रुटि भयो:",
    "Network error: Please check your internet connection!":
      "नेटवर्क त्रुटि: कृपया आफ्नो इन्टरनेटको गति जाँच गर्नुहोस्!",
    "Error: ": "त्रुटि:",
    "Forgot Password": "पासवर्ड बिर्से ",
    "Enter your phone number to reset":
      "रिसेट गर्नको लागि आफ्नो फोन नम्बर हल्नुहोस गर्नुहोस्",
    "This feature will be available in Nepal Soon!":
      "यो विशेषता(सुविधा) नेपाली भाषामा छिट्टै उपलब्ध हुनेछ।",
    "Processing...": "मेनपावर कम्पनीको दर्ता प्रक्रियाको अवस्था...",
    "Send Password Reset Link": "पासवर्ड रिसेट लिङ्क पठाउनुहोस्",
    "Passwords do not match.": "पासवर्डहरू मिलेन ",
    "Password reset successfully!": "पासवर्ड सफलतापूर्वक रिसेट भयो ",
    "Token expired / Bad Request!": "टोकनको म्याद समाप्त भयो/ खराब अनुरोध",
    "No users found to reset password!":
      "पासवर्ड रिसेट गर्न कुनै पनि प्रयोगकर्ता फेला परेन!",
    "There was an error resetting your password.":
      "तपाईंको पासवर्ड रिसेट गर्दा त्रुटि भयो।",
    "Failed to reset password!": "पासवर्ड रिसेट गर्न असफल भयो!",
    "Reset Password": "पासवर्ड रिसेट गर्नुहोस्",
    "New Password": "नयाँ पासवर्ड",
    "Enter new password": "नयाँ पासवर्ड हाल्नुहोस्",
    "Please enter a new password.": "नयाँ पासवर्ड हाल्नुहोस्",
    "Confirm new password": "नयाँ पासवर्ड सुनिश्चित गर्नुहोस",
    "Please confirm your new password.":
      "कृपया आफ्नो नयाँ पासवर्ड कन्फर्म गर्नुहोस्",
    "{number} responses": "{number} प्रतिकृयाहरु",
    "Would you like to participate in the poll with the topic?":
      "तपाईँ शिर्षक सहितको यस मत सर्वेक्षणमा सहभागी हुन चाहानु हुन्छ?",
    "I don't want to answer": "म उतर दिन चाहान्न",
    "I want to answer": "म उतर दिन चाहान्छु",
    "Today's community poll": "आजको सामुदायीक मत सर्वेक्षण",
    "Add a reply...": "उतर उल्लेख गर्नुहोस...",
    "Problem Description": "समस्याको बयान",
    "Please describe the issue you are facing":
      "कृपया तपाईँले भोगिरहेको समस्याको बारेमा बिस्तारमा बताउनुहोस्",
    "Please describe your issue":
      "कृपया तपाईँको समस्याको बारेमा बिस्तारमा बताउनुहोस्",
    "Submit a problem": "एउटा समस्या पठाउनुहोस्",
    "Waiting for Issara's reply": "इसराबाट उतरको लागी प्रतिक्षा गर्नुहोस्",
    "Waiting for worker's reply": "कामदारको उतरको लागी प्रतिक्षा गर्नुहोस्",
    "Issara is waiting for your reply. Click the message to see the reply.":
      "इसरा तपाईँको उतर पर्खिरहेको छ। उतरको लागी मेसेजमा क्लिक गर्नुहोस्",
    Resolved: "समस्या समाधान् भयो",
    "Last update: {date}": "अन्तिम अद्दावधिकः  {date}",
    "Mark as resolved": "समाधान् भएको भनी चिन्ह लगाउनुहोस्",
    "Created: {date}": "निर्माण गरियोः {date}",
    "Show resolved": "समाधान् भएको देखाउनुहोस्",
    "Please login to share a problem..": "कृपया समस्या पठाउन लगइन् गर्नुहोस्",
    "You haven't shared any problems yet. Please click share a problem on the right top corner to share one.":
      "तपाईँले ऐले सम्म कुनै समस्याहरु पठाउनु भएको छैन। कृपया एउटा समस्या पठाउन माथिल्लो दायाँ कुनामा क्लिक गर्नु होस्",
    Profile: "वायोडाटा",
    "Are you sure want to withdraw all of your applications ? The recruiters will no longer see your applications in their list.":
      "के तपाइँ सबै आवेदनहरू फिर्ता लिन चाहानु हुनुहुन्छ ? यदी हो भने भर्नाकर्ताहरूको सूचीमा तपाईंको आवेदनहरू देख्ने छैनन्। ",
    "Yes, withdraw all": "हो, सबै फिर्ता लिनुहोस्",
    "Are you sure want to extend all of your applications ? Your application expiry date will extend by 30 days.":
      "के तपाइँ सबै आवेदनको विस्तार गर्न चाहानु हुनुहुन्छ ? तपाईंको आवेदनको म्याद सकिने मिति ३० दिनसम्म लम्बिनेछ। ",
    "Yes, extend all": "हो, सबै विस्तार गर्नुहोस्",
    "You already sent your cv to this Recruitment Agency":
      "तपाईँले यस म्यानपावर कम्पनीमा पहिलेनै वायोडाटा पठाइ सक्नु भयो",
    "Recruitment Agency viewed your application":
      "म्यानपावर कम्पनीले तपाईँको वायोडाटा हेर्यो",
    "Recruitment Agency Downloaded your resume":
      "म्यानपावर कम्पनीले तपाईँको वायोडाटा डाउनलोड गर्यो",
    "The Recruitment Agency moved your application to the General Waitlist.":
      "भर्ना एजेन्सीले तपाईंको आवेदनलाई प्रतीक्षा सूचीमा सारियो",
    "Recruitment Agency Shortlisted your application":
      "भर्ना एजेन्सीले तपाइँको आवेदन छनोट गर्यो",
    "Please select permission for your resume & enter the remark ":
      "कृपया तपाईँको वायोडाटाको लागी अनुमती छान्नुहोस् र कैफीयत उल्लेख गर्नुहोस्",
    "Allow to view documents": "कागजात हेर्न अनुमती दिनुहोस्",
    "Are you sure want to withdraw this application? Your application will no longer see in the {recruiter_name}'s list":
      "के तपाइँ यो आवेदन फिर्ता लिन चाहानु हुनुहुन्छ ? तपाईंको आवेदन (recruiter_name) को सूचीमा देखिने छैन",
    "Withdrawing Application": "आवेदन फिर्ता लिइदै",
    "Recruiter Detail": "म्यानपावर कम्पनीको विबरण",
    "Date of Registration": "दर्ता मिति",
    "Business Images": "ब्यावसायीक प्रतिष्ठा",
    "RA Business Images": "म्यानपावर कम्पनीको प्रतिष्ठा",
    "You cannot send your resume to this recruiter as you are not residing in same country with this recruiter.":
      "तपाईले आफ्नो बायोडेटा यस मेनपावर कम्पनीलाई पठाउन सक्नुहुन्न किनकि तपाई यो मेनपावर कम्पनी रहेको देशमा हुनुहुन्न ...",
    Processing: "मेनपावर कम्पनीको दर्ता प्रक्रियाको अवस्था",
    "This recruitment agency has experience sending workers to":
      "यो मेनपावर कम्पनीसँग कामदार पठाउने अनुभव छ।",
    comments: "कमेन्ट",
    "Internal server error occur": "सर्वरमा समस्या देखियो",
    "Name of recruitment agency": "म्यानपावर कम्पनीको नाम",
    "Registration number": "दर्ता नम्बर",
    "Date of registration": "दर्ता मिति",
    Username: "युजर नाम  ",
    Registration: "दर्ता",
    "Register to apply": "नेबेदन गर्न दर्ता गर्नुहोस्",
    Jobseeker: "प्रवासी कामदार",
    "Recruitment Agency": "म्यानपावर कम्पनी",
    Employer: "रोजगारदाता",
    "Service Providers": "सेवा प्रदायक",
    "Sorry, no service providers matched filter parameters, please try again.":
      "माफ गर्नु होला, खोजीएका सेवा प्रदायकहरु छनोट गर्ने प्रकृयासँग मेल खाएन, पुन प्रयास गर्नु होला ",
    "We've recieved your problem": "हामीले तपाईँको समस्या प्राप्त गर्यौँ",
    "We'll get back to you as soon as possible":
      "हामी जतिसक्दो चाँडो तपाईँलाई सम्पर्क गर्ने छौँ",
    "Please enter your name": "कृपया तपाईँको नाम उल्लेख गर्नुहोस्",
    "Please enter your phone number":
      "कृपया तपाईँको फोन नम्बर उल्लेख गर्नु होस्",
    "Member Agreement": "सदस्यता सम्झौता",
    "Privacy Policy": "गोपनियता सम्बन्धि निती",
    "Would you like to participate in the survey?":
      "तपाईँ यो सर्वेक्षणमा सहभागि हुन चाहानु हुन्छ?",
    "Thank you for participating in the survey.":
      "सर्वेक्षणमा सहभागि हुनु भएकोमा धन्यवाद",
    "The form couldn't be submitted due to the error":
      "त्रुटिको कारण फाराम पठाउन सकिएन",
    "The answer to this question is required.": "यो प्रश्नको उतर आवश्यक छ",
    "Please answer all the questions that require at least one answer":
      "कम्तिमा एउटा उतर दिएर भएपनि कृपया सबै प्रश्नको उतर दिनुहोस्",
    "Submit answers": "उतर पठानुहोस्",
    "Sorry, There are no surveys!":
      "माफ गर्नुहोस्, त्यहाँ कुनै सर्वेक्षणहरू छैनन्!",
  },
  th: {
    "Update is available": "อัพเดทเวอร์ชั่นล่าสุด",
    "Please press OK to refresh the app.":
      "โปรดกดตกลง เพื่อทำการรีเฟรชแอปพลิเคชั่น",
    "Loading..": "กำลังโหลด..",
    Male: "ชาย",
    Female: "หญิง",
    "Prefer not to say": "ไม่ประสงค์บอกเพศ",
    "Non-Binary": "ไม่ใช่ไบนารี\n ",
    "Passport Include": "รวมพาสปอร์ต ",
    "Passport Not Include": "ไม่รวมพาสปอร์ต",
    Myanmar: "พม่า",
    Thailand: "ไทย",
    Cambodia: "กัมพูชา",
    Laos: "ลาว",
    Nepal: "เนปาล",
    Indonesia: "อินโดนีเซีย",
    Indian: "อินเดีย",
    Bangladeshi: "บังคลาเทศ",
    Others: "อื่น ๆ",
    Sunday: "วันอาทิตย์",
    Monday: "วันจันทร์",
    Tuesday: "วันอังคาร",
    Wednesday: "วันพุธ",
    Thursday: "วันพฤหัส",
    Friday: "วันศุกร์",
    Saturday: "วันเสาร์",
    "It Will Vary": "ไม่แน่นอน",
    Monthly: "รายเดือน",
    "Daily Wage Rate": "รายวัน",
    "Once A Month": "1 ครั้งต่อเดือน",
    "Twice A Month": "2 ครั้งต่อเดือน",
    "Every Week": "ทุกสัปดาห์",
    "Every 10 days": "ทุก 10 วัน",
    Other: "อื่น ๆ",
    Regularly: "ตามปกติปกติ",
    "From time to time": "บางครั้งบางคราว",
    Infrequently: "ไม่บ่อย",
    Never: "ไม่เคย",
    Day: "กลางวัน",
    Swing: "สลับไป-มา",
    Night: "กลางคืน",
    Rotating: "หมุนเวียน",
    Variable: "ไม่แน่นอน",
    "Full-time": "เต็มเวลา",
    "Part-time": "พาร์ทไทม์",
    "Moved General Waitlist": "ย้ายรายการรอทั่วไปแล้ว",
    Waitlisted: "อยู่ในรายการอแล้ว",
    "Contract Signed": "เซ็นสัญญาแล้ว",
    Accepted: "รับ",
    Applied: "สมัครเรียบร้อยแล้ว",
    Downloaded: "ดาวน์โหลด",
    Offered: "ได้รับการเสนอ",
    Rejected: "ถูกปฏิเสธ",
    Shortlisted: "รับพิจารณา",
    Viewed: "เข้าเยี่ยมชม",
    "Documents Requested": "ขอดูเอกสาร",
    "Documents request accepted": "คำขอดูเอกสารได้รับอนุญาต",
    "Documents request rejected": "คำขอดูเอกสารถูกปฏิเสธ",
    "HR Assistant": "ผู้ช่วยฝ่ายบุคคล",
    "HR Interpreter": "ล่ามฝ่ายบุคคล",
    "HR Manager": "ผู้จัดการฝ่ายบุคคล",
    "HR Officer": "เจ้าหน้าที่ฝ่ายบุคคล",
    "Candidate pays fees in advance, then is reimbursed by company after starting work":
      "ผู้สมัครชำระค่าธรรมเนียมไปก่อนแล้วเบิกกับทางบริษัทภายหลัง",
    "Company pays all fees in advance":
      "บริษัทชำระค่าธรรมเนียมทั้งหมดไว้ล่วงหน้าแล้ว",
    "India (+91)": "อินเดีย (+91)",
    "Myanmar (+95)": "พม่า (+95)",
    "Nepal (+977)": "เนปาล (+977)",
    "Thailand (+66)": "ไทย (+66)",
    "Malaysia (+60)": "มาเลเซีย (+60)",
    "Cambodia (+855)": "กัมพูชา (+855)",
    "A Little Bit": "นิดหน่อย",
    Moderately: "ปานกลาง",
    "Very Well": "ดีมาก",
    None: "ไม่ถนัด",
    Some: "ถนัดบ้าง",
    Proficient: "คล่อง",
    English: "อังกฤษ",
    Thai: "ไทย",
    "Bahasa Malaysia": "บาฮาซามาเลเซีย",
    Hindi: "ฮินดี",
    "Cannot read": "อ่านไม่ได้",
    APPLICABLE: "สมัครได้",
    APPLIED: "สมัครแล้ว",
    VIEWED: "ดูแล้ว",
    DOWNLOADED: "ดาวน์โหลดแล้ว",
    SHORTLISTED: "ช็อตลิสต์แล้ว",
    OFFERED: "เสนอแล้ว",
    ACCEPTED: "รับแล้ว",
    REJECTED_BY_BUSINESS: "ถูกปฏิเสธโดยธุรกิจ",
    REJECTED_BY_APPLICANT: "ถูกปฏิเสธโดยผู้สมัคร",
    CONTRACT_SIGNED: "เซ็นสัญญาแล้ว",
    APPLICANT_NA: "ผู้สมัคร_NA",
    WAITLISTED: "อยู่ในรายการรอแล้ว",
    MOVED_GENERAL_WAITLIST: "ย้ายรสยการรอทั่วไปแล้ว",
    "STATUS N/A": "สถานะ N/A",
    "Cannot speak": "พูดไม่ได้",
    "Network Error!": "เครือข่ายผิดพลาด",
    "User with this username already exists": "ชื่อผู้ใช้นี้มีผู้ใช้แล้ว",
    "You are trying to login with Business User Account !":
      "คุณกำลังพยายามเข้าสู่ระบบด้วยบัญชีผู้ใช้แบบธุรกิจ !",
    "There was an authentication problem": "เกิดปัญหาในการรับรองความถูกต้อง",
    Applicable: "สมัครได้",
    "Not Applicable": "สมัครไม่ได้",
    "N/A": "ไม่เกี่ยวข้อง",
    "Basic Information": "ข้อมูลพื้นฐาน",
    Gender: "เพศ",
    "Date of Birth": "วันเกิด",
    "Marital Status": "สถานภาพการสมรส",
    Language: "ภาษา",
    Education: "การศึกษา",
    "Phone number": "หมายเลขโทรศัพท์",
    Email: "อีเมล",
    Address: "ที่อยู่",
    "National ID": "บัตรประจำตัวประชาชน",
    "Passport Number": "หมายเลขพาสปอร์ต",
    "Expires at": "วันหมดอายุ",
    "Measurements & Health": "สัดส่วน และ สุขภาพ",
    Weight: "น้ำหนัก",
    kg: "กิโลกรัม",
    lb: "ปอนด์",
    Height: "ส่วนสูง",
    cm: "เซนติเมตร",
    ft: "ฟุต",
    in: "นิ้ว",
    "Have undergone medical operation recently":
      "คุณเคยเข้ารับการผ่าตัดเมื่อไม่นานมานี้หรือไม่?",
    yes: "ใช่",
    no: "ไม่ใช่",
    "Work Experience": "ประสบการณ์การทำงาน",
    "{duration} years": "{duration} ปี",
    "Advanced Search": "ค้นหาขั้นสูง",
    "Workers who have more than 12 months of experience in this industry":
      "แรงงานที่มีประสบการณ์การทำงานมากกว่า 12 เดือนในกลุ่มอุตสาหกรรมนี้",
    Industry: "อุตสาหกรรม",
    "Workers {language} proficiency":
      "ความถนัดทางด้านภาษา {language} ของแรงงาน",
    "Language Proficiency": "ความถนัดทางด้านภาษา",
    "Workers current location": "ที่อยู่ปัจจุบันของแรงงาน",
    Province: "จังหวัด",
    "Application has been rejected": "ใบสมัครถูกปฏิเสธ",
    "An error occurred while rejecting application.":
      "เกิดข้อผิดพลาดขึ้นระหว่างปฏิเสธใบสมัคร",
    "A request for documents has been sent.": "คำขอดูเอกสารได้ถูกส่งไปแล้ว",
    "An error occurred while sending request for documents.":
      "เกิดข้อผิดพลาดระหว่างยื่นคำขอดูเอกสาร",
    "Job offer has been successfully sent.":
      "ข้อเสนอรับสมัครงานถูกส่งเรียบร้อยแล้ว",
    "An error occurred while sending job offer.":
      "เกิดข้อผิดพลาดระหว่างยื่นข้อเสนอรับสมัครงาน",
    "Application has been shortlisted": "ใบสมัครถูกรับไปพิจารณา",
    "An error occurred while shortlisting application.":
      "เกิดข้อผิดพลาดขึ้นระหว่างรับพิจารณาใบสมัคร",
    "Working Experience": "ประสบการณ์การทำงาน",
    "{experience} years": "{experience} ปี",
    View: "ดู",
    "Are you sure you would like to shortlist the application? This action cannot be undone later.":
      "คุณแน่ใจหรือไม่ว่าจะรับพิจารณาใบสมัครนี้? คุณจะไม่สามารถย้อนกลับมาแก้ไขได้อีก",
    Continue: "ต่อไป",
    Cancel: "ยกเลิก",
    Shortlist: "รับพิจารณา",
    "Are you sure you would like to send the job offer? This action cannot be undone later.":
      "คุณแน่ใจหรือไม่ที่จะยื่นข้อเสนอนี้? คุณจะไม่สามารถแก้ไขอะไรได้อีกเมือข้อเสนอถูกยื่นไปแล้ว",
    "Send offer": "ยื่นข้อเสนอ",
    "Are you sure you would like to request the documents? This action cannot be undone later":
      "คุณต้องการขอเอกสารใช่หรือไม่? คุณจะไม่สามารถย้อนกลับมาแก้ไขได้อีก",
    "Request documents": "ขอเอกสาร",
    "Please select one of the reasons below.": "โปรดเลือกเหตุผลที่กำหนด",
    "Other rejection reason": "เหตุผลอื่นในการปฏิเสธ",
    Reject: "ปฏิเสธ",
    new: "ใหม่",
    "{number} documents attached": "เอกสารที่แนบมา {number} ไฟล์",
    "Documents requested {timeAgo}": "ขอดูเอกสารไปแล้ว {timeAgo}",
    Yes: "ใช่",
    No: "ไม่ใช่",
    Passport: "พาสปอร์ต",
    Included: "รวมอยู่ด้วย",
    "Application has been waitlisted successfully and sent to application #{id}.":
      "การสมัครงานได้อยู่ในรายการรอเรียบร้อยแล้ว และส่งไปยังแอปพลิเคชัน #{id}",
    "An error occurred while waitlisting the application #{id}.":
      "เกิดข้อผิดพลาดขณะรายการรอการสมัคร #{id}",
    "Job offer Expiry Date has been successfully updated.":
      "วันที่หมดอายุของข้อเสนองานได้รับการอัปเดตเรียบร้อยแล้ว",
    "Roster Application": "ใบสมัครบัญชีรายชื่อ",
    "Offered will Expire at: ": "ข้อเสนอจะหมดอายุเมื่อ:",
    "Update Expiry Date": "อัพเดทวันหมดอายุ",
    "Are you sure you would like to move the selected applicants to waitlist? This action cannot be undone later.":
      "คุณแน่ใจหรือไม่ว่าต้องการย้ายผู้สมัครที่เลือกไปยังรายชการรอ? การดำเนินการนี้ไม่สามารถยกเลิกได้ในภายหลัง",
    "Move to waitlist": "ย้ายไปรายการรอ",
    From: "จาก",
    To: "ถึง",
    "Quick Filter": "ฟิลเตอร์แบบเร็ว",
    "Select Available Shift": "เลือกช่วงกะทำงาน",
    "{number} matches": "เจอ {number} งาน",
    "Select city": "เลือกเขต",
    "Select country": "เลือกประเทศ",
    "Select currency": "เลือกอัตราแลกเปลี่ยน",
    "Select Day of Week Off": "เลือกวันหยุดของแต่ล่ะสัปดาห์",
    "Select Level": "เลือกระดับ",
    "Select policy type": "เลือกประเภทของนโยบาย",
    "Select employer": "เลือกนายจ้าง",
    "Other/not listed here": "อื่น ๆ / ไม่มีอยู่ในลิสต์นี้",
    "Select size": "เลือกขนาด",
    "Select Industry": "เลือกกลุ่มอุตสาหกรรม",
    "Select position category": "เลือกประเภทของตำแหน่งงาน",
    "Select job type": "เลือกประเภทของงาน",
    "Select level": "เลือกระดับ",
    "Select Language": "เลือกภาษา",
    "Select Status": "เลือกสถานะ",
    "Select Overtime Expectations": "เลือกการทำงานล่วงเวลาที่คาดไว้",
    "Select terms": "เลือกเงื่อนไข",
    "Select timing": "เลือกระยะเวลา",
    "Select Reason": "เลือกเหตุผล",
    "Select state": "เลือกจังหวัด",
    "Select industry": "เลือกกลุ่มอุตสาหกรรม",
    Verification: "การยืนยัน",
    Account: "บัญชี:",
    "My jobs": "งานของฉัน",
    "Post new": "ประกาศรับสมัครงานใหม่",
    Resume: "เรซูเม่",
    Roster: "บัญชีรายชื่อ",
    Waitlist: "รายการรอ",
    Logout: "ออก",
    Metrics: "เมตริก",
    Surveys: "แบบสำรวจ",
    "Verify RA": "ยืนยันบริษัทจัดหางาน",
    "Verify Employers": "ยืนยันนายจ้าง",
    "My account": "บัญชีของฉัน",
    Profile: "โปรไฟล์",
    "This business is already managed by another user. Are you sure wanted to merge with this recruiter? This action cannot be undone later.":
      "ธุรกิจนี้ได้รับการจัดการโดยผู้ใช้รายอื่นแล้ว คุณแน่ใจหรือไม่ว่าต้องการรวมเข้ากับบริษัทจัดหางานรายนี้? การดำเนินการนี้ไม่สามารถยกเลิกได้ในภายหลัง",
    "This business is managed by Issara. Are you sure wanted to merge with this recruiter? This action cannot be undone later.":
      "ธุรกิจนี้บริหารโดยอิส่รา คุณแน่ใจหรือไม่ว่าต้องการรวมเข้ากับบริษัทจัดหางานรายนี้? การดำเนินการนี้ไม่สามารถยกเลิกได้ในภายหลัง",
    "Are you sure wanted to merge with this recruiter? This action cannot be undone later.":
      "คุณแน่ใจหรือไม่ว่าต้องการรวมเข้ากับบริษัทจัดหางานรายนี้? การดำเนินการนี้ไม่สามารถยกเลิกได้ในภายหลัง",
    "Yes. Merge": "ใช่ รวม",
    "This business is already in our database. Merge it.":
      "ธุรกิจนี้มีอยู่แล้วในฐานข้อมูลของเรา รวม",
    Merge: "รวม",
    Name: "ชื่อ",
    "Managed By": "จัดการโดย",
    "Businesses have been successfully merged":
      "รวมธุรกิจเข้าด้วยกันเรียบร้อยแล้ว",
    "An error occurred while merging. Please contact a developer.":
      "เกิดข้อผิดพลาดขณะการรวม โปรดติดต่อผู้พัฒนา",
    "Search ...": "ค้นหา...",
    "No employers found": "ไม่พบนายจ้าง",
    "recruiter loaded": "นายหน้าโหลดแล้ว",
    "employers loaded": "นายจ้างโหลดแล้ว",
    "Load More employers": "โหลดนายจ้างเพิ่ม",
    "License Number": "หมายเลขใบอนุญาต",
    "No recruiters found": "ไม่พบบริษัทจัดหางาน",
    "recruiters loaded": "บริษัทจัดหางานโหลดแล้ว",
    "Load More Recruiters": "โหลดบริษัทจัดหางานเพิ่ม",
    "Delete Cover Photo": "ลบรูปปก",
    "Add Cover Photo": "เพิ่มรูปปก",
    "Uploading....": "กำลังโหลด..",
    "Upload Cropped Cover Photo": "อัปโหลดรูปปกที่ครอบตัด",
    "Delete This Photo": "ลบรูปนี้",
    "Available image upload": "อัพโหลดรูปภาพที่มีอยู่",
    "Add business image": "เพิ่มรูปบริษัท",
    "Upload Cropped Image": "อัปโหลดรูปที่ครอบตัด",
    "Password reset link was successfully sent to {email}. Please check your inbox.":
      "การเปลี่ยนรหัสผ่านเสร็จสมบูรณ์ ลิงค์จะถูกส่งไปที่ {email} โปรดดูอินบ๊อกซ์ของคุณ",
    "Profile has been successfully updated.": "โปรไฟล์อัพเดทเสร็จสมบูรณ์",
    "An error occurred while updating profile. Please contact a developer.":
      "เกิดข้อผิดพลาดขึ้นระหว่างการอัพเดทโปรไฟล์ โปรดติดต่อทีมงานของเรา",
    "Please use one of the following formats: .jpeg, .jpg or .png":
      "โปรดใช้ไฟล์ตระกูล .jpeg .jpg หรือ .png",
    "Push notifications have been successfully enabled":
      "เปิดการแจ้งเตือนเสร็จสมบูรณ์",
    "Sample Notification": "ตัวอย่างการแจ้งเตือน",
    "You will receive me whenever new job application submitted or content added.":
      "คุณจะได้รับการแจ้งเตือนเมื่อมีผู้สมัครงานเข้ามาหรือมีการเพิ่มเติมเนื้อหาใหม่ ๆ",
    "If you blocked push notifications, you can only undo it in your browser settings.":
      "หากคุณปิดการแจ้งเตือน คุณสามารถเข้าไปแก้ไขในการตั้งค่าได้",
    "Push notifications have been successfully disabled":
      "ปิดการแจ้งเตือนเสร็จสมบูรณ์",
    "Verified Business": "บริษัทที่ได้รับการยืนยันแล้ว",
    Type: "ประเภท",
    Employer: "นายจ้าง",
    Joined: "เข้าร่วม",
    Updated: "อัพเดท",
    "Push notifications are not supported.":
      "ระบบการแจ้งเตือนไม่สามารถใช้งานได้",
    "Push notifications": "การแจ้งเตือน",
    "Are you sure you want to reset the password?":
      "คุณต้องการเปลี่ยนรหัสผ่านหรือไม่?",
    "The reset link will be sent to this email address: {email}":
      "ลิงค์ที่ใช้ในการเปลี่ยนจะส่งไปยังอีเมล: {email}",
    Reset: "เปลี่ยน",
    "Reset password": "เปลี่ยนรหัสผ่าน",
    "Enable view mode": "เปิดใช้งานโหมดดู",
    "Enable edit mode": "เปิดใช้งานโหมดแก้ไข",
    "Basic information": "ข้อมูลพื้นฐาน",
    Logo: "โลโก้",
    "Update logo": "อัพเดทโลโก้",
    "Company Name ({lang})": "ชื่อบริษัท ({lang})",
    "Please fill in the company name": "โปรดกรอกชื่อบริษัท",
    "Company Name": "ชื่อบริษัท",
    "Working since": "วันก่อตั้ง",
    "Registration number": "หมายเลขจดทะเบียน",
    "Max length of the registration number is 12":
      "ความยาวสูงสุดที่ใส่ได้ 12 ตัวอักษร",
    "Example: 027/2019": "ตัวอย่าง: 027/2019",
    Size: "ขนาด",
    "About us ({lang})": "เกี่ยวกับเรา ({lang})",
    "Contact information": "ข้อมูลการติดต่อ",
    "Main contact name": "ชื่อผู้ติดต่อ",
    "Main contact email": "อีเมลติดต่อ",
    "This is not a valid email.": "อีเมลที่ใส่ไม่ถูกต้อง",
    "Website (URL)": "Website (URL)",
    "Website(URL) format is incorrect": "Website(URL) ไม่ถูกต้อง",
    "Facebook (URL)": "Facebook (URL)",
    "Facebook url format is incorrect": "ชื่อ Facebook ที่ใส่ไม่ถูกต้อง",
    "Viber (phone number)": "Viber (หมายเลขโทรศัพท์)",
    "Please use the following format: +999999999":
      "โปรดใช้รูปแบบต่อไปนี้: +999999999",
    "Line (ID)": "Line (ID)",
    "Line format is incorrect": "ชื่อ Line ที่ใส่ไม่ถูกต้อง",
    "Phone numbers": "หมายเลขโทรศัพท์",
    "Add new": "เพิ่ม",
    "Country, State, City": "ประเทศ จังหวัด เขต",
    "Apt, Building, Street ({lang})": "เลขที่, หมู่, ถนน, ตำบล(แขวง) ({lang})",
    "Please fill in the address details": "โปรดระบุรายละเอียดของที่พัก",
    Zip: "รหัสไปรษณีย์",
    "Update Business Profile": "อัพเดทโปรไพล์ของคุณ",
    Description: "รายละเอียด",
    "Contact email": "อีเมลติดต่อ",
    Website: "Website",
    Facebook: "Facebook",
    Viber: "Viber",
    Line: "Line",
    Country: "ประเทศ",
    State: "จังหวัด",
    City: "เขต",
    "Apt, Building, Street": "ที่พัก อาคาร ถนน",
    "Application Details:": "รายละเอียดใบสมัคร:",
    Back: "กลับ",
    "Full Name": "ชื่อ - นามสกุล",
    "Phone Number": "หมายเลขโทรศัพท์",
    "Current Address": "ที่อยู่ปัจจุบัน",
    "Passport Expiry Date": "วันหมดอายุของพาสปอร์ต",
    "After going through the job description, do you think you can do the job well?":
      "หลังจากอ่านรายละเอียดของงานแล้ว คุณคิดว่าคุณสามารพทำงานนี้ได้ดีหรือไม่?",
    "Do you know someone working here?":
      "คุณรู้จักใครที่ทำงานในบริษัทนี้หรือไม่?",
    "Languages Proficiency": "ความถนัดทางด้านภาษา",
    "Work experience": "ประสบการณ์การทำงาน",
    Duration: "ระยะเวลา",
    Remark: "หมายเหตุ",
    "Uploaded Documents": "อัพโหลดเอกสาร",
    "Identification Card": "บัตรประจำตัว",
    "Birth Certificate": "ใบแจ้งเกิด",
    "Family Record Book": "สมุดบันทึกครอบครัว",
    "Residence Book": "หนังสือถิ่นที่อยู่",
    "Covid Vaccine Certificate": "ใบรับรองการฉีดวัคซีคโควิด",
    NRC: "NRC",
    "Household List": "รายชื่อครัวเรือน",
    "Passport (PJ)": "พาสปอร์ต (PJ)",
    "Labour Card": "บัตรแรงงาน",
    "Generating PDF ...": "กำลังสร้าง PDF ...",
    "Generate PDF & Download": "สร้าง PDF และดาวน์โหลด",
    "Download/Print": "ดาวน์โหลด/พิมพ์",
    "Are you sure you would like to request the documents? This action cannot be undone later.":
      "คุณต้องการที่จะขอดูเอกสารใช่หรือไม่? คุณจะไม่สามารถย้อนกลับมาแก้ไขได้อีก",
    "Request documents dfsdfwe": "ขอเอกสาร",
    "Application #{id} has been successfully rejected":
      "ใบสมัคร #{id} ถูกปฏิเสธเรียบร้อยแล้ว",
    "A request for documents for application #{id} has been sent":
      "คำขอดูเอกสารของผู้สมัคร #{id} ได้ถูกส่งไปแล้ว",
    "Application #{id} has been successfully contract signed":
      "ใบสมัคร #{id} ได้รับการลงนามในสัญญาเรียบร้อยแล้ว",
    "An error occurred while contract signing application #{id}.":
      "เกิดข้อผิดพลาดขณะลงนามในสัญญา #{id}",
    "Application #{id} has been successfully shortlisted":
      "ใบสมัคร #{id} ถูกรับไปพิจารณา",
    "An error occurred while shortlisting application #{id}.":
      "เกิดข้อผิดพลาดขึ้นระหว่างรับพิจารณาใบสมัคร #{id}",
    "Job offer has been successfully sent to application #{id}.":
      "ข้อเสนอรับสมัครงานถูกส่งไปยังผู้สมัคร #{id} เรียบร้อยแล้ว",
    "An error occurred while sending job offer to application #{id}.":
      "เกิดข้อผิดพลาดระหว่างยื่นข้อเสนอให้ผู้สมัคร #{id}",
    "Application has been moved to general waitlisted on application #{id}.":
      "แอปพลิเคชันถูกย้ายไปยังรายการรอทั่วไปในแอปพลิเคชัน #{id}",
    "An error occurred while moving the application #{id}. to general waitlist":
      "เกิดข้อผิดพลาดขณะย้ายแอปพลิเคชัน #{id} ไปยังรายการรอทั่วไป",
    "View Applications": "ดูใบสมัคร",
    "Pending ({count})": "รอ ({count}) ใบสมัคร",
    "Shortlisted ({count})": "รับพิจารณา ({count}) ใบสมัคร",
    "Waitlisted ({count})": "อยู่ในรายการรอแล้ว\n ({count}) ",
    "Offered ({count})": "ยื่นข้อเสนอไปแล้ว ({count}) ใบสมัคร",
    "Rejected by applicant ({count})": "ผู้สมัครปฏิเสธ ({count}) ใบสมัคร",
    "Rejected by business ({count})": "นายจ้างปฏิเสธ ({count}) ใบสมัคร",
    "Accepted ({count})": "รับ ({count}) ใบสมัคร",
    "Contract Signed ({count})": "เซ็นสัญญาแล้ว ({count})",
    "Select all ({number})": "เลือกทั้งหมด ({number})",
    "Deselect all ({number})": "ลบทั้งหมด ({number})",
    "Reject ({number})": "ปฏิเสธ ({number})",
    'Are you sure you would like to change the status to "Contract Signed" to the selected applications? This action cannot be undone later':
      'คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนสถานะเป็น "ลงนามในสัญญา" ของใบสมัครที่เลือก การดำเนินการนี้ไม่สามารถยกเลิกได้ในภายหลัง',
    "Contract Signed ({number})": "เซ็นสัญญาแล้ว ({number})",
    "Are you sure you would like to shortlist selected applications? This action cannot be undone later":
      "คุณแน่ใจหรือไม่ว่าจะรับพิจารณาใบสมัครนี้? คุณจะไม่สามารถย้อนกลับมาแก้ไขได้อีก",
    "Shortlist ({number})": "ใบสมัครที่รับพิจารณา ({number})",
    "Request documents ({number})": "ขอดูเอกสาร ({number})",
    "Move to waitlist ({number})": "ย้ายไปรายการรอ ({number})",
    "Are you sure you would like to send the job offer to selected applicants? This action cannot be undone later.":
      "คุณแน่ใจหรือไม่ที่จะยื่นข้อเสนอนี้ไปยังผู้สมัคร? คุณจะไม่สามารถแก้ไขอะไรได้อีกเมื่อข้อเสนอถูกยื่นไปแล้ว",
    "Send offer ({number})": "ยื่นข้อเสนอ ({number})",
    "Are you sure you would like to move the selected applicants to General Waitlist? This action cannot be undone later.":
      "คุณแน่ใจหรือไม่ว่าต้องการย้ายผู้สมัครที่เลือกไปยังรายการรอทั่วไป การดำเนินการนี้ไม่สามารถยกเลิกได้ในภายหลัง",
    "Move to General Waitlist ({number})": "ย้ายไปรายการรอทั่วไป ({number})",
    "Please select an offer expiry date": "โปรดเลือกวันหมดอายุของข้อเสนองาน",
    "Update Now": "อัพเดทตอนนี้",
    "Offer Now": "เสนองานตอนนี้",
    "Offer expiry date will be {expiry_date}":
      "วันหมดอายุของข้อเสนอจะเป็น {expiry_date}",
    "Resume Detail Information": "ข้อมูลรายละเอียดเรซูเม่",
    "Resume Information": "ข้อมูลเรซูเม่",
    "The job seeker has a National ID": "ผู้หางานมีบัตรประจำตัวประชาชน",
    "The job seeker does not have a National ID":
      "ผู้หางานไม่มีบัตรประจำตัวประชาชน",
    "The job seeker has passport": "ผู้หางานมีพาสปอร์ต",
    "The job seeker does not have a passport": "ผู้หางานไม่มีพาสปอร์ต",
    "Read Level": "ระดับการอ่าน",
    "Speak Level": "ระดับการพูด",
    "Applied At": "สมัครที่",
    Action: "ดำเนินการ",
    "Resume View": "ดูเรซูเม่",
    "General Waitlist": "รายการรอทั่วไป",
    Jobseekers: "ผู้หางาน",
    Previous: "ก่อนหน้า",
    Next: "ต่อไป",
    "Your job has been successfully posted": "ประการรับสมัครงานเสร็จสมบูรณ์",
    "View posted jobs": "ดูงานที่ประกาศรับสมัคร",
    "Post new job": "ประกาศรับสมัครงานใหม่",
    "An error occurred while saving draft.":
      "เกิดข้อผิดพลาดขึ้นระหว่างการบันทึก",
    "Job has been successfully saved as a draft":
      "ร่างประกาศรับสมัครงานถูกบันทึกเรียบร้อยแล้ว",
    "Create new job": "สร้างงานใหม่",
    "View drafts": "ดูร่าง",
    "Post job": "ประกาศรับสมัครงาน",
    "Open to": "เปิด",
    Nationality: "สัญชาติ",
    "Choose at least one nationality": "เลือกอย่างน้อย 1 สัญชาติ",
    Documents: "เอกสาร",
    Pinkcard: "บัตรสีชมพู",
    "Job Information": "รายละเอียดของงาน",
    "Job Title": "ตำแหน่งงาน",
    "Please enter job title": "โปรดระบุตำแหน่งงาน",
    "Job title": "ตำแหน่งงาน",
    "Please select industry": "โปรดเลือกกลุ่มอุตสาหกรรม",
    "Job position category": "ประเภทของตำแหน่งงาน",
    "Job type": "ประเภทของงาน",
    "Please select job type": "โปรดระบุประเภทของงาน",
    "Expiry date": "วันหมดอายุ",
    "Please choose expire date": "โปรดเลือกวันหมดอายุ",
    "Headcount needed": "จำนวนแรงงานที่ต้องการ",
    "Please input headcount needed": "โปรดระบุจำนวนแรงงานที่ต้องการ",
    "Number of employees for this position you would like to recruit":
      "จำนวนพนักงานที่คุณต้องการจ้างในตำแหน่งนี้",
    "Contract duration": "ระยะเวลาของสัญญา",
    Months: "เดือน",
    "Please input duration": "โปรดระบุระยะเวลา",
    "Duration of contract, in months":
      "ระยะเวลาของสัญญา, โปรดระบุเป็นจำนวนเดือน",
    Location: "สถานที่",
    "Please select country": "โปรดเลือกประเทศ",
    "The country of workplace": "สถานที่ทำงานตั้งอยู่ในประเทศ",
    "Please select state": "โปรดเลือกจังหวัด",
    "Please select city": "โปรดเลือกเขต",
    "{language} translation": "{language} แปล",
    "Please enter title": "โปรดระบุตำแหน่ง",
    "Name of the recruited job": "ชื่อของตำแหน่งงานที่จ้าง",
    "Position details": "รายละเอียดของตำแหน่งงาน",
    "Please enter position details": "โปรดระบุรายละเอียดของตำแหน่งงาน",
    "Job Description": "รายละเอียดของงาน",
    "Please enter job description": "โปรดระบุรายละเอียดของงาน",
    "Please describe the responsibilities and environment of this position":
      "โปรดระบุความรับผิดชอบและสภาพแวดล้อมของงานตำแหน่งนี้",
    "Applicant Requirements": "คุณสมบัติของผู้สมัคร",
    "Please enter applicant requirements":
      "โปรดระบุคุณสมบัติของผู้สมัครที่ต้องการ",
    "Please describe the prerequisite skills or qualifications needed to do this job sucessfully, if any":
      "โปรดระบุทักษะหรือความสามารถที่ต้องการในการทำงาน (ถ้ามี)",
    "Contact name": "ชื่อผู้ติดต่อ",
    "Please enter contact name": "โปรดระบุชื่อผู้ติดต่อ",
    "Please provide the name of the contact person for {language} applicants":
      "โปรดระบุชื่อผู้ติดต่อสำหรับผู้สมัคร{language}",
    "Please enter contact phone number": "โปรดระบุหมายเลขโทรศัพท์",
    "Please provide the phone number of the contact person for {language} applicants":
      "โปรดระบุหมายเลขโทรศัพท์ที่ใช้ในการติดต่อสำหรัลผู้สมัคร {language}",
    "Contact job title": "ติดต่อเจ้าหน้าที่",
    "Please select contact job title": "โปรดระบุเจ้าหน้าที่ที่รับการติดต่อ",
    "Recruitment Information": "ข้อมูลเกี่ยวกับการจ้างงาน",
    "Do you have an ethical recruitment policy?":
      "คุณมีนโยบายเกี่ยวกับการจ้างงานอย่างมีจริยธรรมหรือไม่",
    "Ethical recruitment policies specify more transparent and ethical conduct in the recruitment of workers":
      "นโยบายด้านการจ้างงานอย่างมีจริยธรรมกำหนดให้กระบวนการในการจ้างงานมีความโปร่งใสและมีจริยธรรมมากขึ้นสำหรับแรงงาน",
    "Do you have an employer pays policy?":
      "คุณมีนโยบายนายจ้างเป็นผู้รับผิดชอบค่าใช้จ่ายในการจ้างงานหรือไม่?",
    "Employer pays policies specify that all costs of recruitment are paid by the employer, and it is guaranteed that zero fees will be charged to workers, including for document processing and fees to recruiters":
      "นโยบายนายจ้างเป็นผู้รับผิดชอบค่าใช้จ่ายในการจ้างงานกำหนดไว้ว่านายจ้างจะจ่ายค่าใช้จ่ายทั้งหมดที่เกี่ยวของกับการจ้างงาน โดยที่แรงงานจะไม่ต้องเสียค่าใช้จ่ายใด ๆ รวมถึงค่าธรรมเนียมในการดำเนินการด้านเอกสารที่จ่ายให้กับนายหน้าจัดหางาน",
    "Employer Pays Policy Type":
      "ประเภทของนโยบายนายจ้างเป็นผู็รับผิดชอบค่าใช้จ่ายในการจ้างงาน",
    "Please select policy type": "โปรดเลือกประเภทของนโยบาย",
    "Who pays the visa fees?": "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมในการออกวีซ่า?",
    Worker: "แรงงาน",
    Amount: "จำนวน",
    "Who pays the work permit fees?":
      "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมในการออกใบอนุญาตทำงาน",
    "Who pays the medical checkup fees?":
      "ใครเป็นผู้รับผิดชอบค่าใช้จ่ายในการตรวจสุขภาพ?",
    "Contract Information: Pay": "ข้อมูลในสัญญา: การจ่ายเงิน",
    "Terms of payment": "เงื่อนไขในการจ่ายเงิน",
    "Please select terms of payment": "โปรดระบุเงื่อนไขในการจ่ายเงิน",
    "Please note whether the employee will be paid according to a daily or monthly rate, and how frequently they will be paid":
      "โปรดระบุว่าลูกจ้างจะได้รับค่าจ้างในอัตรารายวันหรือรายเดือน และความถี่ในการจ่ายค่าจ้าง",
    "Timing of payment": "ช่วงเวลาในการจ่ายเงิน",
    "Please select or create timing of payment":
      "โปรดระบุุหรือกำหนดช่วงเวลาในการจ่ายเงิน",
    "Please note whether the employee will be paid according to once a month or twice a month rate, and how frequently they will be paid":
      "โปรดระบุความถี่ในการจ่ายค่าจ้าง 1 ครั้งต่อเดือน หรือ 2 ครั้งต่อเดือน",
    "Timing of Payment Other": "ช่วงเวลาในการจ่ายเงิน",
    "Please input Timing of Payment Other":
      "โปรดระบุุช่วงเวลาอื่นในการจ่ายเงิน",
    "Other type of Payment Timing": "ช่วงเวลาอื่นในการจ่ายเงิน",
    "Enter Timing of Payment Other": "ระบุช่วงเวลาในการจ่ายเงิน",
    Currency: "อัตราแลกเปลี่ยน",
    "Please select currency": "โปรดเลือกอัตราแลกเปลี่ยน",
    "Monthly salary": "เงินเดือน",
    "{currency}/month": "{currency}/เดือน",
    "Please input salary": "โปรดระบุเงินเดือน",
    "Monthly payment to employee, which must be equal to or greater than the minimum wage for the province":
      "อัตราค่าจ้างรายเดือนเท่ากับหรือมากกว่าค่าจ้างขั้นต่ำที่กำหนดไว้ในจังหวัด",
    "Daily Wage": "ค่าจ้างรายวัน",
    "{currency}/day": "{currency}/วัน",
    "Please input daily wage rate": "โปรดระบุอัตราค่าจ้างรายวัน",
    "Daily wage amount for 8 hours of work, equal to or greater than the minimum daily wage for the province":
      "อัตราค่าจ้างรายวันสำหรับการทำงาน 8 ชั่วโมง เท่ากับหรือมากกว่าอัตราค่าจ้างรายวันที่กำหนดไว้ในจังหวัด",
    "Available Shifts": "ช่วงกะทำงานที่ว่าง",
    "What shift is this position expected to offer?":
      "ตำแหน่งนี้จะต้องทำงานในช่วงกะการทำงานใด?",
    "These are the available shift choices the position offers.":
      "ช่วงเวลากะการทำงานสำหรับงานตำแหน่งนี้",
    "Overtime Expectation": "การทำงานล่วงเวลาที่คาดไว้",
    "How often is this position expected to offer voluntary OT?":
      "ความถี่ในการทำงานล่วงเวลาที่คาดหวังไว้สำหรับตำแหน่งนี้?",
    "How often the position will require overtime.":
      "ความถี่ของการทำงานล่วงเวลาในตำแหน่งนี้?",
    "Day of Week Off": "วันหยุดประจำสัปดาห์",
    "What day of the week is the day off for this position?":
      "วันหยุดประจำสัปดาห์ของตำแหน่งนี้คือ?",
    "Helps workers to understand how fixed their schedule will be.":
      "ช่วยเหลือแรงงานให้เข้าใจตารางเวลาการทำงานของแรงงาน",
    "Minimum Working Hours": "จำนวนชั่วโมงขั้นต่ำในการทำงาน",
    "What are the minimum number of hours per week expected for this position?":
      "ชั่วโมงการทำงานขั้นต่ำต่อสัปดาห์สำหรับตำแหน่งนี้",
    "Minimum number of hours per week expected for this position?":
      "จำนวนชั่วโมงการทำงานขั้นต่ำต่อสัปดาห์สำหรับตำแหน่งนี้",
    "Regular OT": "ทำงานล่วงเวลาปกติ",
    "Please input regular OT rate": "โปรดระบุอัตราค่าทำงานล่วงเวลาปกติ",
    "{currency}/hour": "{currency}/ชั่วโมง",
    "Payment rate per hour for overtime worked on a normal working day to employee, which by law must be at least 1.5x regular hourly rate":
      "อัตราค่าจ้างในการทำงานล่วงเวลาในวันทำงานปกติ กฎหมายกำหนดให้จ่ายอย่างต่ำ 1.5 เท่าของอัตราค่าจ้างปกติ",
    "Day-Off Rate": "อัตราค่าจ้างการทำงานในวันหยุด",
    "Please input normal working hours rate during holiday":
      "โปรดระบุอัตราค่าจ้างรายชั่วโมงในการทำงานช่วงวันหยุด",
    "Payment rate for working on the normally scheduled day off must be at least 2x the daily wage rate according to law":
      "อัตราค่าจ้างในการทำงานในวันหยุดประจำสัปดาห์ กฎหมายกำหนดให้จ่ายในอัตรา 2 เท่าของค่าจ้างปกติ",
    "Holiday OT": "ทำงานล่วงเวลาในวันหยุดประจำปี",
    "Please input Day-Off Rate":
      "โปรดระบุอัตราค่าจ้างในการทำงานในวันหยุดประจำสัปดาห์",
    "Payment rate per hour for overtime worked on a holiday, which by law must be at least 3x regular hourly rate":
      "อัตราค่าจ้างในการทำงานล่วงเวลาในวันหยุดประจำปี/วันลาของพนักงาน กฎหมายกำหนดให้จ่ายค่าจ้างในอัตรา 3 เท่าของค่าจ้างปกติ",
    Benefits: "สิทธิประโยชน์",
    "How many days of paid annual leave are provided per year?":
      "มีการจัดวันลาพักผ่อนประจำปี (โดยได้รับค่าจ้าง) ให้กี่วันต่อปี?",
    "Please input number": "โปรดระบุจำนวน",
    "How many days of paid sick leave are provided per year?":
      "มีการจัดวันลาป่วย (โดยได้รับค่าจ้าง) ให้กี่วันต่อปี?",
    "How many days of paid business leave are provided per year?":
      "มีการจัดวันลากิจ (โดยได้รับค่าจ้าง) ให้กี่วันต่อปี?",
    "How many days of paid national holidays are provided per year?":
      "มีการจัดวันหยุดประจำปีให้กี่วันต่อปี?",
    "Are social security and maternity leave benefits provided as required by law?":
      "มีการให้สิทธิประกันสังคมและสิทธิประโยชน์ในการลาคลอดตามกฎหมายหรือไม่?",
    "Yes, these benefits are provided":
      "มี สิทธิประกันสังคมและสิทธิประโยชน์ในการลาคลอดตามกฎหมาย",
    "No, these benefits are not provided":
      "ไม่มี สิทธิประกันสังคมและสิทธิประโยชน์ในการลาคลอดตามกฎหมาย",
    "Benefits details": "รายละเอียดของสิทธิประโยชน์",
    Accommodation: "ที่พัก",
    "Accommodation is on site": "ที่พักอยู่ในโรงงาน",
    "Family members can live together": "สมาชิกในครอบครัวสามารถอยู่ด้วยกันได้",
    "Rent/utilities auto deducted from payroll":
      "ค่าเช่า/ค่าน้ำ ค่าไฟ ถูกหักออกจากเงินเดือนโดยอัตโนมัติ",
    "Accommodation details": "รายละเอียดของที่พัก",
    "Please fill in accommodation details": "โปรดระบุรายละเอียดของที่พัก",
    "Living arrangement details for the employees":
      "รายระเอียดในการจัดที่พักสำหรับพนักงาน",
    "Rent/utilites deduction details": "รายละเอียยของค่าเช่า/ค่าน้ำ ค่าไฟ",
    "Please fill in rent details": "โปรดระบุรายละเอียดของค่าเช่า",
    "Safe, grievance mechanism": "ความปลอดภัย กลไกการร้องทุกข์",
    "Probation period": "ระยะเวลาการทดลองงาน",
    "Grievance mechanism details": "รายละเอียดของกลไกการร้องทุกข์",
    "Please input grievance mechanism details":
      "โปรดระบุรายละเอียดของกลไกการร้องทุกข์",
    "Probation period details": "รายละเอียดของระยะเวลาการทดลองงาน",
    "Please input probation period details":
      "โปรดระบุรายละเอียดของระยะเวลาทดลองงาน",
    Preview: "ดูตัวอย่าง",
    "Save draft": "บันทึกร่าง",
    Post: "ประกาศ",
    "You cannot make changes after posting the job. Are you sure you want to post this job?":
      "คุณไม่สามารถเปลี่ยนแปลงรายละเอียดได้เมื่อประกาศรับสมัครงานไปแล้ว คุณต้องการที่จะประกาศรับสมัครงานนี้หรือไม่?",
    "Job Preview": "ดูตัวอย่าง",
    "General Information": "ข้อมูลทั่วไป",
    "No information were provided": "ไม่มีข้อมูล",
    "Open To": "เปิด",
    "No of Headcount Needed": "จำนวนแรงงานที่ต้องการ",
    "Job will be expired on": "ประกาศรับสมัครงานจะสิ้นสุดลงในวันที่",
    "Pink Card Holder": "ผู้ถือบัตรชมพู",
    "Passport Holder": "ผู้ถือพาสปอร์ต",
    "Job Position Category": "ประเภทของงานที่สมัคร",
    "Job Type": "ประเภทของงาน",
    "Contract Duration": "ระยะเวลาของสัญญา",
    "{duration} months": "{duration} เดือน",
    "Job Position Details": "รายละเอียดของตำแหน่งงานที่สมัคร",
    "Job Requirements": "คุณสมบัติที่ต้องการ",
    "Have an ethical recruitment policy": "มีนโยบายการจ้างงานอย่างมีจริยธรรม",
    "Have an employer pays policy":
      "มีนโยบายนายจ้างเป็นผู้รับผิดชอบค่าธรรมเนียมในการจ้างงาน",
    "Who pays visa fees?": "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมในการออกวีซ่า?",
    "Who pays work permit fees?":
      "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมในการออกใบอนุญาตทำงาน?",
    "Who pays medical checkup fees?":
      "ใครเป็นผู้รับผิดชอบค่าใช้จ่ายในการตรวจสุขภาพ?",
    "Terms of Payment": "เงื่อนไขในการจ่ายเงิน",
    "Timing of Payment": "ช่วงเวลาในการจ่ายเงิน",
    Salary: "เงินเดือน",
    "OT Rate Regular": "อัตราค่าทำงานล่วงเวลาปกติ",
    "OT Rate Holiday": "อัตราค่าทำงานล่วงเวลาในวันหยุด",
    "Holiday Rate": "อัตรทำงานล่วงเวลาในวันหยุด",
    "Other Benefits": "สิทธิประโยชน์อื่น ๆ",
    "Benefits Details": "รายละเอียดของสิทธิประโยชน์",
    "Other Information": "ข้อมูลอื่น ๆ",
    "Accommodation on-site is available": "มีที่พักในพื้นที่โรงงาน",
    "Rent & utilities autodeducted from payroll":
      "ค่าเช่า/ค่าน้ำ ค่าไฟ ถูกหักออกจากเงินเดือนโดยอัตโนมัติ",
    "Safe & functioning grievance mechanism": "ความปลอดภัย และกลไกการร้องทุกข์",
    "Accommodation Details": "รายละเอียดของที่พัก",
    "Contact Information": "ข้อมูลการติดต่อ",
    Edit: "แก้ไข",
    "Job has been successfully archived.":
      "ประกาศรับสมัครงานถูกจัดเก็บเรียบร้อยแล้ว",
    "An error occurred while archiving draft.":
      "เกิดข้อผิดพลาดระหว่างจัดเก็บร่างประกาศรับสมัครงาน",
    "Job has been successfully deleted.": "ประกาศรับสมัครงานถูกลบเรียบร้อยแล้ว",
    "An error occurred while deleting job.":
      "เกิดข้อผิดพลาดขึ้นระหว่างการรลบประกาศรับสมัครงาน",
    "Recruitment has been successfully stopped.":
      "ปิดรับการรับสมัครงานเรียบร้อยแล้ว",
    "An error occurred while stopping recruitment.":
      "เกิดข้อผิดพลาดขึ้นระหว่างการการปิดการรับสมัครงาน",
    "Job has been successfully set to offline.":
      "งานถูกสร้างแบบออฟไลน์เรียบร้อยแล้ว",
    "An error occurred while setting job to offline.":
      "เกิดข้อผิดพลาดระหว่างการสร้างงานแบบออฟไลน์",
    "Job has been successfully set to online.":
      "งานถูกสร้างแบบออนไลน์เรียบร้อยแล้ว",
    "An error occurred while setting job to online.":
      "เกิดข้อผิดพลาดระหว่างการสร้างงานแบบออนไลน์",
    Title: "ตำแหน่ง",
    Posted: "ประกาศ",
    Expires: "วันสุดท้ายของการรับสมัคร",
    Views: "ดูประวัติ",
    Applicants: "ผู้สมัคร",
    "Filled Vacancies": "จำนวนผู้สมัครที่ได้รับการคัดเลือกไปแล้ว",
    Online: "ออนไลน์",
    "Are you sure you would like to end the recruitment? This action cannot be undone later!":
      "คุณต้องการปิดรับสมัครงานใช่หรือไม่? คุณจะไม่สามารถย้อนกลับมาแก้ไขได้อีก",
    "End recruitment": "ปิดการรับสมัครงาน",
    "Are you sure you would like to archive this job?":
      "คุณต้องการจะเก็บประกาศรับสมัครงานนี้หรือไม่?",
    Archive: "เก็บ",
    Saved: "บันทึก",
    Archived: "เก็บ",
    "Are you sure you would like to delete this job?":
      "คุณต้องการที่จะลบประกาศรับสมัครงานี้?",
    Delete: "ลบ",
    "View Jobs:": "ดูงานที่ประกาศรับสมัคร:",
    "Posted ({count})": "ประกาศไปแล้ว ({count}) งาน",
    "Closed ({count})": "ปิดรับสมัครไปแล้ว ({count}) งาน",
    "Drafts ({count})": "ร่าง ({count}) งาน",
    "Archived ({count})": "เก็บ ({count}) งาน",
    Workpermit: "ใบอนุญาตทำงาน",
    Visa: "วีซ่า",
    "Cancellation Letter": "แบบแจ้งคนต่างด้าวออกจากงาน",
    "Position category": "ประเภทของตำแหน่งงาน",
    "Please select timing of payment": "โปรดระบุุช่วงเวลาในการจ่ายเงิน",
    "Please input Day-Off OT":
      "โปรดระบุอัตราค่าจ้างในการทำงานในวันหยุดประจำสัปดาห์",
    "Successfully shortlisted the resume with job!":
      "ช็อตลิสต์เรซูเม่กับงานได้สำเร็จ!",
    "Error while shortlisting the resume.": "เกิดข้อผิดพลาดขณะช็อตลิสต์เรซูเม่",
    "Moved to general waitlist": "ย้ายไปรายการรอทั่วไป",
    "Are you sure you would like to shortlist this job seeker? This action cannot be undone later!":
      "คุณแน่ใจหรือไม่ว่าจะช็อตลิต์ผู้หางานนี้? คุณจะไม่สามารถย้อนกลับมาแก้ไขได้อีก",
    "Shortlist now": "ช็อตลิสต์ตอนนี้",
    Close: "ปิด",
    "Please Choose A Job to shortlist the Job seeker":
      "โปรดเลือกงานเพื่อช็อตลิสต์ผู้หางาน",
    "Resume Detail": "รายละเอียดเรซูเม่",
    "Shortlisting...": "กำลังช็อตลิสต์...",
    "Successfully shortlisted the resume !": "ช็อตลิสต์เรซูเม่เรียบร้อยแล้ว",
    "Search name": "ค้นหาชื่อ",
    "Search Phone Number": "ค้นหาหมายเลขโทรศัพท์",
    "Detail View": "ดูรายละเอียด",
    "Shortlisted At": "ช็อตลิสต์แล้วที่",
    "Viewed At": "เข้าเยี่ยมชมแล้วที่",
    "Applied resumes": "เรซูเม่ที่สมัคร",
    "Shortlisted resumes": "เรซูเม่ที่ได้รับการช็อตลิสต์",
    "Viewed resumes": "เรซูเม่ที่ได้รับการเข้าเยี่ยมชม",
    "Fetching...": "ค้นหา...",
    "Shortlist with JOBS": "ช็อตลิสต์เข้ากับงาน",
    "Are you sure you would like to shortlist ? This action cannot be undone later!":
      "คุณแน่ใจหรือไม่ว่าจะช็อตลิสต์ใบสมัครนี้? คุณจะไม่สามารถย้อนกลับมาแก้ไขได้อีก",
    "Account Verification Status": "สถานะการยืนยันบัญชี",
    Registration: "ลงทะเบียน",
    "Fill out the registration form": "โปรดกรอกใบลงทะเบียน",
    "Email Verification": "การยืนยันทางอีเมล",
    "Please verify your email using the link sent to {email}":
      "โปรดยืนยันอีเมลของคุณ โดยใช้ลิงค์ที่ส่งไปที่ {email}",
    "Business Verification": "การยืนยันธุรกิจ",
    "Issara will contact you to verify that you are actually the owner of this business":
      "ทางสถาบันอิสราจะติดต่อกลับเพื่อยืนยันกับคุณว่าคุณเป็นเจ้าของธุรกิจนี้",
    Actions: "ดำเนินการ",
    "Are you sure this business is not in our database yet?":
      "คุณแน่ใจหรือว่าธุรกิจนี้ไม่ได้อยู่ในฐานข้อมูลของเรา?",
    "This business is not in our database yet. Verify as new.":
      "ธุรกิจนี้ยังไม่อยู่ในฐานข้อมูลของเรา ตรวจสอบว่าเป็นธุรกิจใหม่",
    "Are you sure you want to reject verification and delete this user?":
      "คุณแน่ใจหรือไม่ว่าต้องการปฏิเสธการยืนยันและลบผู้ใช้รายนี้",
    "The user couldn't prove ownership of the business. Reject it.":
      "ผู้ใช้ไม่สามารถพิสูจน์ความเป็นเจ้าของธุรกิจได้ ปฏิเสธ",
    "Owner's Email": "อีเมลล์เจ้าของ",
    Registered: "ลงทะเบียนแล้ว",
    "Business has been successfully verified":
      "ธุรกิจได้รับการยืนยันเรียบร้อยแล้ว",
    "An error occurred while verifying business. Please contact a developer.":
      "เกิดข้อผิดพลาดขึ้นระหว่างการยืนยันธุรกิจ โปรดติดต่อทีมงานของเรา",
    "Business has been successfully removed": "ลบธุรกิจเรียบร้อยแล้ว",
    "An error occurred while rejecting account. Please contact a developer.":
      "เกิดข้อผิดพลาดขึ้นระหว่างการปฏิเสธบัญชี โปรดติดต่อทีมงานของเรา",
    "Merge Dialogue": "รวมบทสนทนา",
    "To be merged": "ที่จะนำมารวมกัน",
    Into: "เข้าไปข้างใน",
    "Awaiting Employers": "นายจ้างที่รออยู่",
    "Total ({count})": "รวมทั้งหมด ({count}) ",
    "No awaiting employers to verify": "ไม่มีนายจ้างที่รออยู่เพื่อยืนยัน",
    "Previously verified employers": "นายจ้างที่ได้รับการยืนยันก่อนหน้านี้",
    "No previously verified employers":
      "ไม่มีนายจ้างที่ได้รับการยืนยันก่อนหน้านี้",
    Verify: "ยืนยัน",
    "Awaiting Recruitment agencies": "บริษัทจัดหางานที่รออยู่",
    "No businesses awaiting verification": "ไม่มีธุรกิจที่รอการยืนยัน",
    "Previously verified Recruiters":
      "บริษัทจัดหางานที่ได้รับการยืนยันก่อนหน้านี้",
    "No previously verified businesses":
      "ไม่มีธุรกิจที่ได้รับการยืนยันก่อนหน้านี้",
    "Survey Details": "รายละเอียดของแบบสำรวจ",
    "(Burmese)": "(พม่า)",
    "(English)": "(อังกฤษ)",
    "(Nepali)": "(เนปาล)",
    "(Thailand)": "(ไทย)",
    "(Khmer)": "(เขมร)",
    "Update/Post job": "อัปเดท/โพสต์งาน",
    "This job is for the country ! ({country})":
      "งานนี้เป็นของประเทศ! ({ประเทศ})",
    "Need to choose a country for this job!": "ต้องเลือกประเทสสำหรับงานนี้",
    "Please select the sending country !": "โปรดเลือกประเทศที่จะไป",
    Malaysia: "มาเลเซีย",
    UAE: "สหรัฐอาหรับเอมิเรตส์",
    "Employer Information": "ข้อมูลนายจ้าง",
    "Please select employer": "โปรดเลือกนายจ้าง",
    "Please input employer's name": "โปรดระบุชื่อนายจ้าง",
    "The name of the Employer": "ชื่อนายจ้าง",
    "The country where the workplace is located":
      "สถานที่ทำงานตั้งอยู่ในประเทศ",
    "The state where the workplace is located": "สถานที่ทำงานตั้งอยู่ในรัฐ",
    "The city where the workplace is located": "สถานที่ทำงานตั้งอยู่ในเมือง",
    "Please select size": "โปรดเลือกขนาด",
    "Nationalities of Workers working at this workplace":
      "สัญชาติของแรงงานที่ทำงานอยู่ในสถานที่ทำงานนี้",
    "Please specify the nationalities of workers working at this workplace.":
      "โปรดระบุสัญชาติของแรงงานที่ทำงานอยู่ในสถานที่ทำงานนี้",
    "Headcount by Genders": "จำนวนพนักงานแยกตามเพศ",
    Genders: "เพศ",
    "Male headcount needed": "จำนวนแรงงานชายที่ต้องการ",
    "Female headcount needed": "โปรดระบุจำนวนแรงงานหญิงที่ต้องการ",
    "Demand Pre-approval date": "วันที่อนุมัติล่วงหน้าของการขอดีมานด์",
    "Demand Approved Date": "วันที่อนุมัติการขอดีมานด์",
    "Please choose demand pre-approval date":
      "โปรดเลือกวันที่อนุมัติล่วงหน้าของการขอดีมานด์",
    "Please choose demand approved date": "โปรดเลือกวันที่อนุมัติการขอดีมานด์",
    "LT number": "หมายเลข LT",
    "Demand number": "หมายเลขดีมานด์",
    "Please enter LT number": "โปรดใส่หมายเลข LT",
    "Please enter demand number": "โปรดใส่หมายเลขดีมานด์",
    "Application Closed Date": "วันที่ปิดรับสมัคร",
    "Please choose Application Closed date": "โปรดเลือกวันที่ปิดรับสมัคร",
    "Email to receive notifications for this job announcement":
      "อีเมลเพื่อรับการแจ้งเตือนสำหรับประกาศงานนี้",
    "Please enter a valid email address": "โปรดใส่อีเมลล์ที่ติดต่อได้",
    "ER Information": "รายละเอียด ER",
    "Does the employer have an ethical recruitment policy?":
      "นายจ้างมีนโยบายการสรรหาจ้างแรงงานอย่างมีจริยธรรมหรือไม่?",
    Have: "มี",
    "Not Have": "ไม่มี",
    "Does the RA have an ethical recruitment policy?":
      "บริษัทจัดหางานนี้มีนโยบายเกี่ยวกับการจ้างงานอย่างมีจริยธรรมหรือไม่",
    "Does the RA have an ethical recruitment policy? is required":
      "บริษัทจัดหางานนี้มีนโยบายเกี่ยวกับการจ้างงานอย่างมีจริยธรรมหรือไม่ เป็นสิ่งจำเป็น",
    "Does the employer have an employer pays policy?":
      "นายจ้างนี้มีนโยบายนายจ้างเป็นผู้รับผิดชอบค่าใช้จ่ายในการจ้างงานหรือไม่?",
    "Monthly salary ({currency}/month)": "เงินเดือน (\n{currency}/เดือน)",
    "How much will be provided for meals allowance?": "ได้รับค่าอาหารเท่าไร?",
    "Please enter a meal allowance.": "โปรดใส่ค่าอาหาร",
    "Please enter a meal allowance of no less than 300 AED.":
      "โปรดใส่ค่าอาหารไม่น้อยกว่า 300 AED",
    "Any additional leave ?": "มีวันลาเพิ่ม?",
    "Add Detail Here": "ใส่รายละเอียดที่นี่",
    "Any additional leave ? (This is optional)": "มีวันลาเพิ่ม? (ไม่บังคับ)",
    "Add detail here (optional)": "ใส่รายละเอียดที่นี่ (ไม่บังคับ)",
    "Are social security benefits provided as required by law?":
      "มีการให้สิทธิประโยชน์ด้านประกันสังคมตามที่กฎหมายกำหนดหรือไม่?",
    "Skill training information": "ข้อมูลการอบรมทักษะ",
    "Is skill training required for this job?":
      "งานนี้จำเป็นต้องมีการฝึกอบรมทักษะหรือไม่?",
    "if so, who will cover those costs?": "ถ้ามี ใครเป็นคนครอบคลุมค่าใช้จ่าย",
    "if workwes have to pay, please provide the amount they should pay":
      "ถ้าแรงงานเป็นคนจ่าย กรุณาระบุค่าใช้จ่าย",
    "Cost of skill-training?": "ค่าใช้จ่ายการฝึกอบรม",
    "Cost of skill-training? is required": "ค่าใช้จ่ายการฝึกอบรม จำเป็น",
    "Accommodation  during skill training?": "ที่พักระหว่างการฝึกอบรม",
    "Please specify, does the employer provide accommodation during skill-training of Workers":
      "โปรดระบุ นายจ้างมีที่พักสำหรับแรงงานระหว่างการฝึกอบรมหรือไม่",
    "Accommodation  during skill training? is required":
      "ที่พักระหว่างการฝึกอบรม จำเป็น",
    "Meals during skill training?": "อาหารระหว่างการฝึกอบรม",
    "Please specify, does the employer provide meals during skill-training of Workers":
      "โปรดระบุ รายจ้างมีอาหารสำหรับแรงงานระหว่างการฝึกอบรมหรือไม่",
    "Meals during skill training? is required": "อาหารระหว่างการฝึกอบรม จำเป็น",
    "Based on individual cost/spending.":
      "ขึ้นอยู่กับต้นทุน/รายจ่ายของแต่ละบุคคล",
    "Transportation costs to and from for attending skill training?":
      "มีค่าเดินทางไปกลับสำหรับการฝึกอบรมหรือไม่",
    "Transportation costs to and from for attending skill training? is required":
      "มีค่าเดินทางไปกลับสำหรับการฝึกอบรมหรือไม่ จำเป็น",
    "Brief description of skill training": "คำอธิบายโดยย่อของการฝึกทักษะ",
    "Please fill the brief description of skill training":
      "โปรดเติมคำอธิบายโดยย่อของการฝึกทักษะ",
    "Costs for Pre-departure": "ค่าใช้จ่ายก่อนออกเดินทาง",
    "Is pre-departure orientation training required for this job?":
      "การอบรมก่อนการเดินทางจำเป็นสำหรับงานนี้หรือไม่",
    "Cost of pre-departure orientation training?":
      "ค่าใช้จ่ายสำหรับการอบรมก่อนการเดินทาง",
    "Cost of pre-departure orientation training? is required":
      "ค่าใช้จ่ายสำหรับการอบรมก่อนการเดินทาง จำเป็น",
    "Accommodation during pre-departure orientation training?":
      "มีที่พักสำหรับการอบรมก่อนการเดินทางหรือไม่",
    "Please specify, does the employer provide accommodation during pre-departure orientation training of Workers":
      "โปรดระบุ นายจ้างมีที่พักสำหรับแรงงานในการอบรมก่อนการเดินทางหรือไม่",
    "Accommodation during pre-departure orientation training? is required":
      "มีที่พักระหว่างการอบรมก่อนการเดินทาง จำเป็น",
    "Meals during pre-departure orientation training?":
      "มีอาหารระหว่างการอบรมก่อนการเดินทางหรือไม่",
    "Please specify, does the employer provide meals during pre-departure orientation training of Workers":
      "โปรดระบุ นายจ้างมีอาหารสำหรับแรงงานระหว่างการอบรมก่อนการเดินทางหรือไม่",
    "Meals during pre-departure orientation training? is required":
      "มีอาหารระหว่างการอบรมก่อนการเดินทางหรือไม่ จำเป็น",
    "Transportation costs to and from pre-departure orientation training?":
      "มีค่าเดินทางไปกลับระหว่างการอบรมก่อนการเดินทางหรือไม่",
    "Transportation costs to and from pre-departure orientation training? is required":
      "มีค่าเดินทางไปกลับระหว่างการอบรมก่อนการเดินทางหรือไม่ จำเป็น",
    "Additional information for Pre-departure orientation course":
      "ข้อมูลเพิ่มเติมเกี่ยวกับการอบรมก่อนการเดินทาง",
    "Please fill in additional information for Pre-departure orientation course (number of days, location, kind of training, etc)":
      "กรุณากรอกข้อมูลเพิ่มเติมสำหรับหลักสูตรการอบรมก่อนออกเดินทาง (จำนวนวัน สถานที่ ประเภทของการอบรม ฯลฯ)",
    "Costs and fees for recruitment in the origin country":
      "ค่าใช้จ่ายและค่าธรรมเนียมการจ้างงานในประเทศต้นทาง",
    "Select the origin country currency": "เลือกอัตราแลกเปลี่ยนในประเทศต้นทาง",
    "Origin country Currency": "อัตราแลกเปลี่ยนในประเทศต้นทาง",
    "Recruitment service fee to RA and sub-agent?":
      "มีค่าบริการการจัดหางานให้บริษัทจัดหางานและตัวแทนย่อยหรือไม่",
    "Recruitment service fee to RA and sub-agent? is required":
      "มีค่าบริการการจัดหางานให้บริษัทจัดหางานและตัวแทนย่อยหรือไม่ จำเป็น",
    "Accommodation costs for contract signing": "ค่าที่พักสำหรับการเซ็นสัญญา",
    "Accommodation costs for contract signing is required":
      "ค่าที่พักสำหรับการเซ็นสัญญา จำเป็น",
    "Medical check-up fee?": "มีค่าใช้จ่ายในการตรวจสุขภาพหรือไม่",
    "Medical check-up fee? is required":
      "มีค่าใช้จ่ายในการตรวจสุขภาพหรือไม่ จำเป็น",
    "Passport application fee?": "มีค่าธรรมเนียมการทำพาสปอร์ตหรือไม่",
    "Passport application fee? is required":
      "มีค่าธรรมเนียมการทำพาสปอร์ตหรือไม่ จำเป็น",
    "Overseas Worker Identification Card (OWIC) fee?":
      "มีค่าธรรมเนียมบัตรประจำตัวคนงานต่างด้าว (OWIC) หรือไม่",
    "Overseas Worker Identification Card (OWIC) fee? is required":
      "มีค่าธรรมเนียมบัตรประจำตัวคนงานต่างด้าว (OWIC) หรือไม่ จำเป็น",
    "Travel costs for a job interview?":
      "มีค่าเดินทางสำหรับการสัมภาษณ์งานหรือไม่",
    "Travel costs for a job interview? is required":
      "มีค่าเดินทางสำหรับการสัมภาษณ์งานหรือไม่ จำเป็น",
    "Transportation cost to and from for contract signing?":
      "มีค่าเดินทางไปกลับสำหรับการเซ็นสัญญาหรือไม่",
    "Transportation cost to and from for contract signing? is required":
      "มีค่าเดินทางไปกลับสำหรับการเซ็นสัญญาหรือไม่ จำเป็น",
    "Meals for contract signing?": "มีอาหารสำหรับการเซ็นสัญญาหรือไม่",
    "Meals for contract signing? is required":
      "มีอาหารสำหรับการเซ็นสัญญาหรือไม่ จำเป็น",
    "Transportation costs to the border or airport?":
      "มีค่าเดินทางสำหรับการไปกลับชายแดนหรือสนามบินหรือไม่",
    "Transportation costs to the border or airport? is required":
      "มีค่าเดินทางสำหรับการไปกลับชายแดนหรือสนามบินหรือไม่ จำเป็น",
    "Costs and fees for recruitment in the destination country":
      "ค่าใช้จ่ายและค่าธรรมเนียมสำหรับการจ้างงานในประเทศปลายทาง",
    "Select the desitnation country currency":
      "เลือกอัตราแลกเปลี่ยนในประเทศปลายทาง",
    "Destination country Currency": "อัตราแลกเปลี่ยนในประเทศปลายทาง",
    "Transportation cost from the border or airport to the workplace (or to the destination country)?":
      "มีค่าเดินทางจากชายแดนหรือสนามบินไปยังที่ทำงาน (หรือไปยังประเทศปลายทาง) หรือไม่",
    "Transportation cost from the border or airport to the workplace (or to the destination country)? is required":
      "มีค่าเดินทางจากชายแดนหรือสนามบินไปยังที่ทำงาน (หรือไปยังประเทศปลายทาง) หรือไม่ จำเป็น",
    "Visa fee?": "มีค่าธรรมเนียมวีซ่าหรือไม่",
    "Visa fees is required": "ค่าธรรมเนียมวีซ่าจำเป็น",
    "Work permit?": "มีใบอนุญาตทำงานหรือไม่",
    "Work Permit fees is required": "ใบอนุญาตทำงานจำเป็น",
    "Medical check-up fee (if I have to take another check up)?":
      "มีค่าตรวจสุขภาพหรือไม่ (หากต้องตรวจซ้ำอีก) ",
    "Medical check-up fee (if I have to take another check up)? is required":
      "มีค่าตรวจสุขภาพหรือไม่ (หากต้องตรวจซ้ำอีก)  จำเป็น",
    "Hospitalization Insurance": "ประกันสุขภาพ",
    "Other Costs": "ค่าใช่จ่ายอื่น ๆ",
    "Please provide any additional information on other costs that are not being covered above":
      "กรุณาให้ข้อมูลเพิ่มเติมเกี่ยวกับค่าใช้จ่ายอื่น ๆ ที่ไม่ได้ระบุไว้ข้างต้น",
    "Please provide any additional information on other costs that are not being covered above. ":
      "กรุณาให้ข้อมูลเพิ่มเติมเกี่ยวกับค่าใช้จ่ายอื่น ๆ ที่ไม่ได้ระบุไว้ข้างต้น",
    "Information on repatriation": "ข้อมูลของการส่งตัวกลับประเทศ",
    "Please describe how you and the employer would arrange the repatriation of migrant workers":
      "โปรดอธิบายว่าคุณและนายจ้างจะจัดการเรื่องการส่งตัวแรงงานข้ามชาติกลับประเทศอย่างไร",
    "Please describe how you and the employer would arrange the repatriation of migrant workers after the completion of the contract, if the worker has difficulties performing the job or if workers need to return to the origin country for personal reasons.":
      "โปรดอธิบายว่าคุณและนายจ้างจะจัดเตรียมการส่งตัวแรงงานข้ามชาติกลับประเทศอย่างไรหลังจากสิ้นสุดสัญญา หากแรงงานประสบปัญหาในการทำงาน หรือหากแรงงานจำเป็นต้องเดินทางกลับประเทศต้นทางด้วยเหตุผลส่วนตัว",
    "Employer Name": "ชื่อนายจ้าง",
    "Employer Address": "ที่อยู่นายจ้าง",
    "Employer Size": "ขนาดนายจ้าง",
    "Number of Male Needed": "จำนวนแรงงานชายที่ต้องการ",
    "Number of Female Needed": "จำนวนแรงงานหญิงที่ต้องการ",
    "NRC Card Holder": "ผู้ถือบัตร NRC",
    "OWIC Card Holder": "ผู้ถือบัตร OWIC",
    "SKILL TRAINING INFORMATION": "ข้อมูลการฝึกอบรมทักษะ",
    "Who pays cost of skill-training?":
      "ใครเป็นผู้รับผิดชอบค่าใช้จ่ายในการฝึกอบรมทักษะ",
    "Does the employer provide accommodation during skill training?":
      "นายจ้างให้ที่พักระหว่างการฝึกอบรมหรือไม่",
    "Does the employer provide meals during skill training?":
      "นายจ้างมีอาหารให้ระหว่างการฝึกอบรมหรือไม่",
    "Who pays transportation costs to and from for attending skill training?":
      "ใครเป็นผู้รับผิดชอบค่าใช้จ่ายเรื่องการเดินทางไปกลับในการฝึกอบรม",
    "COSTS FOR PRE-DEPARTURE": "ค่าใช้จ่ายก่อนการออกเดินทาง",
    "Who pays cost of pre-departure orientation training?":
      "ใครเป็นผู้รับผิดชอบค่าใช้จ่ายในการอบรมก่อนการเดินทาง",
    "Who pays for acoomodation during pre-departure orientation training?":
      "ใครเป็นผู้รับผิดชอบเรื่องที่พักระหว่างการอบรมก่อนการออกเดินทาง",
    "Who pays for meals during pre-departure orientation training?":
      "ใครเป็นผู้รับผิดชอบค่าอาหารระหว่างการอบรมก่อนการออกเดินทาง",
    "Who pays the cost of transportation to and from pre-departure orientation training?":
      "ใครเป็นผู้รับผิดชอบค่าเดินทางไปกลับระหว่างการอบรมก่อนการออกเดินทาง",
    "COSTS AND FEES FOR RECRUITMENT IN THE ORIGIN COUNTRY":
      "ค่าใช้จ่ายและค่าธรรมเนียมสำหรับการจ้างงานในประเทศต้นทาง",
    "Who pays the recruitment service fee to RA and sub-agent?":
      "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมให้กับบริษัทจัดหางานและตัวแทนย่อย",
    "Who pays travel costs for a job interview?":
      "ใครเป็นผู้รับผิดชอบค่าเดินทางไปสัมภาษณ์งาน",
    "Who pays accommodation costs for contract signing":
      "ใครเป็นผู้รับผิดชอบค่าใช้จ่ายเรื่องที่พักในระหว่างการเซ็นสัญญา",
    "Who pays transportation cost to and from for contract signing?":
      "ใครเป็นผู้รับผิดชอบค่าเดินทางไปกลับระหว่างการเซ็นสัญญา",
    "Who pays for meals during contract signing?":
      "ใครเป็นผู้รับผิดชอบค่าอาหารระหว่างการเซ็นสัญญา",
    "Who pays medical check-up fee?":
      "ใครเป็นผู้รับผิดชอบค่าใช้จ่ายในการตรวจสุขภาพ",
    "Who pays passport application fee?":
      "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมในการทำพาสปอร์ต",
    "Who pays Overseas Worker Identification Card (OWIC) fee?":
      "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมบัตรประจำตัวแรงานข้ามชาติ (OWIC)",
    "Who pays transportation costs to the border or airport?":
      "ใครเป็นผู้รับผิดชอบค่าเดินทางไปชายแดนหรือไปสนามบิน",
    "COSTS AND FEES FOR RECRUITMENT IN THE DESTINATION COUNTRY":
      "ค่าใช้จ่ายและค่าธรรมเนียมสำหรับการจ้างงานในประเทศปลายทาง",
    "Who pays transportation cost from the border or airport to the workplace (or to the destination country)?":
      "ใครเป็นผู้รับผิดชอบค่าเดินทางจากชายแดนหรือจากสนามบินไปที่ทำงาน (หรือไปประเทศปลายทาง)",
    "Who pays Visa fee?": "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมในการออกวีซ่า",
    "Who pays work permit?":
      "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมในการออกใบอนุญาตทำงาน",
    "Who pays medical check-up fee (if I have to take another check up)?":
      "ใครเป็นผู้รับผิดชอบค่าตรวจสุขภาพ (ถ้าต้องตรวจอีกครั้ง)",
    "Who pays for Hospitalization Insurance?":
      "ใครเป็นผู้รับผิดชอบค่าประกันสุขภาพ",
    "COVID-19 RELATED COSTS AND PROCEDURES":
      "ค่าใช้จ่ายและกระบวนการที่เกี่ยวกับโควิด",
    "Who pays for the mandatory PCR test fee prior to departure?":
      "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมในการตรวจ PCR ภาคบังคับก่อนออกเดินทาง",
    "Who pays the PCR test fee on Thailand side?":
      "ใครเป็นผู้รับผิดชอบค่าธรรมเนียมในการตรวจ PCR ในประเทศไทย",
    "Who pays for the quarantine in the destination country?":
      "ใครเป็นผู้รับผิดชอบค่าใช้จ่ายในการกักตัวในประเทศปลายทาง",
    "Who pays for the Covid-19 insurance fee in the destination country?":
      "ใครเป็นผู้รับผิดชอบค่าประกันโควิดใครประเทศปลายทาง",
    "Who pays for the Covid-19 vaccination fee?":
      "ใครเป็นผู้รับผิดชอบค่าฉีดวัคซีนโควิด",
    "Other costs": "ค่าใช้จ่ายอื่นๆ",
    "Meals Allowance": "ค่าอาหาร",
    "Any additional leave detail": "รายละเอียดการลาเพิ่มเติมใด ๆ",
    "Edit & Post": "แก้ไข และ โพสต์",
    "Account:": "บัญชี:",
    "Type:": "ประเภท:",
    "Email:": "อีเมล:",
    "Joined:": "เข้าร่วม:",
    "Updated:": "อัพเดท:",
    "Please Select An Employer To Fill Employer's Information":
      "กรุณาเลือกนายจ้างเพื่อกรอกข้อมูลของนายจ้าง",
    "Please Select An Employer": "โปรดเลือกนายจ้าง",
    "Select Size": "เลือกขนาด",
    "Please input title": "โปรดระบุตำแหน่ง",
    "Additional information": "ข้อมูลเพิ่มเติม",
    "Profile:": "โปรไฟล์",
    "Address was successfully updated.": "ที่อยู่ถูกอัพเดทเรียบร้อยแล้ว",
    "Something went wrong while updating the address. Please contact a developer":
      "มีข้อผิดพลาดเกิดขึ้นระหว่าง อัพเดทข้อมูลที่อยู่ โปรดติดต่อทีมงานของเรา",
    "Please enter Main Contact Name": "โปรดระบุชื่อผู้ติดต่อหลัก",
    "Company name": "ชื่อบริษัท",
    "Please enter Company name": "โปรดระบุชื่อบริษัท",
    "Please select Working since": "กรุณาเลือก การทำงานตั้งแต่",
    "License number": "หมายเลขใบอนุญาต",
    "Please enter License number": "กรุณากรอกหมายเลขใบอนุญาต",
    "Max length of the license number is 12":
      "ความยาวสูงสุดที่ใส่ได้ 12 ตัวอักษร",
    "Valid till": "วันหมดอายุ",
    "Please select Valid till": "กรุณาเลือกวันหมดอายุ",
    "Thai License number": "หมายเลขใบอนุญาตของไทย",
    "Please select Thai License Number": "โปรดเลือกหมายเลขใบอนุญาตของไทย",
    "Thai License Expiry Date": "วันหมดอายุใบอนุญาตของไทย",
    "Please select Thai License Expiry Date":
      "กรุณาเลือกวันหมดอายุใบอนุญาตของไทย",
    "Please describe the years of experience to specific industries to which countries, the years of experience to specific industries to which countries with zero costs and sub agents information":
      "โปรดอธิบายจำนวนปีของประสบการณ์ในการทำงานในอุตสาหกรรมเฉพาะ ไปยังประเทศใด จำนวนปีของประสบการณ์ในการทำงานในอุตสาหกรรมเฉพาะไปยังประเทศใด โดยไม่มีค่าใช้จ่ายใดๆ และข้อมูลตัวแทนย่อย",
    "About us": "เกี่ยวกับเรา",
    "Discard changes": "ยกเลิกการเปลี่ยนแปลง",
    Save: "บันทึก",
    "Main contact name:": "ชื่อผู้ติดต่อ:",
    "Company name:": "ชื่อบริษัท:",
    "Working since:": "เริ่มทำงานตั้งแต่:",
    "License number:": "หมายเลขใบอนุญาต",
    "Valid till:": "วันหมดอายุ:",
    "Thai license number:": "หมายเลขใบอนุญาตของไทย:",
    "Thai license expiry date:": "วันหมดอายุใบอนุญาตของไทย:",
    "Business Images": "รูปบริษัท",
    "Upload Cover Photo": "อัปโหลดรูป",
    "Please enter email": "โปรดระบุอีเมลล์",
    "Please enter Phone Number": "โปรดระบุหมายเลขโทรศัพท์",
    "Add more": "เพิ่ม",
    "Website:": "Website:",
    "Facebook:": "Facebook:",
    "Line:": "Line:",
    "Viber:": "Viber:",
    "Phone numbers:": "หมายเลขโทรศัพท์:",
    "Please select Country, State, City": "โปรดเลือกประเทศ จังหวัด เขต",
    "Country:": "ประเทศ:",
    "State/Province:": "จังหวัด:",
    "City/District:": "เขต:",
    "Street:": "ถนน:",
    "Zip:": "รหัสไปรษณีย์",
    "Issara will contact you to verify that you actually the owner of this business":
      "ทางสถาบันอิสราจะติดต่อกลับเพื่อยืนยันว่าคุณเป็นเจ้าของธุรกิจนี้",
    "Golden Dreams": "Golden Dreams",
    "Golden Dreams Marketplace ©{date} Created by {issara}":
      "Golden Dreams Marketplace ©{date} พัฒนาโดยมูลนิธิสถาบันอิสรา",
    "Privacy Policy": "นโยบายความเป็นส่วนตัว",
    Disclaimer: "ข้อความปฏิเสธความรับผิด",
    "Issara Staff": "ทีมงานอิสรา",
    Recruiter: "นายหน้าจัดหางาน",
    "Reset your password": "เปลี่ยนรหัสผ่านของคุณ",
    "The input is not valid Email": "อีเมลที่คุณใส่ไม่ถูกต้อง",
    "Please input your Email": "โปรดใส่อีเมลของคุณ",
    "Send reset link": "ส่งลิงค์",
    "We will send password reset link to this email address, please change your password within 24 hours.":
      "เราจะจัดส่งลิงค์เปลี่ยนรหัสผ่านไปยังอีเมล โปรดเปลี่ยนรหัสผ่านภายใน 24 ชั่วโมง",
    "Golden Dreams Job Marketplace": "Golden Dreams Job Marketplace",
    "Powered by Issara Institute": "พัฒนาโดยมูลนิธิสถาบันอิสรา",
    "Register and login here to modernize and digitize how your business manages labour recruitment ->":
      "ลงทะเบียนและเข้าใช้งาน เพื่อให้ธรุกิจของคุณบริหารจัดการการจ้างแรงงานในรูปแบบดิจิตอลที่ทันสมัย",
    Login: "เข้าใช้งาน",
    Password: "รหัสผ่าน",
    "Forgot Password?": "ลืมรหัสผ่าน?",
    "Please input your password!": "โปรดใส่รหัสผ่าน",
    "Not registered?": "ยังไม่ได้ลงทะเบียน?",
    "Create an account": "สร้างบัญชี",
    "Oops! You tried to log in with a job seeker account. Please check and try again.":
      "ขออภัย คุณพยายามเข้าสู่ระบบด้วยบัญชีผู้หางาน กรุณาตรวจสอบและลองอีกครั้ง",
    "Log in with Business Account": "ล็อกอินด้วยบัญชีธุรกิจ",
    "Sorry, you are not authorized to access this page.":
      "ขออภัย คุณไม่ได้รับอนุญาตให้เข้าหน้านี้",
    "Use another account": "ใช้บัญชีอื่น",
    "Go back to console": "ไปยังหน้าหลัก",
    "Sorry, the page you visited does not exist.":
      "ขออภัย ไม่ปรากฎหน้าที่คุณเยี่ยมชม",
    "Back Home": "กลับไปหน้าแรก",
    "Successfully changed password": "เปลี่ยนรหัสผ่านเรียบร้อยแล้ว",
    "You'll be redirected to login page shortly":
      "คุณจะถูกส่งกลับไปที่หน้าเข้าใช้งานในไม่ช้า",
    "Go to login page": "ไปที่หน้าเข้าใช้งาน",
    "Password reset failed": "การเปลี่ยนรหัสผ่านล้มเหลว",
    "The reset link has expired. Please request a new one.":
      "ลิงค์หมดอายุ โปรดขอลิงค์ใหม่",
    "New Password": "รหัสผ่านใหม่",
    "Confirm Password": "ยืนยันรหัสผ่าน",
    "Please confirm your password!": "โปรดยืนยันรหัสผ่าน!",
    "The two passwords that you entered do not match!":
      "รหัสผ่านที่คุณใส่ไม่ตรงกัน!",
    "Update password": "เปลี่ยนรหัสผ่าน",
    "Create Account": "สร้างบัญชี",
    "We are:": "เราคือ:",
    "Please select your business type!": "โปรดเลือกประเภทของธรุกิจ!",
    "Recruitment Agency": "บริษัทจัดหางาน",
    "Business Name": "ชื่อบริษัท",
    "What is your offical business name?":
      "ชื่ออย่างเป็นทางการของบริษัทคุณคือ?",
    "Please input your business name!": "โปรดใส่ชื่อบริษัทของคุณ",
    "The input is not valid E-mail!": "อีเมลที่คุณใส่ไม่ถูกต้อง",
    "Please input your E-mail!": "โปรดใส่อีเมลของคุณ",
    "Create your free account": "สร้างบัญชีฟรี",
    "By clicking register, you agree to our {privacy_policy}.":
      "เมื่อลงทะเบียน หมายความว่าคุณยอมรับ {privacy_policy} ของเรา",
    "privacy policy": "นโยบายความเป็นส่วนตัว",
    "Already have an account?": "มีบัญชีแล้วหรือยัง?",
    "Login here": "เข้าใช้งานที่นี่",
    "Email verification in progress..": "กำลังยืนยันอีเมล",
    "Successfully verified email": "ยืนยันอีเมลเรียบร้อยแล้ว",
    "Please follow further instructions in the console":
      "โปรดปฏิบัติตามคำสั่งในหน้าหลัก",
    "Go to console": "ไปยังหน้าหลัก",
    "Email verification failed": "การยืนยันอีเมลไม่สำเร็จ",
    "This link has already expired.": "ลิงค์นี้หมดอายุแล้ว",
  },
};
